import { useTranslation } from 'react-i18next';

export const InputSearch = props => {

  const { t } = useTranslation()

  return (
    <input
      type='search'
      className='form-control'
      placeholder={t('search')}
      {...props}
    />
  )


}
