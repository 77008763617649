import React from 'react';
import { Trans } from 'react-i18next';
import DateDisplay from '../../components/UI/Displays/DateDisplay';

const LogLevel = ({ value }) => {
  switch (value.toLowerCase()) {
    case 'emergency':
    case 'critical':
    case 'error':
      return <span className="text-danger fw-bold">{value}</span>
    case 'alert':
    case 'warning':
      return <span className="text-warning fw-bold">{value}</span>
    case 'notice':
      return <span className="text-success fw-bold">{value}</span>
    case 'info':
      return <span className="text-info fw-bold">{value}</span>
    case 'debug':
    default:
      return <span className="text-muted fw-bold">{value}</span>
  }
}


const logsTableConfig = [{
  Header: <Trans i18nKey="log_messages.level_name"/>,
  accessor: 'level_name',
  Cell: ({ row, value }) => <LogLevel value={value}/>,
}, {
  Header: <Trans i18nKey="log_messages.logged_at"/>,
  accessor: 'logged_at',
  Cell: ({ row, value }) => <DateDisplay value={value} formatString={"dd/MM/yyyy HH:ii:ss"}/>,
}, {
  Header: <Trans i18nKey="log_messages.message"/>,
  accessor: 'message'
}]

export default logsTableConfig;
