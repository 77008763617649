import React from 'react';
import FormGroup from '../../FormGroup';
import { FormInput } from '../../FormInput';
import { useTranslation } from 'react-i18next';

const LocationPickerForm = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className='row'>
        <div className="col-md-8">
          <FormGroup name="route" label={t("locations.route")} inline={false}>
            <FormInput name="route"/>
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup name="street_number" label={t("locations.street_number")} inline={false}>
            <FormInput name="street_number"/>
          </FormGroup>
        </div>
      </div>

      <div className='row'>
        <div className="col-md-8">
          <FormGroup name="locality" label={t("locations.locality")} inline={false} required>
            <FormInput name="locality"/>
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup name="postal_code" label={t("locations.postal_code")} inline={false}>
            <FormInput name="postal_code"/>
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup name="administrative_area_level_1" label={t("locations.administrative_area_level_1")} inline={false}>
            <FormInput name="administrative_area_level_1"/>
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup name="administrative_area_level_2" label={t("locations.administrative_area_level_2")} inline={false}>
            <FormInput name="administrative_area_level_2"/>
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup name="administrative_area_level_3" label={t("locations.administrative_area_level_3")} inline={false}>
            <FormInput name="administrative_area_level_3"/>
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup name="country" label={t("locations.country")} inline={false} required>
            <FormInput name="country"/>
          </FormGroup>
        </div>
      </div>
    </>
  )

}

export default LocationPickerForm
