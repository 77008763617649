import React from 'react';

export const WorkContext = React.createContext({
  work: undefined,
  production_states: undefined,
  commercial_states: undefined,
  history: [],
  part_categories: [],
  color_parts: [],
  media: []
})
