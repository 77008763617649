import React from 'react';
import OfferPartCategory from './OfferPartCategory';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import { List } from '../../../../../components/UI/ItemList/List';

const OfferPartCategories = ({ partCategories = [] }) => {

  return (
    <Card>
      <CardBody>
        <CardLabel>Marginalità per categoria</CardLabel>
        <List>
          {partCategories.map(pc => <OfferPartCategory
            key={pc.uuid}
            partCategory={pc}/>
          )}
        </List>
      </CardBody>
    </Card>
  )

}

export default OfferPartCategories
