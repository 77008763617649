import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import * as Yup from 'yup';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import WordsSort from '../../BaseCodes/components/WordsSort/WordsSort';
import PartMeasures from '../components/PartMeasures';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import DisplayFormattedMeasures from './components/DisplayFormattedMeasures';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import partsService from '../../../services/parts/partsService';
import partTypeEnum from '../../../constants/enums/PartTypeEnum';
import { toast } from 'react-toastify';

const initialValues = {
  words: [],
  width: '',
  depth: '',
  height: '',
  note: '',
  drawing_note: '',
}

const validationSchema = yup.object().shape({
  words: Yup.array().min(1).required(),
  width: Yup.number().min(0).nullable().notRequired(),
  depth: Yup.number().min(0).nullable().notRequired(),
  height: Yup.number().min(0).nullable().notRequired(),
  note: yup.string().max(800).nullable().notRequired(),
  drawing_note: yup.string().max(200).nullable().notRequired(),
})

function CustomCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();


  const handleSubmit = (values, { setSubmitting }) => partsService.store(partTypeEnum.values.CUSTOM, {
    ...values,
    part_id: location?.state?.partId,
    words: values.words.map(w => w.value)
  }).then(res => {
    toast.success(t('created'));
    navigate(`../${res.uuid}`);
  })
  .catch(err => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false));


  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form>

        <h5 className="text-uppercase">{t('enums.part_types.CUSTOM')}</h5>

        <div className='card'>
          <div className='card-body'>

            <div className='row'>
              <div className='col-lg-4'>
                <FormDisplay label={t('parts.code')}>
                  3DFC0XXXXXX
                </FormDisplay>
                {location?.state?.partId && <>

                  <FormDisplay label={t('parts.part_id')}>
                    {location.state.partCode}<br />
                    {location.state.partDescription}
                  </FormDisplay>

                </>}
              </div>
              <div className='col-lg-8'>
                <FormDisplay label={t('description')}>
                  {formik.values.words.map(w => w.label).join(' ')}
                  &nbsp;
                  <DisplayFormattedMeasures
                    width={formik.values.width}
                    depth={formik.values.depth}
                    height={formik.values.height}
                  />
                </FormDisplay>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4'>

              </div>
              <div className='col-lg-4'>
                <WordsSort/>
              </div>
              <div className='col-lg-4'>
                <PartMeasures/>
              </div>
            </div>

            <FormGroup name="note" label={t('note')}>
              <FormInput name="note" rows={5} as="textarea"/>
            </FormGroup>

            <FormGroup name="drawing_note" label={t('parts.drawing_note')}>
              <FormInput name="drawing_note" rows={3} as="textarea"/>
            </FormGroup>

            <div className="d-flex align-items-center justify-content-end gap-2">
              <CancelButton onClick={() => navigate('/parts/customs')}/>
              <FormikSaveButton/>
            </div>

          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default CustomCreate;
