import React from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

export function FormSearch(props) {
  return (
    <InputGroup className="input-group-sm">
      <InputGroupText>
        <i className='fa fa-search'/>
      </InputGroupText>
      <Input
        className="form-control"
        type="search"
        {...props}
      />
    </InputGroup>
  )
}
