import React, { useContext } from 'react';
import Drawer from '../../../../../components/UI/Drawer/Drawer';
import locationPickerFormConfig from '../../../../../components/UI/Forms/Pickers/LocationPicker/locationPickerInitialValues';
import { Form, FormikProvider, useFormik } from 'formik';
import { AppContext } from '../../../../../components/AppContext';
import * as Yup from 'yup';
import LocationPickerForm from '../../../../../components/UI/Forms/Pickers/LocationPicker/LocationPickerForm';
import FormGroup from '../../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../../components/UI/Forms/FormInput';
import { useTranslation } from 'react-i18next';
import { FormDisplay } from '../../../../../components/UI/Displays/FormDisplay';
import LocationPicker from '../../../../../components/UI/Forms/Pickers/LocationPicker/LocationPicker';
import { FormActions } from '../../../../../components/UI/Forms/FormActions';
import worksService from '../../../../../services/works/worksService';
import { toast } from 'react-toastify';
import { WorkContext } from '../../WorkContext/WorkContext';

const validationSchema = Yup.object().shape({
  label: Yup.string().max(255).nullable().required(),
  locality: Yup.string().max(255).required(),
  country: Yup.string().max(255).required(),
});

const WorkTriangulationDrawer = ({ isOpen, setIsOpen, workLocation = undefined, onUpdate }) => {

  const { t } = useTranslation()
  const {work} = useContext(WorkContext)
  const { setLoadingOverlayState } = useContext(AppContext)
  const initialValues = {
    label: workLocation?.label ?? '',
    ...locationPickerFormConfig.setLocationPickerInitialValues(workLocation)
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setLoadingOverlayState(true)
    worksService.storeWorkLocation(work.uuid, values)
    .then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => {
      setLoadingOverlayState(false)
      setSubmitting(false)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title='Trinagolazione'
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="label" label={t("locations.label")} required inline={false}>
            <FormInput name="label"/>
          </FormGroup>

          <div className="mb-3">
            <FormDisplay label={t("search")}>
              <LocationPicker onPlaceSelected={place => {
                formik.setValues(values => ({
                  ...values,
                  ...place
                }))
              }}/>
            </FormDisplay>
          </div>

          <LocationPickerForm/>

          <FormActions withReset={true} onCancel={() => setIsOpen(false)}/>

        </Form>
      </FormikProvider>
    </Drawer>
  )
}

export default WorkTriangulationDrawer;
