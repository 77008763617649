import {
  layoutModeTypes,
  layoutWidthTypes,
  leftBarThemeImageTypes,
  leftSideBarThemeTypes,
  leftSidebarTypes,
  topBarThemeTypes,
} from '../constants/layout';

const layoutConfig = {
  layoutModeType: layoutModeTypes.LIGHT,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,

  showRightSidebar: false,
  showLeftSidebar: true,

  toastConfig: {
    position: 'top-right',
    autoClose: 2500,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: false,
    pauseOnHover: true,
    theme: 'light',
  },
};

export default layoutConfig;
