import React from 'react';
import OfferProvider from './offerContext/OfferProvider';
import OfferRevision from './OfferRevisions/OfferRevision';


const Offer = () => {

  return (
    <OfferProvider>
      <OfferRevision/>
    </OfferProvider>
  )
}

export default Offer
