import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';
import DateDisplay from '../../../components/UI/Displays/DateDisplay';
import DelayDisplay from '../../../components/UI/Displays/DelayDisplay';
import WorkStateEnumDisplay from '../../../components/UI/Displays/WorkStateEnumDisplay';
import WorkStateEnum from '../../../constants/enums/WorkStateEnum';
import MarketDisplay from '../../../components/UI/Displays/MarketDisplay';
import { Tag } from '../../../components/UI/Tags/Tag';
import { TagList } from '../../../components/UI/Tags/TagList';

const workTableConfig = [{
  Header: <Trans i18nKey='works.sing'/>,
  accessor: 'number',
  Cell: ({ row, value }) =>
    <div className='d-flex flex-column'>
      <h3 className='mb-0'>
        <TableLink
          to={`/works/${row.original.uuid}`}
          value={value}
          className={`${row.original.state.state === WorkStateEnum.values.recovery && 'text-danger'}`}
        />
      </h3>
      {row.original.description}
    </div>
}, {
  Header: <Trans i18nKey="works.state"/>,
  accessor: 'state',
  disableSortBy: true,
  Cell: ({ value }) => <WorkStateEnumDisplay state={value}/>
}, {
  Header: <Trans i18nKey="works.sap_orders"/>,
  accessor: 'sap_orders',
  disableSortBy: true,
  Cell: ({ value }) => value.length > 0 ?
    <div className='d-flex justify-content-start align-items-center gap-1'>
      {value?.map((so, index) => <Tag key={so}>{so}</Tag>)}
    </div> : '---'

}, {
  Header: <Trans i18nKey="works.production_state"/>,
  accessor: 'production_state',
  disableSortBy: true,
  Cell: ({ value }) => <WorkStateEnumDisplay state={value}/>
}, {
  Header: <Trans i18nKey='works.customer'/>,
  disableSortBy: true,
  accessor: 'company',
  Cell: ({ value, row }) =>
    <div className='d-flex flex-column'>
      <TableLink to={`/companies/${value?.uuid}`} value={value?.business_name}/>
      {row.original.company_location &&
        <span className="fw-bold">{row.original.company_location?.label}</span>}
      {row.original.company_person && <span>{row.original.company_person?.full_name}</span>}
    </div>
}, {
  Header: <Trans i18nKey='works.delivery_date'/>,
  accessor: 'delivery_date',
  Cell: ({ value }) => <DateDisplay value={value}/>
}, {
  Header: <Trans i18nKey='works.forecast_date'/>,
  accessor: 'forecast_date',
  Cell: ({ value }) => <DateDisplay value={value}/>
}, {
  Header: <Trans i18nKey='Tags'/>,
  accessor: 'tags',
  Cell: ({ value }) => <TagList tags={value} color='dark'/>
}, {
  Header: <Trans i18nKey='market'/>,
  accessor: 'company.market',
  disableSortBy: true,
  Cell: ({ value }) => <MarketDisplay market={value}/>
}, {
  Header: <Trans i18nKey='works.delivery_status'/>,
  disableSortBy: true,
  accessor: 'delivery_status',
  Cell: ({ value }) => <DelayDisplay value={value}/>
}];

export default workTableConfig;
