import React, { useState } from 'react';
import { Alert, } from 'reactstrap';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup'
import { authenticationService as authService } from '../../services/authenticationService';
import FormGroup from '../../components/UI/Forms/FormGroup';
import { FormInput } from '../../components/UI/Forms/FormInput';
import FormikSaveButton from '../../components/UI/Buttons/Save/FormikSaveButton';

const ForgotPassword = props => {

  const [message, setMessage] = useState(undefined)
  const initialValues = { email: '' }
  const validation = Yup.object().shape({ email: Yup.string().email().required() })


  return (
    <div style={{ width: '340px' }}>

      <h5 className="text-center my-3"><i className="fa fa-envelope me-1"/>Request reset password</h5>

      {message &&
        <Alert color={message?.errors ? 'danger' : 'success'}>{message?.message}</Alert>}

      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setMessage(undefined)
          authService.requestPasswordLink(values.email)
          .then(res => {
            setMessage(res)
            resetForm()
          })
          .catch(err => {
            setMessage(err)
          }).finally(() => {
            setSubmitting(false)
          })
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <FormGroup required={true} name='email' inline={false} label='Email'>
              <FormInput name='email' type='email'/>
            </FormGroup>
            <div className="d-flex align-items-center justify-content-between">
              <FormikSaveButton
                color="primary"
                label="Get reset link"
              />
              <Link to="/auth/login">Login</Link>
            </div>
          </Form>
        )}
      </Formik>

    </div>
  );
}

export default ForgotPassword;
