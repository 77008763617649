import TableLink from '../../../../components/UI/Buttons/TableLink';
import EuroDisplay from '../../../../components/UI/Displays/EuroDisplay';
import PartStateEnumDisplay from '../../../../components/UI/Displays/Parts/PartStateEnumDisplay';
import DateDisplay from '../../../../components/UI/Displays/DateDisplay';
import { Trans } from 'react-i18next';

const johnDoePartTableConfig = [{
  Header: <Trans i18nKey="parts.code"/>,
  accessor: 'code',
  Cell: ({ row, value }) => <TableLink to={`/parts/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="description"/>,
  accessor: 'description',
  disableSortBy: true
}, {
  Header: <Trans i18nKey="parts.drawing_note"/>,
  accessor: 'drawing_note',
  disableSortBy: true
}, {
  Header: <Trans i18nKey="parts.cost"/>,
  accessor: 'cost',
  Cell: ({ value }) => <EuroDisplay value={value}/>
}, {
  Header: <Trans i18nKey="parts.price"/>,
  accessor: 'price',
  Cell: ({ value }) => <EuroDisplay value={value}/>
},
  {
    Header: <Trans i18nKey="part_categories.sing"/>,
    accessor: 'part_category',
    Cell: ({ value }) => <span className='fw-bold text-nowrap'
                               style={{ color: value?.color }}>{value ? value?.label + " - " + parseFloat((value?.margin * 100).toFixed(2)) + '%' : '---'}</span>
  },
  {
    Header: <Trans i18nKey="parts.margin"/>,
    accessor: 'margin',
    Cell: ({ value }) => <span className='fw-bold text-nowrap'>{value ? value * 100 : '---'}%</span>
  }, {
    Header: <Trans i18nKey="parts.state"/>,
    accessor: 'state',
    disableSortBy: true,
    Cell: ({ value }) => <PartStateEnumDisplay state={value} onlyDot={true}/>,
  }, {
    Header: <Trans i18nKey="updated"/>,
    accessor: 'updated_at',
    Cell: ({ value }) => <DateDisplay value={value} formatString='dd/MM/yyyy HH:mm'/>
  }];

export default johnDoePartTableConfig;
