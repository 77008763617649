import React from 'react';
import { useTranslation } from 'react-i18next';
import AssyStateEnum from '../../../../constants/enums/AssyStateEnum';
import { FormSelect } from '../FormSelect';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';

function AssyStatePicker({ name, handleChange }) {

  const { t } = useTranslation();

  const formatOptions = () => AssyStateEnum.collection.map(ase => ({
    ...ase,
    label: `${ase.value} - ${t(ase.label)}`,
  }));

  return (
    <FormSelect
      name={name}
      multiple
      styles={reactSelectStyles}
      onChange={(value) => handleChange(value)}
      options={formatOptions()}
    />
  );
}

export default AssyStatePicker;
