import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDetailsDrawer } from './CompanyDetailsDrawer';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import EditButton from '../../../../components/UI/Buttons/EditButton';
import { List } from '../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../components/UI/ItemList/ListItem';
import { TagList } from '../../../../components/UI/Tags/TagList';
import { ListContactsItem } from '../../../../components/UI/ItemList/ListContactsItem';
import MarketDisplay from '../../../../components/UI/Displays/MarketDisplay';

export const CompanyDetails = ({ company, setCompany }) => {

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = res => setCompany(c => ({ ...c, ...res }))

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('details')}</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>
          <List>
            <ListItem label={t('market')}>
              <MarketDisplay market={company.market} />
            </ListItem>
            <ListItem label={t('Tags')}>
              <TagList tags={company.tags}/>
            </ListItem>
            <ListItem label={t('companies.fiscal_code')}>
              {company?.fiscal_code}
            </ListItem>
            <ListItem label={t('companies.sdi')}>
              {company?.sdi}
            </ListItem>
            <ListItem label={t('companies.sap_code')}>
              {company?.sap_code}
            </ListItem>
            <ListContactsItem
              label={t('contacts.plural')}
              contacts={company.contacts}
            />
            <ListItem label={t('note')}>
              {company?.note}
            </ListItem>
          </List>
        </CardBody>
      </Card>

      <CompanyDetailsDrawer
        company={company}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleUpdate={handleUpdate}
      />
    </>
  )

}
