import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import MultipleTextFilter from '../../../components/UI/Filters/MultipleTextFilter';


const ColorFilters = ({ handleUpdate, defaultValues }) => {

  const { t } = useTranslation()
  const [values, setValues] = React.useState(defaultValues)
  const getValue = name => get(values, name, values[name])
  const setValue = (name, value) => setValues(v => ({ ...v, [name]: value }))
  const handleSubmit = () => handleUpdate(values)

  return (
    <>
      <div className='row mb-1'>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('supplier_code')}
              setValue={setValue}
              name='supplier_code'
              label={t('colors.supplier_code')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('description')}
              setValue={setValue}
              name='description'
              label={t('description')}
            />
          </div>
        </div>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('supplier_business_name')}
              setValue={setValue}
              name='supplier_business_name'
              label={t('colors.supplier_business_name')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('supplier_material_code')}
              setValue={setValue}
              name='supplier_material_code'
              label={t('colors.supplier_material_code')}
            />
          </div>
        </div>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('sap_code')}
              setValue={setValue}
              name='sap_code'
              label={t('colors.sap_code')}
            />
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-end gap-2 mb-3'>
        <button className='btn btn-primary' type='button' onClick={handleSubmit}>
          <i className='fa fa-filter'/>&nbsp;{t('search')}
        </button>
      </div>
    </>
  )

}

export default ColorFilters
