import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../../components/UI/Drawer/Drawer';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { FormPercentage } from '../../../../components/UI/Forms/FormPercentage';
import FormGroup from '../../../../components/UI/Forms/FormGroup';
import { FormActions } from '../../../../components/UI/Forms/FormActions';
import companiesService from '../../../../services/companies/companiesService';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  beta_pricing_discount: Yup.number().min(0).max(100).nullable().required(),
  internal_pricing_discount: Yup.number().min(0).max(100).nullable().required(),
  internal_pricing_increment: Yup.number().min(0).max(100).nullable().required(),
})

export const CompanyPriceListDrawer = ({ company, onUpdate, isOpen, setIsOpen }) => {

  const { t } = useTranslation()

  const initialValues = {
    beta_pricing_discount: company?.beta_pricing_discount ?? 0,
    internal_pricing_discount: company?.internal_pricing_discount ?? 0,
    internal_pricing_increment: company?.internal_pricing_increment ?? 0,
  }

  const handleSubmit = (values, { setSubmitting }) => {
    companiesService.update(company?.uuid, values)
    .then(res => onUpdate(res))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  }

  const handleClose = () => {
    formik.resetForm()
    setIsOpen(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  return (
    <Drawer
      status={isOpen}
      toggle={handleClose}
      title={t('companies.pricing')}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="beta_pricing_discount" label={t('companies.beta_pricing_discount')}>
            <FormPercentage name='beta_pricing_discount'/>
          </FormGroup>
          <FormGroup name="beta_pricing_discount" label={t('companies.internal_pricing_discount')}>
            <FormPercentage name='internal_pricing_discount'/>
          </FormGroup>
          <FormGroup name="beta_pricing_discount" label={t('companies.internal_pricing_increment')}>
            <FormPercentage name='internal_pricing_increment'/>
          </FormGroup>
          <FormActions onCancel={handleClose} withReset={true}/>
        </Form>
      </FormikProvider>
    </Drawer>
  )

}
