import React, { useContext } from 'react';
import Drawer from '../../../../../components/UI/Drawer/Drawer';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import FormGroup from '../../../../../components/UI/Forms/FormGroup';
import { useTranslation } from 'react-i18next';
import CompanyPicker from '../../../../../components/UI/Forms/Pickers/CompanyPicker';
import { WorkContext } from '../../WorkContext/WorkContext';
import { AppContext } from '../../../../../components/AppContext';
import worksService from '../../../../../services/works/worksService';
import { FormActions } from '../../../../../components/UI/Forms/FormActions';
import { toast } from 'react-toastify';


const WorkPartnersDrawer = ({ isOpen, setIsOpen, companies, onUpdate }) => {

  const { work } = useContext(WorkContext)
  const { t } = useTranslation();
  const { setLoadingOverlayState } = useContext(AppContext)
  const initialValues = {
    partners: companies.partners,
    agents: companies.agents,
    distributors: companies.distributors,
    signalmen: companies.signalmen,
    brand_ambassadors: companies.brand_ambassadors,
  }
  const validationSchema = Yup.object().shape({
    partners: Yup.array().required(),
    agents: Yup.array().required(),
    distributors: Yup.array().required(),
    signalmen: Yup.array().required(),
    brand_ambassadors: Yup.array().required(),
  })

  const handleSubmit = (values, { setSubmitting }) => {
    setLoadingOverlayState(true)

    worksService.syncCompanies(work.uuid, {
      partners: values.partners.map(u => u.value),
      agents: values.agents.map(u => u.value),
      distributors: values.distributors.map(u => u.value),
      signalmen: values.signalmen.map(u => u.value),
      brand_ambassadors: values.brand_ambassadors.map(u => u.value),
    })
    .then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title='Partners'
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="partners" label={t('enums.company_partnership.PARTNER')} inline={false}>
            <CompanyPicker
              handleChange={values => formik.setFieldValue("partners", values)}
              value={formik.values.partners}
              isMulti={true}
            />
          </FormGroup>
          <FormGroup name="agents" label={t('enums.company_partnership.AGENT')} inline={false}>
            <CompanyPicker
              handleChange={values => formik.setFieldValue("agents", values)}
              value={formik.values.agents}
              isMulti={true}
            />
          </FormGroup>
          <FormGroup name="distributors" label={t('enums.company_partnership.DISTRIBUTOR')} inline={false}>
            <CompanyPicker
              handleChange={values => formik.setFieldValue("distributors", values)}
              value={formik.values.distributors}
              isMulti={true}
            />
          </FormGroup>
          <FormGroup name="brand_ambassadors" label={t('enums.company_partnership.BRAND_AMBASSADOR')} inline={false}>
            <CompanyPicker
              handleChange={values => formik.setFieldValue("brand_ambassadors", values)}
              value={formik.values.brand_ambassadors}
              isMulti={true}
            />
          </FormGroup>
          <FormGroup name="signalmen" label={t('enums.company_partnership.SIGNALMAN')} inline={false}>
            <CompanyPicker
              handleChange={values => formik.setFieldValue("signalmen", values)}
              value={formik.values.signalmen}
              isMulti={true}
            />
          </FormGroup>

          <FormActions
            withReset={true}
            onCancel={() => setIsOpen(false)}
          />
        </Form>
      </FormikProvider>

    </Drawer>
  )

}

export default WorkPartnersDrawer
