import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';
import localesConfig from './locales.config';
import { localeValidation } from './localeValidation';

const applicationLanguage = localStorage.getItem(localesConfig.I18N_LANGUAGE);
if (!applicationLanguage) {
  localStorage.setItem(localesConfig.I18N_LANGUAGE, localesConfig.defaultFallback);
}

const translationLanguage = localStorage.getItem(localesConfig.TRANSLATION_LANGUAGE);
if (!translationLanguage) {
  localStorage.setItem(localesConfig.TRANSLATION_LANGUAGE, 'en');
}

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: localesConfig.resources,
    keySeparator: '.',
    lng: localStorage.getItem(localesConfig.I18N_LANGUAGE) || localesConfig.defaultFallback,
    fallbackLng: localesConfig.defaultFallback,
  });

setLocale(localeValidation);

export default i18n;
