import React from 'react';
import { Trans } from 'react-i18next';
import UserState from './UserState';
import UserDisplay from '../../../components/UI/Displays/UserDisplay';
import { Link } from 'react-router-dom';

const userTableConfig = [{
  Header: <Trans i18nKey="users.sing"/>,
  accessor: 'full_name',
  Cell: ({ row, value }) =>
    row.original.deleted_at !== null
      ? <UserDisplay user={row.original}/>
      : <Link to={`/users/${row.original.uuid}`}><UserDisplay user={row.original}/></Link>,
}, {
  Header: <Trans i18nKey="users.state"/>,
  accessor: "deleted_at",
  Cell: ({ value }) => <UserState deleted_at={value}/>
}];
export default userTableConfig;
