import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ColorDetailsDrawer from './ColorDetailsDrawer';
import { AppContext } from '../../../../components/AppContext';

const ColorDetails = ({ color, setColor }) => {

  const { t } = useTranslation();
  const { colorParts } = React.useContext(AppContext)
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = res => {
    setColor(c => ({ ...c, ...res }))
    setIsOpen(o => false)
  }

  return (
    <>
      <div className='card w-100'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center gap-4'>
            <span className='text-black-50'>{t('details')}</span>
            <button
              className='btn btn-light'
              type='button'
              onClick={() => setIsOpen(true)}
            >{t('edit')}
            </button>
          </div>
          <ul className='list-group list-group-flush'>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('colors.supplier_code')}</div>
              {color.supplier_code}
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('colors.supplier_material_code')}</div>
              {color.supplier_material_code}
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('colors.supplier_business_name')}</div>
              {color.supplier_business_name}
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('colors.sap_code')}</div>
              {color.sap_code}
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('colors.price')}</div>
              {color.price}
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('colors.stock')}</div>
              {color.stock}
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('color_parts.plural')}</div>
              {color?.color_parts?.map(c => <div key={c.uuid}>{c.label}</div>)}
            </li>
          </ul>
        </div>
      </div>

      <ColorDetailsDrawer
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        color={color}
        colorParts={colorParts}
        handleUpdate={handleUpdate}
      />
    </>
  )

}

export default ColorDetails
