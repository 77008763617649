import api from '../../configs/api.config';
import FileDownload from 'js-file-download';

const url = 'templates'
const partBom = () => download(`${url}/part-bom`, 'part_bom.xlsx');
const subOfferBom = () => download(`${url}/sub-offer-bom`, 'sub_offer_bom.xlsx')

const download = (url, filename) => api.get(url, { responseType: 'blob' }).then((res) => FileDownload(res.data, filename))


const templatesService = {
  partBom,
  subOfferBom
}

export default templatesService
