import React, { useContext, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SubOffer from './SubOffer/SubOffer';
import { offerActionTypes } from '../../offerContext/offerReducer/offerActionTypes';
import { OfferContext } from '../../offerContext/offerContext';
import SubOffersSummary from '../SubOffersSummary/SubOffersSummary';
import { useTranslation } from 'react-i18next';
import { SubOfferCreateDrawer } from './SubOffer/SubOfferCreateDrawer';
import subOffersService from '../../../../../services/offers/subOffersService';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../../components/AppContext';
import { SubOfferTools } from './SubOfferTools/SubOfferTools';
import DestroyButtonIcon from '../../../../../components/UI/Buttons/Destroy/DestroyButtonIcon';

const SubOffers = ({ offerUuid, offerRevisionUuid, offerRevisionIsInvalid, subOffers = [] }) => {

  const { current_selected_tab, dispatchAction, revision_editable } = useContext(OfferContext)
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const handleTabSelected = subOfferCode => dispatchAction({
    type: offerActionTypes.CHANGE_TAB,
    payload: subOfferCode
  })

  const handleCreate = subOffer => {
    dispatchAction({ type: offerActionTypes.ADD_SUB_OFFER, payload: subOffer })
    setIsOpen(false)
  }

  const { setLoadingOverlayState } = useContext(AppContext)

  const handleDelete = subOffer => {
    setLoadingOverlayState(true)
    subOffersService.destroy(offerUuid, offerRevisionUuid, subOffer.uuid)
    .then(res => dispatchAction({
      type: offerActionTypes.REMOVE_SUB_OFFER,
      payload: subOffer
    }))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => setLoadingOverlayState(false))
  }


  return (
    <>
      <Nav pills className='border-0 d-flex align-items-center gap-1'>

        <NavItem>
          <NavLink
            active={current_selected_tab === "default"}
            onClick={() => handleTabSelected("default")}>
            {t('recap')}
          </NavLink>
        </NavItem>

        {subOffers.map((subOffer, index) =>
          <NavItem key={`${subOffer.uuid}_nav_item`} className='d-flex align-items-center'>
            <NavLink
              active={current_selected_tab === index}
              onClick={() => handleTabSelected(index)}>
              {subOffer.code}
            </NavLink>

            {subOffer.sub_work_id === null && <DestroyButtonIcon
              message={`Sei sicuro di voler eliminare l'area ${subOffer.code} dalla revisione offerta?`}
              onClick={() => handleDelete(subOffer)}/>
            }
          </NavItem>
        )}
        {revision_editable && <NavItem>
          <NavLink
            className='btn btn-soft-secondary'
            onClick={() => setIsOpen(true)}>
            <i className='fa fa-plus me-1'/>&nbsp;{t('new')}
          </NavLink>
        </NavItem>}

        <NavItem>
          <NavLink
            active={current_selected_tab === "tools"}
            onClick={() => handleTabSelected("tools")}>
            {t('sub_offers.tools')}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={current_selected_tab}>
        <TabPane tabId="default">
          <SubOffersSummary
            offerRevisionUuid={offerRevisionUuid}
            isInvalid={offerRevisionIsInvalid}
            handleTabSelected={handleTabSelected}
          />
        </TabPane>

        {subOffers.map((subOffer, index) =>
          <TabPane key={`${subOffer.uuid}_tab_content`} tabId={index}>
            <SubOffer
              offerUuid={offerUuid}
              offerRevisionUuid={offerRevisionUuid}
              subOffer={subOffer}
            />
          </TabPane>
        )}
        <TabPane tabId="tools">
          <SubOfferTools/>
        </TabPane>

      </TabContent>

      <SubOfferCreateDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        offerUuid={offerUuid}
        offerRevisionUuid={offerRevisionUuid}
        handleCreate={handleCreate}
      />
    </>
  )

}

export default SubOffers
