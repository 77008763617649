import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';
import optionsService from '../../../../services/optionsService';

const CompanyPersonPicker = props => {

  const { companyId, locationId, handleChange } = props;
  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(optionsService.companyPersons, { manual: true });
  const selectInputRef = useRef();
  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({
      filter: {
        company_id: companyId,
        company_location_id: locationId,
        q: value || ''
      },
    }).then((res) => callback(res))
    .catch(err => callback([]));
  };

  useEffect(() => {
    if (!companyId) {
      selectInputRef && selectInputRef.current.clearValue();
    }
  }, [companyId]);

  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <AsyncSelect
      ref={selectInputRef}
      isDisabled={!!!companyId}
      cacheOptions={false}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={handleChange}
      placeholder={props?.placeholder || t('type_to_search')}
      loading={loading}
      styles={reactSelectStyles}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
      {...props}
    />
  );
}

export default CompanyPersonPicker;
