import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import worksService from '../../../services/works/worksService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Drawer from '../../../components/UI/Drawer/Drawer';
import * as Yup from 'yup';
import CompanyFullSelection from '../../../components/CompanyFullSelection/CompanyFullSelection';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import UsersPicker from '../../../components/UI/Forms/Pickers/UsersPicker';
import { useSanctum } from 'react-sanctum';
import { FormActions } from '../../../components/UI/Forms/FormActions';


function WorkCreateDrawer({ isOpen, setIsOpen }) {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useSanctum()

  const initialValues = {
    company: null,
    company_location: null,
    company_person: null,
    forecast_date: "",
    description: "",
    sellers: [{
      value: user.id,
      label: user.full_name,
      uuid: user.uuid,
    }],
    designers: [],
    note: ""
  }

  const validationSchema = Yup.object().shape({
    company: Yup.object().nullable().required(),
    company_location: Yup.object().nullable().notRequired(),
    company_person: Yup.object().nullable().notRequired(),
    forecast_date: Yup.date("Data non valida").nullable().notRequired(),
    description: Yup.string().max(255).required(),
    sellers: Yup.array().min(1).required(),
    designers: Yup.array().required(),
    note: Yup.string().max(800).nullable().notRequired(),
  });

  const handleSubmit = (values, { setSubmitting }) => worksService.store({
    ...values,
    company_id: values.company?.value,
    company_location_id: values.company_location?.value,
    company_person_id: values.company_person?.value,
    sellers: values.sellers.map(s => s.value),
    designers: values.designers.map(d => d.value),
  })
  .then((res) => {
    toast.success(t('created'));
    navigate(`/works/${res.uuid}`);
  })
  .catch((err) => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false))

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    formik.resetForm(initialValues)
  }, [isOpen]);


  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('new')}
    >
      <FormikProvider value={formik}>
        <Form>
          <h5>{t('works.sing')}</h5>
          <FormGroup name="description" label={t('description')} required={true}>
            <FormInput name="description"/>
          </FormGroup>
          <FormGroup name='forecast_date' label={t('works.forecast_date')} required={false}>
            <FormInput name="forecast_date" type="date"/>
          </FormGroup>
          <FormGroup name="note" required={false} label={t('note')}>
            <FormInput rows={5} as='textarea' name="note"/>
          </FormGroup>

          <h5>{t('works.users')}</h5>
          <div className='row'>
            <div className="col-lg-6">
              <FormGroup name="sellers" label={t('enums.work_user_role.SELLER')} required={true}>
                <UsersPicker
                  handleChange={values => formik.setFieldValue("sellers", values)}
                  value={formik.values.sellers}
                  placeholder={t('works.users')}
                  isMulti={true}
                />
              </FormGroup>
            </div>
            <div className="col-lg-6">
              <FormGroup name="designers" label={t('enums.work_user_role.DESIGNER')}>
                <UsersPicker
                  handleChange={values => formik.setFieldValue("designers", values)}
                  value={formik.values.designers}
                  placeholder={t('works.users')}
                  isMulti={true}
                />
              </FormGroup>
            </div>
          </div>

          <h5>{t('works.customer')}</h5>
          <CompanyFullSelection/>

          <FormActions
            withReset={false}
            onCancel={() => setIsOpen(false)}
          />
        </Form>
      </FormikProvider>
    </Drawer>
  )

}

export default WorkCreateDrawer;
