import React from 'react';
import { useSanctum } from 'react-sanctum';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import FormikResetButton from '../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import userService from '../../../services/userService';
import AvatarUpdater from '../../../components/UI/Forms/AvatarUpdater/AvatarUpdater';

function UserProfile() {
  const { user, setUser } = useSanctum();
  const { t } = useTranslation();

  const initialValues = {
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
  };

  const validation = Yup.object().shape({
    first_name: Yup.string().max(255).required(),
    last_name: Yup.string().max(255).required(),
    email: Yup.string().max(255).email().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        userService.updateProfile(values)
        .then(() => userService.authUser().then((res) => {
          setUser(res);
          toast.success(t('updated'));
        })).catch((err) => toast.error(err?.response?.data?.message))
        .finally(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <h5>{t('Account')}</h5>
              <div className="d-flex align-content-start justify-content-start">
                <div className="me-3">
                  <AvatarUpdater/>
                </div>
                <div className="flex-grow-1">
                  <FormGroup
                    name="first_name"
                    label={t('users.first_name')}
                    required
                    inline={false}
                  >
                    <FormInput name="first_name"/>
                  </FormGroup>
                  <FormGroup
                    name="last_name"
                    label={t('users.first_name')}
                    required
                    inline={false}
                  >
                    <FormInput name="last_name"/>
                  </FormGroup>
                  <FormGroup name="email" label={t('users.email')} required inline={false}>
                    <FormInput name="email" type="email"/>
                  </FormGroup>
                  <div className="d-flex align-content-center justify-content-end gap-1">
                    <FormikResetButton/>
                    <FormikSaveButton/>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default UserProfile;
