import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../../components/UI/Drawer/Drawer';
import { isNull, isUndefined } from 'lodash';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import FormGroup from '../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../components/UI/Forms/FormInput';
import { contactsValidation } from '../Contacts/contacts.form';
import { ContactsArrayEditor } from '../Contacts/ContactsArrayEditor';
import companyPersonsService from '../../../../services/companies/companyPersonsService';
import { toast } from 'react-toastify';
import { FormSelect } from '../../../../components/UI/Forms/FormSelect';
import { FormActions } from '../../../../components/UI/Forms/FormActions';
import DestroyButton from '../../../../components/UI/Buttons/Destroy/DestroyButton';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().max(50).required(),
  last_name: Yup.string().max(50).required(),
  company_location_id: Yup.number().notRequired(),
  note: Yup.string().nullable().notRequired(),
  contacts: contactsValidation
});

export const CompanyPersonDrawer = ({ companyUuid, person, isOpen, setSelectedPerson, handleUpdate, handleCreate, handleDelete, locations }) => {

  const { t } = useTranslation()

  const initialValues = {
    first_name: person?.first_name ?? "",
    last_name: person?.last_name ?? "",
    company_location_id: person?.company_location_id ?? "",
    note: person?.note ?? "",
    contacts: person?.contacts ?? [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => person
      ? companyPersonsService.update(companyUuid, person.uuid, values)
      .then(res => handleUpdate(res))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => setSubmitting(false))
      : companyPersonsService.store(companyUuid, values)
      .then(res => handleCreate(res))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => setSubmitting(false))
  })

  return (
    <Drawer
      title={isUndefined(person) || isNull(person) ? t('new') : person.full_name}
      status={isOpen}
      toggle={() => setSelectedPerson(null)}
    >
      <FormikProvider value={formik}>
        <Form>

          <div className='row'>
            <div className='col-md-6'>
              <FormGroup name="first_name" required inline={false} label={t("users.first_name")}>
                <FormInput name="first_name" placeholder={t("users.first_name")}/>
              </FormGroup>
            </div>
            <div className='col-md-6'>
              <FormGroup name="last_name" required inline={false} label={t("users.last_name")}>
                <FormInput name="last_name" placeholder={t("users.last_name")}/>
              </FormGroup>
            </div>
          </div>
          <FormGroup name="company_location_id" label={t('locations.sing')}>
            <FormSelect
              onChange={(value) => formik.setFieldValue(value || "")}
              name="company_location_id"
              placeholder={t('locations.sing')}
              options={locations.map(d => ({
                value: d.id,
                label: d.label
              }))}
            />
          </FormGroup>

          <ContactsArrayEditor/>

          <FormGroup name="note" label={t('note')} required={false}>
            <FormInput name="note" as="textarea"/>
          </FormGroup>

          <FormActions withReset={true} onCancel={() => setSelectedPerson(null)}>
            {person?.id !== undefined && <DestroyButton onClick={handleDelete}/>}
          </FormActions>

        </Form>
      </FormikProvider>
    </Drawer>
  )

}
