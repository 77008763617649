import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';
import optionsService from '../../../../services/optionsService';

function WorkPicker(props) {
  const [isClicked, setIsClicked] = useState(false);
  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(optionsService.works, { manual: true });
  const selectInputRef = useRef();
  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({ filter: { ...props.defaultParams ?? {}, q: value || '' }, })
    .then(res => callback(res))
    .catch(() => callback([]));
  };

  useEffect(() => {
    if(!props.value){
      selectInputRef.current.clearValue()
    }
  }, [props.value]);


  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);
  const noOptionsMessage = () => {
    if (!isClicked) {
      return t("type_to_search");
    } else {
      return t("no-results");
    }
  };


  return (
    <AsyncSelect
      className='bg-white'
      ref={selectInputRef}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={props.handleChange}
      loading={loading}
      placeholder={t('type_to_search')}
      styles={reactSelectStyles}
      multiple={false}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
      {...props}
    />
  );
}

export default WorkPicker;
