import { ListItem } from '../../../../../components/UI/ItemList/ListItem';
import React, { useContext, useState } from 'react';
import DestroyButton from '../../../../../components/UI/Buttons/Destroy/DestroyButton';
import CreateButton from '../../../../../components/UI/Buttons/CreateButton';
import { UncontrolledTooltip } from 'reactstrap';
import WorkTriangulationDrawer from './WorkTriangulationDrawer';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { WorkContext } from '../../WorkContext/WorkContext';
import worksService from '../../../../../services/works/worksService';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../../components/AppContext';
import { useTranslation } from 'react-i18next';

const WorkTriangulation = ({ work = undefined }) => {

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const { dispatchAction } = useContext(WorkContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const handleUpdate = res => {
    setIsOpen(false)
    dispatchAction({
      type: workActionTypes.TRIANGULATION_CHANGED,
      payload: res
    });
  }

  const handleDestroy = () => {
    setLoadingOverlayState(true)
    worksService.destroyWorkLocation(work.uuid, work?.work_location?.uuid)
    .then(() => {
      dispatchAction({
        type: workActionTypes.TRIANGULATION_CHANGED,
        payload: null
      })
    }).catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => {
      setLoadingOverlayState(false)
    })
  }

  return work ? (
    <>
      <ListItem
        label={t(work.has_triangulation ? 'works.triangulation' : 'works.location')}
        extra={
          <>
            {work.has_triangulation ? <>
              <UncontrolledTooltip target='destroy_triangulation_button'>Annulla la triangolazione</UncontrolledTooltip>
              <DestroyButton
                id='destroy_triangulation_button'
                size='btn-sm'
                onClick={() => handleDestroy()}
              />
            </> : <>
              <UncontrolledTooltip target='create_triangulation_button'>Imposta triangolazione</UncontrolledTooltip>
              <CreateButton
                id='create_triangulation_button'
                size='btn-sm'
                label='Triangola'
                onClick={() => setIsOpen(o => !o)}
              />
            </>}
          </>
        }
      >

        {work.has_triangulation
          ? <div className='d-flex flex-column'>
            <span>{work.work_location.label}</span>
            {work.work_location.full_address}
          </div>
          : work.company_location &&
          <div className='d-flex flex-column'>
            <span>{work.company_location.label}</span>
            {work.company_location.full_address}
          </div>
        }

      </ListItem>

      <WorkTriangulationDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        workLocation={work.work_location}
        onUpdate={handleUpdate}
      />
    </>
  ) : <></>

}

export default WorkTriangulation;
