import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CodesNewDropdown() {
  const { t } = useTranslation();

  return (
    <UncontrolledButtonDropdown size="md">
      <DropdownToggle className="btn btn-soft-secondary">
        <i className="fa fa-plus me-1"/>
        {t('new')}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem>
          <Link to="create-category">
            {t('enums.code_types.CATEGORY')}
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="create-code">
            {t('codes.code')}
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="create-measure">
            {t('enums.code_types.MEASURE')}
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}

export default CodesNewDropdown;
