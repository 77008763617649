import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Drawer from '../../../components/UI/Drawer/Drawer';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import companiesService from '../../../services/companies/companiesService';
import { toast } from 'react-toastify';
import { FormActions } from '../../../components/UI/Forms/FormActions';
import MarketPicker from '../../../components/UI/Forms/Pickers/MarketPicker';

const initialValues = {
  business_name: "",
  market: "",
  sdi: "",
  sap_code: "",
  vat_number: "",
  fiscal_code: "",
  note: ""
};

const validationSchema = Yup.object().shape({
  business_name: Yup.string().max(100).required(),
  market: Yup.string().notRequired().nullable(),
  sdi: Yup.string().max(10).nullable().notRequired(),
  sap_code: Yup.string().max(50).nullable().notRequired(),
  vat_number: Yup.string().max(20).nullable().notRequired(),
  fiscal_code: Yup.string().max(20).nullable().notRequired(),
  note: Yup.string().nullable().notRequired(),
});

function CompanyCreateDrawer({ isOpen, setIsOpen }) {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (values, { setSubmitting }) => companiesService.store(values)
  .then((res) => {
    toast.success(t('created'));
    navigate(`/companies/${res.uuid}`);
  })
  .catch((err) => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false))

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: handleSubmit
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('new')}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="business_name" label={t('companies.business_name')} required>
            <FormInput name="business_name"/>
          </FormGroup>
          <FormGroup name="market" label={t('market')} required={false} inline={false}>
            <MarketPicker name='market'/>
          </FormGroup>
          <div className='row'>
            <div className='col-lg-6'>
              <FormGroup name="vat_number" label={t('companies.vat_number')} required={false}>
                <FormInput name="vat_number"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="fiscal_code" label={t('companies.fiscal_code')} required={false}>
                <FormInput name="fiscal_code"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="sdi" label={t('companies.sdi')} required={false}>
                <FormInput name="sdi"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="sap_code" label={t('companies.sap_code')} required={false}>
                <FormInput name="sap_code"/>
              </FormGroup>
            </div>
          </div>
          <FormGroup name="note" label={t('note')} required={false}>
            <FormInput name="note" as="textarea"/>
          </FormGroup>

          <FormActions
            onCancel={() => setIsOpen(false)}
            withReset={false}
          />
        </Form>
      </FormikProvider>
    </Drawer>
  );
}

export default CompanyCreateDrawer;
