import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import Header from './_components/Header/Header';
import layoutConfig from '../../../configs/layout.configs';
import Navbar from './_components/Navbar/Navbar';
import Footer from './_components/Footer';
import Breadcrumb from './_components/Breadcrumb';
import { PageLoader } from '../../UI/PageLoader';
import { AppContext } from '../../AppContext';

function PrivateLayout() {
  document.title = process.env.REACT_APP_NAME;
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div id="layout-wrapper">
      <Header
        theme={layoutConfig.topbarTheme}
        isMenuOpened={isMenuOpened}
        openLeftMenuCallBack={() => setIsMenuOpened(!isMenuOpened)}
      />

      <ToastContainer toastConfig={layoutConfig.toastConfig}/>

      <Navbar menuOpen={isMenuOpened}/>
      <div className="main-content">
        <div className="page-content">
          <Container fluid>
            <Breadcrumb/>
            <Outlet/>
          </Container>
        </div>
      </div>
      <Footer/>
      <PageLoader/>
    </div>
  );
}

export default PrivateLayout;
