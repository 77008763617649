import React, { useContext } from 'react';
import Drawer from '../../../../../components/UI/Drawer/Drawer';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import FormGroup from '../../../../../components/UI/Forms/FormGroup';
import { useTranslation } from 'react-i18next';
import worksService from '../../../../../services/works/worksService';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { WorkContext } from '../../WorkContext/WorkContext';
import { AppContext } from '../../../../../components/AppContext';
import { FormActions } from '../../../../../components/UI/Forms/FormActions';


const validationSchema = Yup.object().shape({ sap_orders: Yup.array().required() })

const WorkSapOrdersDrawer = ({ isOpen, setIsOpen, onUpdate }) => {

  const { work } = useContext(WorkContext)
  const { setLoadingOverlayState } = useContext(AppContext)
  const { t } = useTranslation()
  const initialValues = {
    sap_orders: work.sap_orders.map(so => ({
      label: so,
      value: so
    })) || []
  }

  const onSubmit = (values, { setSubmitting }) => {
    setLoadingOverlayState(true)
    worksService.update(work.uuid, { sap_orders: values.sap_orders.map(v => v.value) })
    .then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit
  })


  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title='Ordini SAP'
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup
            name="sap_orders"
            label={t('works.sap_orders')}
            required={false}
            inline={false}>
            <CreatableSelect
              isMulti={true}
              options={formik.values.sap_orders}
              value={formik.values.sap_orders}
              onChange={value => formik.setFieldValue("sap_orders", value)}
              isClearable={true}
              placeholder={t('search')}
            />
          </FormGroup>
          <FormActions
            onCancel={() => setIsOpen(false)}
            withReset={true}
          />
        </Form>
      </FormikProvider>

    </Drawer>
  )

}

export default WorkSapOrdersDrawer
