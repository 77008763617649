import React from 'react';
import { Trans } from 'react-i18next';

const PARTNER = 'PARTNER';
const AGENT = 'AGENT';
const DISTRIBUTOR = 'DISTRIBUTOR';
const SIGNALMAN = 'SIGNALMAN';
const BRAND_AMBASSADOR = 'BRAND_AMBASSADOR';

const values = {
  PARTNER,
  AGENT,
  DISTRIBUTOR,
  SIGNALMAN,
  BRAND_AMBASSADOR
};

const collection = [
  { value: PARTNER, label: <Trans i18nKey='enums.company_partnership.PARTNER'/>, color: 'primary' },
  { value: AGENT, label: <Trans i18nKey='enums.company_partnership.AGENT'/>, color: 'primary' },
  { value: DISTRIBUTOR, label: <Trans i18nKey='enums.company_partnership.DISTRIBUTOR'/>, color: 'primary' },
  { value: BRAND_AMBASSADOR, label: <Trans i18nKey='enums.company_partnership.BRAND_AMBASSADOR'/>, color: 'primary' },
  { value: SIGNALMAN, label: <Trans i18nKey='enums.company_partnership.SIGNALMAN'/>, color: 'primary' },
];

const WorkCompanyPartnershipEnum = {
  values,
  collection,
};

export default WorkCompanyPartnershipEnum;
