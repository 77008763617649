import React from 'react';
import { useRequest } from 'ahooks';
import optionsService from '../../../../../../services/optionsService';
import { debounce } from 'lodash';
import { reactSelectStyles } from '../../../../../../configs/reactSelect.config';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SearchRecordsForm = () => {

  const { data: users = [], loading, runAsync } = useRequest(optionsService.searchRecords, { manual: true });
  const { t } = useTranslation()
  const navigate = useNavigate()
  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({ filter: { search: value || '' } })
    .then((res) => callback(res))
    .catch(() => callback([]));
  };

  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <form className="app-search d-none d-lg-block" onSubmit={e => e.preventDefault()}>
      <div className="position-relative" style={{ minWidth: "250px" }}>
        <AsyncSelect
          cacheOptions
          defaultOptions={[]}
          loadOptions={handleLoadOptions}
          isLoading={loading}
          placeholder={t('search')}
          isClearable
          onChange={value => navigate(value?.url)}
          loading={loading}
          styles={reactSelectStyles}
          noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
        />
      </div>
    </form>
  )

}

export default SearchRecordsForm
