import React from 'react';
import templatesService from '../../../../../../services/parts/templatesService';

export const SubOfferBomDownload = () => {

  return (
    <div>
      <div className='fw-bold'>Carica una distinta base</div>
      <div>Carica una lista di parti utilizzando il file excel scaricabile cliccando su questo
        <span
          onClick={() => templatesService.subOfferBom()}
          id='download_template_button'
          className="cursor-pointer fw-bold">
          &nbsp;link
        </span> oppure carica un file <code>.csv</code> dal configuratore.
      </div>
      <div>Per ogni parte elencata sono obbligatori codice, descrizione e quantità.</div>
    </div>
  )


}
