import React, { useContext } from 'react'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { OfferContext } from '../../offerContext/offerContext';
import { AppContext } from '../../../../../components/AppContext';
import partCategoriesService from '../../../../../services/parts/partCategoriesService';
import { toast } from 'react-toastify';
import { offerActionTypes } from '../../offerContext/offerReducer/offerActionTypes';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';
import { FormPercentage } from '../../../../../components/UI/Forms/FormPercentage';
import PercentageDisplay from '../../../../../components/UI/Displays/PercentageDisplay';

const OfferPartCategory = ({ partCategory }) => {

  const { dispatchAction, revision_editable } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)
  const initialValues = { 'margin': partCategory.margin * 100 }
  const validationSchema = Yup.object().shape({ margin: Yup.number().min(0).max(100).required() })


  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoadingOverlayState(true)
      partCategoriesService.update(partCategory.uuid, values)
      .then(res => dispatchAction({
        type: offerActionTypes.UPDATE_REVISION_CATEGORY_MARGIN,
        payload: res
      }))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => {
        setSubmitting(false)
        setLoadingOverlayState(false)
      })
    }
  })

  return (
    <ListItem
      label={partCategory.label}
      inline={true}
      extra={<i className='fa fa-square' style={{ color: partCategory.color }}/>}>
      <FormikProvider value={formik}>
        <Form>
          {revision_editable
          ?          <FormPercentage
            value={parseInt(formik.values.margin)}
            min={0}
            max={100}
            name={`margin`}
          /> : <PercentageDisplay value={formik.values.margin} />}
        </Form>
      </FormikProvider>
    </ListItem>
  )

}

export default OfferPartCategory
