import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import colorsService from '../../../../services/colors/colorsService';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';

const ColorPicker = ({ handleChange, defaultParams = {}, colorPart = null, ...props }) => {

  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(colorsService.selectable, { manual: true });

  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({
      filter: {
        color_part: colorPart,
        description: value || '',
      },
    }).then((res) => {
      return callback(res)
    })
    .catch(() => callback([]));
  };

  const onChange = (value) => {
    if (value) {
      handleChange(value);
    }
  };
  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={onChange}
      loading={loading}
      placeholder={t('search')}
      styles={reactSelectStyles}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
      {...props}
    />
  );
}

export default ColorPicker
