import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import SubOfferItem from './SubOfferItem';
import { UncontrolledTooltip } from 'reactstrap';

function SubOfferDraggableItem({ onDrag, canDrag, id, pos, subOfferUuid, offerRevisionUuid, subOfferItem, setDragEnabled }) {

  const previewRef = useRef(null);
  const dragRef = useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'div',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item) {
      if (onDrag) onDrag(item.id, id);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { id },
    type: 'div',
    canDrag: canDrag !== false,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const isActive = canDrop && isOver;

  drag(dragRef)
  drop(preview(previewRef))

  const styles = {
    opacity: isDragging ? 0.5 : 1,
    borderTop: isActive ? '3px dashed #e0e0e0' : '0',
  };

  return (
    <div ref={previewRef} style={styles}>
      <SubOfferItem
        pos={pos}
        subOfferUuid={subOfferUuid}
        subOfferItem={subOfferItem}
        setDragEnabled={setDragEnabled}
        offerRevisionUuid={offerRevisionUuid}
      >
        <UncontrolledTooltip target={`drag_and_drop_button${subOfferItem.uuid}`}>
          Riordina l'elemento selezionato trascinandolo
        </UncontrolledTooltip>
        <i
          id={`drag_and_drop_button${subOfferItem.uuid}`}
          className={`fa small fa-arrows-alt ${isDragging ? 'text-info' : 'text-black-50'}`}
          ref={dragRef}
          style={{ cursor: 'grab', width: '1.2rem' }}
        />
      </SubOfferItem>
    </div>
  );
}

export default SubOfferDraggableItem;
