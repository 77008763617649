import React, { useContext } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { OfferContext } from '../../../offerContext/offerContext';
import { AppContext } from '../../../../../../components/AppContext';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import FormFileInput from '../../../../../../components/UI/Forms/FormFile/FormFileInput';
import { SubOfferBomDownload } from '../SubOffer/SubOfferBomDownload';
import { FormActions } from '../../../../../../components/UI/Forms/FormActions';
import subOffersService from '../../../../../../services/offers/subOffersService';
import { toast } from 'react-toastify';
import { offerActionTypes } from '../../../offerContext/offerReducer/offerActionTypes';

const validationSchema = Yup.object().shape({
  file: Yup.mixed().nullable().notRequired()
})

const SubOfferItemFileAdder = ({ subOfferUuid, offerRevisionUuid, setIsNewClicked, partTypes, action }) => {

  const { t } = useTranslation()
  const initialValues = { file: undefined };
  const { offer, dispatchAction } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setLoadingOverlayState(true)
    subOffersService.attach(offer.uuid, offer.offer_revision.uuid, subOfferUuid, values)
    .then(res => dispatchAction({ type: offerActionTypes.UPDATE_SUB_OFFER_TOOLS_FROM_FILE, payload: res }))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      resetForm()
      setLoadingOverlayState(false)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <SubOfferBomDownload/>
        <div className='d-flex align-items-center justify-content-start'>
          <FormGroup
            name="file"
            label={false}
            inline={false}>
            <FormFileInput
              name='file'
              file={undefined}
              onFileChange={e => formik.setFieldValue('file', e.target.files ? e.target.files[0] : undefined)}
            />
          </FormGroup>
        </div>
        <FormActions
          withReset={false}
          onCancel={() => setIsNewClicked(null)}
        />
      </Form>
    </FormikProvider>
  )

}

export default SubOfferItemFileAdder
