import React from 'react';
import { Col, Row } from 'reactstrap';
import UserProfile from './_components/UserProfile';
import UserPasswordUpdater from './_components/UserPasswordUpdater';
import UserSettings from './_components/UserSettings';

function UserIndex() {
  return (
    <>
      <Row>
        <Col lg={6}>
          <UserProfile/>
        </Col>
        <Col lg={6}>
          <UserPasswordUpdater/>
        </Col>
      </Row>
      <UserSettings/>
    </>
  );
}

export default UserIndex;
