import React from 'react';

const enabled = 'enabled';
const disabled = 'disabled';
const pending = 'pending';

const values = {
  enabled,
  disabled,
  pending,
};

const collection = [
  { value: enabled, label: 'enums.part_states.ENABLED', color: 'success' },
  { value: disabled, label: 'enums.part_states.DISABLED', color: 'light' },
  { value: pending, label: 'enums.part_states.PENDING', color: 'danger' },
];

const PartTypeEnum = {
  values,
  collection,
};

export default PartTypeEnum;
