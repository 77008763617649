import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classname from 'classnames';
import NavBarDropdownTitle from '../NavBarDropdownTitle';

function CommManagementDropdown() {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown">
        <NavBarDropdownTitle
          label={t('menu.master_comm')}
          state={isOpen}
          handleState={setIsOpen}
          icon="fa fa-chart-line"
        />
        <div className={classname('dropdown-menu', { show: isOpen })}>
          <Link to="/companies" className="dropdown-item">
            <i className="fa fa-briefcase me-2"/>
            {t('menu.manage_companies')}
          </Link>
          <Link to="/offers" className="dropdown-item">
            <i className="fa fa-file-signature me-2"/>
            {t('menu.manage_offers')}
          </Link>
          <Link to="/works" className="dropdown-item">
            <i className="fa fa-draw-polygon me-2"/>
            {t('menu.manage_works')}
          </Link>

          <div className="dropdown-divider"/>

          <Link to="part-categories" className="dropdown-item">
            <i className="fa fa-percent me-2"/>
            {t('menu.manage_part_categories')}
          </Link>

        </div>
      </li>
    </ul>
  );
}

export default CommManagementDropdown;
