import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubOfferToolsTotals } from './SubOfferToolsTotals';
import { SubOfferItemAdder } from '../SubOfferItemAdder/SubOfferItemAdder';
import { OfferContext } from '../../../offerContext/offerContext';
import PartTypeEnum from '../../../../../../constants/enums/PartTypeEnum';
import SubOfferToolItem from './SubOfferToolItem';
import { offerActionTypes } from '../../../offerContext/offerReducer/offerActionTypes';
import * as Yup from 'yup';
import { AppContext } from '../../../../../../components/AppContext';
import { Form, FormikProvider, useFormik } from 'formik';
import subOffersService from '../../../../../../services/offers/subOffersService';
import { toast } from 'react-toastify';
import { FormPercentage } from '../../../../../../components/UI/Forms/FormPercentage';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { FormNumber } from '../../../../../../components/UI/Forms/FormNumber';
import FormikSaveButton from '../../../../../../components/UI/Buttons/Save/FormikSaveButton';
import { CardSmMargin } from '../../../../../../components/UI/Card/CardSmMargin';
import { CardBody } from '../../../../../../components/UI/Card/CardBody';
import NumberDisplay from '../../../../../../components/UI/Displays/NumberDisplay';
import PercentageDisplay from '../../../../../../components/UI/Displays/PercentageDisplay';

const validationSchema = Yup.object().shape({
  qty: Yup.number().min(0).nullable().required(),
  description: Yup.string().max(100).nullable().notRequired(),
  discount: Yup.string().min(0).max(100).nullable().notRequired(),
})

export const SubOfferTools = () => {

  const { t } = useTranslation()
  const { offer, dispatchAction, revision_editable } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const formik = useFormik({
    initialValues: {
      qty: offer.offer_revision.sub_offer_tools.qty ?? 1,
      discount: (offer.offer_revision.sub_offer_tools.discount ?? 0) * 100,
      description: offer.offer_revision.sub_offer_tools.description ?? "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoadingOverlayState(true)
      subOffersService.update(offer.uuid, offer.offer_revision.uuid, offer.offer_revision.sub_offer_tools.uuid, values)
      .then(res => dispatchAction({ type: offerActionTypes.UPDATE_SUB_OFFER_TOOLS, payload: { subOfferTools: res } }))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => {
        setSubmitting(false)
        setLoadingOverlayState(false)
      })
    }
  })


  return (
    <>
      <SubOfferToolsTotals subOffer={offer.offer_revision.sub_offer_tools}/>
      <CardSmMargin>
        <CardBody>
          <FormikProvider value={formik}>
            <Form>
              <div className='d-flex justify-content-between align-items-center gap-2'>
                <span>{t('sub_offers.tools')}</span>
                <div className='d-flex justify-content-end align-items-center gap-2'>
                  <FormGroup name='discount' label={t('sub_offers.discount')}>
                    {revision_editable ? <FormPercentage
                        name='discount'
                        max={99.9}/>
                      : <PercentageDisplay value={revision_editable.discount}/>}
                  </FormGroup>
                  <FormGroup name='qty' label={t('offers.qty')}>
                    {revision_editable ? <FormNumber
                      name='qty'
                      step={1}
                      min={0}
                    /> : <NumberDisplay value={formik.values.qty}/>}
                  </FormGroup>
                  {revision_editable && <FormikSaveButton/>}
                </div>
              </div>
            </Form>
          </FormikProvider>
        </CardBody>
      </CardSmMargin>

      {offer.offer_revision?.sub_offer_tools?.sub_offer_items?.map((subOfferItem, index) =>
        <SubOfferToolItem
          key={subOfferItem.uuid}
          subOfferItem={subOfferItem}
          offerRevisionUuid={offer.offer_revision?.uuid}
          subOfferUuid={offer.offer_revision?.sub_offer_tools?.uuid}
          pos={index}
        />
      )}

      {revision_editable &&
        <SubOfferItemAdder
          action={offerActionTypes.ADD_SUB_OFFER_TOOL_ITEM}
          subOfferUuid={offer.offer_revision?.sub_offer_tools?.uuid}
          offerRevisionUuid={offer.offer_revision.uuid}
          partTypes={[PartTypeEnum.values.BETA_TOOL]}
          withFile={true}
        />}
    </>
  )

}
