import React from 'react';
import { Field } from 'formik';
import { CirclePicker } from 'react-color';

const FormColorPicker = props => {

  return (
    <Field {...props}>
      {({ field, form, }) => (
        <>
          <div className='mb-3 fw-bold d-flex align-items-center gap-1'>
            <i className='fa fa-circle fa-2x' style={{color: field.value}} />
            {field.value}
          </div>

          <CirclePicker
            value={field.value}
            onBlur={() => form.setFieldValue(field.name, field.value)}
            onChangeComplete={value => form.setFieldValue(field.name, value?.hex ?? '')}
          />
        </>
      )}
    </Field>
  )

}

export default FormColorPicker
