import React, { useState } from 'react';
import ColorPresetsService from '../../../services/colors/colorPresetsService';
import colorPresetsService from '../../../services/colors/colorPresetsService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Drawer from '../../../components/UI/Drawer/Drawer';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import { FormGroup } from 'reactstrap';
import Toolbar from '../../../components/UI/Toolbar';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikResetButton from '../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import DestroyButton from '../../../components/UI/Buttons/Destroy/DestroyButton';


const validation = Yup.object().shape({
  label: Yup.string().max(100).required(),
  note: Yup.string().max(800).nullable().notRequired()
})

const ColorPresetDrawer = ({ colorPreset, handleUpdate, isOpen, setIsOpen }) => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [initialValues] = useState({
    label: colorPreset?.label ?? '',
    note: colorPreset?.note ?? '',
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      ColorPresetsService.update(colorPreset?.uuid, values)
      .then(res => handleUpdate(res))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => setSubmitting(false))
    }
  })

  const handleDestroy = () => colorPresetsService.destroy(colorPreset?.uuid).then(res => navigate('/color-presets'))

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('edit')}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup required label={t('color_presets.label')} name='label'>
            <FormInput name='label'/>
          </FormGroup>
          <FormGroup label={t('note')} name='note'>
            <FormInput name='note' as='textarea' rows={10}/>
          </FormGroup>

          <div className='d-flex align-items-center justify-content-between gap-4'>
            <CancelButton onClick={() => setIsOpen(false)}/>
            <div className='d-flex align-items-center justify-content-end gap-2'>
              <FormikResetButton/>
              <div className="d-flex gap-1">
                <FormikSaveButton/>
                <DestroyButton onClick={() => handleDestroy()}/>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Drawer>
  )
}

export default ColorPresetDrawer
