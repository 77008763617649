import React from 'react';
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from 'react-i18next';
import { chain } from 'lodash';


const LocationPicker = ({ onPlaceSelected }) => {

  const { t } = useTranslation();

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const autoCompleteOptions = {
    types: ['address'],
    fields: ['address_components', 'geometry.location']
  };

  const handlePlaceSelected = gPlace => {
    const placeExploded = chain(gPlace.address_components)
    .keyBy("types[0]")
    .mapValues("short_name")
    .value();

    onPlaceSelected({
      route: placeExploded?.route || "",
      street_number: placeExploded?.street_number || "",
      locality: placeExploded?.locality || "",
      administrative_area_level_1: placeExploded?.administrative_area_level_1 || "",
      administrative_area_level_2: placeExploded?.administrative_area_level_2 || "",
      administrative_area_level_3: placeExploded?.administrative_area_level_3 || "",
      postal_code: placeExploded?.postal_code || "",
      country: placeExploded?.country || "",
      latitude: gPlace.geometry.location.lat(),
      longitude: gPlace.geometry.location.lng()
    })
  }

  return <>
    <Autocomplete
      apiKey={apiKey}
      onPlaceSelected={handlePlaceSelected}
      options={autoCompleteOptions}
      placeholder={t('type_to_search_location')}
      className="form-control text-dark"
    />
  </>

}

export default LocationPicker
