import React from 'react';
import { useTranslation } from 'react-i18next';
import UserDisplay from '../../../../../components/UI/Displays/UserDisplay';

export const WorkUsersList = ({ users = [] }) => {

  const { t } = useTranslation();

  return users.length > 0
    ? users.map(user => <UserDisplay key={`s_${user.uuid}`} user={user} small={true}/>)
    : <>{t('no-results')}</>

}
