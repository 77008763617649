import React from 'react';
import CodesWrapper from '../../../pages/BaseCodes/CodesWrapper';
import CodesIndex from '../../../pages/BaseCodes/CodesIndex';
import CategoryCreate from '../../../pages/BaseCodes/creates/CategoryCreate';
import CodeShow from '../../../pages/BaseCodes/CodeShow/CodeShow';
import CodeCreate from '../../../pages/BaseCodes/creates/CodeCreate';
import MeasureCreate from '../../../pages/BaseCodes/creates/MeasureCreate';

const codesRoutes = {
  path: 'codes/*',
  element: <CodesWrapper />,
  children: [{
    path: '',
    index: true,
    element: <CodesIndex />,
  }, {
    path: 'create-category',
    element: <CategoryCreate />,
  }, {
    path: 'create-code',
    element: <CodeCreate />,
  }, {
    path: 'create-measure',
    element: <MeasureCreate />,
  }, {
    path: ':id',
    element: <CodeShow />,
  }],
};

export default codesRoutes;
