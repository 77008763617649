import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';
import PercentageDisplay from '../../../components/UI/Displays/PercentageDisplay';

const partCategoriesTableConfig = [{
  Header: <Trans i18nKey="part_categories.label"/>,
  accessor: 'label',
  Cell: ({ row, value }) => <TableLink to={`/part-categories/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="part_categories.margin"/>,
  accessor: 'margin',
  Cell: ({ row, value }) => <PercentageDisplay value={value * 100}/>
}, {
  Header: <Trans i18nKey="part_categories.color"/>,
  accessor: 'color',
  Cell: ({ row, value }) => <i className='fa fa-2x fa-circle' style={{color: value}}/>
}];

export default partCategoriesTableConfig;
