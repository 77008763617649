import React from 'react';
import { useRequest } from 'ahooks';
import optionsService from '../../../../services/optionsService';
import { FormSelect } from '../FormSelect';

const MarketPicker = props => {

  const { data: markets = [], loading } = useRequest(optionsService.markets, {})

  return (
    <FormSelect
      disabled={loading || props.disabled}
      options={markets}
      {...props}
    />
  )

}

export default MarketPicker
