import React from 'react';
import { Button } from 'reactstrap';

function DownloadButtonIcon(props) {

  return (
    <Button color="light" type="button" size="sm" {...props}>
      <i className="fa text-primary fa-file-download"/>
    </Button>
  );
}

export default DownloadButtonIcon;
