import * as Yup from 'yup';

const initialValues = {
  color_configs: []
}

const resolveColorConfig = (colorConfigs, colorPartId) => _.find(colorConfigs, cc => cc.color_part?.id === colorPartId)

const setInitialValues = (colorParts = [], colorConfigs) => {
  return {
    color_configs: colorParts.map(colorPart => {
      const config = resolveColorConfig(colorConfigs, colorPart.id)

      return {
        colors: config?.colors ?? [],
        color_part: colorPart,
        note: config?.note ?? ''
      }
    })
  }
}

const validation = Yup.object().shape({
  color_configs: Yup.array().required().of(
    Yup.object().shape({
      colors: Yup.array().nullable().notRequired(),
      color_part: Yup.object().nullable().notRequired(),
      note: Yup.string().when(["colors"], {
        is: (colors) => colors.length > 1,
        then: Yup.string().max(800).required("if multiple colors are selected you must specify item quantities"),
        otherwise: Yup.string().nullable().notRequired()
      })
    })
  )
})

const mapValuesForRequest = values => {
  return {
    color_configs: values.color_configs
    .map(cc => ({
      'color_part_id': cc.color_part.id,
      'note': cc.note,
      'colors': cc.colors.map(c => c.value)
    })) ?? []
  }
}


export const formConfig = {
  resolveColorConfig,
  initialValues,
  setInitialValues,
  validation,
  mapValuesForRequest
}
