import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import CodeTypeEnum from '../../../constants/enums/CodeTypeEnum';
import PartTypeEnum from '../../../constants/enums/PartTypeEnum';
import partTypeEnum from '../../../constants/enums/PartTypeEnum';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import partsService from '../../../services/parts/partsService';
import BaseCodePicker from '../../../components/UI/Forms/Pickers/BaseCodePicker';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import EuroDisplay from '../../../components/UI/Displays/EuroDisplay';


const validationSchema = yup.object().shape({
  category_id: yup.number().nullable().required(),
  code_id: yup.number().nullable().notRequired(),
  measure_id: yup.number().nullable().required(),
  note: yup.string().max(800).nullable().notRequired()
})

const initialValues = {
  category_id: undefined,
  code_id: undefined,
  measure_id: undefined,
  note: ""
}

const WithMeasureCreate = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  // Potrebbe essere stato passato un FC
  const { state } = useLocation();

  const [category, setCategory] = React.useState(undefined);
  const [code, setCode] = React.useState(undefined);
  const [measure, setMeasure] = React.useState(undefined);


  const handleSubmit = (values, { setSubmitting }) => partsService.store(partTypeEnum.values.WITH_MEASURE, {
    ...values,
    part_id: state?.id ?? null
  })
  .then(res => {
    toast.success(t('created'));
    navigate(`../${res.uuid}`);
  })
  .catch(err => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false));


  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })


  return (
    <FormikProvider value={formik}>
      <Form>

        <h5 className="text-uppercase">{t('enums.part_types.WITH_MEASURE')}</h5>

        <div className='card'>
          <div className='card-body'>

            <div className='row'>
              <div className='col-lg-4'>
                <FormDisplay label={t('parts.code')}>
                  {`3D${category?.code || '---'}${code?.code || '000'}${measure?.code || '---'}`}
                </FormDisplay>
              </div>
              <div className='col-lg-4'>
                <FormDisplay label={t('description')}>
                  {`${category?.description || ''} ${code?.description || ''} ${measure?.description || ''}`}
                </FormDisplay>
              </div>


              {state !== undefined && state !== null &&
                <div className='col-lg-4'>
                  <h5>{t('parts.part_id')}</h5>
                  <div>
                    {state.code}<br/>
                    {state.description}<br/>
                    {t('parts.cost')} <EuroDisplay value={state.cost}/><br/>
                    {t('parts.price')} <EuroDisplay value={state.price}/>
                  </div>
                </div>
              }
            </div>

            <div className='row'>

              <div className='col-lg-4'>
                <FormGroup required name="category_id" label={t('enums.code_types.CATEGORY')}>
                  <BaseCodePicker
                    defaultParams={{ code_type: CodeTypeEnum.values.CATEGORY, part_type: PartTypeEnum.values.WITH_MEASURE, }}
                    handleChange={v => {
                      setCategory(oldValue => v)
                      formik.setFieldValue('category_id', v?.value ?? undefined)
                    }}
                  />
                </FormGroup>
              </div>

              <div className='col-lg-4'>
                <FormGroup required={false} name="code_id" label={t('enums.code_types.CODE')}>
                  <BaseCodePicker
                    defaultParams={{ code_type: CodeTypeEnum.values.CODE, part_type: PartTypeEnum.values.WITH_MEASURE, }}
                    handleChange={v => {
                      setCode(oldValue => v)
                      formik.setFieldValue('code_id', v?.value ?? undefined)
                    }}
                  />
                </FormGroup>
              </div>

              <div className='col-lg-4'>
                <FormGroup required name="measure_id" label={t('enums.code_types.MEASURE')}>
                  <BaseCodePicker
                    defaultParams={{
                      code_type: CodeTypeEnum.values.MEASURE,
                      part_type: PartTypeEnum.values.WITH_MEASURE,
                    }}
                    handleChange={v => {
                      setMeasure(oldValue => v)
                      formik.setFieldValue('measure_id', v?.value ?? undefined)
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            <FormGroup name="note" label={t('note')}>
              <FormInput name="note" rows={5} as="textarea"/>
            </FormGroup>

            <div className="d-flex align-items-center gap-2 justify-content-end">
              <CancelButton onClick={() => navigate('../standards')}/>
              <FormikSaveButton/>
            </div>

          </div>

        </div>
      </Form>
    </FormikProvider>
  )
    ;
}

export default WithMeasureCreate;
