import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import colorPresetsService from '../../../services/colors/colorPresetsService';
import { useTranslation } from 'react-i18next';
import utilityService from '../../../services/utilityService';
import ColorConfigs from '../../../components/ColorConfigs/ColorConfigs';
import colorConfigsService from '../../../services/colors/colorConfigsService';
import ColorPresetDrawer from './ColorPresetDrawer';

const ColorPreset = () => {

  const { id } = useParams()
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false);
  const { data: colorPreset, mutate: setColorPreset } = useRequest(colorPresetsService.fetch, { defaultParams: [id] })
  const { data: colorParts = [] } = useRequest(utilityService.fetchColorParts)


  const handleUpdateColorConfigs = res => setColorPreset(cp => ({ ...cp, color_configs: res }))
  const handleUpdate = res => {
    setColorPreset(cp => ({ ...cp, ...res }))
    setIsOpen(false)
  }

  return colorPreset ? (
      <>
        <div className='d-flex flex-column gap-4'>
          <div className='d-flex align-items-center justify-content-between gap-4'>
            <div>
              <div className='fs-3 fw-bolder text-dark'>{colorPreset.label}</div>
              {/*<div className='fs-5'></div>*/}
            </div>
          </div>

          <div className='row'>
            <div className="col-xl-3 col-lg-4">
              <div className='card w-100'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between align-items-center gap-4'>
                    <span className='text-black-50'>{t('details')}</span>
                    <button
                      className='btn btn-light'
                      type='button'
                      onClick={() => setIsOpen(true)}
                    >{t('edit')}
                    </button>
                  </div>
                  <ul className='list-group list-group-flush'>
                    <li className='list-group-item px-0'>
                      <div className='fw-bold'>{t('note')}</div>
                      {colorPreset?.note}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className='card w-100'>
                <div className='card-body'>
                  <ColorConfigs
                    onUpdate={handleUpdateColorConfigs}
                    serviceParams={colorPreset.uuid}
                    serviceCall={colorConfigsService.storePresetColorConfig}
                    colorParts={colorParts}
                    colorConfigs={colorPreset.color_configs}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ColorPresetDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleUpdate={handleUpdate}
          colorPreset={colorPreset}
        />
      </>
    ) :
    <></>
}

export default ColorPreset
