import React from 'react';
import { useTranslation } from 'react-i18next';

function CreateButton(props) {
  const { t } = useTranslation();

  return (
    <button className={`btn btn-soft-secondary ${props?.size}`} type='button'  {...props}>
      <i className='fa fa-plus'/>&nbsp;{t(props?.label ?? 'new')}
    </button>
  );
}

export default CreateButton;
