import React from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import colorsService from '../../../services/colors/colorsService';
import ColorDetails from './ColorDetails/ColorDetails';
import ColorWorks from './ColorWorks/ColorWorks';

const Color = () => {

  const { id } = useParams();

  const { data: color = undefined, mutate: setColor } = useRequest(colorsService.fetch, { defaultParams: [id] });


  return color ? (
    <div className='d-flex flex-column gap-4'>
      <div className='d-flex align-items-center justify-content-between gap-4'>
        <div>
          <div className='fs-3 fw-bolder text-dark'>{color.supplier_code}</div>
          <div className='fs-5'>{color.description}</div>
        </div>
      </div>

      <div className='row'>
        <div className="col-xl-3 col-lg-4 ">
          <ColorDetails
            color={color}
            setColor={setColor}
          />
        </div>
        <div className="col-xl-9 col-lg-8 ">
          <ColorWorks colorId={color?.id}/>
        </div>
      </div>
    </div>
  ) : <></>

}

export default Color
