import React from 'react';
import { Field } from 'formik';

export function FormInput(props) {

  return (
    <Field
      {...props}
      id={props.name}
      className={`form-control ${props?.className}`}
    />
  )
}
