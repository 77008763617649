import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import { CardContentBetween } from '../../../../components/UI/Card/CardContentBetween';
import EditButton from '../../../../components/UI/Buttons/EditButton';
import { List } from '../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../components/UI/ItemList/ListItem';
import { ListContactsItem } from '../../../../components/UI/ItemList/ListContactsItem';
import { Link } from 'react-router-dom';
import { CompanyLocationsDrawer } from '../CompanyLocations/CompanyLocationsDrawer';
import { ListEmptyItem } from '../../../../components/UI/ItemList/ListEmptyItem';


export const DefaultCompanyLocation = ({ company, setCompany }) => {

  const { t } = useTranslation()
  const { default_location = undefined } = company
  const [isOpen, setIsOpen] = useState(false);


  const handleUpdate = defaultLocation => {
    setCompany(c => ({ ...c, default_location: defaultLocation }))
    setIsOpen(o => false)
  }

  const handleDestroy = () => {
    setCompany(c => ({ ...c, default_location: undefined }))
    setIsOpen(o => false)
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('companies.registered_office')}</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>

          <List>
            {default_location ?
              <>
                <ListItem label={t('locations.full_address')}>
                  <Link to={default_location?.map_link} target={'_blank'}>
                    {default_location?.full_address}
                  </Link>
                </ListItem>
                <ListContactsItem contacts={default_location?.contacts}/>
              </>
              : <ListEmptyItem/>}
          </List>

        </CardBody>
      </Card>

      <CompanyLocationsDrawer
        companyUuid={company.uuid}
        isDefault={true}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        location={default_location}
        handleUpdate={handleUpdate}
        handleCreate={handleUpdate}
        handleDestroy={handleDestroy}
      />
    </>
  )

}
