import React from 'react';
import { NumericFormat } from 'react-number-format';

function DecimalDisplay({ value }) {
  return value || value === 0 ? <NumericFormat
    value={value}
    displayType={"text"}
    decimalSeparator=','
    decimalScale={2}
    fixedDecimalScale={true}
    thousandSeparator='.'
  /> : '---'
}

export default DecimalDisplay;
