import React from 'react';
import { Outlet } from 'react-router-dom';
import logo from '../../../assets/images/logo-dark.png';
import authBg from '../../../assets/images/auth-bg.jpg';

function PublicLayout() {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center vh-100"
      style={{
        background: `url(${authBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      <div className="mb-2 d-flex align-items-center justify-content-center gap-2">
        <img src={logo} alt="3dBeta" height="60"/>
        <span
          className="fs-3 fw-bold text-primary border border-4 border-primary ps-1 border-top-0 border-bottom-0 border-end-0">
          QUADIF
        </span>
      </div>
      <Outlet/>
    </div>
  );
}

export default PublicLayout;
