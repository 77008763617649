import React, { useContext } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { OfferContext } from '../../../offerContext/offerContext';
import { AppContext } from '../../../../../../components/AppContext';
import { Form, FormikProvider, useFormik } from 'formik';
import subOfferItemsService from '../../../../../../services/offers/subOfferItemsService';
import SubOfferItemTypeEnum from '../../../../../../constants/enums/SubOfferItemTypeEnum';
import { toast } from 'react-toastify';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../../../components/UI/Forms/FormInput';
import { FormActions } from '../../../../../../components/UI/Forms/FormActions';


const validationSchema = Yup.object().shape({
  description: Yup.string().max(40).nullable().required()
})


const SubOfferItemMessageAdder = ({ subOfferUuid, offerRevisionUuid, setIsNewClicked, action }) => {

  const { t } = useTranslation()
  const initialValues = {
    description: ''
  };
  const { offer, dispatchAction } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setLoadingOverlayState(true)
    subOfferItemsService.store(offer.uuid, offerRevisionUuid, subOfferUuid, {
      'description': values.description,
      'type': SubOfferItemTypeEnum.MESSAGE
    }).then(res => {
      resetForm();
      setIsNewClicked(false)
      dispatchAction({
        type: action,
        payload: {
          subOfferItem: res,
          subOfferUuid: subOfferUuid
        }
      })
    }).catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <FormGroup label={t('description')} name="description" required inline={false}>
          <FormInput name="description"/>
        </FormGroup>
        <FormActions
          withReset={false}
          onCancel={() => setIsNewClicked(null)}
        />
      </Form>
    </FormikProvider>
  )

}

export default SubOfferItemMessageAdder
