import React from 'react';
import FormGroup from '../UI/Forms/FormGroup';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import CompanyPicker from '../UI/Forms/Pickers/CompanyPicker';
import CompanyLocationPicker from '../UI/Forms/Pickers/CompanyLocationPicker';
import CompanyPersonPicker from '../UI/Forms/Pickers/CompanyPersonPicker';
import SelectedItem from './SelectedItem';

const CompanyFullSelection = () => {

  const { values, setValues, setFieldValue } = useFormikContext()
  const { t } = useTranslation()

  const handleChangeCompany = value => setValues({
    ...values,
    company: value,
    company_location: null,
    company_person: null
  })


  return <>

    <FormGroup name="customer" label={t('offers.customer')} required>

      {values.company
        ? <SelectedItem
          label={values?.company?.label}
          onDelete={() => handleChangeCompany(null)}
        />
        : <CompanyPicker
          handleChange={handleChangeCompany}
          value={values.company}
        />}

    </FormGroup>

    <FormGroup name="company_location" label={t('offers.location')}>
      {values.company_location
        ? <SelectedItem
          label={values?.company_location?.label}
          onDelete={() => setFieldValue('company_location', null)}
        />
        : <CompanyLocationPicker
          companyId={values?.company?.value}
          handleChange={value => setFieldValue('company_location', value)}
        />}
    </FormGroup>


    <FormGroup
      name="company_person"
      label={t('referent')}
    >
      {values.company_person
        ? <SelectedItem
          label={values?.company_person?.label}
          onDelete={() => setFieldValue('company_person', null)}
        />
        : <CompanyPersonPicker
          companyId={values?.company?.value}
          handleChange={value => setFieldValue('company_person', value)}
        />}
    </FormGroup>
  </>

}

export default CompanyFullSelection
