import api from '../../configs/api.config';

const url = '/color-presets';
const fetchAll = (params) => api.get(url, { params }).then((res) => res.data);
const fetch = (id) => api.get(`${url}/${id}`).then((res) => res.data.data);
const store = (params) => api.post(url, params).then((res) => res.data.data);
const update = (id, params) => api.patch(`${url}/${id}`, params).then((res) => res.data.data);
const destroy = (id) => api.delete(`${url}/${id}`).then((res) => res.data.data);

const colorPresetsService = {
  fetchAll,
  fetch,
  store,
  update,
  destroy,
};

export default colorPresetsService;
