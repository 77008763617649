import React, { useMemo } from 'react';
import { useRequest } from 'ahooks';
import worksService from '../../../../services/works/worksService';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import workColorTableConfig from './workColorTable.config';
import ColorWorkFilters from './ColorWorkFilters';

const ColorWorks = ({ colorId }) => {

  const columns = useMemo(() => workColorTableConfig, []);
  const { data, loading, run } = useRequest(worksService.fetchAll, {
    manual: true,
    ready: colorId
  })

  const [extraParams, setExtraParams] = React.useState({
    filter: {
      color: colorId,
      number: [],
      description: [],
      year: [],
      sap_orders: []
    }
  })

  const handleSubmit = values => setExtraParams(ext => ({
    filter: {
      ...ext.filter,
      number: values.number.map(v => v.value),
      description: values.description.map(v => v.value),
      year: values.year.map(v => v.value),
      sap_orders: values.sap_orders.map(v => v.value),
    }
  }))

  return (
    <>
      <ColorWorkFilters
        defaultValues={extraParams.filter}
        handleUpdate={handleSubmit}
      />

      <div className='card w-100'>
        <div className='card-body'>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data || []}
            columns={columns}
            sortable
            onParamsChange={run}
            extraParams={extraParams}
          />
        </div>
      </div>
    </>
  )

}

export default ColorWorks
