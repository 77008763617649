import React from 'react';
import { Field } from 'formik';

export function FormRadio(props) {
  return (
    <div className="form-check">
      <Field type="radio" className="form-check-input" {...props} />
      <label>{props.label}</label>
    </div>
  );
}
