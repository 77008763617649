import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const cards = [{
  title: 'Standard',
  url: 'standards'
}, {
  title: 'enums.part_types.CUSTOM',
  url: 'customs'
}, {
  title: 'enums.part_types.ASSEMBLY',
  url: 'assemblies'
}, {
  title: 'enums.part_types.BETA',
  url: 'beta'
}, {
  title: 'enums.part_types.BETA_TOOL',
  url: 'beta-tools'
}];


export const PartsDashboard = () => {

  const { t } = useTranslation()

  return (
    <div className='row'>
      {cards.map(c =>
        <Link className='col-lg-2 col-md-4 col-sm-6 col-xs-12 ' to={c.url} key={c.title}>
          <div className='card-body card mb-0 text-center fs-5'>
            {t(c.title)}
          </div>
        </Link>
      )}
    </div>)

}
