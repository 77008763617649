import React from 'react';
import ReactCountryFlag from 'react-country-flag';

const MarketDisplay = ({ market }) => {

  return market ?
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <ReactCountryFlag
        countryCode={market.value}
        svg
        style={{ width: '2em', height: '2em' }}
        title={market.label}
      />
      {market.label}
    </div>
    : <>---</>

}

export default MarketDisplay
