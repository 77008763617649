import React from 'react';
import { Outlet } from 'react-router-dom';

function UsersWrapper() {

  return (
    <Outlet/>
  );
}

export default UsersWrapper;
