import Drawer from '../../../components/UI/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import React from 'react';
import { FormCheckbox } from '../../../components/UI/Forms/FormCheckbox';
import { FormActions } from '../../../components/UI/Forms/FormActions';
import DestroyButton from '../../../components/UI/Buttons/Destroy/DestroyButton';
import usersService from '../../../services/usersService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().max(100).nullable().required(),
  last_name: Yup.string().max(100).nullable().required(),
  email: Yup.string().email().max(100).nullable().required(),
  permissions: Yup.array().nullable().required(),
});

export const UserDrawer = ({ user = undefined, isOpen, onUpdate, permissions, setIsOpen }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const initialValues = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email || "",
    permissions: (user?.permissions ?? []).map(p => p.id.toString())
  }

  const handleSubmit = (values, { setSubmitting }) => user
    ? usersService.update(user.uuid, values)
    .then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
    : usersService.store(values).then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const handleDestroy = () => usersService.destroy(user.uuid)
  .then(res => {
    toast.success(t('deleted'));
    navigate('../')
  }).catch((err) => toast.error(err?.response?.data?.message))

  return (
    <Drawer
      title={t('users.sing')}
      status={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="first_name" label={t('users.first_name')} required>
            <FormInput name="first_name"/>
          </FormGroup>
          <FormGroup name="last_name" label={t('users.last_name')} required>
            <FormInput name="last_name"/>
          </FormGroup>
          <FormGroup name="email" label={t('users.email')} required>
            <FormInput name="email" type="email"/>
          </FormGroup>

          {permissions.map(permission =>
            <FormCheckbox
              name='permissions'
              key={permission.name}
              value={permission.id}
              checked={(formik.values.permissions ?? []).includes(permission.id.toString())}
              onChange={event => event.target.checked
                ? formik.setFieldValue('permissions', [...formik.values.permissions, event.target.value])
                : formik.setFieldValue('permissions', formik.values.permissions.filter(v => v !== event.target.value))
              }
              label={t("permissions.names." + permission.label)}/>
          )}

          <FormActions onCancel={() => setIsOpen(false)} withReset={false}>
            {user && <DestroyButton onClick={handleDestroy}/>}
          </FormActions>
        </Form>
      </FormikProvider>

    </Drawer>
  )
}

