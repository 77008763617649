import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const PartBomNavigation = ({ paginationResponse, onPrevClicked, onCurrentClicked, onNextClicked }) => {

  const { links, data = [], meta } = paginationResponse
  const { t } = useTranslation()

  if (data.length < 1) {
    return <></>
  }

  return (
    <div className="py-2 d-flex align-items-center justify-content-center gap-2">
      {links.next
        ? <>
          <button type='button' id='prev_revision_button' className='btn btn-light btn-icon' onClick={() => onPrevClicked(meta?.current_page + 1)}>
            <i className='fa fa-chevron-left'/>
          </button>
        </> : <></>
      }
      {links.first
        ? <>
          <UncontrolledTooltip target='current_revision_button'>Revisione corrente</UncontrolledTooltip>
          <button type='button' id='current_revision_button' className='btn btn-light btn-icon' onClick={() => onCurrentClicked(1)}>
            <i className='fa fa-check'/>
          </button>
        </>
        : <></>
      }

      <div className="fw-bold">
        {t('part_boms.revision')} {data[0].revision}
      </div>

      {links.prev ?
        <>
          <UncontrolledTooltip target='prev_revision_button'>Revisione successiva</UncontrolledTooltip>
          <button type='button' id='prev_revision_button' className='btn btn-light btn-icon' onClick={() => onNextClicked(meta?.current_page - 1)}>
            <i className='fa fa-chevron-right'/>
          </button>
        </> : <></>}

    </div>
  )

}

export default PartBomNavigation
