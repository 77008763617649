import api from '../../configs/api.config';
import codeTypeEnum from '../../constants/enums/CodeTypeEnum';

const url = '/base-codes';
const categoryUrl = '/categories';
const codesUrl = '/codes';
const measuresUrl = '/measures';

const fetchAll = (params) => api.get(url, { params }).then((res) => res.data);
const fetchSimilar = (params) => api.post(`${url}-similar`, params).then((res) => res.data.data);
const fetch = (id) => api.get(`${url}/${id}`).then((res) => res.data.data);
const nextAssemblyCategory = () => api.get(`${categoryUrl}/assembly-available`)
  .then((res) => res.data);

const availableMeasure = partCollectionId => api.get(`${measuresUrl}/available/${partCollectionId}`).then((res) => res.data);

const store = (codeType, params) => {
  switch (codeType) {
    case codeTypeEnum.values.CATEGORY:
      return api.post(categoryUrl, params).then((res) => res.data.data);
    case codeTypeEnum.values.CODE:
      return api.post(codesUrl, params).then((res) => res.data.data);
    default: // codeTypeEnum.values.MEASURE:
      return api.post(measuresUrl, params).then((res) => res.data.data);
  }
};

const update = (codeType, id, params) => {
  switch (codeType) {
    case codeTypeEnum.values.CATEGORY:
      return api.patch(`${categoryUrl}/${id}`, params).then((res) => res.data.data);
    case codeTypeEnum.values.CODE:
      return api.patch(`${codesUrl}/${id}`, params).then((res) => res.data.data);
    default: // codeTypeEnum.values.MEASURE:
      return api.patch(`${measuresUrl}/${id}`, params).then((res) => res.data.data);
  }
};
const destroy = (id) => api.delete(`${url}/${id}`).then((res) => res.data.data);

const codesService = {
  fetchAll,
  fetchSimilar,
  fetch,
  nextAssemblyCategory,
  store,
  update,
  destroy,
  availableMeasure
};

export default codesService;
