import { format, parseISO } from 'date-fns';
import React from 'react';

function DateDisplay({ value, formatString = 'dd/MM/yyyy' }) {

  return value
    ? <>{value && format(parseISO(value), formatString)}</>
    : <>---</>;
}

export default DateDisplay;
