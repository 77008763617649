import React from 'react';
import { Trans } from 'react-i18next';

const SELLER = 'SELLER';
const DESIGNER = 'DESIGNER';

const values = {
  SELLER,
  DESIGNER
};


const collection = [
  { value: SELLER, label: <Trans i18nKey='enums.work_user_role.SELLER' />, color: 'info' },
  { value: DESIGNER, label: <Trans i18nKey='enums.work_user_role.DESIGNER' />, color: 'success' },
];

const WorkUserRoleEnum = {
  values,
  collection,
};

export default WorkUserRoleEnum;
