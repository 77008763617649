import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WorkSapOrdersDrawer from './WorkSapOrdersDrawer';
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import EditButton from '../../../../../components/UI/Buttons/EditButton';
import { List } from '../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';
import { Tag } from '../../../../../components/UI/Tags/Tag';

const WorkSapOrders = () => {

  const { work, dispatchAction } = useContext(WorkContext)
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleUpdate = res => {
    setIsOpen(false)
    dispatchAction({ type: workActionTypes.UPDATE_SAP_ORDERS, payload: res })
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>SAP</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>
          <List>
            <ListItem label={t('works.sap_orders')}>
              {work.sap_orders.length > 0
                ? <div className='d-flex justify-content-start align-items-center gap-2'>
                  {work.sap_orders.map((so, index) => <Tag key={so}>{so}</Tag>)}
                </div>
                : t('no-results')}
            </ListItem>
          </List>
        </CardBody>
      </Card>

      <WorkSapOrdersDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onUpdate={handleUpdate}
      />

    </>
  )

}

export default WorkSapOrders
