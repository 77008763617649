import React, { useContext } from 'react';
import Drawer from '../../../../../components/UI/Drawer/Drawer';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import worksService from '../../../../../services/works/worksService';
import { WorkContext } from '../../WorkContext/WorkContext';
import { AppContext } from '../../../../../components/AppContext';
import { FormActions } from '../../../../../components/UI/Forms/FormActions';
import CompanyPicker from '../../../../../components/UI/Forms/Pickers/CompanyPicker';
import FormGroup from '../../../../../components/UI/Forms/FormGroup';
import { useTranslation } from 'react-i18next';
import CompanyPersonPicker from '../../../../../components/UI/Forms/Pickers/CompanyPersonPicker';
import CompanyLocationPicker from '../../../../../components/UI/Forms/Pickers/CompanyLocationPicker';

const WorkCustomersDrawer = ({ isOpen, setIsOpen, onUpdate, billTo, company, companyLocation, companyPerson }) => {

  const { t } = useTranslation();
  const { work } = useContext(WorkContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const initialValues = {
    bill_to: billTo ? {
      value: billTo.id,
      label: billTo?.business_name
    } : null,
    company: company ? {
      value: company.id,
      label: company?.business_name
    } : null,
    company_location: companyLocation ? {
      value: companyLocation.id,
      label: companyLocation?.label
    } : null,
    company_person: companyPerson ? {
      value: companyPerson.id,
      label: companyPerson?.full_name
    } : null
  }


  const validationSchema = Yup.object().shape({
    bill_to: Yup.object().nullable().required(),
    company: Yup.object().nullable().required(),
    company_location: Yup.object().nullable().notRequired(),
    company_person: Yup.object().nullable().notRequired(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setLoadingOverlayState(true)
    worksService.update(work.uuid, {
      ...values,
      bill_to_id: values.bill_to?.value,
      company_id: values.company?.value,
      company_location_id: values.company_location?.value ?? "",
      company_person_id: values.company_person?.value ?? "",
    })
    .then(res => onUpdate(res))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })


  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title='Committente'
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name={t('works.bill_to')} label={'Fatturazione'} required description={t('works.bill_to_message')}>
            <CompanyPicker
              handleChange={value => formik.setFieldValue("bill_to", value)}
              value={formik.values.bill_to}
            />
          </FormGroup>

          <FormGroup name={'company'} label={t('works.customer')} required description={t('works.customer_message')}>
            <CompanyPicker
              handleChange={value => formik.setFieldValue("company", value)}
              value={formik.values.company}
            />
          </FormGroup>

          <FormGroup name={'company_person'} label={t('works.person')} description={t('works.person_message')}>
            <CompanyPersonPicker
              companyId={formik.values.company?.value}
              handleChange={value => formik.setFieldValue("company_person", value)}
              value={formik.values.company_person}
            />
          </FormGroup>

          <FormGroup name={'company'} label={t('works.location')} description={t('works.location_message')}>
            <CompanyLocationPicker
              companyId={formik.values.company?.value}
              handleChange={value => formik.setFieldValue('company_location', value)}
              value={formik.values.company_location}
            />
          </FormGroup>

          <FormActions
            onCancel={() => setIsOpen(false)}
            withReset={true}
          />
        </Form>
      </FormikProvider>
    </Drawer>
  )
}

export default WorkCustomersDrawer
