import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../../components/UI/Drawer/Drawer';
import locationPickerFormConfig from '../../../../components/UI/Forms/Pickers/LocationPicker/locationPickerInitialValues';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import FormGroup from '../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../components/UI/Forms/FormInput';
import { FormDisplay } from '../../../../components/UI/Displays/FormDisplay';
import LocationPicker from '../../../../components/UI/Forms/Pickers/LocationPicker/LocationPicker';
import LocationPickerForm from '../../../../components/UI/Forms/Pickers/LocationPicker/LocationPickerForm';
import { toast } from 'react-toastify';
import companyLocationsService from '../../../../services/companies/companyLocationsService';
import CompanyLocationsService from '../../../../services/companies/companyLocationsService';
import { ContactsArrayEditor } from '../Contacts/ContactsArrayEditor';
import { contactsValidation } from '../Contacts/contacts.form';
import { FormActions } from '../../../../components/UI/Forms/FormActions';
import DestroyButton from '../../../../components/UI/Buttons/Destroy/DestroyButton';

const validationSchema = Yup.object().shape({
  label: Yup.string().when('default', {
    is: true,
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().max(255).nullable().required(),
  }),
  locality: Yup.string().max(255).required(),
  country: Yup.string().max(255).required(),
  default: Yup.bool().required(),
  contacts: contactsValidation
});

export const CompanyLocationsDrawer = ({
                                         companyUuid,
                                         location = undefined,
                                         isDefault = false,
                                         isOpen,
                                         setIsOpen,
                                         handleUpdate,
                                         handleCreate,
                                         handleDestroy
                                       }) => {

  const { t } = useTranslation()

  const initialValues = {
    label: location?.label ?? '',
    default: isDefault,
    contacts: location ? location.contacts.map(c => ({
      id: c.id,
      label: c.label,
      value: c.value
    })) : [],
    ...locationPickerFormConfig.setLocationPickerInitialValues(location)
  }


  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      location
        ? companyLocationsService.update(companyUuid, location.uuid, values)
        .then(res => handleUpdate(res))
        .catch(err => toast.error(err?.response?.data?.message))
        .finally(() => setSubmitting(false))
        : companyLocationsService.store(companyUuid, values)
        .then(res => handleCreate(res))
        .catch(err => toast.error(err?.response?.data?.message))
        .finally(() => setSubmitting(false))
    }
  })

  const handleDelete = () => CompanyLocationsService.destroy(companyUuid, location?.uuid)
  .then(res => handleDestroy(location))
  .catch(err => toast.error(err?.response?.data?.message))

  return (
    <Drawer
      title={isDefault ? t('companies.registered_office') : t('locations.sing')}
      status={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <FormikProvider value={formik}>
        <Form>
          {!formik.values.default &&
            <FormGroup
              name="label"
              label={t("locations.label")}
              inline={false}
            >
              <FormInput name="label"/>
            </FormGroup>}

          <div className="mb-3">
            <FormDisplay label={t("search")}>
              <LocationPicker onPlaceSelected={place => {
                formik.setValues(values => ({
                  ...values,
                  ...place
                }))
              }}/>
            </FormDisplay>
          </div>

          <LocationPickerForm/>
          <ContactsArrayEditor/>

          <FormActions onCancel={() => setIsOpen(false)} withReset={true}>
            {location?.uuid !== undefined &&
              <DestroyButton
                onClick={() => handleDelete()}
                message={`Sei sicuro di voler eliminare la sede ${location?.label}, i suoi contatti e i suoi recapiti?`}
              />}
          </FormActions>

        </Form>
      </FormikProvider>
    </Drawer>
  )

}
