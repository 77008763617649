import React, { useContext, useEffect } from 'react';
import Drawer from '../../../../../../components/UI/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { FormPercentage } from '../../../../../../components/UI/Forms/FormPercentage';
import offerRevisionsService from '../../../../../../services/offers/offerRevisionsService';
import { toast } from 'react-toastify';
import { OfferContext } from '../../../offerContext/offerContext';
import { offerActionTypes } from '../../../offerContext/offerReducer/offerActionTypes';
import { List } from '../../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../../components/UI/ItemList/ListItem';
import { FormActions } from '../../../../../../components/UI/Forms/FormActions';
import { InfoTooltip } from '../../../../../../components/UI/Tooltips/InfoTooltip';

const OfferPrintDrawer = ({ isOpen, setIsOpen }) => {

  const { t } = useTranslation()
  const { offer, dispatchAction } = useContext(OfferContext)
  const initialValues = { percentage: 0 }
  const validationSchema = Yup.object().shape({
    percentage: Yup.number().moreThan(-100).lessThan(100).notOneOf([0]).nullable().required()
  })

  useEffect(() => formik.resetForm(initialValues), [isOpen]);

  const handleSubmit = (values, { setSubmitting }) => offerRevisionsService.massivePriceUpdate(
    offer.uuid,
    offer.offer_revision.uuid,
    values
  )
  .then(res => dispatchAction({ type: offerActionTypes.MASSIVE_PRICE_UPDATE, payload: values }))
  .catch(err => toast.error(err?.response?.data?.message))
  .finally(() => {
    setSubmitting(false)
    setIsOpen(false)
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <Drawer status={isOpen} toggle={() => setIsOpen(false)} title={`${t('edit')} ${t('sub_offer_items.offer_item_price_cad')}`}>
      <FormikProvider value={formik}>
        <Form>
          <List>
            <ListItem
              label={t('sub_offer_items.offer_item_massive_percentage')}
              extra={<InfoTooltip message={t('sub_offer_items.offer_item_massive_percentage_description')} /> }
            >
              <FormPercentage name='percentage'/>
            </ListItem>
          </List>
          <FormActions
            onCancel={() => setIsOpen(false)}
            withReset={true}
          />
        </Form>
      </FormikProvider>
    </Drawer>
  )

}

export default OfferPrintDrawer
