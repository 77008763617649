import React, { useState } from 'react';
import ColorConfigsDrawer from './ColorConfigsDrawer';
import { useTranslation } from 'react-i18next';
import { CardContentBetween } from '../UI/Card/CardContentBetween';
import { CardLabel } from '../UI/Card/CardLabel';
import EditButton from '../UI/Buttons/EditButton';
import { List } from '../UI/ItemList/List';
import { ListItem } from '../UI/ItemList/ListItem';
import { InfoTooltip } from '../UI/Tooltips/InfoTooltip';
import { TagList } from '../UI/Tags/TagList';

const ColorConfigs = ({ serviceParams, serviceCall, colorConfigs = [], colorParts = [], onUpdate, canEdit = true }) => {

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const handleUpdate = res => {
    setIsOpen(false)
    onUpdate(res)
  }

  return (
    <>
      <CardContentBetween>
        <CardLabel>{t('color_configs.sing')}</CardLabel>
        {canEdit && <EditButton onClick={() => setIsOpen(true)}/>}
      </CardContentBetween>

      <List>
        {colorConfigs.length > 0
          ? colorConfigs
          //.filter(colorConfig => colorConfig.colors.length > 0)
          .map(colorConfig =>
            <ListItem
              key={colorConfig.uuid}
              label={colorConfig?.color_part.description}
              extra={<InfoTooltip message={colorConfig?.note}/>}
            >
              <TagList tags={colorConfig.colors}/>
            </ListItem>
          ) : <li className='list-group-item px-1'>{t('no-results')}</li>
        }
      </List>


      <ColorConfigsDrawer
        colorParts={colorParts}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        colorConfigs={colorConfigs}
        serviceParams={serviceParams}
        serviceCall={serviceCall}
        onUpdate={handleUpdate}
      />

    </>
  )

}

export default ColorConfigs
