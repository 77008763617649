import React from 'react';
import ColorsWrapper from '../../../pages/Colors/ColorsWrapper';
import Colors from '../../../pages/Colors/Colors/Colors';
import Color from '../../../pages/Colors/Color/Color';

const colorsRoutes = {
  path: 'colors/*',
  element: <ColorsWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <Colors/>,
  }, {
    path: ':id',
    element: <Color/>,
  }]
};

export default colorsRoutes;
