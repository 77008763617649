import { Tag } from './Tag';

export const TagList = ({ tags = [], color = 'primary',  children }) => {

  return (
    <div className='d-flex gap-1 align-items-center justify-content-start gap-1'>
      {tags.length > 0 ? tags.map(tag =>
        <Tag
          key={tag.value}
          color={color}
        >
          {tag.label}
        </Tag>
      ) : <>---</>}
    </div>
  )

}
