import React from 'react';
import { Field } from 'formik';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { reactSelectStyles } from '../../../configs/reactSelect.config';

export function FormSelect(props) {
  const { t } = useTranslation();

  return (
    <Field
      name={props.name}
      disabled={props.disabled}
      component={InnerSelect}
      options={props.options}
      required={props.required || false}
      myOnChange={props.onChange}
      isMulti={props.multiple}
      loading={props.loading}
      placeholder={props.placeholder || t('select')}
    />
  );
}

function InnerSelect({
                       options,
                       field,
                       form,
                       disabled,
                       myOnChange,
                       isMulti,
                       required,
                       placeholder,
                       loading,
                     }) {
  myOnChange ??= () => {
  };

  const onChangeHandler = (option) => {
    const selected = isMulti
      ? (option ? (option).map((item) => item.value) : [])
      : (option ? (option).value : undefined);
    myOnChange(selected);
    form.setFieldValue(field.name, selected);
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value?.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    }
    return isMulti ? [] : ('');
  };

  return (
    <Select
      isDisabled={disabled}
      placeholder={placeholder}
      name={field.name}
      options={options}
      isClearable
      value={getValue()}
      onChange={onChangeHandler}
      onBlur={field.onBlur}
      isMulti={isMulti}
      required={required}
      styles={reactSelectStyles}
    />
  );
}
