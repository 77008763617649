import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';
import optionsService from '../../../../services/optionsService';

const CompanyLocationPicker = props => {

  const { t } = useTranslation()
  const { companyId, handleChange } = props;
  const { loading, runAsync } = useRequest(optionsService.companyLocations, { manual: true });
  const selectInputRef = useRef();

  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({
      filter: {
        label: value,
        company_id: companyId
      },
    }).then(res => callback(res))
    .catch(() => callback([]));
  };

  useEffect(() => {
    if (!companyId) {
      selectInputRef && selectInputRef.current.clearValue();
    }
  }, [companyId]);

  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);


  return (
    <AsyncSelect
      {...props}
      ref={selectInputRef}
      isDisabled={!!!companyId}
      cacheOptions={false}
      defaultValue={undefined}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={handleChange}
      loading={loading}
      placeholder={props?.placeholder || t('type_to_search')}
      styles={reactSelectStyles}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
    />
  );
}

export default CompanyLocationPicker;
