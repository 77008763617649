import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classname from 'classnames';
import NavBarDropdownTitle from '../NavBarDropdownTitle';
import NavBarSubDropdownTitle from '../NavBarSubDropdownTitle';

function UtManagementDropdown() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown">
        <NavBarDropdownTitle
          label={t('menu.master_data')}
          state={isOpen}
          handleState={setIsOpen}
          icon="fa fa-list"
        />
        <div className={classname('dropdown-menu', { show: isOpen })}>
          <div className="dropdown">
            <NavBarSubDropdownTitle
              label={t('menu.manage_parts')}
              icon="fa fa-tools"
            />
            <div className={classname('dropdown-menu', { show: isOpen })}>
              <Link to="parts/standards" className="dropdown-item">
                {t('menu.manage_catalogue_parts')}
              </Link>
              <Link to="parts/customs" className="dropdown-item">
                {t('menu.manage_custom_parts')}
              </Link>
              <Link to="parts/assemblies" className="dropdown-item">
                {t('menu.manage_assembly_parts')}
              </Link>

              <div className="dropdown-divider"/>
              <Link to="/parts/beta" className="dropdown-item">
                {t('menu.manage_beta_parts')}
              </Link>
              <Link to="/parts/beta-tools" className="dropdown-item">
                {t('menu.manage_beta_tool_parts')}
              </Link>

              <div className="dropdown-divider"/>

              <Link to="/codes" className="dropdown-item">
                {t('codes.plural')}
              </Link>
              <Link to="/words" className="dropdown-item">
                {t('words.plural')}
              </Link>
            </div>
          </div>
          <div className="dropdown">
            <NavBarSubDropdownTitle
              label={t('menu.manage_colors')}
              icon="fa fa-brush"
            />
            <div className={classname('dropdown-menu', { show: isOpen })}>
              <Link to="/colors" className="dropdown-item">
                {t('colors.plural')}
              </Link>
              <Link to="/color-presets" className="dropdown-item">
                {t('color_presets.plural')}
              </Link>
            </div>
          </div>
          <Link to="/batch" className="dropdown-item">
            <i className="fa fa-database me-2"/>
            {t('menu.manage_batch')}
          </Link>
        </div>

      </li>
    </ul>
  );
}

export default UtManagementDropdown;
