import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import partTypeEnum from '../../../constants/enums/PartTypeEnum';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import PartMeasures from '../components/PartMeasures';
import WordsSort from '../../BaseCodes/components/WordsSort/WordsSort';
import partsService from '../../../services/parts/partsService';
import { useRequest } from 'ahooks';
import OptionsService from '../../../services/optionsService';
import DisplayFormattedMeasures from './components/DisplayFormattedMeasures';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import { FormSelect } from '../../../components/UI/Forms/FormSelect';
import AssyStatePicker from '../../../components/UI/Forms/Pickers/AssyStatePicker';

const initialValues = {
  category_id: undefined,
  words: [],
  assy_states: [],
  width: '',
  depth: '',
  height: '',
  note: '',
  drawing_note: '',
}

const validationSchema = yup.object().shape({
  category_id: yup.number().required(),
  words: yup.array().min(1).required(),
  assy_states: yup.array().min(1).required(),
  width: yup.number().min(0).nullable().notRequired(),
  depth: yup.number().min(0).nullable().notRequired(),
  height: yup.number().min(0).nullable().notRequired(),
  note: yup.string().max(800).nullable().notRequired(),
  drawing_note: yup.string().max(200).nullable().notRequired(),
})


function AssemblyCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [category, setCategory] = React.useState(undefined);

  const { data: assemblyCategories = [] } = useRequest(OptionsService.assemblyCategories)

  const handleSubmit = (values, { setSubmitting }) => partsService.store(partTypeEnum.values.ASSEMBLY, {
    ...values,
    words: values.words.map(w => w.value)
  }).then(res => {
    toast.success(t('created'))
    navigate(`../${res.uuid}`);
  })
  .catch(err => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false));

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })


  return (
    <FormikProvider value={formik}>
      <Form>

        <h5 className="text-uppercase">{t('enums.part_types.ASSEMBLY')}</h5>

        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-4'>
                <FormDisplay label={t('parts.code')}>
                  {formik.values?.assy_states?.length > 0 ? formik.values.assy_states.map((assyState, index) => (
                    <div key={index}>
                      3D
                      {category?.code || '---'}
                      XXXX
                      {assyState}
                    </div>
                  )) : (
                    <>
                      3D
                      {category?.code || '---'}
                      XXXXXX
                    </>
                  )}
                </FormDisplay>
              </div>
              <div className='col-lg-8'>
                <FormDisplay label={t('description')}>
                  {formik.values.words.map(w => w.label).join(' ')}
                  &nbsp;
                  <DisplayFormattedMeasures
                    width={formik.values.width}
                    depth={formik.values.depth}
                    height={formik.values.height}
                  />
                </FormDisplay>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4'>
                <h5>&nbsp;</h5>
                <FormGroup
                  required
                  name="category_id"
                  label={t('enums.code_types.CATEGORY')}
                >
                  <FormSelect
                    onChange={(value) => formik.setFieldValue('category_id', value?.id || undefined)}
                    name="category_id"
                    placeholder={t('enums.code_types.CATEGORY')}
                    options={assemblyCategories}
                  />
                </FormGroup>
                <FormGroup required name="assy_states" label={t('parts.state')}>
                  <AssyStatePicker
                    name="assy_states"
                    handleChange={stateValues => formik.setFieldValue('assy_states', stateValues || [])}
                  />
                </FormGroup>
              </div>
              <div className='col-lg-4'>
                <WordsSort/>
              </div>
              <div className='col-lg-4'>
                <PartMeasures/>
              </div>
            </div>

            <FormGroup name="note" label={t('note')}>
              <FormInput name="note" rows={5} as="textarea"/>
            </FormGroup>

            <FormGroup name="drawing_note" label={t('parts.drawing_note')}>
              <FormInput name="drawing_note" rows={3} as="textarea"/>
            </FormGroup>

            <div className="d-flex align-items-center justify-content-end gap-2">
              <CancelButton onClick={() => navigate('/parts/assemblies')}/>
              <FormikSaveButton/>
            </div>

          </div>
        </div>

      </Form>
    </FormikProvider>
  );
}

export default AssemblyCreate;
