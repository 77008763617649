import React from 'react';
import { useTranslation } from 'react-i18next';
import EuroDisplay from '../../../../components/UI/Displays/EuroDisplay';
import { sumBy } from 'lodash';
import { Link } from 'react-router-dom';
import DecimalDisplay from '../../../../components/UI/Displays/DecimalDisplay';

const PartBom = ({ part, partBom, onRowUpdated }) => {

  const { t } = useTranslation()

  return (
    <table className='table table-responsive align-middle table-striped table-condensed'>
      <thead>
      <tr>
        <th>{t('parts.sing')}</th>
        <th>{t('part_boms.qty')}</th>
        <th className='text-end '>{t('parts.price')}</th>
        <th className='text-end '>{t('parts.cost')}</th>
        <th className='text-end'>{t('part_boms.total_price')}</th>
        <th className='text-end'>{t('part_boms.total_cost')}</th>
      </tr>
      </thead>
      <tbody>
      {(partBom?.part_bom_rows || []).map((partBomRow, index) =>
        <tr key={index}>
          <td className='flex flex-column gap-1'>
            <div className='fw-bold'>
              {partBomRow.part_uuid ? <Link reloadDocument to={`/parts/${partBomRow.part_uuid}`}>{partBomRow.code}</Link> : partBomRow.code}
            </div>
            {partBomRow.description}
          </td>
          <td><DecimalDisplay value={partBomRow.qty}/></td>
          <td className="text-end"><EuroDisplay value={partBomRow.price}/></td>
          <td className="text-end"><EuroDisplay value={partBomRow.cost}/></td>
          <td className="text-end fw-bold text-info"><EuroDisplay value={partBomRow.total_price}/></td>
          <td className="text-end fw-bold text-success"><EuroDisplay value={partBomRow.total_cost}/></td>
        </tr>
      )}
      </tbody>
      <tfoot>
      <tr>
        <td colSpan={4}></td>
        <td className="text-info fw-bold text-end">
          <EuroDisplay value={sumBy((partBom?.part_bom_rows || []), 'total_price')}/>
        </td>
        <td className="text-success fw-bold text-end">
          <EuroDisplay value={sumBy((partBom?.part_bom_rows || []), 'total_cost')}/>
        </td>
        <td></td>
      </tr>
      </tfoot>
    </table>
  )

}

export default PartBom
