import React from 'react';
import Drawer from '../../../../components/UI/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import localesConfig from '../../../../locales/locales.config';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import wordsService from '../../../../services/parts/wordsService';
import { toast } from 'react-toastify';
import { WordForm } from '../../components/WordForm';

const validationSchema = Yup.object().shape({
  label: Yup.string().required(),
  locales: Yup.array().of(
    Yup.object().shape({
      lang: Yup.string().required(),
      description: Yup.string().required(),
      short_description: Yup.string().max(40).required(),
    }),
  ),
});
export const WordDetailsDrawer = ({ word, isOpen, setIsOpen, onUpdate }) => {

  const { t } = useTranslation()
  const initialValues = {
    label: word.label,
    locales: localesConfig.availableTranslations.map((at) => ({
      label: at.label,
      lang: at.locale,
      description: word?.descriptions[at.locale] || '',
      short_description: word?.short_descriptions[at.locale] || '',
    })),
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => wordsService.update(word?.uuid, values)
    .then(res => {
      setIsOpen(false)
      onUpdate(res)
    })
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  })

  return <Drawer
    status={isOpen}
    toggle={() => setIsOpen(false)}
    title={t('edit')}>
    <FormikProvider value={formik}>
      <Form>
        <WordForm setIsOpen={setIsOpen}/>
      </Form>
    </FormikProvider>
  </Drawer>

}
