import React from 'react';
import { useSanctum } from 'react-sanctum';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import FormikResetButton from '../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import userService from '../../../services/userService';

function UserPasswordUpdater() {
  const { user, setUser } = useSanctum();
  const { t } = useTranslation();

  const initialValues = {
    password: '',
    password_confirmation: '',
    current_password: '',
  };

  const validation = Yup.object().shape({
    password: Yup.string().min(8).required(),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null],
      'Passwords does not match'),
    current_password: Yup.string().max(255).required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      enableReinitialize
      onSubmit={(values, { resetForm, setSubmitting }) => {
        userService.updatePassword(values)
        .then(() => toast.success(t('updated')))
        .catch((err) => toast.error(err?.response?.data?.message))
        .finally(() => {
          setSubmitting(false);
          resetForm();
        });
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <h5>{t('Password')}</h5>
              <FormGroup name="password" label={t('users.password')} required inline={false}>
                <FormInput name="password" type="password"/>
              </FormGroup>
              <FormGroup
                name="password_confirmation"
                label={t('users.password_confirmation')}
                required
                inline={false}
              >
                <FormInput name="password_confirmation" type="password"/>
              </FormGroup>
              <FormGroup
                name="current_password"
                label={t('users.current_password')}
                required
                inline={false}
              >
                <FormInput name="current_password" type="password"/>
              </FormGroup>
              <div className="d-flex align-content-center justify-content-end gap-1">
                <FormikResetButton/>
                <FormikSaveButton/>
              </div>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default UserPasswordUpdater;
