import React from 'react';
import { useTranslation } from 'react-i18next';

const CancelButton = props => {
  const { t } = useTranslation();

  return (
    <button className='btn btn-soft-secondary' type='button' {...props}>
      {t(props?.label ?? 'cancel')}
    </button>
  );
}

export default CancelButton;
