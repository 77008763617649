import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { Spinner, } from 'reactstrap';
import wordsService from '../../../services/parts/wordsService';
import { WordDetails } from './WordDetails/WordDetails';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import codeTableConfig from '../../BaseCodes/components/codeTable.config';
import { useTranslation } from 'react-i18next';

function Word() {

  const { id } = useParams();
  const { data: word, loading, mutate } = useRequest(wordsService.fetch, { defaultParams: [id] });
  const columns = useMemo(() => codeTableConfig, []);

  const handleUpdate = res => mutate(old => ({ ...old, ...res }));

  const {t} = useTranslation()

  return word ? (<>
    <div className='d-flex flex-column gap-4'>
      <div className='d-flex align-items-center justify-content-between gap-4'>
        <div>
          <div className='fs-3 fw-bolder text-dark'>{word.label}</div>
        </div>
        <div className='d-flex justify-content-end align-items-center gap-4'>
        </div>
      </div>

      <div className='row'>
        <div className="col-xl-3 col-lg-4 ">
          <div className="row">
            <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
              <WordDetails
                word={word}
                onUpdate={handleUpdate}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 ">
          <div className='card'>
            <div className='card-body'>
              <h5>{t('codes.plural')}</h5>
              <PaginatedTable
                isLoading={loading}
                options={paginatedTableConfig}
                sortable
                data={word.codes}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  </>) : <Spinner/>;
}

export default Word;
