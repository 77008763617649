import React from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import mediaService from '../../services/media/mediaService';

function MediaDropzone({ modelId, modelType, onSuccess }) {
  const { t } = useTranslation();

  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach(file => {
      mediaService.store(modelId, modelType, file)
      .then(res => onSuccess(res))
      .catch(err => toast.error(`${file.name} ${err}`));
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div style={{height: "100px"}} {...getRootProps({ className: 'bg-primary bg-opacity-10  mb-3 d-flex justify-content-center align-items-center' })}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      <span className="fs-6 text-muted">
        <i className="fa fa-upload me-1"/>
        Drop files to upload
      </span>
    </div>
  );
}

export default MediaDropzone;
