import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import trendChart from './trendChartOptions';

const PartTrendsChart = ({ trends }) => {

  const [series, setSeries] = useState([])
  const [options, setOptions] = useState(trendChart.trendChartOptions);

  useEffect(() => {
    if (trends) {
      setOptions(trendChart.setTrendChartOptions({ xaxis: { categories: trends.labels } }));
      setSeries(trends.series)
    }
  }, [trends])

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        width={'100%'}
        height={450}
      />
    </div>
  )
}

export default PartTrendsChart
