import React from 'react';

const A0 = 'A0';
const Q0 = 'Q0';
const Z0 = 'Z0';

const values = {
  A0,
  Q0,
  Z0,
};

const collection = [
  { value: A0, label: 'enums.assy_states.A0', color: 'info' },
  { value: Q0, label: 'enums.assy_states.Q0', color: 'info' },
  { value: Z0, label: 'enums.assy_states.Z0', color: 'info' },
];

const AssyStateEnum = {
  values,
  collection,
};

export default AssyStateEnum;
