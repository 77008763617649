import api from '../../configs/api.config';

const buildUrl = (companyUuid) => `/companies/${companyUuid}/company-persons`;
const fetchAll = (companyUuid, params) => api.get(buildUrl(companyUuid), { params }).then((res) => res.data.data);
const fetch = (companyUuid, personUuid) => api.get(`${buildUrl(companyUuid)}/${personUuid}`).then((res) => res.data.data);
const store = (companyUuid, params) => api.post(buildUrl(companyUuid), params).then((res) => res.data.data);
const update = (companyUuid, personUuid, params) => api.patch(`${buildUrl(companyUuid)}/${personUuid}`, params).then((res) => res.data.data);
const destroy = (companyUuid, personUuid) => api.delete(`${buildUrl(companyUuid)}/${personUuid}`).then((res) => res.data.data);

const companyPersonsService = {
  fetchAll,
  fetch,
  store,
  update,
  destroy,
};

export default companyPersonsService;
