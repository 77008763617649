import api from '../configs/api.config';

const url = 'users';

const fetchAll = (params) => api.get(url, { params }).then((res) => res.data);
const fetch = (id) => api.get(`${url}/${id}`).then((res) => res.data);
const store = (params) => api.post(url, params).then((res) => res.data);
const update = (id, params) => api.patch(`${url}/${id}`, params).then((res) => res.data);
const destroy = (id) => api.delete(`${url}/${id}`).then((res) => res.data);
const restore = (id) => api.post(`${url}/${id}`).then((res) => res.data);

const fetchPermissions = () => api.get('permissions').then(res => res.data.data)

const usersService = {
  fetchAll,
  fetch,
  store,
  update,
  destroy,
  restore,
  fetchPermissions
};

export default usersService;
