import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';
import MarketDisplay from '../../../components/UI/Displays/MarketDisplay';

const companyTableConfig = [{
  Header: <Trans i18nKey="companies.business_name"/>,
  accessor: 'business_name',
  Cell: ({ row, value }) => <TableLink to={`/companies/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="companies.vat_number"/>,
  accessor: 'vat_number'
}, {
  Header: <Trans i18nKey="companies.sdi"/>,
  accessor: 'sdi'
}, {
  Header: <Trans i18nKey="market"/>,
  accessor: 'market',
  Cell: ({ value }) => <MarketDisplay market={value}/>,
}, {
  Header: <Trans i18nKey="companies.sap_code"/>,
  accessor: 'sap_code'
}];

export default companyTableConfig;
