import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TranslatableDisplay from '../../../../components/UI/Displays/TranslatableDisplay';
import { WordDetailsDrawer } from './WordDetailsDrawer';

export const WordDetails = ({ word, onUpdate }) => {

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className='card w-100'>
        <div className='card-body'>

          <div className='d-flex justify-content-between align-items-center gap-4'>
            <span className='text-black-50'>{t('details')}</span>
            <button
              className='btn btn-light'
              type='button'
              onClick={() => setIsOpen(true)}
            >{t('edit')}
            </button>
          </div>

          <ul className='list-group list-group-flush'>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('words.label')}</div>
              {word.label}
            </li>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('description')}</div>
              <TranslatableDisplay
                currentTranslation={word.description}
                translatableArray={word.descriptions}
              />
            </li>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('short_description')}</div>
              <TranslatableDisplay
                currentTranslation={word.short_description}
                translatableArray={word.short_descriptions}
              />
            </li>
          </ul>
        </div>
      </div>


      <WordDetailsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        word={word}
        onUpdate={onUpdate}
      />

    </>
  )

}

