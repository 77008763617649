import React, { useContext, useRef, useState } from 'react';
import { PopoverBody, PopoverHeader, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import { useFormikContext } from 'formik';
import { OfferContext } from '../../../offerContext/offerContext';
import subOfferItemsService from '../../../../../../services/offers/subOfferItemsService';
import { toast } from 'react-toastify';
import EmptyDisplay from '../../../../../../components/UI/Displays/EmptyDisplay';
import { useTranslation } from 'react-i18next';
import EuroDisplay from '../../../../../../components/UI/Displays/EuroDisplay';
import { sortBy } from 'lodash';
import FormikSaveButtonIcon from '../../../../../../components/UI/Buttons/Save/FormikSaveButtonIcon';
import DefaultButtonIcon from '../../../../../../components/UI/Buttons/DefaultButtonIcon';
import DestroyButtonIcon from '../../../../../../components/UI/Buttons/Destroy/DestroyButtonIcon';

export const SubOfferItemActions = ({ subOfferItem, handleDeleteItem }) => {

  const { isSubmitting, isValid } = useFormikContext()
  const { offer, revision_editable } = useContext(OfferContext);
  const { t } = useTranslation()

  const [history, setHistory] = useState({
    alreadyFetched: false,
    data: [],
    loading: true
  })

  const handleItemHistory = () => history.alreadyFetched
    ? popoverRef.current.toggle()
    : subOfferItemsService.index(offer.uuid, subOfferItem.uuid)
    .then(res => setHistory(h => ({
      data: res.data,
      alreadyFetched: true,
      loading: false
    })))
    .catch((err) => toast.error(err?.response?.data?.message))


  const popoverRef = useRef(undefined);

  return (
    <>
      <UncontrolledTooltip target={`get_revisions_item_button_${subOfferItem.uuid}`}>
        Visualizza storico articolo nell'offerta {offer.number}
      </UncontrolledTooltip>

      <UncontrolledPopover ref={popoverRef} target={`get_revisions_item_button_${subOfferItem.uuid}`}>
        <PopoverHeader className='text-white bg-primary d-flex align-items-center justify-content-between'>
          Storico offerta {offer.number}
          <button className='btn btn-danger btn-icon btn-sm' type='button' onClick={() => popoverRef.current.toggle()}>
            <i className='fa fa-times'/>
          </button>
        </PopoverHeader>
        {history.data.length > 0
          ? (
            <table className='table table-sm table-hover'>
              <thead className='table-light'>
              <tr>
                <th className='text-center'>Rev</th>
                <th>{t('sub_offers.sing')}</th>
                <th>{t('parts.code')}</th>
                <th>{t('parts.cost')}</th>
                <th>{t('parts.price')}</th>
              </tr>
              </thead>
              <tbody>
              {sortBy(history.data, ['offer_revision_code', 'sub_offer_code']).map((entry, index) =>
                <tr key={index}>
                  <td className='text-center'>{entry.offer_revision_code}</td>
                  <td>{entry.sub_offer_code ?? t('sub_offers.tools')}</td>
                  <td>{entry.code}</td>
                  <td className='text-success fw-bold'><EuroDisplay value={entry.cost}/></td>
                  <td className='text-info fw-bold'><EuroDisplay value={entry.price}/></td>
                </tr>
              )}
              </tbody>
            </table>
          ) : <PopoverBody><EmptyDisplay/></PopoverBody>}
      </UncontrolledPopover>


      <DefaultButtonIcon
        icon='history'
        id={`get_revisions_item_button_${subOfferItem.uuid}`}
        onClick={handleItemHistory}
        disabled={isSubmitting || !isValid}>
      </DefaultButtonIcon>

      {revision_editable &&
        <>
          <UncontrolledTooltip target={`save_button_${subOfferItem.uuid}`}>Salva le modifiche apportate all'articolo</UncontrolledTooltip>
          <FormikSaveButtonIcon id={`save_button_${subOfferItem.uuid}`}/>
        </>}


      {subOfferItem.sub_work_item_id || !revision_editable
        ? <></>
        : <>
          <UncontrolledTooltip target={`destroy_button_${subOfferItem.uuid}`}>Elimina articolo dall'area</UncontrolledTooltip>
          <DestroyButtonIcon
            id={`destroy_button_${subOfferItem.uuid}`}
            type='button'
            message={`Sei sicuro di voler eliminare dalla sotto offerta il codice ${subOfferItem.code}?`}
            onClick={handleDeleteItem}/>
        </>
      }
    </>
  )


}
