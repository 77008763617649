import { ListItemLabel } from './ListItemLabel';

export const ListItem = ({ label = "", children = undefined, extra = undefined, inline = false }) => {
  return inline ? (
    <li className="list-group-item px-0 py-1 d-flex align-items-center justify-content-between">
      <div className='d-flex align-items-center justify-content-start gap-2 my-2'>
        <ListItemLabel label={label}/>
        {extra}
      </div>
      {children !== undefined ? children : '---'}
    </li>
  ) : (
    <li className="list-group-item px-0 py-1 d-flex flex-column">
      <div className="d-flex align-items-center justify-content-start gap-2 mb-2">
        <ListItemLabel label={label}/>
        {extra}
      </div>
      {children !== undefined ? children : '---'}
    </li>
  )

}
