import api from '../../configs/api.config';

const index = (
  offerUuid,
  subOfferUuid
) => api.get(`offers/${offerUuid}/sub-offer-items/${subOfferUuid}`)

const store = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid,
  params
) => api.post(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/sub-offer-items`, params).then(res => res.data.data);

const update = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid,
  subOfferItemUuid,
  params
) => api.patch(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/sub-offer-items/${subOfferItemUuid}`, params)
.then(res => res.data.data);

const destroy = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid,
  subOfferItemUuid
) => api.delete(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/sub-offer-items/${subOfferItemUuid}`)
.then(res => res.data.data);


const offersService = {
  index,
  store,
  update,
  destroy
};

export default offersService;
