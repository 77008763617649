import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import MultipleTextFilter from '../../../components/UI/Filters/MultipleTextFilter';
import CompanyFilter from '../../../components/UI/Filters/CompanyFilter';
import WorkStateFilter from '../../../components/UI/Filters/WorkStateFilter';
import WorkProductionStateFilter from '../../../components/UI/Filters/WorkProductionStateFilter';
import UsersFilter from '../../../components/UI/Filters/UsersFilter';
import BooleanFilter from '../../../components/UI/Filters/BooleanFilter';
import SearchButton from '../../../components/UI/Buttons/SearchButton';


const WorkFilters = ({ handleUpdate, defaultValues }) => {

  const { t } = useTranslation()
  const [values, setValues] = React.useState(defaultValues)
  const getValue = name => get(values, name, values[name])
  const setValue = (name, value) => setValues(v => ({ ...v, [name]: value }))
  const handleSubmit = () => handleUpdate(values)

  return (
    <>
      <div className='row mb-1'>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('number')}
              setValue={setValue}
              name='number'
              label={t('offers.number')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('description')}
              setValue={setValue}
              name='description'
              label={t('description')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('sap_orders')}
              setValue={setValue}
              name='sap_orders'
              label={t('works.sap_orders')}
            />
          </div>
        </div>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <CompanyFilter
              handleSubmit={handleSubmit}
              value={getValue('company_id')}
              setValue={setValue}
              name='company_id'
              label={t('works.customer')}
            />
            <CompanyFilter
              handleSubmit={handleSubmit}
              value={getValue('companies')}
              setValue={setValue}
              name='companies'
              label={t('works.partners')}
            />
            <UsersFilter
              handleSubmit={handleSubmit}
              value={getValue('users')}
              setValue={setValue}
              name='users'
              label={t('works.users')}
            />
          </div>
        </div>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('tags')}
              setValue={setValue}
              name='tags'
              label={t('Tags')}
            />
            <WorkStateFilter
              handleSubmit={handleSubmit}
              value={getValue('state')}
              setValue={setValue}
              name='state'
              label={t('works.state')}
            />
            <WorkProductionStateFilter
              handleSubmit={handleSubmit}
              value={getValue('production_state')}
              setValue={setValue}
              name='production_state'
              label={t('works.production_state')}
            />
            <BooleanFilter
              handleSubmit={handleSubmit}
              value={getValue('without_sap_orders')}
              setValue={setValue}
              name='without_sap_orders'
              label={t('works.without_sap_orders')}
            />
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-end gap-2 mb-3'>
        <SearchButton onClick={handleSubmit} />
      </div>
    </>
  )

}

export default WorkFilters
