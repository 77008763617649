import React, { useMemo } from 'react';
import { useRequest, useSessionStorageState } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import partsService from '../../../../services/parts/partsService';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';
import Toolbar from '../../../../components/UI/Toolbar';
import partTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { Link, useLocation } from 'react-router-dom';
import CustomPartFilters from './CustomPartFilters';
import customPartTableConfig from './customPartTableConfig';
import ExportExcelLocalizedButton from '../../../../components/UI/Buttons/Excel/ExportExcelLocalizedButton';
import exportsService from '../../../../services/exportsService';

function CustomParts() {
  const { data = [], loading, run } = useRequest(partsService.fetchAll, { manual: true });
  const location = useLocation()
  const columns = useMemo(() => customPartTableConfig, []);
  const { t } = useTranslation();
  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        code: [],
        description: [],
        drawing_note: '',
        width: [],
        height: [],
        depth: [],
        state: [],
        works: [],
        no_margin: false,
        no_price: false,
        no_cost: false,
        part_type: [partTypeEnum.values.CUSTOM],
      },
    }
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))
  const mapFilters = values => ({
    ...values, filter: {
      ...values.filter,
      code: values.filter.code.map(opt => opt.value),
      description: values.filter.description.map(opt => opt.value),
      drawing_note: values.filter.drawing_note,
      width: values.filter.width.map(opt => opt.value),
      height: values.filter.height.map(opt => opt.value),
      depth: values.filter.depth.map(opt => opt.value),
      state: values.filter.state.map(opt => opt.value),
      works: values.filter.works.map(opt => opt.value),
      no_margin: values.filter.no_margin,
      no_price: values.filter.no_price,
      no_cost: values.filter.no_cost,
    }
  })

  const handleUpdate = values => run(mapFilters(values))

  const handleExportExcel = locale => exportsService.parts(mapFilters({ ...extraParams, locale: locale }), 'parts');

  return (
    <>
      <Toolbar title={t('enums.part_types.CUSTOM')}>
        <div className="d-flex gap-1">
          <ExportExcelLocalizedButton buttonId='export_parts' handleClick={handleExportExcel}/>
          <Link className='btn btn-primary' to="../create-custom">
            <i className='fa fa-plus me-1'/>{t('new')}
          </Link>
          <Link to="generic" className='btn btn-primary'>
            {t('menu.manage_john_doe_parts')}
          </Link>
        </div>
      </Toolbar>

      <CustomPartFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <div className='card'>
        <div className='card-body'>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
          />
        </div>
      </div>
    </>
  );
}

export default CustomParts;
