import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';
import optionsService from '../../../../services/optionsService';

const CompanyPicker = props => {

  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(optionsService.companies, { manual: true });
  const { handleChange } = props;
  const selectInputRef = useRef();

  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({
      filter: { business_name: value || '' },
    }).then(res => callback(res))
    .catch(() => callback([]));
  };

  useEffect(() => {
    if (!props.value) {
      selectInputRef.current.clearValue()
    }
  }, [props.value]);


  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <AsyncSelect
      {...props}
      className='bg-white'
      ref={selectInputRef}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={handleChange}
      loading={loading}
      placeholder={props?.placeholder || t('type_to_search')}
      styles={reactSelectStyles}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
    />
  );
}

export default CompanyPicker;
