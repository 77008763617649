import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import Toolbar from '../../../components/UI/Toolbar';
import { Card, CardBody } from 'reactstrap';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import colorPresetTableConfig from './colorPresetTable.config';
import colorPresetsService from '../../../services/colors/colorPresetsService';
import ColorPresetCreateDrawer from './ColorPresetCreateDrawer';

const ColorPresets = () => {

  const { t } = useTranslation()
  const { data = [], loading, run } = useRequest(colorPresetsService.fetchAll, { manual: true });
  const columns = useMemo(() => colorPresetTableConfig, []);
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Toolbar title={t('color_presets.plural')}>
        <div className="d-flex gap-1">
          <CreateButton onClick={() => setIsOpen(true)}/>
        </div>
      </Toolbar>

      <Card>
        <CardBody>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={run}
          />
        </CardBody>
      </Card>

      <ColorPresetCreateDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )

}

export default ColorPresets
