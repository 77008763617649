import { offerActionTypes } from './offerActionTypes';
import {
  addSubOffer,
  addSubOfferItem,
  addSubOfferToolItem,
  changeOfferRevision,
  changeOfferTab,
  initOfferState,
  removeSubOffer,
  removeSubOfferItemByIndex,
  removeSubOfferToolItem,
  swapSubOfferItemPositions, toggleSubOffer, updateColorConfig,
  updateMassivePrice,
  updateRevision,
  updateRevisionCategoriesMargin,
  updateSubOffer, updateSubOfferColorConfig,
  updateSubOfferItem,
  updateSubOfferToolItem, updateSubOfferTools, updateSubOfferToolsFromFile
} from './offerReducerHelpers';
import OfferRevisionStateEnum from '../../../../../constants/enums/OfferRevisionStateEnum';


export const offerReducer = (state, action) => {

  const { type, payload } = action

  switch (type) {
    case offerActionTypes.INIT : {
      return initOfferState(payload)
    }
    case offerReducer.SET_LOADING: {
      return { ...state, loading: true }
    }
    case offerReducer.UNSET_LOADING: {
      return { ...state, loading: false }
    }
    case offerActionTypes.UPDATE_OFFER_REVISION_STATES : {
      return {
        ...state,
        revision_editable: !(payload.state.value === OfferRevisionStateEnum.values.accepted),
        states: payload
      }
    }
    case offerActionTypes.UPDATE_COLOR_CONFIG : {
      return updateColorConfig(state, payload)
    }
    case offerActionTypes.CHANGE_TAB : {
      return changeOfferTab(state, payload)
    }
    case offerActionTypes.CHANGE_OFFER_REVISION: {
      return changeOfferRevision(state, payload)
    }
    case offerActionTypes.TOGGLE_SUB_OFFER: {
      return toggleSubOffer(state, payload)
    }
    case offerActionTypes.ADD_SUB_OFFER: {
      return addSubOffer(state, payload)
    }
    case offerActionTypes.REMOVE_SUB_OFFER: {
      return removeSubOffer(state, payload)
    }
    case offerActionTypes.UPDATE_REVISION: {
      return updateRevision(state, payload)
    }
    case offerActionTypes.MASSIVE_PRICE_UPDATE: {
      return updateMassivePrice(state, payload)
    }
    case offerActionTypes.UPDATE_REVISION_CATEGORY_MARGIN: {
      return updateRevisionCategoriesMargin(state, payload)
    }
    case offerActionTypes.UPDATE_SUB_OFFER : {
      return updateSubOffer(state, payload)
    }
    case offerActionTypes.UPDATE_SUB_OFFER_TOOLS : {
      return updateSubOfferTools(state, payload)
    }
    case offerActionTypes.UPDATE_SUB_OFFER_TOOLS_FROM_FILE: {
      return updateSubOfferToolsFromFile(state, payload)
    }
    case offerActionTypes.UPDATE_SUB_OFFER_COLOR_CONFIG: {
      return updateSubOfferColorConfig(state, payload)
    }
    case offerActionTypes.ADD_SUB_OFFER_ITEM: {
      return addSubOfferItem(state, payload)
    }
    case offerActionTypes.UPDATE_SUB_OFFER_ITEM: {
      return updateSubOfferItem(state, payload)
    }
    case offerActionTypes.SWAP_SUB_OFFER_ITEM_POSITION: {
      return swapSubOfferItemPositions(state, payload)
    }
    case offerActionTypes.REMOVE_SUB_OFFER_ITEM: {
      return removeSubOfferItemByIndex(state, payload)
    }
    case offerActionTypes.ADD_SUB_OFFER_TOOL_ITEM: {
      return addSubOfferToolItem(state, payload)
    }
    case offerActionTypes.UPDATE_SUB_OFFER_TOOL_ITEM: {
      return updateSubOfferToolItem(state, payload)
    }
    case offerActionTypes.REMOVE_SUB_OFFER_TOOL_ITEM: {
      return removeSubOfferToolItem(state, payload)
    }

    default: {
      console.log('NO ACTION DETECTED')
      return state;
    }
  }

}

export default offerReducer
