import React from 'react';
import { NumericFormat } from 'react-number-format';
import { Field } from 'formik';

export function FormEuro(props) {


  return (
    <>
      <Field {...props}>
        {({ field, form, }) => (
          <NumericFormat
            value={field.value}
            prefix="€ "
            className={`form-control text-end ${props.color}`}
            style={{ width: "130px" }}
            decimalSeparator=','
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator='.'
            onChange={value => null}
            onBlur={() => form.setFieldValue(field.name, field.value)}
            onValueChange={value => form.setFieldValue(field.name, value?.floatValue)}
            {...props}
          />)}
      </Field>
    </>
  )

}
