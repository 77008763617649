import React from 'react';
import WorkProvider from './WorkContext/WorkrProvider';
import Work from './Work';

export const WorkWrapper = () => {

  return (
    <WorkProvider>
      <Work/>
    </WorkProvider>
  )

}
