import React from 'react';

const DisplayFormattedMeasures = ({ width = null, depth = null, height = null }) => {


  const getMeasureDescription = () => {

    const array = [];

    if (width) {
      array.push(width.toString());
    }
    if (depth) {
      array.push(depth.toString());
    }
    if (height) {
      array.push(`H${height}`);
    }

    return <>{array.length > 0 ? array.join('X') : ''}</>;
  };

  return getMeasureDescription();

}

export default DisplayFormattedMeasures
