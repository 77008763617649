import React from 'react';
import OffersWrapper from '../../../pages/Offers/Offers/OffersWrapper';
import Offers from '../../../pages/Offers/Offers/Offers';
import OfferCreateDrawer from '../../../pages/Offers/Offers/OfferCreateDrawer';
import Offer from '../../../pages/Offers/Offer/Offer';

const offersRoutes = {
    path: 'offers/*',
    element: <OffersWrapper/>,
    children: [{
      path: '',
      index: true,
      element: <Offers/>,
    }, {
      path: 'create',
      element: <OfferCreateDrawer/>,
    }, {
      path: ':id/*',
      element: <Offer/>,
    }],
  }
;

export default offersRoutes;
