import FormikResetButton from '../Buttons/FormikResetButton';
import FormikSaveButton from '../Buttons/Save/FormikSaveButton';
import CancelButton from '../Buttons/CancelButton';

export const FormActions = ({ withReset = true, onCancel = undefined, children}) => {

  return (
    <div className='d-flex align-items-center justify-content-between'>
      {withReset ? <FormikResetButton/> : <div />}
      <div className='d-flex align-items-center justify-content-end gap-2'>
        {onCancel !== undefined && <CancelButton onClick={onCancel}/>}
        <FormikSaveButton/>
        {children}
      </div>
    </div>
  )

}
