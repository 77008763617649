const forgotPath = '/forgot-password';
const resetPath = '/reset-password';
import api from '../configs/api.config';

const requestPasswordLink = (email) => api.post(forgotPath, { email })
  .then((res) => res.data)
  .catch((err) => err.response.data);

const resetPassword = (email, token, password, password_confirmation) => api.post(resetPath, {
  email,
  token,
  password,
  password_confirmation,
})
  .then((res) => res.data)
  .catch((err) => err.response.data);

export const authenticationService = {
  requestPasswordLink,
  resetPassword,
};
