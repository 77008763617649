import React from 'react';
import { Outlet } from 'react-router-dom';


function OffersWrapper() {


  return <Outlet/>
}

export default OffersWrapper;
