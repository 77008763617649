import { CardActions } from '../../../../../components/UI/Card/CardActions';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DownloadButton from '../../../../../components/UI/Buttons/Download/DownloadButton';

export const WorkMediaVaultAction = ({
                                       label,
                                       onDownloadMerged,
                                       onDownloadColorConfig,
                                       onDownloadBomsPdf,
                                       tooltipContent
                                     }) => {


  const { t } = useTranslation()

  return (
    <CardActions>
      <div className='d-flex gap-1'>
        {onDownloadColorConfig !== null &&
          <button type='button' className='btn btn-soft-secondary btn-icon' onClick={onDownloadColorConfig} id='color_config_download_button'>
            <UncontrolledTooltip target='color_config_download_button'>Scarica le configurazioni colore</UncontrolledTooltip>
            <i className='fa fa-fill-drip'/>
          </button>
        }
        {onDownloadBomsPdf !== null &&
          <button type='button' className='btn btn-soft-secondary btn-icon' onClick={onDownloadBomsPdf} id='boms_download_button'>
            <UncontrolledTooltip target='boms_download_button'>Scarica le distinte base</UncontrolledTooltip>
            <i className='fa fa-list-ol'/>
          </button>
        }

        <UncontrolledTooltip target={`${label}_download_button`}>{tooltipContent}</UncontrolledTooltip>
        <DownloadButton id={`${label}_download_button`} label={t(label)} onClick={() => onDownloadMerged(0)}/>
      </div>
    </CardActions>
  )

}
