import React, { useEffect, useRef } from 'react';
import { useRequest } from 'ahooks';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';
import optionsService from '../../../../services/optionsService';
import { useTranslation } from 'react-i18next';

const UsersPicker = props => {

  const { handleChange } = props;
  const { t } = useTranslation()
  const { data: users = [], loading, runAsync } = useRequest(optionsService.users, { manual: true });
  const selectInputRef = useRef();

  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({ filter: { q: value || '' } })
    .then((res) => callback(res))
    .catch(() => callback([]));
  };

  useEffect(() => {
    if (!props.value) {
      selectInputRef.current.clearValue()
    }
  }, [props.value]);

  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <AsyncSelect
      ref={selectInputRef}
      className='bg-white'
      defaultOptions={[]}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={handleChange}
      placeholder={t('type_to_search')}
      loading={loading}
      styles={reactSelectStyles}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
      {...props}
    />
  );
}

export default UsersPicker;
