import React from 'react';
import { useTranslation } from 'react-i18next';

const CancelButton = props => {
  const { t } = useTranslation();

  return (
    <button className='btn btn-soft-secondary' type='button' {...props}>
      <i className='fa fa-filter'/>&nbsp;{t(props?.label ?? 'search')}
    </button>
  );
}

export default CancelButton;
