import React from 'react';
import { Outlet } from 'react-router-dom';

const ColorPresetsWrapper = () => {

  return (
    <Outlet/>
  )

}

export default ColorPresetsWrapper