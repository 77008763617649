import React from 'react';
import EuroDisplay from '../EuroDisplay';
import clsx from 'clsx';

function PartCostDisplay({ cost = 0 }) {

  return (
    <div className="badge fs-6 bg-light">
      <i
        className={clsx('fa ', {
          'badge-soft-danger fa-arrow-circle-down': cost === 0,
          'badge-soft-success fa-arrow-circle-up': cost > 0,
        })}
        title={cost?.toString()}
      />&nbsp;
      <EuroDisplay value={cost}/>
    </div>
  );


}

export default PartCostDisplay;
