import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkPartner = ({ type = "", companies = [] }) => {

  const { t } = useTranslation();

  return companies.length > 0
    ? companies.map(c => <div key={`${type}_${c.uuid}`}>{c.business_name}</div>)
    : t('no-results')

}

export default WorkPartner
