import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import usersService from '../../../services/usersService';
import { PageHeader } from '../../../components/UI/Page/PageHeader';
import { Card } from '../../../components/UI/Card/Card';
import { CardBody } from '../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../components/UI/Card/CardLabel';
import { List } from '../../../components/UI/ItemList/List';
import { ListItem } from '../../../components/UI/ItemList/ListItem';
import { ListEmptyItem } from '../../../components/UI/ItemList/ListEmptyItem';
import { CardContentBetween } from '../../../components/UI/Card/CardContentBetween';
import EditButton from '../../../components/UI/Buttons/EditButton';
import { UserDrawer } from './UserDrawer';

const User = () => {

  const { id } = useParams()
  const { t } = useTranslation();

  const { data: user = undefined, mutate } = useRequest(usersService.fetch, { defaultParams: [id] });
  const { data: permissions = [], loading } = useRequest(usersService.fetchPermissions)
  const [isOpen, setIsOpen] = React.useState(false);

  const handleUpdate = res => {
    mutate(u => res)
    setIsOpen(o => false)
  }

  return (
    <>
      <PageHeader title={user?.full_name}/>
      <div className='row'>
        <div className='col-xl-4 col-md-6'>
          <Card>
            <CardBody>
              <CardContentBetween>
                <CardLabel>{t('users.sing')}</CardLabel>
                <EditButton onClick={() => setIsOpen(!isOpen)}/>
              </CardContentBetween>
              <List>
                <ListItem label={t('users.first_name')}>{user?.full_name}</ListItem>
                <ListItem label={t('users.email')}>{user?.email}</ListItem>
              </List>
            </CardBody>
          </Card>
        </div>
        <div className='col-xl-8 col-md-6'>
          <Card>
            <CardBody>
              <CardLabel label={t("permissions.plural")}/>
              <List>
                {permissions.length > 0 && !loading
                  ? permissions.map((permission, index) =>
                    <ListItem key={index} label={t("permissions.names." + permission.label)}>
                      {user?.permissions.find(p => p.name === permission.name) !== undefined ? 'SI' : 'NO'}
                    </ListItem>
                  ) : <ListEmptyItem/>
                }
              </List>
            </CardBody>
          </Card>
        </div>
      </div>

      <UserDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        user={user}
        permissions={permissions}
        onUpdate={handleUpdate}
      />
    </>
  )

}

export default User
