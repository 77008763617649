import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SubOfferItemTypeEnum from '../../../../../../constants/enums/SubOfferItemTypeEnum';
import subOfferItemTypeEnum from '../../../../../../constants/enums/SubOfferItemTypeEnum';
import SubOfferItemPartAdder from './SubOfferItemPartAdder';
import SubOfferItemMessageAdder from './SubOfferItemMessageAdder';
import SubOfferItemFileAdder from './SubOfferItemFileAdder';
import CreateButton from '../../../../../../components/UI/Buttons/CreateButton';
import { CardSmMargin } from '../../../../../../components/UI/Card/CardSmMargin';
import { CardBody } from '../../../../../../components/UI/Card/CardBody';


export const SubOfferItemAdder = ({
                                    subOfferUuid,
                                    offerRevisionUuid,
                                    partTypes = [],
                                    action,
                                    withFile = false
                                  }) => {

  const [isNewClicked, setIsNewClicked] = useState(null)
  const { t } = useTranslation()

  return isNewClicked
    ? (
      <CardSmMargin>
        <CardBody>
          {isNewClicked === subOfferItemTypeEnum.PART &&
            <SubOfferItemPartAdder
              action={action}
              subOfferUuid={subOfferUuid}
              offerRevisionUuid={offerRevisionUuid}
              setIsNewClicked={setIsNewClicked}
              partTypes={partTypes}
            />}
          {isNewClicked === subOfferItemTypeEnum.MESSAGE &&
            <SubOfferItemMessageAdder
              action={action}
              subOfferUuid={subOfferUuid}
              offerRevisionUuid={offerRevisionUuid}
              setIsNewClicked={setIsNewClicked}
            />}
          {isNewClicked === subOfferItemTypeEnum.TOOL && withFile &&
            <SubOfferItemFileAdder
              action={action}
              subOfferUuid={subOfferUuid}
              offerRevisionUuid={offerRevisionUuid}
              setIsNewClicked={setIsNewClicked}
            />}
        </CardBody>
      </CardSmMargin>
    ) : (
      <div className='d-flex align-items-center justify-content-end gap-2 mb-3'>
        <UncontrolledTooltip target='add_sub_offer_item_button'>Aggiunge un nuovo articolo codificato</UncontrolledTooltip>
        <UncontrolledTooltip target='add_sub_offer_message_button'>Aggiunge un nuovo elemento libero non codificato</UncontrolledTooltip>
        <UncontrolledTooltip target='add_sub_offer_file_button'>Aggiunge in fondo alla lista dal file caricato </UncontrolledTooltip>
        <div className='d-flex align-items-center justify-content-end gap-2'>
          <CreateButton
            label={'sub_offer_items.part'}
            id='add_sub_offer_message_button'
            onClick={() => setIsNewClicked(SubOfferItemTypeEnum.PART)}
          />
          <CreateButton
            label={'sub_offer_items.message'}
            id='add_sub_offer_item_button'
            onClick={() => setIsNewClicked(SubOfferItemTypeEnum.MESSAGE)}
          />
          {withFile &&
            <CreateButton
              label={'sub_offer_items.file'}
              id='add_sub_offer_file_button'
              onClick={() => setIsNewClicked(SubOfferItemTypeEnum.TOOL)}
            />}
        </div>
      </div>
    )

}
