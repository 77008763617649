import React, { useContext, useState } from 'react';
import EuroDisplay from '../../../../../components/UI/Displays/EuroDisplay';
import { useTranslation } from 'react-i18next';
import PercentageDisplay from '../../../../../components/UI/Displays/PercentageDisplay';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardSmMargin } from '../../../../../components/UI/Card/CardSmMargin';
import { UncontrolledTooltip } from 'reactstrap';
import { OfferContext } from '../../offerContext/offerContext';
import { AppContext } from '../../../../../components/AppContext';
import SubOffersService from '../../../../../services/offers/subOffersService';
import subOffersService from '../../../../../services/offers/subOffersService';
import { toast } from 'react-toastify';
import { offerActionTypes } from '../../offerContext/offerReducer/offerActionTypes';
import { InfoTooltip } from '../../../../../components/UI/Tooltips/InfoTooltip';
import BoolDisplay from '../../../../../components/UI/Displays/BoolDisplay';
import ColorConfigsDrawer from '../../../../../components/ColorConfigs/ColorConfigsDrawer';

const SubOfferSummaryItem = ({ subOffer, offerRevisionUuid, handleTabSelected }) => {

  const { t } = useTranslation()
  const { offer, dispatchAction, revision_editable } = useContext(OfferContext)
  const { colorParts } = useContext(AppContext)
  const [subOfferColorConfigsDrawerState, setSubOfferColorConfigsDrawerState] = useState(false);
  const { setLoadingOverlayState } = useContext(AppContext);

  const handleToogle = () => {
    setLoadingOverlayState(true)
    SubOffersService.toggle(
      offer?.uuid,
      offerRevisionUuid,
      subOffer.uuid
    ).then(res => dispatchAction({
      type: offerActionTypes.TOGGLE_SUB_OFFER,
      payload: { subOfferUuid: subOffer.uuid }
    }))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setLoadingOverlayState(false))
  }

  const handleUpdateSubOfferColorConfigs = colorConfigs => {
    dispatchAction({
      type: offerActionTypes.UPDATE_SUB_OFFER_COLOR_CONFIG,
      payload: {
        subOffer: subOffer,
        colorConfigs: colorConfigs
      }
    })
    setSubOfferColorConfigsDrawerState(false)
    toast.success(t('updated'))
  }

  return (
    <CardSmMargin>
      <CardBody>

        <div className='d-flex justify-content-between align-items-center gap-4'>

          <div className='d-flex align-items-center justify-content-start gap-2'>

            {revision_editable ? <>
              <UncontrolledTooltip target={`toggle_${subOffer.uuid}`}>Abilita/Disabilita area</UncontrolledTooltip>
              <input
                id={`toggle_${subOffer.uuid}`}
                type="checkbox"
                checked={subOffer.is_enabled}
                onChange={handleToogle}
                className="form-check-input"
              />
            </> : <BoolDisplay value={subOffer.is_enabled}/>}
            <div className='d-flex flex-column'>
              <div className='d-flex flex-nowrap align-items-center justify-content-start fs-5 fw-bold gap-2'>
                <span className={`cursor-pointer ${subOffer.is_invalid && 'text-danger'}`} onClick={handleTabSelected}>
                  {subOffer.code}
                </span>
                {subOffer.is_invalid && <span className='bx-flashing'><InfoTooltip message='Costo o prezzo non valido' color='danger'/></span>}
              </div>
              <div>{subOffer.description ?? subOffer.code}</div>
            </div>
          </div>

          <div className='d-flex justify-content-end gap-4 flex-wrap text-end'>
            <div className='d-flex justify-content-end align-items-center gap-4' style={{ minWidth: "50px" }}>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.qty')}</span>
                <span className='fw-bold'>{subOffer.qty}</span>
              </div>
            </div>
            <div className='d-flex justify-content-end align-items-center gap-4' style={{ minWidth: "250px" }}>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.offer_sub_price_total')}</span>
                <span className='fw-bold text-info'><EuroDisplay value={subOffer.total_price}/></span>
              </div>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.offer_sub_net_price_total')}</span>
                <span className='fw-bold text-info'><EuroDisplay value={subOffer.total_net_price}/></span>
              </div>
            </div>
            <div className='d-flex justify-content-end align-items-center gap-4' style={{ minWidth: "300px" }}>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.offer_sub_cost_total')}</span>
                <span className='fw-bold text-success'><EuroDisplay value={subOffer.total_cost}/></span>
              </div>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.offer_sub_min_sell_price_total')}</span>
                <span className='fw-bold text-success'><EuroDisplay value={subOffer.total_net_cost}/></span>
              </div>
            </div>
            <div className='d-flex justify-content-end align-items-center gap-4' style={{ minWidth: "100px" }}>
              <div className='d-flex flex-column align-items-end'>
                <span className='small'>{t('sub_offers.margin')}</span>
                <span className='fw-bold'><PercentageDisplay value={subOffer.margin * 100}/></span>
              </div>
            </div>

            {offer.work === null &&
              <>
                <UncontrolledTooltip target={`color_config_rev_button_${subOffer.uuid}`}>{t('color_configs.sing')} {subOffer.code}</UncontrolledTooltip>
                <button
                  onClick={() => setSubOfferColorConfigsDrawerState(() => true)}
                  className={'btn btn-soft-secondary'}
                  id={`color_config_rev_button_${subOffer.uuid}`}
                  type='button'>
                  <i className='fa fa-palette'/>
                </button>
              </>}
          </div>

        </div>

        {offer.work === null &&
          <ColorConfigsDrawer
            colorParts={colorParts}
            isOpen={subOfferColorConfigsDrawerState}
            setIsOpen={setSubOfferColorConfigsDrawerState}
            colorConfigs={subOffer?.color_configs}
            serviceParams={{
              offerUuid: offer.uuid,
              offerRevisionUuid: offer?.offer_revision?.uuid,
              subOfferUuid: subOffer.uuid,
            }}
            serviceCall={subOffersService.updateColorConfigs}
            onUpdate={handleUpdateSubOfferColorConfigs}
            canEdit={revision_editable}
          />}

      </CardBody>
    </CardSmMargin>
  )

}

export default SubOfferSummaryItem
