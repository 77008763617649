export const List = ({ children }) => {

  return (
    <ul className='list-group list-group-flush'>
      {children}
    </ul>
  )

}

