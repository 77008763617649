import React from 'react';
import MediaListItem from './MediaListItem';
import { useTranslation } from 'react-i18next';
import { List } from '../UI/ItemList/List';
import { ListItemHeader } from '../UI/ItemList/ListItemHeader';
import DownloadButton from '../UI/Buttons/Download/DownloadButton';
import { ListItemFile } from '../UI/ItemList/ListItemFile';
import { ListEmptyItem } from '../UI/ItemList/ListEmptyItem';

const MediaList = ({ media = [], title, color = '', onDownload, onDelete, canDelete, onDownloadCollection }) => {

  const { t } = useTranslation()


  return (
    <List>
      <ListItemHeader
        label={title}
        extra={media.length > 1 && <DownloadButton label='Zip' onClick={onDownloadCollection} />}
      />
      {media.length > 0 ? media.map(m =>
        <ListItemFile
          key={m.uuid}
          media={m}
          onDownload={onDownload}
          canDelete={true}
          onDelete={onDelete}
        />
      ) : <ListEmptyItem />}
    </List>
  )

}

export default MediaList
