import React from 'react';
import { Link } from 'react-router-dom';

const TableLink = props => {

  const { value, to } = props;

  return (
    <Link to={to} className={`fw-bold ${props?.className}`}>
      {value}
    </Link>
  );
}

export default TableLink;
