import React, { useContext } from 'react';
import DateDisplay from '../../../../../components/UI/Displays/DateDisplay';
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion, UncontrolledTooltip } from 'reactstrap';
import WorkSubWorkInlineBom from './WorkSubWorkInlineBom';
import ColorConfigs from '../../../../../components/ColorConfigs/ColorConfigs';
import colorConfigsService from '../../../../../services/colors/colorConfigsService';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert2';
import worksService from '../../../../../services/works/worksService';
import { toast } from 'react-toastify';
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { AppContext } from '../../../../../components/AppContext';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';
import WorkSubWorkAction from './WorkSubWorkAction';

const WorkSubWork = ({ subWork }) => {

  const { work, color_parts, dispatchAction } = useContext(WorkContext)
  const { t } = useTranslation()
  const { setLoadingOverlayState } = useContext(AppContext)
  const handleUpdate = res => dispatchAction({
    type: workActionTypes.UPDATE_SUBWORK_COLOR_CONFIGS,
    payload: { sub_work_uuid: subWork.uuid, color_configs: res }
  })

  const handleChangeStatus = () => SweetAlert.fire({
    titleText: t('are_you_sure'),
    text: "Abilitando/Disabilitando l'area verranno rimosse anche le relative tavole tecniche.",
    icon: 'warning',
    showCancelButton: true
  }).then(res => {
    if (res.isConfirmed) {
      setLoadingOverlayState(true)
      worksService.toggleSubWorkActivation(work.uuid, subWork.uuid)
      .then(res => dispatchAction({
        type: workActionTypes.TOGGLE_SUBWORK,
        payload: { sub_work_uuid: subWork.uuid, response: res }
      }))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => setLoadingOverlayState(false))
    }
  })

  return (
    <ListItem
      label={`${subWork.code} x ${subWork.qty}`}
      extra={<WorkSubWorkAction
        subWork={subWork}
        onDownloadSubworkBom={() => worksService.downloadSubWorkBom(work.uuid, subWork.uuid, subWork.code)}
        handleChangeStatus={handleChangeStatus}
      />}>

      <DateDisplay value={subWork.updated_at} formatString='dd/MM/yyyy HH:mm:ss'/>

      <UncontrolledAccordion defaultOpen={"s"} className='mt-2'>
        <AccordionItem className='border-0'>
          <AccordionHeader targetId={`${subWork.uuid}_bom`}>
            <i className='fa fa-arrow-right me-1'/>
            {t('sub_works.bom')}
          </AccordionHeader>
          <AccordionBody accordionId={`${subWork.uuid}_bom`}>
            <WorkSubWorkInlineBom items={subWork.items}/>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem className='border-0'>
          <AccordionHeader targetId={`${subWork.uuid}_color_configs`}>
            {subWork.color_configs.length > 0
              ? <>
                <UncontrolledTooltip target={`color_config_tooltip_${subWork.uuid}`}>
                  Per quest'area è stata definita una configurazione colore ad hoc
                </UncontrolledTooltip>
                <i id={`color_config_tooltip_${subWork.uuid}`} className='fa fa-exclamation-triangle text-danger me-1'/>
              </>
              : <i className='fa fa-arrow-right me-1'/>}
            {t('color_configs.sing')} {subWork.code}
          </AccordionHeader>
          <AccordionBody accordionId={`${subWork.uuid}_color_configs`}>
            <div className='mt-2'>
              <ColorConfigs
                onUpdate={handleUpdate}
                serviceParams={{ workUuid: work.uuid, subWorkUuid: subWork.uuid }}
                serviceCall={colorConfigsService.storeSubWorkConfig}
                colorParts={color_parts}
                colorConfigs={subWork.color_configs}
              />
            </div>
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>
    </ListItem>
  )

}

export default WorkSubWork
