import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import localesConfig from '../../../locales/locales.config';
import { UncontrolledTooltip } from 'reactstrap';

function TranslatableDisplay({ currentTranslation, translatableArray = {} }) {
  const { t } = useTranslation();
  const { availableTranslations } = localesConfig;
  const [currentSelectedTranslation, setCurrentSelectedTranslation] = React.useState(currentTranslation);
  const handleTranslationChange = (locale) => setCurrentSelectedTranslation(
    // eslint-disable-next-line no-prototype-builtins
    translatableArray.hasOwnProperty(locale)
      ? translatableArray[locale]
      : currentTranslation
  );

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(currentSelectedTranslation || '');
    toast.success(t('copied'));
  };

  return (
    <div>
      <div className='text-nowrap'>
        <UncontrolledTooltip target='copy_to_clipboard'>Copia la descrizione in lingua corrente</UncontrolledTooltip>
        {/* COPY TO CLIPBOARD */}
        <button
          type="button"
          className="p-0 btn btn-link font-size-18 me-1"
          onClick={() => handleCopyToClipboard()}
        >
          <i className="far fa-copy" id='copy_to_clipboard'/>
        </button>
        <span className='text-wrap'>{currentSelectedTranslation}</span>
      </div>
      {/* TRANSLATION SELECTION */}
      <div className="d-flex align-items-center justify-content-start gap-2">
        {availableTranslations.map((translation) => (
          <button
            type="button"
            className="p-0 btn btn-link font-size-12"
            key={translation.locale}
            onClick={() => handleTranslationChange(translation.locale)}
          >
            <img src={translation.flag} height={14}  alt={translation.label}/>
          </button>
        ))}
      </div>

    </div>
  );
}

export default TranslatableDisplay;
