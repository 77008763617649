import React, { useMemo } from 'react';
import { isUndefined } from 'lodash';
import { useRequest } from 'ahooks';
import partsService from '../../../services/parts/partsService';
import partTableConfig from '../../Parts/components/partTableConfig';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import { useTranslation } from 'react-i18next';

const PartCategoryParts = ({ partCategoryId = undefined }) => {

  const { t } = useTranslation()
  const columns = useMemo(() => partTableConfig, []);
  const { data, loading, run } = useRequest(partsService.fetchAll, { manual: true, ready: !isUndefined(partCategoryId) })

  const handleRun = params => run({
    ...params,
    filter: {
      ...params.filter,
      part_category_id: partCategoryId
    }
  })


  if (isUndefined(partCategoryId))
    return <></>


  return (
    <div className='card w-100'>
      <div className='card-body'>
        <div className='d-flex justify-content-between align-items-center gap-4'>
          <span className='text-black-50'>{t('parts.plural')}</span>
        </div>
        <PaginatedTable
          options={paginatedTableConfig}
          isLoading={loading}
          data={data || []}
          columns={columns}
          sortable
          onParamsChange={handleRun}
        />
      </div>
    </div>
  )

}

export default PartCategoryParts
