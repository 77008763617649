import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

function DraggableItem({ children, onDrag, canDrag, id, }) {
  const ref = useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'div',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item) {
      if (onDrag) onDrag(item.id, id);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id },
    type: 'div',
    canDrag: canDrag !== false,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const isActive = canDrop && isOver;

  drag(drop(ref));

  const styles = {
    cursor: 'grab',
    opacity: isDragging ? 0.5 : 1,
    borderTop: isActive ? '3px dashed #e0e0e0' : '0',
  };

  return (
    <div ref={ref} style={styles}>
      {children}
    </div>
  );
}

export default DraggableItem;
