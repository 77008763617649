import React from 'react';
import { Link } from 'react-router-dom';
import logoLight from '../../../../../../assets/images/logo-light.png';
import logoDark from '../../../../../../assets/images/logo-dark.png';

function HeaderLogo() {
  return (
    <div className="navbar-brand-box">
      <Link to="/" className="logo logo-dark">
        <span className="logo-sm"><img src={logoDark} alt="" width={100} /></span>
        <span className="logo-lg"><img src={logoDark} alt="" width={80} /></span>
      </Link>
      <Link to="/" className="logo logo-light">
        <span className="logo-sm"><img src={logoLight} alt="" width={100} /></span>
        <span className="logo-lg"><img src={logoLight} alt="" width={80} /></span>
      </Link>
    </div>
  );
}

export default HeaderLogo;
