import React from 'react';

function CodeWord({ word, onRemove }) {

  return (
    <div id={word.uuid} className="bg-light bg-opacity-50 d-flex align-items-center justify-content-between mb-1 py-1 px-2 rounded-1">
      <div className='d-flex align-items-center justify-content-start gap-1'>
        <i className='fa small fa-arrows-alt text-black-50'/>
        {word.label}
      </div>
      <button
        type='button'
        onClick={() => onRemove()}
        className='btn btn-sm btn-soft-danger align-self-end btn-icon'>
        <i className='fa fa-times'/>
      </button>
    </div>

  );
}

export default CodeWord;
