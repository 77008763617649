import EuroDisplay from '../EuroDisplay';
import clsx from 'clsx';

function PartMinimumPriceDisplay({
                                   price = 0,
                                   minimumSalePrice = 0,
                                 }) {

  return (
    <div
      title={minimumSalePrice?.toString()}
      className='badge fs-6 bg-light text-dark'>
      <i
        className={clsx('fa', {
          'text-danger fa-arrow-circle-down': price < minimumSalePrice,
          'text-warning fa-minus-circle': price === minimumSalePrice,
          'text-success fa-arrow-circle-up': price > minimumSalePrice,
        })}
      />&nbsp;
      <EuroDisplay value={minimumSalePrice}/>
    </div>
  )

}

export default PartMinimumPriceDisplay
