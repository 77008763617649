import React from 'react';
import { useSanctum } from 'react-sanctum';
import { Navigate } from 'react-router-dom';
import LoadingScreen from '../components/Layouts/LoadingScreen';

function AuthMiddleware({ children, auth, redirect }) {
  const { authenticated } = useSanctum();

  if (authenticated !== null) {
    if (authenticated === auth) {
      return <>{children}</>;
    }
    return <Navigate to={{ pathname: redirect }} replace />;
  }
  return <LoadingScreen />;
}

export default AuthMiddleware;
