import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '../../../../components/UI/Tags/Tag';
import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

const SourcePart = ({ part }) => {

  const { t } = useTranslation();

  return part ? (
    <div className='h5 d-flex align-items-center justify-content-start gap-2'>
      <span className='fw-bold'>{t('parts.source_part')}</span>
      <Tag color='success' key={part.uuid}>
        <UncontrolledTooltip target={`traget_${part.uuid}`}>
          {part.description}
        </UncontrolledTooltip>
        <Link to={`/parts/${part.uuid}`} id={`traget_${part.uuid}`} className='link-success'>
          {part.code}
        </Link>
      </Tag>
    </div>) : <></>

}

export default SourcePart
