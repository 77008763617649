import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '../Tags/Tag';

const DelayDisplay = ({ value, onlyText = false }) => {

  const { t } = useTranslation()

  const getColorClass = () => {
    if (value < -5) {
      return 'success'
    } else if (value > 0) {
      return 'danger'
    } else {
      return 'warning'
    }
  }

  if (value === null || value === undefined)
    return (<>---</>)

  if (onlyText)
    return (
      <span className={`text-${getColorClass()}`}>
        {value} {t('days')}
      </span>
    )

  return (
    <Tag color={getColorClass()}>
      {value} {t('days')}
    </Tag>
  )

}

export default DelayDisplay
