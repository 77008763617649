import React from 'react';
import { useTranslation } from 'react-i18next';
import MultipleTextFilter from '../../../components/UI/Filters/MultipleTextFilter';
import { get } from 'lodash';
import WorkFilter from '../../../components/UI/Filters/WorkFilter';
import CompanyFilter from '../../../components/UI/Filters/CompanyFilter';
import UsersFilter from '../../../components/UI/Filters/UsersFilter';
import OfferStateFilter from '../../../components/UI/Filters/OfferStateFilter';
import SearchButton from '../../../components/UI/Buttons/SearchButton';

const OfferFilters = ({ handleUpdate, defaultValues }) => {

  const { t } = useTranslation()
  const [values, setValues] = React.useState(defaultValues)
  const getValue = name => get(values, name, values[name])
  const setValue = (name, value) => setValues(v => ({ ...v, [name]: value }))
  const handleSubmit = () => handleUpdate(values)

  return (
    <>
      <div className='row mb-1'>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <WorkFilter
              handleSubmit={handleSubmit}
              value={getValue('work_id')}
              setValue={setValue}
              name='work_id'
              label={t('works.plural')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('description')}
              setValue={setValue}
              name='description'
              label={t('description')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('number')}
              setValue={setValue}
              name='number'
              label={t('offers.number')}
            />
          </div>
        </div>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <CompanyFilter
              handleSubmit={handleSubmit}
              value={getValue('company_id')}
              setValue={setValue}
              name='company_id'
              label={t('offers.customer')}
            />
            <UsersFilter
              handleSubmit={handleSubmit}
              value={getValue('users')}
              setValue={setValue}
              name='users'
              label={t('offers.users')}
            />
          </div>
        </div>
        <div className='col-lg-4 col-md-6 d-flex d-lg-block align-items-stretch'>
          <div className="d-flex flex-column gap-1">
            <OfferStateFilter
              handleSubmit={handleSubmit}
              value={getValue('state')}
              setValue={setValue}
              name='state'
              label={t('offers.state')}
            />
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-end gap-2 mb-3'>
        <SearchButton onClick={handleSubmit} />
      </div>
    </>
  )

}

export default OfferFilters
