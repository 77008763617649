import React from 'react';
import DateDisplay from '../../../components/UI/Displays/DateDisplay';
import { useTranslation } from 'react-i18next';
import { Tag } from '../../../components/UI/Tags/Tag';

const UserState = ({ deleted_at }) => {

  const { t } = useTranslation()

  return deleted_at
    ? <Tag color='error' ><DateDisplay value={deleted_at}/></Tag>
    : <Tag color='success'>{t('enabled')}</Tag>

}

export default UserState
