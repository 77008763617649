import api from '../configs/api.config';

const url = 'user-messages';

const fetch = (modelId, modelType) => api.get(`${modelType}/${modelId}/${url}`).then(res => res.data.data)
const store = (modelId, modelType, params) => api.post(`${modelType}/${modelId}/${url}`, params).then(res => res.data.data)
const update = (modelId, modelType, userMessageUuid, params) => api.put(`${modelType}/${modelId}/${url}/${userMessageUuid}`, params).then(res => res.data.data)
const destroy = (modelId, modelType, userMessageUuid, params) => api.delete(`${modelType}/${modelId}/${url}/${userMessageUuid}`, params).then(res => res.data)

const userMessageService = {
  fetch,
  store,
  update,
  destroy,
};

export default userMessageService;
