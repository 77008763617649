import React from 'react';
import { useRequest } from 'ahooks';
import partsService from '../../../../services/parts/partsService';
import PartTrendPeriodBar from './components/PartTrendPeriodBar';
import PartTrendsChart from './components/PartTrendsChart';

export const PartPriceChart = ({ part }) => {

  const {
    data: trends,
    loading: trendsLoading,
    run: fetchTrends
  } = useRequest(partsService.partTrends, { manual: true })

  return (
    <div className='card w-100'>
      <div className='card-body'>
        <PartTrendPeriodBar onPeriodChange={(period) => fetchTrends(part?.uuid, period)}/>
        <PartTrendsChart trends={trends} loading={trendsLoading}/>
      </div>
    </div>
  )
}
