import React from 'react';

function NavBarDropdownTitle({
  label, icon,
}) {
  return (
    <span className="dropdown-item dropdown-toggle arrow-none cursor-pointer">
      {icon && <i className={`${icon} me-2`} />}
      {label}
      <div className="arrow-down" />
    </span>
  );
}

export default NavBarDropdownTitle;
