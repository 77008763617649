import api from '../../configs/api.config';



// @TODO spotare in workcolorconfigservice
const fetchAll = workUuid => api.get(`/works/${workUuid}/color-configs`).then(res => res.data.data)
const storeWorkConfig = (workUuid, params) => api.post(`/works/${workUuid}/color-configs`, params).then(res => res.data.data)
const storeSubWorkConfig = ({ workUuid = "", subWorkUuid = "" }, params) =>
  api.post(`/works/${workUuid}/sub-works/${subWorkUuid}/color-configs`, params).then(res => res.data.data)
const storePresetColorConfig = (colorPresetUuid, params) => api.post(`/color-presets/${colorPresetUuid}/color-configs`, params).then(res => res.data.data)
const destroyPresetColorConfig = (colorPresetUuid, colorConfigUuid) => api.delete(`/color-presets/${colorPresetUuid}/color-configs`, params)
.then(res => res.data.data)


const colorConfigsService = {
  fetchAll,
  storeWorkConfig,
  storeSubWorkConfig,
  storePresetColorConfig,
  destroyPresetColorConfig
};

export default colorConfigsService;
