import React from 'react';
import ColorPresetsWrapper from '../../../pages/ColorPresets/ColorPresetsWrapper';
import ColorPresets from '../../../pages/ColorPresets/ColorPresets/ColorPresets';
import ColorPreset from '../../../pages/ColorPresets/ColorPreset/ColorPreset';

const colorPresetsRoutes = {
  path: 'color-presets/*',
  element: <ColorPresetsWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <ColorPresets/>,
  }, {
    path: ':id',
    element: <ColorPreset/>,
  }]
};

export default colorPresetsRoutes;
