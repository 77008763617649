import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Drawer from '../../../components/UI/Drawer/Drawer';
import partCategoriesService from '../../../services/parts/partCategoriesService';
import PartCategoryForm from '../components/PartCategoryForm';

const validationSchema = Yup.object().shape({
  label: Yup.string().max(100).required(),
  color: Yup.string().max(50).required(),
  margin: Yup.number().min(0).max(100).required()
});

function PartCategoryCreateDrawer({ isOpen, setIsOpen }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues = {
    label: '',
    color: '',
    margin: 0
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => partCategoriesService.store(values)
    .then((res) => {
      toast.success(t('created'));
      navigate(res.uuid)
    })
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('new')}
    >
      <FormikProvider value={formik}>
        <Form>
          <PartCategoryForm setIsOpen={setIsOpen}/>
        </Form>
      </FormikProvider>
    </Drawer>
  );
}

export default PartCategoryCreateDrawer;
