import React, { useMemo } from 'react';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import { useTranslation } from 'react-i18next';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import { useRequest, useSessionStorageState } from 'ahooks';
import worksService from '../../../services/works/worksService';
import workTableConfig from './workTable.config';
import WorkCreateDrawer from './WorkCreateDrawer';
import WorkFilters from './workFilters';
import ExportExcelButton from '../../../components/UI/Buttons/Excel/ExportExcelButton';
import exportsService from '../../../services/exportsService';
import { useLocation } from "react-router-dom";
import { Card } from '../../../components/UI/Card/Card';
import { CardBody } from '../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../components/UI/Card/CardLabel';
import { CardActions } from '../../../components/UI/Card/CardActions';

const Works = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { data = [], loading, run } = useRequest(worksService.fetchAll, { manual: true });
  const columns = useMemo(() => workTableConfig, []);

  const [isOpenCreateWork, setIsOpenCreateWork] = React.useState(false);

  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        number: [],
        description: [],
        company_id: [],
        company_location_id: [],
        company_person_id: [],
        year: [],
        sap_orders: [],
        state: [],
        production_state: [],
        companies: [],
        users: [],
        tags: [],
        without_sap_orders: false,
      },
    }
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))

  const mapValues = values => ({
    ...values, filter: {
      ...values.filter,
      number: values.filter.number.map(opt => opt.value),
      description: values.filter.description.map(opt => opt.value),
      company_id: values.filter.company_id.map(opt => opt.value),
      sap_orders: values.filter.sap_orders.map(opt => opt.value),
      state: values.filter.state.map(opt => opt.value),
      production_state: values.filter.production_state.map(opt => opt.value),
      companies: values.filter.companies.map(opt => opt.value),
      users: values.filter.users.map(opt => opt.value),
      tags: values.filter.tags.map(opt => opt.value),
      without_sap_orders: values.filter.without_sap_orders
    }
  })

  const handleUpdate = values => run(mapValues(values))

  const handleExportExcel = () => exportsService.works(mapValues(extraParams), 'works');

  return (
    <>
      <WorkFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('works.plural')}</CardLabel>
            <CardActions>
              <CreateButton onClick={() => setIsOpenCreateWork(true)}/>
              <ExportExcelButton handleClick={handleExportExcel}/>
            </CardActions>
          </CardContentBetween>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
          />
        </CardBody>
      </Card>

      <WorkCreateDrawer
        isOpen={isOpenCreateWork}
        setIsOpen={setIsOpenCreateWork}
      />
    </>
  )
}

export default Works;
