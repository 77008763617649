import React, { useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import partCategoriesService from '../../../services/parts/partCategoriesService';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import partCategoriesTableConfig from '../components/partCategoriesTableConfig';
import Toolbar from '../../../components/UI/Toolbar';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import PartCategoryCreateDrawer from './PartCategoryCreateDrawer';

function PartCategories() {
  const { data = [], loading, run } = useRequest(partCategoriesService.fetchAll, { manual: true });
  const columns = useMemo(() => partCategoriesTableConfig, []);
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState()

  const handleOnChange = params => run({ ...params, filter: { empty_revision_id: true } })


  return (
    <>
      <Toolbar title={t('part_categories.plural')}>
        <div className="d-flex gap-1">
          <CreateButton onClick={() => setIsDrawerOpen(true)}/>
        </div>
      </Toolbar>

      <Card>
        <CardBody>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            onParamsChange={handleOnChange}
          />
        </CardBody>
      </Card>

      <PartCategoryCreateDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
      />
    </>
  );
}

export default PartCategories;
