import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';
import TranslatableDisplay from '../../../components/UI/Displays/TranslatableDisplay';

const wordTableConfig = [{
  Header: <Trans i18nKey="words.label"/>,
  accessor: 'label',
  Cell: ({ row, value }) => <TableLink to={`/words/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="description"/>,
  accessor: 'description',
  Cell: ({ row, value }) => (
    <TranslatableDisplay
      translatableArray={row.original.descriptions}
      currentTranslation={value}
    />
  ),
}];

export default wordTableConfig;
