import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TranslatableDisplay from '../../../../components/UI/Displays/TranslatableDisplay';
import PartTypeEnumDisplay from '../../../../components/UI/Displays/Parts/PartTypeEnumDisplay';
import { PartDetailsDrawer } from './PartDetailsDrawer';
import PercentageDisplay from '../../../../components/UI/Displays/PercentageDisplay';
import { UncontrolledTooltip } from 'reactstrap';

export const PartDetails = ({ part, setPart, categories }) => {


  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = res => {
    setPart(p => ({
      ...p,
      cost: res.cost,
      price: res.price,
      calculated_price_by_margin: res.calculated_price_by_margin,
      calculated_price_by_part_Category: res.calculated_price_by_part_category,
      part_category: res.part_category,
      note: res.note,
      drawing_note: res.drawing_note,
      margin: res.margin,
    }))

    setIsOpen(o => false)
  }

  return (
    <>
      <div className='card w-100'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center gap-4'>
            <span className='text-black-50'>{t('details')}</span>
            <button
              className='btn btn-light'
              type='button'
              onClick={() => setIsOpen(true)}
            >{t('edit')}
            </button>
          </div>
          <ul className='list-group list-group-flush'>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('description')}</div>
              <TranslatableDisplay
                currentTranslation={part.description}
                translatableArray={part.descriptions}
              />
            </li>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('short_description')}</div>
              <TranslatableDisplay
                currentTranslation={part.short_description}
                translatableArray={part.short_descriptions}
              />
            </li>
            <li className='list-group-item px-1'>
              <div className='fw-bold'>{t('parts.provisional_description')}</div>
              {part.provisional_description ?? '---'}
            </li>
            <li className='list-group-item px-1'>
              <div className='fw-bold'>{t('parts.um')}</div>
              {part.um}
            </li>
            <li className='list-group-item px-1'>
              <div className='fw-bold'>{t('parts.weight')}</div>
              {part.weight}
            </li>
            <li className='list-group-item px-1'>
              <div className='fw-bold'>{t('parts.volume')}</div>
              {part.volume}
            </li>
            <li className='list-group-item px-1'>
              <div className='fw-bold'>{t('parts.mass')}</div>
              {part.mass}
            </li>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('part_collections.sing')}</div>
              {part.part_collection?.label ?? '---'}
            </li>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('part_type')}</div>
              <PartTypeEnumDisplay type={part.part_type}/>
            </li>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('note')}</div>
              {part.note}
            </li>
            <li className="list-group-item px-1">
              <div className='fw-bold'>{t('parts.drawing_note')}</div>
              {part.drawing_note}
            </li>
          </ul>
        </div>
      </div>

      <PartDetailsDrawer
        part={part}
        categories={categories}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onUpdate={handleUpdate}
      />
    </>
  )

}
