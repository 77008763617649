import React from 'react';
import { Outlet } from 'react-router-dom';

const WordsWrapper = () => {
  return (
    <Outlet/>
  );
}

export default WordsWrapper;
