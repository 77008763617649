import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';

function UserWrapper() {
  const { user } = useSanctum();

  return (
    <Outlet/>
  );
}

export default UserWrapper;
