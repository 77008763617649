import React, { useMemo } from 'react';
import { useRequest, useSessionStorageState } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import partsService from '../../../../services/parts/partsService';
import Toolbar from '../../../../components/UI/Toolbar';
import partTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { useLocation } from 'react-router-dom';
import BetaToolPartFilter from './BetaPartFilter';
import betaPartTableConfig from './betaPartTableConfig';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';

function BetaParts() {
  const { data = [], loading, run } = useRequest(partsService.fetchAll, { manual: true });
  const location = useLocation()
  const columns = useMemo(() => betaPartTableConfig, []);
  const { t } = useTranslation();
  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        code: [],
        description: [],
        works: [],
        part_type: [partTypeEnum.values.BETA],
      },
    }
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))
  const handleUpdate = values => {
    run({
      ...values, filter: {
        ...values.filter,
        code: values.filter.code.map(opt => opt.value),
        description: values.filter.description.map(opt => opt.value),
        works: values.filter.works.map(opt => opt.value)
      }
    })
  }

  return (
    <>
      <Toolbar title={t('enums.part_types.BETA')}/>

      <BetaToolPartFilter handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <div className='card'>
        <div className='card-body'>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
          />
        </div>
      </div>
    </>
  );
}

export default BetaParts;
