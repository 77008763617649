import clsx from 'clsx';
import PercentageDisplay from '../PercentageDisplay';

function PartRealMarginDisplay({
                                 margin = 0,
                                 realMargin = 0
                               }) {

  return (
    <div title={(realMargin * 100)?.toString()} className='badge bg-light text-dark fs-6'>
      <i
        className={clsx('fa', {
          'badge-soft-danger fa-arrow-circle-down': realMargin < margin,
          'badge-soft-warning fa-minus-circle': realMargin === margin,
          'badge-soft-success fa-arrow-circle-up': realMargin > margin,
        })}
      />&nbsp;
      <PercentageDisplay value={realMargin * 100}/>
    </div>
  )
}

export default PartRealMarginDisplay
