import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import codesService from '../../../services/parts/codesService';
import EmptyDisplay from '../../../components/UI/Displays/EmptyDisplay';

function SimilarCodes({ words, id = null }) {
  const { t } = useTranslation();
  const { data = [], run } = useRequest(codesService.fetchSimilar, { manual: true });

  useEffect(() => {
    if (words.length > 0) {
      run({ id, words: words.map(w => w.value), });
    }
  }, [words]);


  return (
    <>
      <h5>{t('similar-results')}</h5>

      {words.length < 1 || data.length < 1
        ? <EmptyDisplay/>
        : data.map((d, index) => (
          <div className='bg-light bg-opacity-50 d-flex align-items-center justify-content-between mb-1 py-1 px-2 rounded-1' key={index}>
            <div className='d-flex align-items-center justify-content-start gap-1'>
              {_.isEqual(d.words.map(w => w.id), words.map(w => w.value)) && <i className="fa fa-exclamation-circle text-danger ms-1"/>}
              {d.code} - {d.description}
            </div>
          </div>
        ))}
    </>
  );
}

export default SimilarCodes;
