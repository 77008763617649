import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';
import { Tag } from '../../../../../components/UI/Tags/Tag';

const WorkCodesCheckFault = ({ item }) => {

  const { t } = useTranslation()

  return (
    <ListItem
      label={item.code}
      extra={item.part_uuid ?
        <Link className='btn btn-soft-danger btn-sm' to={`/parts/${item.part_uuid}`}>
          {t('parts.no_cost_price')}
        </Link> : <Tag color='danger'>{t('not_found')}</Tag>}
    >
      {item.description}
    </ListItem>
  )


}

export default WorkCodesCheckFault
