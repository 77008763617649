import React from 'react';
import { ErrorMessage, useField } from 'formik';
import clsx from 'clsx';
import { FormDisplay } from '../Displays/FormDisplay';

export function FormGroup({
  name,
  inline,
  required,
  label,
  description,
  children,
  disabled,
  disabledValue,
}) {
  const [meta] = useField(name);

  const { value, error, touched } = meta;
  const isInvalid = required && (error || value == null || value.length === 0) && touched;


  const labelClasses = {
    required,
    'text-danger font-weight-bold': isInvalid,
  };

  if (disabled) {
    return (<FormDisplay label={label}>{disabledValue}</FormDisplay>);
  }

  return (
    <div className="mb-3">
      {inline
        ? (
          <div className="row">
            <label htmlFor={name} className={clsx('col-lg-4 ', labelClasses)}>
              {isInvalid && <i className="fa fa-square-exclamation ms-1" />}
              {label}
            </label>
            <div className="col-lg-8">
              {children}
              {description && <div className="text-muted mt-1">{description}</div>}
              <ErrorMessage name={name} component="div" className="d-block invalid-feedback" />
            </div>
          </div>
        )
        : (
          <>

            <label htmlFor={name} className={clsx('d-block form-label mb-1', labelClasses)}>
              {isInvalid && <i className="fa fa-square-exclamation ms-1" />}
              {label}
            </label>
            {children}
            {description && <div className="text-muted mt-1">{description}</div>}
            <ErrorMessage name={name} component="div" className="d-block invalid-feedback" />
          </>
        )}
    </div>
  );
}

export default FormGroup;
