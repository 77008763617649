import React from 'react';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';
import CodeEdit from './_components/CodeEdit';
import codesService from '../../../services/parts/codesService';
import PartList from './_components/PartList';
import CodeTypeEnum from '../../../constants/enums/CodeTypeEnum';
import MeasureEdit from './_components/MeasureEdit';

const CodesShow = () => {
  const { id } = useParams();
  const { data, loading, mutate, refresh } = useRequest(codesService.fetch, { defaultParams: [id] });

  const handleUpdate = values => {
    refresh()
    mutate(values);
  }


  return (
    <>
      <h5>{data?.code}&nbsp;-&nbsp;{data?.description}</h5>

      {data?.code_type === CodeTypeEnum.values.MEASURE
        ? <MeasureEdit
          data={data}
          onUpdate={handleUpdate}
        />
        : <CodeEdit
          data={data}
          onUpdate={handleUpdate}
        />}

      <PartList parts={data?.parts} loading={loading}/>
    </>
  );
}

export default CodesShow;
