import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import partsService from '../../../services/parts/partsService';
import partTypeEnum from '../../../constants/enums/PartTypeEnum';
import PartTypeEnum from '../../../constants/enums/PartTypeEnum';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import CodeTypeEnum from '../../../constants/enums/CodeTypeEnum';
import WordsSort from '../../BaseCodes/components/WordsSort/WordsSort';
import PartMeasures from '../components/PartMeasures';
import BaseCodePicker from '../../../components/UI/Forms/Pickers/BaseCodePicker';
import DisplayFormattedMeasures from './components/DisplayFormattedMeasures';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import EuroDisplay from '../../../components/UI/Displays/EuroDisplay';

const initialValues = {
  category_id: undefined,
  words: [],
  width: '',
  depth: '',
  height: '',
  note: ''
}

const validationSchema = yup.object().shape({
  category_id: yup.number().required(),
  words: Yup.array().min(1).required(),
  width: Yup.number().min(0).nullable().notRequired(),
  depth: Yup.number().min(0).nullable().notRequired(),
  height: Yup.number().min(0).nullable().notRequired(),
  note: yup.string().max(800).nullable().notRequired()
});

const WithoutMeasureCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [category, setCategory] = React.useState(undefined);

  // Potrebbe essere stato passato un FC
  const { state } = useLocation();

  const handleSubmit = (values, { setSubmitting }) => partsService.store(partTypeEnum.values.WITHOUT_MEASURE, {
    ...values,
    part_id: state?.id ?? null,
    words: values.words.map(w => w.value)
  })
  .then(res => {
    toast.success(t('created'));
    navigate(`../${res.uuid}`);
  })
  .catch(err => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false));

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
    if (state?.id !== undefined && state?.id !== null) {
      formik.setValues(v => ({
        ...v,
        width: state.width ?? undefined,
        depth: state.depth ?? undefined,
        height: state.height ?? undefined,
      }))
    }
  }, [state]);


  return (
    <FormikProvider value={formik}>
      <Form>
        <h5 className="text-uppercase">{t('enums.part_types.WITHOUT_MEASURE')}</h5>

        <div className='card'>
          <div className='card-body'>

            <div className='row'>

              <div className='col-lg-4'>
                <FormDisplay label={t('parts.code')}>
                  {`3D${category?.code || '---'}XXXXXX`}
                </FormDisplay>
              </div>

              <div className='col-lg-4'>
                <FormDisplay label={t('description')}>
                  {formik.values.words.map(w => w.label).join(' ')}
                  &nbsp;
                  <DisplayFormattedMeasures
                    width={formik.values.width}
                    depth={formik.values.depth}
                    height={formik.values.height}
                  />
                </FormDisplay>
              </div>

              {state !== undefined && state !== null &&
                <div className='col-lg-4'>
                  <h5>{t('parts.part_id')}</h5>
                  <div>
                    {state.code}<br/>
                    {state.description}<br/>
                    {t('parts.cost')} <EuroDisplay value={state.cost}/><br/>
                    {t('parts.price')} <EuroDisplay value={state.price}/>
                  </div>
                </div>
              }

            </div>

            <div className='row'>
              <div className='col-lg-4'>
                <h5>&nbsp;</h5>
                <FormGroup required name="category_id" label={t('enums.code_types.CATEGORY')}>
                  <BaseCodePicker
                    defaultParams={{
                      code_type: CodeTypeEnum.values.CATEGORY,
                      part_type: PartTypeEnum.values.WITHOUT_MEASURE,
                    }}
                    handleChange={v => {
                      setCategory(oldValue => v || undefined);
                      formik.setFieldValue('category_id', v?.value || undefined);
                    }}
                  />
                </FormGroup>
              </div>
              <div className='col-lg-4'>
                <WordsSort/>
              </div>
              <div className='col-lg-4'>
                <PartMeasures/>
              </div>
            </div>

            <FormGroup name="note" label={t('note')}>
              <FormInput name="note" rows={5} as="textarea"/>
            </FormGroup>

            <div className="d-flex align-items-center justify-content-end gap-2">
              <CancelButton onClick={() => navigate('/parts/standards')}/>
              <FormikSaveButton/>
            </div>

          </div>
        </div>


      </Form>
    </FormikProvider>
  );
}

export default WithoutMeasureCreate;
