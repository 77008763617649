import React from "react";

const TableCheckbox = (props) => {

  return (
    <div className="form-check">
      <input className="form-check-input" type="checkbox" {...props}/>
    </div>
  )

}

export default TableCheckbox
