import React from 'react';
import { Col, Container, Row } from 'reactstrap';

function Footer() {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col md={6}>
            {new Date().getFullYear()}
            {' '}
            © 3dBeta
          </Col>
          <Col md={6}>
            <div className="text-sm-end d-none d-sm-block">
              All rights reserved
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
