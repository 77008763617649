import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBarDropdownTitle from '../NavBarDropdownTitle';
import classname from 'classnames';
import { Link } from 'react-router-dom';

const UserManagementDropdown = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown">
        <NavBarDropdownTitle
          label={t('menu.master_access')}
          state={isOpen}
          handleState={setIsOpen}
          icon="fa fa-key"
        />
        <div className={classname('dropdown-menu', { show: isOpen })}>
          <Link to="/users" className="dropdown-item">
            <i className="fa fa-user me-2" />
            {t('menu.manage_users')}
          </Link>
        </div>
      </li>
    </ul>
  )
}

export default UserManagementDropdown