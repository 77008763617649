import React from 'react';
import { useTranslation } from 'react-i18next';
import EuroDisplay from '../../../../../../components/UI/Displays/EuroDisplay';

export const SubOfferToolsTotals = ({ subOffer }) => {
  
  const { t } = useTranslation()

  return (

    <div className='card-group gap-1 py-1'>
      <div className={`card text-white bg-opacity-75 bg-${subOffer.is_invalid ? 'danger' : 'info'}`}>
        <div className='card-body text-end d-flex flex-column'>
          <span>{t('sub_offers.offer_sub_price_total')}</span>
          <span className='fs-5 fw-bold'><EuroDisplay value={subOffer?.total_price}/></span>
        </div>
      </div>
      <div className='card text-white bg-opacity-75 bg-info'>
        <div className='card-body text-end d-flex flex-column'>
          <span>{t('sub_offers.offer_sub_net_price_total')}</span>
          <span className='fs-5 fw-bold'><EuroDisplay value={subOffer?.total_net_price}/></span>
        </div>
      </div>
      <div className='card text-white bg-opacity-75 bg-success'>
        <div className='card-body text-end d-flex flex-column'>
          <span>{t('sub_offers.offer_sub_cost_total')}</span>
          <span className='fs-5 fw-bold'>---</span>
        </div>
      </div>
      <div className='card text-white bg-opacity-75 bg-success'>
        <div className='card-body text-end d-flex flex-column'>
          <span>{t('sub_offers.offer_sub_min_sell_price_total')}</span>
          <span className='fs-5 fw-bold'>---</span>
        </div>
      </div>
    </div>

  )

}
