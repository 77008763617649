import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Toolbar from '../../../components/UI/Toolbar';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikResetButton from '../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import * as Yup from 'yup';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import ColorPresetsService from '../../../services/colors/colorPresetsService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Drawer from '../../../components/UI/Drawer/Drawer';

const ColorPresetCreateDrawer = ({ isOpen, setIsOpen }) => {


  const { t } = useTranslation()
  const navigate = useNavigate()

  const [initialValues] = useState({
    label: '',
    note: '',
  })

  const validation = Yup.object().shape({
    label: Yup.string().max(100).required(),
    note: Yup.string().max(800).nullable().notRequired()
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      ColorPresetsService.store(values).then((res) => {
        navigate(res.uuid)
      })
      .catch((err) => toast.error(err?.response?.data?.message))
      .finally(() => setSubmitting(false))
    }
  })

  return (
    <Drawer
      title={t('new')}
      status={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup required label={t('color_presets.label')} name='label'>
            <FormInput name='label'/>
          </FormGroup>
          <FormGroup label={t('note')} name='note'>
            <FormInput name='note' as='textarea' rows={3}/>
          </FormGroup>
          <Toolbar>
            <CancelButton onClick={() => navigate('../')}/>
            <div className='d-flex align-items-center justify-content-end gap-2'>
              <FormikResetButton/>
              <FormikSaveButton/>
            </div>
          </Toolbar>
        </Form>
      </FormikProvider>
    </Drawer>
  )

}

export default ColorPresetCreateDrawer
