import React from 'react';
import BatchWrapper from '../../../pages/Batch/BatchWrapper';
import BatchIndex from '../../../pages/Batch/BatchIndex';

const batchRoutes = {
  path: 'batch/*',
  element: <BatchWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <BatchIndex/>,
  }]
};


export default batchRoutes;