import React from 'react';
import { Field } from 'formik';

export function FormCheckbox(props) {
  return (
    <div className="form-check">
      <Field
        type="checkbox"
        className="form-check-input"
        {...props}
      />
      <label className='d-flex flex-column gap-1'>
        <div>{props.label}</div>
        {props?.description !== undefined && <span className='fw-normal text-muted'>{props.description}</span>}
      </label>
    </div>
  );
}
