import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import colorsService from '../../../services/colors/colorsService';
import { toast } from 'react-toastify';
import Drawer from '../../../components/UI/Drawer/Drawer';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import { FormEuro } from '../../../components/UI/Forms/FormEuro';
import { FormCheckbox } from '../../../components/UI/Forms/FormCheckbox';

const validationSchema = Yup.object().shape({
  supplier_code: Yup.string().max(50).nullable().required(),
  supplier_material_code: Yup.string().max(50).nullable().notRequired(),
  supplier_business_name: Yup.string().max(100).nullable().notRequired(),
  sap_code: Yup.string().max(20).nullable().notRequired(),
  description: Yup.string().max(255).nullable().required(),
  price: Yup.number().min(0).nullable().notRequired(),
  stock: Yup.number().min(0).nullable().notRequired(),
  note: Yup.string().nullable().notRequired(),
  color_parts: Yup.array().nullable().notRequired()
});

const ColorDetailsDrawer = ({ isOpen, setIsOpen, colorParts }) => {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const initialValues = {
    supplier_code: '',
    supplier_material_code: '',
    supplier_business_name: '',
    sap_code: '',
    description: '',
    price: '',
    stock: '',
    note: '',
    color_parts: []
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => colorsService.store(values)
    .then(res => navigate(res.uuid))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  })

  return (
    <Drawer
      title={t('new')}
      toggle={() => setIsOpen(false)}
      status={isOpen}
    >
      <FormikProvider value={formik}>
        <Form>
          <div className='row'>
            <div className='col-lg-6'>
              <FormGroup name="supplier_code"  required  label={t('colors.supplier_code')}>
                <FormInput name="supplier_code"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="supplier_material_code" label={t('colors.supplier_material_code')}>
                <FormInput name="supplier_material_code"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="supplier_business_name" label={t('colors.supplier_business_name')}>
                <FormInput name="supplier_business_name"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="sap_code" label={t('colors.sap_code')}>
                <FormInput name="sap_code"/>
              </FormGroup>
            </div>
          </div>
          <FormGroup name="description" label={t('colors.description')} required>
            <FormInput name="description" as="textarea"/>
          </FormGroup>
          <div className='row'>
            <div className='col-lg-6'>
              <FormGroup name="price" label={t('colors.price')}>
                <FormEuro name="price"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="stock" label={t('colors.stock')}>
                <FormInput name="stock" min={0} type="number" step={0.01}/>
              </FormGroup>
            </div>
          </div>
          <FormGroup name='color_parts' label={t('color_parts.plural')}>
            <div className='row'>
              {colorParts.map(cp =>
                <div className='col-lg-6' key={cp.uuid}>
                  <FormCheckbox
                    name='color_parts'
                    value={cp.id.toString()}
                    label={cp.label}
                  />
                </div>
              )}
            </div>
          </FormGroup>
          <FormGroup name="note" label={t('note')} required>
            <FormInput name="note" rows={10} as="textarea"/>
          </FormGroup>


          <div className="d-flex align-items-center justify-content-between">
            <CancelButton onClick={() => setIsOpen(false)}/>
            <div className="d-flex gap-1">
              <FormikSaveButton/>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Drawer>
  )

}

export default ColorDetailsDrawer
