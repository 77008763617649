import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import localesConfig from '../../../../locales/locales.config';

function ExportExcelLocalizedButton({ handleClick, buttonId = "export_button" }) {

  const { t } = useTranslation();

  const [currentTranslationLanguage] = useState(localStorage.getItem(localesConfig.TRANSLATION_LANGUAGE));
  const [currentTranslationSelected, setCurrentTranslationSelected] = useState(localesConfig.availableTranslations.find(at => at.locale = currentTranslationLanguage))

  return (
    <>
      <UncontrolledTooltip target={buttonId}>Seleziona la lingua cliccando sulla bandierina e esporta in formato Excel</UncontrolledTooltip>

      <div className="btn-group text-nowrap" role="group">
        <UncontrolledDropdown>
          <DropdownToggle caret className='btn btn-icon btn-soft-success rounded-end-0 px-2' color='success'>
            {currentTranslationSelected !== 'all'
              ? <img className="border border-opacity-50 border-white" src={currentTranslationSelected.flag} height="14"/>
              : <i className='fa fa-globe-europe' />
            }

          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setCurrentTranslationSelected('all')}>
              <i className='fa fa-globe-europe' />&nbsp;{t('all_languages')}
            </DropdownItem>
            {localesConfig.availableTranslations.map(locale =>
              <DropdownItem key={locale.locale} onClick={() => setCurrentTranslationSelected(locale)}>
                <img src={locale.flag} height="16"/>&nbsp;{locale.label}
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>


        <button className='btn btn-soft-success' id={buttonId} onClick={() => handleClick(currentTranslationSelected?.locale ?? 'all')}>
          <i className='fa fa-file-excel'/>&nbsp;{t("export_excel")}
        </button>
      </div>
    </>
  );
}

export default ExportExcelLocalizedButton;
