import React from 'react';

const UserDisplay = ({ user, small = false }) => {

  return (
    <div className="d-flex align-items-center justify-content-start gap-1">
      <img className="rounded-circle"
           width={small ? 18 : 36}
           height={small ? 18 : 36}
           src={user?.avatar}
           alt="Avatar"
      />
      <div className="d-flex flex-column">
        <span className={small ? "": "fw-bold"}>{user?.full_name ?? user?.label}</span>
        {!small && <span className="text-muted">{user?.email}</span>}
      </div>
    </div>
  )

}
export default UserDisplay
