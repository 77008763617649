const MESSAGE = 'message';
const PART = 'part';
const TOOL = 'tool';

const SubOfferItemTypeEnum = {
  MESSAGE,
  PART,
  TOOL
};


export default SubOfferItemTypeEnum;
