import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest, useSessionStorageState } from 'ahooks';
import offersService from '../../../services/offers/offersService';
import offerTableConfig from './offerTable.config';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import OfferCreateDrawer from './OfferCreateDrawer';
import OfferFilters from './OfferFilters';
import { useLocation } from "react-router-dom";
import { Card } from '../../../components/UI/Card/Card';
import { CardBody } from '../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../components/UI/Card/CardLabel';
import ExportExcelButton from '../../../components/UI/Buttons/Excel/ExportExcelButton';
import { CardActions } from '../../../components/UI/Card/CardActions';
import exportsService from '../../../services/exportsService';


function Offers() {
  const location = useLocation()
  const { t } = useTranslation()
  const { data = [], loading, run } = useRequest(offersService.fetchAll, { manual: true });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const columns = useMemo(() => offerTableConfig, []);

  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        description: [],
        work_id: [],
        number: [],
        company_id: [],
        state: [],
        users: [],
      }
    },
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))
  const handleExportExcel = () => exportsService.offers(extraParams, 'offers');

  const handleUpdate = (values) => {
    run({
      ...values, filter: {
        description: values.filter.description.map(opt => opt.value),
        work_id: values.filter.work_id.map(opt => opt.value),
        number: values.filter.number.map(opt => opt.value),
        company_id: values.filter.company_id.map(opt => opt.value),
        state: values.filter.state.map(opt => opt.value),
        users: values.filter.users.map(opt => opt.value)
      }
    })
  }

  return <>

    <OfferFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

    <Card>
      <CardBody>
        <CardContentBetween>
          <CardLabel>{t('offers.plural')}</CardLabel>
          <CardActions>
            <ExportExcelButton buttonId='offers_excel_btn' handleClick={handleExportExcel}/>
            <CreateButton onClick={() => setIsDrawerOpen(true)}/>
          </CardActions>
        </CardContentBetween>
        <PaginatedTable
          options={paginatedTableConfig}
          isLoading={loading}
          data={data}
          columns={columns}
          sortable
          onParamsChange={handleUpdate}
          extraParams={extraParams}
        />
      </CardBody>
    </Card>

    <OfferCreateDrawer
      isOpen={isDrawerOpen}
      setIsOpen={setIsDrawerOpen}
    />
  </>
}

export default Offers;
