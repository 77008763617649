import React from 'react';
import { Tag } from '../Tags/Tag';

function GenericStateDisplay({ state }) {

  return <Tag color={state?.color}>{state?.label}</Tag>


}

export default GenericStateDisplay;
