import React, { useContext, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { toast } from 'react-toastify';
import subOffersService from '../../../../../../services/offers/subOffersService';
import SubOfferDraggableItem from '../SubOfferItem/SubOfferDraggableItem';
import SubOfferTotals from './SubOfferTotals';
import { OfferContext } from '../../../offerContext/offerContext';
import { offerActionTypes } from '../../../offerContext/offerReducer/offerActionTypes';
import { SubOfferItemAdder } from '../SubOfferItemAdder/SubOfferItemAdder';
import { AppContext } from '../../../../../../components/AppContext';
import PartTypeEnum from '../../../../../../constants/enums/PartTypeEnum';

const SubOffer = ({ offerUuid, offerRevisionUuid, subOffer }) => {

  const [dragEnabled, setDragEnabled] = useState(true)
  const { dispatchAction, revision_editable } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const handleDrag = (oldPosition, newPosition) => {
    setLoadingOverlayState(true)
    subOffersService.swap(offerUuid, offerRevisionUuid, subOffer.uuid, {
      swap_from: oldPosition,
      swap_to: newPosition
    })
    .then(res => dispatchAction({
      type: offerActionTypes.SWAP_SUB_OFFER_ITEM_POSITION, payload: {
        oldPosition,
        newPosition,
        subOfferUuid: subOffer.uuid
      }
    }))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setLoadingOverlayState(false))
  }

  return (
    <>
      <SubOfferTotals
        subOffer={subOffer}
        offerRevisionUuid={offerRevisionUuid}
        revision_editable={revision_editable}
      />

      <DndProvider backend={HTML5Backend}>
        {subOffer.sub_offer_items.map((subOfferItem, pos) =>
          <SubOfferDraggableItem
            key={subOfferItem.uuid}
            pos={pos}
            id={subOfferItem.id}
            onDrag={handleDrag}
            canDrag={dragEnabled}
            offerUuid={offerUuid}
            offerRevisionUuid={offerRevisionUuid}
            subOfferUuid={subOffer.uuid}
            subOfferItem={subOfferItem}
            setDragEnabled={setDragEnabled}
          />
        )}
      </DndProvider>

      {revision_editable &&
      <SubOfferItemAdder
        action={offerActionTypes.ADD_SUB_OFFER_ITEM}
        subOfferUuid={subOffer.uuid}
        offerRevisionUuid={offerRevisionUuid}
        partTypes={[
          PartTypeEnum.values.WITH_MEASURE,
          PartTypeEnum.values.WITHOUT_MEASURE,
          PartTypeEnum.values.BETA
        ]}
      />}


    </>
  )

}

export default SubOffer
