import React from 'react';
import PartsWrapper from '../../../pages/Parts/PartsWrapper';
import WithMeasureCreate from '../../../pages/Parts/creates/WithMeasureCreate';
import WithoutMeasureCreate from '../../../pages/Parts/creates/WithoutMeasureCreate';
import CustomCreate from '../../../pages/Parts/creates/CustomCreate';
import AssemblyCreate from '../../../pages/Parts/creates/AssemblyCreate';
import Part from '../../../pages/Parts/Part/Part';
import { PartsDashboard } from '../../../pages/Parts/PartsDashboard';
import CustomParts from '../../../pages/Parts/Parts/Custom/CustomParts';
import AssemblyParts from '../../../pages/Parts/Parts/Assembly/AssemblyParts';
import StandardParts from '../../../pages/Parts/Parts/Standard/StandardParts';
import BetaToolParts from '../../../pages/Parts/Parts/BetaTools/BetaToolParts';
import BetaParts from '../../../pages/Parts/Parts/Beta/BetaParts';
import { JohnDoeParts } from '../../../pages/Parts/Parts/JohnDoe/JohnDoeParts';
import { Outlet } from 'react-router-dom';

const partsRoutes = {
  path: 'parts/*',
  element: <PartsWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <PartsDashboard/>
  }, {
    path: 'customs',
    element: <Outlet />,
    children: [{
      path: '',
      element: <CustomParts/>
    },{
      path: 'generic',
      element: <JohnDoeParts/>,
    }]
  }, {
    path: 'assemblies',
    element: <AssemblyParts/>,
  }, {
    path: 'standards',
    element: <StandardParts/>,
  }, {
    path: 'beta-tools',
    element: <BetaToolParts/>,
  }, {
    path: 'beta',
    element: <BetaParts/>,
  }, {
    path: 'create-with-measure',
    element: <WithMeasureCreate/>,
  }, {
    path: 'create-without-measure',
    element: <WithoutMeasureCreate/>,
  }, {
    path: 'create-custom',
    element: <CustomCreate/>,
  }, {
    path: 'create-assembly',
    element: <AssemblyCreate/>,
  }, {
    path: ':id',
    element: <Part/>,
  }],
};

export default partsRoutes;
