import React from 'react';
import { Outlet } from 'react-router-dom';

function PartsWrapper() {
  return (
    <Outlet/>
  );
}

export default PartsWrapper;
