import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbItem, Col, Row } from 'reactstrap';

function Breadcrumb() {
  const { pathname } = useLocation();
  const pathNames = pathname.split('/').filter(Boolean);

  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          {/* <h4 className="mb-sm-0 font-size-18">Page Title</h4> */}
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/"><i className="fa fa-home" /></Link>
              </BreadcrumbItem>
              {pathNames.map((name, index) => {
                const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathNames.length - 1;
                return isLast
                  ? (<BreadcrumbItem key={name}>{name.toUpperCase()}</BreadcrumbItem>)
                  : (
                    <BreadcrumbItem key={name}>
                      <Link
                        to={routeTo}
                      >
                        {name.toUpperCase()}
                      </Link>
                    </BreadcrumbItem>
                  );
              })}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Breadcrumb;
