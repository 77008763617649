import { ListItemLabel } from './ListItemLabel';
import { Contact } from '../../Companies/Contacts/Contact';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const ListPersonItem = ({ person, setSelectedPerson }) => {

  const { t } = useTranslation();
  const { contacts = [] } = person;

  return (
    <li className="list-group-item px-0">
      <div className="d-flex align-items-center justify-content-between">
        <div className='link-primary cursor-pointer' onClick={() => setSelectedPerson(person)}>
          <ListItemLabel label={person.full_name}/>
        </div>
        {!!person.note &&
          <>
            <UncontrolledTooltip target={`person_${person.uuid}`}>{person.note}</UncontrolledTooltip>
            <span id={`person_${person.uuid}`} className='link-primary'><i className='fa fa-eye'/>&nbsp;{t('note')}</span>
          </>
        }
      </div>
      {contacts.length > 0 ? contacts.map(c => <Contact contact={c} key={c.uuid}/>) : '---'}
    </li>
  )

}

