import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest, useSessionStorageState } from 'ahooks';
import colorsService from '../../../services/colors/colorsService';
import colorTableConfig from './colorTable.config';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import Toolbar from '../../../components/UI/Toolbar';
import { Card, CardBody } from 'reactstrap';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import ColorCreateDrawer from './ColorCreateDrawer';
import { AppContext } from '../../../components/AppContext';
import ColorFilters from './ColorFilters';
import { useLocation } from "react-router-dom";
import ExportExcelButton from '../../../components/UI/Buttons/Excel/ExportExcelButton';
import exportsService from '../../../services/exportsService';

const Colors = () => {
  const location = useLocation();
  const { t } = useTranslation()
  const { data = [], loading, run } = useRequest(colorsService.fetchAll, { manual: true });
  const { colorParts } = React.useContext(AppContext);
  const columns = useMemo(() => colorTableConfig, []);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        supplier_code: [],
        supplier_material_code: [],
        supplier_business_name: [],
        sap_code: [],
        description: [],
      },
    }
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))

  const mapValues = values => ({
    ...values, filter: {
      supplier_code: values.filter.supplier_code.map(opt => opt.value),
      supplier_material_code: values.filter.supplier_material_code.map(opt => opt.value),
      supplier_business_name: values.filter.supplier_business_name.map(opt => opt.value),
      sap_code: values.filter.sap_code.map(opt => opt.value),
      description: values.filter.description.map(opt => opt.value)
    }
  })
  const handleUpdate = values => {
    run(mapValues(values))
  }
  const handleExportExcel = values => exportsService.colors(mapValues(extraParams), 'colors');

  return (
    <>
      <Toolbar title={t('colors.plural')}>
        <div className="d-flex gap-1">
          <ExportExcelButton buttonId='colors_export' handleClick={handleExportExcel} />
          <CreateButton onClick={() => setIsDrawerOpen(true)}/>
        </div>
      </Toolbar>


      <ColorFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <Card>
        <CardBody>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
          />
        </CardBody>
      </Card>

      <ColorCreateDrawer
        colorParts={colorParts}
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
      />
    </>
  )

}

export default Colors
