import React, { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import Toolbar from '../../../components/UI/Toolbar';
import { FormFile } from '../../../components/UI/Forms/FormFile/FormFile';
import { useRequest } from 'ahooks';
import batchService from '../../../services/batchService';
import { toast } from 'react-toastify';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import logsTableConfig from '../logsTableConfig';
import { FormSearch } from '../../../components/UI/Forms/FormSearch';
import { useTranslation } from 'react-i18next';

function ImportPartCosts() {

  const columns = useMemo(() => logsTableConfig, []);
  const { t } = useTranslation()

  const [extraParams, setExtraParams] = React.useState({
    filter: {
      message: ''
    },
  });
  const {
    data: logMessages = [],
    mutate: mutateLogMessages,
    run: fetchMessages,
    loading
  } = useRequest(batchService.fetchImportCostsLogs, { manual: true });

  const { run, cancel } = useRequest(batchService.fetchImportCostsLogs, {
        pollingInterval: 5000,
        pollingErrorRetryCount: 1,
        manual: true,
        onError: (err) => toast.error(err?.response?.data?.message),
        onSuccess: (res) => {
          if (_.isEqual(logMessages, res) && res?.data.length > 0) {
            toast.success(t('completed'))
            cancel()
          } else {
            mutateLogMessages(res)
          }
        }
      }
    )
  ;

  return (
    <Card>
      <CardBody>
        <h5>{t('batch.import_costs')}</h5>
        <Toolbar>
          <div>
            <FormSearch
              name="message" value={extraParams.filter.message}
              onChange={(e) => setExtraParams({
                ...extraParams, filter: { ...extraParams.filter, message: e.target.value }
              })}
            />
          </div>
          <div>
            <FormFile
              name='file'
              url={batchService.importCostsUrl()}
              onUploadFinished={(res) => {
                run()
              }}
            />
          </div>
        </Toolbar>

        <PaginatedTable
          options={paginatedTableConfig}
          isLoading={loading}
          data={logMessages}
          sortable={false}
          onParamsChange={fetchMessages}
          extraParams={extraParams}
          columns={columns}
        />


      </CardBody>
    </Card>
  );
}

export default ImportPartCosts;
