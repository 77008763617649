import React from 'react';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const FormikSaveButton = props => {
  const { t } = useTranslation();
  const { isSubmitting, isValid } = useFormikContext();


  return (
    <button
      className='btn btn-soft-success'
      disabled={isSubmitting || !isValid}
      type="submit"
      {...props}
    >
      <span className="d-flex align-items-center">
        {isSubmitting && <Spinner size="sm" className="me-1"/>}
        {props?.label || t('save')}
      </span>
    </button>
  );
}

export default FormikSaveButton;
