import api from '../configs/api.config';

const url = 'user';

const authUser = () => api.get(url).then((res) => res.data);
const updateProfile = (params) => api.put(`${url}/profile-information`, params)
  .then((res) => res.data);
const updatePassword = (params) => api.put(`${url}/password`, params).then((res) => res.data);
const updateAvatar = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(`${url}/avatar`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then((res) => res.data);
};

const userService = {
  authUser,
  updateProfile,
  updatePassword,
  updateAvatar,
};

export default userService;
