import { format } from 'date-fns';

export const localeValidation = {
  mixed: {
    default: 'field_invalid',
    required: 'Field is mandatory',
    oneOf: (params) => `Field must be one of ${params.values}`,
  },
  string: {
    max: (params) => `Field maximum length is ${params.max} digits, instead is ${params.value?.length} digits length`,
    min: (params) => `Field minimum length is ${params.min} digits, instead is ${params.value?.length} digits length`,
    length: (params) => `Field length must be ${params.length} digits, instead is ${params.value?.length} digits length`,
    email: 'Field must be a valid email',
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: ({ min }) => `Value must be greater or equal then ${min}`,
    max: ({ max }) => `Value must be lower or equal then ${max}`,
  },
  date: {
    min: ({ min }) => `Value must be after or equal then ${format(min, 'dd-MM-yyyy')}`,
    max: ({ max }) => `Value must be before or equal then ${format(max, 'dd-mm-yyyy')}`,
  },
};
