import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';
import PartStateEnumDisplay from '../../../components/UI/Displays/Parts/PartStateEnumDisplay';
import EuroDisplay from '../../../components/UI/Displays/EuroDisplay';
import DateDisplay from '../../../components/UI/Displays/DateDisplay';

const partTableConfig = [{
  Header: <Trans i18nKey="parts.code"/>,
  accessor: 'code',
  Cell: ({ row, value }) => <TableLink to={`/parts/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="description"/>,
  accessor: 'description',
  disableSortBy: true
}, {
  Header: <Trans i18nKey="parts.cost"/>,
  accessor: 'cost',
  disableSortBy: true,
  Cell: ({ value }) => <EuroDisplay value={value}/>
}, {
  Header: <Trans i18nKey="parts.price"/>,
  accessor: 'price',
  disableSortBy: true,
  Cell: ({ value }) => <EuroDisplay value={value}/>
}, {
  Header: <Trans i18nKey="parts.state"/>,
  accessor: 'state',
  disableSortBy: true,
  Cell: ({ value }) => <PartStateEnumDisplay state={value}/>,
}, {
  Header: <Trans i18nKey="part_categories.sing"/>,
  accessor: 'part_category',
  disableSortBy: true,
  Cell: ({ value }) => <span className='fw-bold text-nowrap' style={{ color: value?.color }}>{value?.label}</span>
}, {
  Header: <Trans i18nKey="updated"/>,
  accessor: 'updated_at',
  Cell: ({ value }) => <DateDisplay value={value} formatString='dd/MM/yyyy HH:mm'/>
}];

export default partTableConfig;
