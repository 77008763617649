import React from 'react';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

function ExportExcelButton({ handleClick, buttonId = "export_button" }) {
  const { t } = useTranslation();

  return (
    <>
      <UncontrolledTooltip target={buttonId}>Esporta in formato Excel</UncontrolledTooltip>
      <button className='btn btn-soft-success' id={buttonId} onClick={handleClick}>
        <i className='fa fa-file-excel'/>&nbsp;{t("export_excel")}
      </button>
    </>
  );
}

export default ExportExcelButton;
