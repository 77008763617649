import { useTranslation } from 'react-i18next';

export const ListEmptyItem = () => {

  const { t } = useTranslation()

  return (
    <li className="list-group-item px-0">
      {t('no-results')}
    </li>
  )

}

