import React from 'react';
import WorkPicker from '../Forms/Pickers/WorkPicker';

function WorkFilter({ name, label, value, setValue , handleSubmit}) {

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.target.value.trim()) {
      handleSubmit()
    }
  };

  return (
    <WorkPicker
        value={value}
        defaultOption={true}
      onKeyDown={handleKeyDown}
      isMulti={true}
      defaultParams={[]}
      placeholder={label}
      handleChange={values => setValue(name, values)}
    />
  );
}

export default WorkFilter;
