import React from 'react';
import { useRequest } from 'ahooks';
import UserMessageService from '../../services/userMessageService';
import { useTranslation } from 'react-i18next';
import { Card } from '../UI/Card/Card';
import { CardBody } from '../UI/Card/CardBody';
import { CardLabel } from '../UI/Card/CardLabel';
import { List } from '../UI/ItemList/List';
import { ListItemUserMessage } from '../UI/ItemList/UserMessages/ListItemUserMessage';
import CreateButton from '../UI/Buttons/CreateButton';
import { CardContentBetween } from '../UI/Card/CardContentBetween';
import { ListItemUserMessageCreate } from '../UI/ItemList/UserMessages/ListItemUserMessageCreate';

const UserMessages = ({ model_id = null, model_type = null }) => {

  const { t } = useTranslation()
  const [store, setStore] = React.useState(false);

  const { data: userMessages = [], mutate } = useRequest(UserMessageService.fetch, {
    defaultParams: [
      model_id,
      model_type
    ], ready: model_id !== null && model_type !== null
  })

  const handleStore = userMessage => {
    mutate(msgs => [userMessage, ...msgs])
    setStore(s => false)
  }

  const handleUpdate = userMessage => mutate(msgs => msgs.map(msg => msg.uuid === userMessage.uuid ? userMessage : msg))

  const handleDelete = userMessage => mutate(msgs => msgs.filter(msg => msg.uuid !== userMessage.uuid))


  return (
    <Card>
      <CardBody>
        <CardContentBetween>
          <CardLabel>{t('user_messages.plural')}</CardLabel>
          {!store && <CreateButton onClick={() => setStore(!store)}/>}
        </CardContentBetween>
        <List>
          {store &&
            <ListItemUserMessageCreate
              modelId={model_id}
              modelType={model_type}
              onStore={handleStore}
              setStore={setStore}
            />
          }


          {userMessages.length > 0 && userMessages.map(userMessage =>
            <ListItemUserMessage
              userMessage={userMessage}
              key={userMessage.uuid}
              modelId={model_id}
              modelType={model_type}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
            />
          )}
        </List>
        {/*<UserMessageCreate*/}
        {/*  modelId={model_id}*/}
        {/*  modelType={model_type}*/}
        {/*  onStore={handleStore}*/}
        {/*/>*/}
      </CardBody>
    </Card>
  )

}

export default UserMessages
