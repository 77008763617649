import FileDownload from 'js-file-download';
import api from '../../configs/api.config';
import { toast } from 'react-toastify';

const url = '/medias';

const buildUrl = (modelId, modelType) => `${modelType}/${modelId}${url}`;
const buildFormData = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

const fetchAll = (modelId, modelType, params) => api.get(buildUrl(modelId, modelType), { params })
.then((res) => res.data.data);
const store = (modelId, modelType, file) => api.post(
  buildUrl(modelId, modelType),
  buildFormData(file),
  { headers: { 'Content-Type': 'multipart/form-data' } })
.then((res) => res.data.data);

const download = (modelId, modelType, media) => api.get(`${buildUrl(modelId, modelType)}/${media.id}`, { responseType: 'blob' })
.then(res => {
  const file = new Blob([res.data], { type: media.mime_type });
  const fileURL = URL.createObjectURL(file);
  const pdfWindow = window.open();
  pdfWindow.location.href = fileURL;
});

const downloadWorkMergedPdf = (workUuid, filename, production) => api.get(`works/${workUuid}/merge-pdf`, {
  params: { production: production },
  responseType: 'blob'
}).then((res) => FileDownload(res.data, filename))
.catch((err) => toast.error(err.response?.message ?? "Error"));

const downloadColorConfigsPdf = (workUuid, filename) => api.get(`works/${workUuid}/color-config-pdf`, { responseType: 'blob' })
.then((res) => FileDownload(res.data, filename))
.catch((err) => toast.error(err.response?.message ?? "Error"));
const downloadBomsPdf = (workUuid, filename) => api.get(`works/${workUuid}/bom-pdf`, { responseType: 'blob' })
.then((res) => FileDownload(res.data, filename))
.catch((err) => toast.error(err.response?.message ?? "Error"));

const downloadZippedCollection = (modelId, modelType, collection, filename) => api.get(`${modelType}/${modelId}/media-collection/${collection}`, {
  responseType: 'blob'
}).then(res => FileDownload(res.data, filename))
.catch(err => toast.error(err?.response?.data?.message ?? "Error"));

const destroy = (modelId, modelType, mediaId) => api.delete(`${buildUrl(
  modelId,
  modelType,
)}/${mediaId}`)
.then((res) => res.data.data)
.catch((err) => toast.error(err.response?.message ?? "Error"));

const mediaService = {
  fetchAll,
  store,
  download,
  downloadWorkMergedPdf,
  downloadColorConfigsPdf,
  downloadBomsPdf,
  downloadZippedCollection,
  destroy
};

export default mediaService;
