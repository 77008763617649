import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Drawer from '../../../components/UI/Drawer/Drawer';
import { Form, FormikProvider, useFormik } from 'formik';
import PartCategoryForm from '../components/PartCategoryForm';
import partCategoriesService from '../../../services/parts/partCategoriesService';

const validationSchema = Yup.object().shape({
  label: Yup.string().max(100).required(),
  color: Yup.string().max(50).required(),
  margin: Yup.number().min(1).max(100).required()
});

export const PartCategoryDetailsDrawer = ({ partCategory, isOpen, setIsOpen, onUpdate }) => {

  const { t } = useTranslation()
  const initialValues = {
    label: partCategory.label,
    color: partCategory.color,
    margin: partCategory.margin * 100
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => partCategoriesService.update(partCategory.uuid, values)
    .then(res => {
      setIsOpen(false)
      onUpdate(res)
    })
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('edit')}>
      <FormikProvider value={formik}>
        <Form>
          <PartCategoryForm setIsOpen={setIsOpen}/>
        </Form>
      </FormikProvider>
    </Drawer>
  )
}
