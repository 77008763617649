const locationPickerInitialValues = {
  route: "",
  street_number: "",
  locality: "",
  administrative_area_level_1: "",
  administrative_area_level_2: "",
  administrative_area_level_3: "",
  postal_code: "",
  country: "",
  latitude: "",
  longitude: ""
}

const setLocationPickerInitialValues = (location = undefined) => ({
  route: location?.route || "",
  street_number: location?.street_number || "",
  locality: location?.locality || "",
  administrative_area_level_1: location?.administrative_area_level_1 || "",
  administrative_area_level_2: location?.administrative_area_level_2 || "",
  administrative_area_level_3: location?.administrative_area_level_3 || "",
  postal_code: location?.postal_code || "",
  country: location?.country || "",
  latitude: location?.latitude || "",
  longitude: location?.longitude || ""
})

const locationPickerFormConfig = {
  locationPickerInitialValues: locationPickerInitialValues,
  setLocationPickerInitialValues: setLocationPickerInitialValues
}

export default locationPickerFormConfig;
