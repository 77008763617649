import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { Spinner } from 'reactstrap';

export const PageLoader = () => {

  const { loadingOverlayState } = useContext(AppContext)

  return loadingOverlayState ? (
    <div className='d-flex align-items-center justify-content-center vh-100 w-100 position-fixed top-0 start-0 bg-black bg-opacity-25'>
      L<Spinner size="sm"/>ADING
    </div>
  ) : <></>

}
