import React, { useMemo } from 'react';
import { useRequest } from 'ahooks';
// import workPartTableConfig from './workPartTable.config';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import { useTranslation } from 'react-i18next';
import offersService from '../../../../services/offers/offersService';
import OfferFilters from '../../../Offers/Offers/OfferFilters';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import offerTableConfig from '../../../Offers/Offers/offerTable.config';

const PartOffers = ({ partId }) => {

  const { t } = useTranslation();
  const columns = useMemo(() => offerTableConfig, []);
  const { data = [], loading, run } = useRequest(offersService.fetchAll, {
    manual: true,
    ready: partId
  })

  const [extraParams, setExtraParams] = React.useState({
    filter: {
      part_id: partId,
      work_id: [],
      number: [],
      company_id: [],
      state: [],
      users: [],
    }
  })

  const handleSubmit = values => setExtraParams(ext => ({
    filter: {
      ...ext.filter,
      work_id: values.filter.work_id.map(opt => opt.value),
      number: values.filter.number.map(opt => opt.value),
      company_id: values.filter.company_id.map(opt => opt.value),
      state: values.filter.state.map(opt => opt.value),
      users: values.filter.users.map(opt => opt.value)
    }
  }))

  return (
    <Card>
      <CardBody>
        <CardLabel>{t('offers.plural')}</CardLabel>
        <OfferFilters
          defaultValues={extraParams.filter}
          handleUpdate={handleSubmit}
        />
        <PaginatedTable
          options={paginatedTableConfig}
          isLoading={loading}
          data={data}
          columns={columns}
          sortable
          onParamsChange={run}
          extraParams={extraParams}
        />
      </CardBody>
    </Card>
  )

}

export default PartOffers
