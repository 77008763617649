import React from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

const WidgetPrevNext = ({ loading, currentPage, prev, next, setParams }) => {

  const { t } = useTranslation()

  const handleChange = debounce(e => setParams(p => ({
    ...p,
    page: 1,
    filter: {
      ...p.filter,
      number: e.target.value ? [e.target.value] : []
    }
  })), 500);

  return (
    <div className='d-flex justify-content-end align-items-center gap-1'>
      <input
        name='number'
        type='text'
        disabled={loading}
        onChange={handleChange}
        maxLength={15}
        placeholder={t('works.number')}
        className='form-control'
      />
      <button
        className='btn btn-icon btn-light'
        type='button'
        onClick={() => setParams(p => ({ ...p, page: currentPage - 1 }))}
        disabled={!!!prev || loading}>
        <i className='fa fa-chevron-left'/>
      </button>
      <button
        className='btn btn-icon btn-light'
        type='button'
        onClick={() => setParams(p => ({ ...p, page: currentPage + 1 }))}
        disabled={!!!next || loading}>
        <i className='fa fa-chevron-right'/>
      </button>
    </div>
  )
}

export default WidgetPrevNext
