import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';
import optionsService from '../../../../services/optionsService';


const BaseCodePicker = ({ handleChange, defaultParams = {} }) => {

  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(optionsService.baseCodes, { manual: true });
  const getFilters = value => ({ filter: { ...defaultParams, search: value || '' } })
  const bouncedHandleLoadOptions = (value, callback) => runAsync(getFilters(value))
  .then(res => callback(res))
  .catch(() => callback([]));

  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={[]}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={handleChange}
      loading={loading}
      placeholder={t('type_to_search')}
      styles={reactSelectStyles}
      multiple={false}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
    />
  );
}

export default BaseCodePicker;
