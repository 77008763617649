import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImportPartCosts from './ImportPartCosts/ImportPartCosts';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ImportPartPrices from './ImportPartPrices/ImportPartPrices';
import ImportBetaTools from './ImportBetaTools/ImportBetaTools';

function BatchIndex() {

  const { t } = useTranslation();

  const tabs = [
    { label: t('batch.import_costs'), index: 'costs' },
    { label: t('batch.import_prices'), index: 'prices' },
    { label: t('batch.import_beta_tools'), index: 'beta_tools' },
  ];

  const [activeTab, setActiveTab] = useState('costs');

  return (
    <>
      <Nav pills className="mb-3">
        {tabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({ active: tab.index === activeTab })}
              onClick={() => activeTab !== tab.index && setActiveTab(tab.index)}
            >
              {tab.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="costs">
          <ImportPartCosts/>
        </TabPane>
        <TabPane tabId="prices">
          <ImportPartPrices/>
        </TabPane>
        <TabPane tabId="beta_tools">
          <ImportBetaTools/>
        </TabPane>
      </TabContent>
    </>
  );
}

export default BatchIndex;
