import React from 'react';
import Dashboard from '../../pages/Dashboard/Dashboard';
import wordsRoutes from './partials/wordsRoutes';
import codesRoutes from './partials/codesRoutes';
import partsRoutes from './partials/partsRoutes';
import userRoutes from './partials/userRoutes';
import batchRoutes from './partials/batchRoutes';
import companiesRoutes from './partials/companiesRoutes';
import colorsRoutes from './partials/colorsRoutes';
import usersRoutes from './partials/usersRoutes';
import worksRoutes from './partials/works/worksRoutes';
import colorPresetsRoutes from './partials/colorPresetsRoutes';
import offersRoutes from './partials/offersRoutes';
import partCategoriesRoutes from './partials/partCategoriesRoutes';

const privateRoutes = [
  {
    index: true,
    path: '',
    element: <Dashboard/>,
  },
  {
    path: 'dashboard',
    element: <Dashboard/>,
  },
  wordsRoutes,
  codesRoutes,
  partsRoutes,
  colorsRoutes,
  colorPresetsRoutes,
  userRoutes,
  usersRoutes,
  companiesRoutes,
  partCategoriesRoutes,
  worksRoutes,
  batchRoutes,
  offersRoutes
];

export default privateRoutes;
