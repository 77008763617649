import React from 'react';
import { Spinner } from 'reactstrap';
import { useFormikContext } from 'formik';

const FormikSaveButtonIcon = props => {
  const { isSubmitting, isValid } = useFormikContext();


  return (
    <button
      className='btn btn-soft-success'
      disabled={isSubmitting || !isValid}
      type="submit"
      {...props}
    >
      {isSubmitting ? <Spinner size="sm"/> : <i className='fa fa-save'/>}
    </button>
  );
}

export default FormikSaveButtonIcon;
