import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import MultipleTextFilter from '../../../../components/UI/Filters/MultipleTextFilter';
import SearchButton from '../../../../components/UI/Buttons/SearchButton';


const PartWorkFilters = ({ handleUpdate, defaultValues }) => {

  const { t } = useTranslation()
  const [values, setValues] = React.useState(defaultValues)
  const getValue = name => get(values, name, values[name])
  const setValue = (name, value) => setValues(v => ({ ...v, [name]: value }))
  const handleSubmit = () => handleUpdate(values)

  return (
    <>
      <div className='row mb-1'>
        <div className='col-md-6'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('number')}
              setValue={setValue}
              name='number'
              label={t('works.number')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('description')}
              setValue={setValue}
              name='description'
              label={t('description')}
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('year')}
              setValue={setValue}
              name='year'
              label={t('works.year')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('sap_orders')}
              setValue={setValue}
              name='sap_orders'
              label={t('works.sap_orders')}
            />
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-end gap-2 mb-3'>
        <SearchButton onClick={handleSubmit} />
      </div>
    </>
  )

}

export default PartWorkFilters
