import { Progress } from 'reactstrap';
import React from 'react';

const FormFileProgress = ({ percent }) => <Progress
  style={{ height: 25, minWidth: 300 }}
  color="primary"
  animated
  max={100}
  min={0}
  value={percent}>
  Uploading {percent}%
</Progress>

export default FormFileProgress