import React from 'react';

function Toolbar({ children, title }) {
  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      {!!title && <h3>{title}</h3>}
      {children}
    </div>
  );
}

export default Toolbar;
