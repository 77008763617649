import React, { useState } from 'react';
import api from '../../../../configs/api.config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import FormFileProgress from './FormFileProgress';
import FormFileInput from './FormFileInput';

export function FormFile({ name, url, onUploadFinished }) {

  const { t } = useTranslation()
  const [file, setFile] = useState(undefined);
  const [percent, setPercent] = useState(35);
  const [loading, setLoading] = useState(false);

  const handleFileChange = e => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    setPercent(Math.floor((loaded * 100) / total));
  };

  const handleUploadClick = () => {
    if (file) {
      setLoading(true)
      api.post(url,
        buildFormData(),
        { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress })
      .then((res) => {
        toast.success(t('uploaded'))
        onUploadFinished(res)
      })
      .catch((err) => toast.error(err?.response?.data?.message))
      .finally(() => {
        setFile(undefined)
        setPercent(0)
        setLoading(false)
      })
    }
  }

  const buildFormData = () => {
    const formData = new FormData();
    formData.append(name, file);
    return formData;
  }

  return loading
    ? <FormFileProgress percent={percent}/>
    : <FormFileInput
      name={name}
      file={file}
      onFileChange={handleFileChange}
      onUploadClick={handleUploadClick}
    />
}
