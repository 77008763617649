import React, { useMemo } from 'react';
import { useRequest, useSessionStorageState } from 'ahooks';
import { Card, CardBody } from 'reactstrap';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import { useTranslation } from 'react-i18next';
import codesService from '../../services/parts/codesService';
import paginatedTableConfig from '../../configs/paginatedTable.config';
import codeTableConfig from './components/codeTable.config';
import CodesNewDropdown from './components/CodesNewDropdown';
import CodeFilters from './components/CodeFilters';
import { useLocation } from "react-router-dom";
import { CardLabel } from '../../components/UI/Card/CardLabel';
import { CardContentBetween } from '../../components/UI/Card/CardContentBetween';
import { CardActions } from '../../components/UI/Card/CardActions';
import ExportExcelButton from '../../components/UI/Buttons/Excel/ExportExcelButton';
import exportsService from '../../services/exportsService';

function CodesIndex() {
  const location = useLocation()
  const { data = [], loading, run } = useRequest(codesService.fetchAll, { manual: true });
  const columns = useMemo(() => codeTableConfig, []);
  const { t } = useTranslation();
  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        code: [],
        description: [],
        code_type: [],
        part_type: [],
        part_collection: undefined
      }
    },
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))
  const filterValues = values => ({
    ...values, filter: {
      code: values.filter.code.map(opt => opt.value),
      description: values.filter.description.map(opt => opt.value),
      code_type: values.filter.code_type.map(opt => opt.value),
      part_type: values.filter.part_type.map(opt => opt.value),
      part_collection: values.filter.part_collection?.letter,
    }
  })
  const handleUpdate = values => {
    run(filterValues(values))
  }


  const handleExportExcel = () => exportsService.codes(filterValues(extraParams), 'codes');

  return (
    <>
      <CodeFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('codes.plural')}</CardLabel>
            <CardActions>
              <ExportExcelButton
                buttonId='code_xls_btn'
                handleClick={handleExportExcel}
              />
              <CodesNewDropdown/>
            </CardActions>
          </CardContentBetween>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default CodesIndex;
