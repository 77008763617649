import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import { CardContentBetween } from '../../../../components/UI/Card/CardContentBetween';
import { CardActions } from '../../../../components/UI/Card/CardActions';
import GenericStateDisplay from '../../../../components/UI/Displays/GenericStateDisplay';
import { List } from '../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../components/UI/ItemList/ListItem';

const OfferWorkDetails = ({ work }) => {

  const { t } = useTranslation()

  return work ? (
    <Card>
      <CardBody>
        <CardContentBetween>
          <CardLabel>{t('works.sing')}</CardLabel>
          <CardActions>
            <GenericStateDisplay state={work?.state}/>
            <GenericStateDisplay state={work?.production_state}/>
          </CardActions>
        </CardContentBetween>
        <List>
          <ListItem label={work?.number}>{work?.description}</ListItem>
        </List>
      </CardBody>
    </Card>) : null

}

export default OfferWorkDetails
