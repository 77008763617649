import React from 'react';
import EuroDisplay from '../EuroDisplay';
import clsx from 'clsx';

function PartPriceDisplay({ price = 0, cost = 0 }) {

  return (
    <div className="badge fs-6 bg-light">
      <i
        className={clsx('fa', {
          'badge-soft-danger fa-arrow-circle-down': price < cost,
          'badge-soft-warning fa-minus-circle': price === cost,
          'badge-soft-success fa-arrow-circle-up': price > cost,
        })}
        title={price?.toString()}
      />&nbsp;
      <EuroDisplay value={price}/>
    </div>
  );


}

export default PartPriceDisplay;
