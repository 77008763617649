import api from '../configs/api.config';
import FileDownload from 'js-file-download';

const offerRevision = (offerUuid, offerRevisionUuid, fileName, locale) => handleDownload(`exports/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}`,
  fileName,
  { locale: locale })
const works = (params, fileName) => handleDownload(`exports/works`, fileName, params)
const offers = (params, fileName) => handleDownload(`exports/offers`, fileName, params)
const parts = (params, fileName) => handleDownload(`exports/parts`, fileName, params)
const colors = (params, fileName) => handleDownload(`exports/colors`, fileName, params)
const codes = (params, fileName) => handleDownload(`exports/base-codes`, fileName, params)

const handleDownload = (url, fileName, params) => api.get(url, { params: params, responseType: 'blob' }).then(res => FileDownload(res.data, `${fileName}.xlsx`))

const exportsService = {
  offerRevision,
  works,
  offers,
  parts,
  colors,
  codes
}

export default exportsService;
