import EuroDisplay from '../EuroDisplay';

function CalculatedPriceByMarginDisplay({
                                          margin = 0,
                                          calculatedPriceByPartMargin = 0,
                                          partCategory = 0,
                                          calculatedPriceByPartCategory = 0
                                        }) {

  if (margin) {
    return <CalculatedPriceBadge value={calculatedPriceByPartMargin}/>
  } else if (partCategory) {
    return <CalculatedPriceBadge value={calculatedPriceByPartCategory}/>
  } else {
    return <CalculatedPriceBadge value={undefined} />
  }

}

export default CalculatedPriceByMarginDisplay;


function CalculatedPriceBadge({ value }) {
  return (
    <div className='badge fs-6 bg-light text-dark' title={value?.toString()}>
      <i className='fa fa-dot-circle' />&nbsp;
      <EuroDisplay value={value}/>
    </div>
  )
}
