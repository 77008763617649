import React from 'react';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function PartStateEnumDisplay({ state, onlyDot = false }) {
  const { t } = useTranslation();

  return onlyDot
    ? <i className={`fa fa-dot-circle text-${state?.color}`} title={state?.label}/>
    : <Badge className="p-1 font-size-11" color={state?.color}>{t(state?.label)}</Badge>

}

export default PartStateEnumDisplay;
