import React, { useEffect, useState } from 'react';
import {
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  parse,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear
} from 'date-fns';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';
import DateDisplay from '../../../../../components/UI/Displays/DateDisplay';

const PartTrendPeriodBar = ({ onPeriodChange }) => {

  const { t } = useTranslation()
  const dateFormat = 'yyyy-MM-dd';

  const options = [{
    value: 'week',
    label: t('period.week'),
  }, {
    value: 'month',
    label: t('period.month')
  }, {
    value: 'quarter',
    label: t('period.quarter')
  }, {
    value: 'year',
    label: t('period.year')
  }];

  const [selectedOption, setSelectedOption] = useState(options.find(o => o.value === 'quarter'))

  const [period, setPeriod] = useState({
    start_date: format(startOfQuarter(new Date), dateFormat),
    end_date: format(endOfQuarter(new Date), dateFormat),
  });

  const setThisWeek = () => {
    setPeriod({
      start_date: format(startOfWeek(new Date), dateFormat),
      end_date: format(endOfWeek(new Date), dateFormat),
    })
  }

  const setThisMonth = () => {
    setPeriod({
      start_date: format(startOfMonth(new Date), dateFormat),
      end_date: format(endOfMonth(new Date), dateFormat),
    })
  }

  const setThisQuarter = () => {
    setPeriod({
      start_date: format(startOfQuarter(new Date), dateFormat),
      end_date: format(endOfQuarter(new Date), dateFormat),
    })
  }

  const setThisYear = () => {
    setPeriod({
      start_date: format(startOfYear(new Date), dateFormat),
      end_date: format(endOfYear(new Date), dateFormat),
    })
  }

  const handlePeriod = (direction) => {
    switch (selectedOption.value) {
      case 'week':
        setPeriod((period) => ({
          start_date: format(addWeeks(getDate(period.start_date), 1 * direction), dateFormat),
          end_date: format(addWeeks(getDate(period.end_date), 1 * direction), dateFormat)
        }))
        break;
      case 'month':
        setPeriod((period) => ({
          start_date: format(addMonths(getDate(period.start_date), 1 * direction), dateFormat),
          end_date: format(addMonths(getDate(period.end_date), 1 * direction), dateFormat)
        }))
        break;
      case 'quarter':
        setPeriod((period) => ({
          start_date: format(addMonths(getDate(period.start_date), 3 * direction), dateFormat),
          end_date: format(addMonths(getDate(period.end_date), 3 * direction), dateFormat)
        }))
        break;
      case 'year':
        setPeriod((period) => ({
          start_date: format(addYears(getDate(period.start_date), 1 * direction), dateFormat),
          end_date: format(addYears(getDate(period.end_date), 1 * direction), dateFormat)
        }))
        break;
      default:
        return;
    }
  }

  const getDate = (date) => parse(date, dateFormat, new Date())

  const handleChangePeriod = () => {
    switch (selectedOption.value) {
      case 'week':
        setThisWeek();
        break;
      case 'month':
        setThisMonth();
        break;
      case 'quarter':
        setThisQuarter();
        break;
      case 'year':
        setThisYear();
        break;
      default:
        setThisWeek();
    }
  }

  useEffect(() => handleChangePeriod(), [selectedOption])
  useEffect(() => onPeriodChange(period), [period]);


  return (
    <div className="py-2 d-flex align-items-center justify-content-center gap-2">

      <UncontrolledTooltip target='previous_period_button'>Periodo precedente</UncontrolledTooltip>
      <button id="previous_period_button" className='btn btn-light btn-icon' type='button' onClick={() => handlePeriod(-1)}>
        <i className='fa fa-chevron-left'/>
      </button>

      <Select
        options={options}
        value={selectedOption}
        onChange={(value) => setSelectedOption(value)}
      />

      <UncontrolledTooltip target='current_period'>Periodo corrente</UncontrolledTooltip>
      <button id="current_period" className='btn btn-light btn-icon' type='button' onClick={() => handleChangePeriod()}>
        <i className="fa fa-check"/>
      </button>

      <div className="fw-bold">
        <DateDisplay value={period.start_date}/>-<DateDisplay value={period.end_date}/>
      </div>

      <UncontrolledTooltip target='next_period_button'>Periodo successivo</UncontrolledTooltip>
      <button id="next_period_button" className='btn btn-light btn-icon' type='button' onClick={() => handlePeriod(1)}>
        <i className='fa fa-chevron-right'/>
      </button>

    </div>
  )
}


export default PartTrendPeriodBar
