import React from 'react';
import UsersWrapper from '../../../pages/Users/UsersWrapper';
import Users from '../../../pages/Users/Users';
import User from '../../../pages/Users/User/User';

const usersRoutes = {
  path: 'users/*',
  element: <UsersWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <Users/>,
  }, {
    path: ':id',
    element: <User/>,
  }]
};


export default usersRoutes;
