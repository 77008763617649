import React from 'react';
import { useTranslation } from 'react-i18next';

const ButtonIcon = props => {
  const { t } = useTranslation();

  return (
    <button className='btn btn-soft-secondary btn-icon' type='button' {...props}>
      <i className={props.icon} />
    </button>
  );
}

export default ButtonIcon;
