import React from 'react';
import WorksWidget from './components/WorksWidget';
import OffersWidget from './components/OffersWidget';

function Dashboard() {
  return (
    <div className='row'>
      <div className='col-lg-6 col-xl-4'>
        <WorksWidget/>
      </div>
      <div className='col-lg-6 col-xl-4'>
        <OffersWidget/>
      </div>
    </div>
  );
}

export default Dashboard;
