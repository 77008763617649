import React, { useRef } from 'react';
import { useSanctum } from 'react-sanctum';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import { toast } from 'react-toastify';
import userService from '../../../../services/userService';

function AvatarUpdater() {
  const { user, setUser } = useSanctum();
  const inputRef = useRef();
  const { t } = useTranslation();

  const onEditClicked = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const { runAsync } = useRequest(userService.updateAvatar, { manual: true });

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];

    if (fileObj) {
      runAsync(fileObj).then(() => userService.authUser()
      .then((res) => {
        setUser(res);
        toast.success(t('updated'));
      }));
    }
  };

  return (
    <div className="position-relative">
      <img
        className="rounded-circle"
        width={75}
        height={75}
        src={user?.avatar}
        alt="Avatar"
      />
      <button
        type="button"
        className="position-absolute rounded-5 bg-primary border-2 border-white text-white justify-content-center align-content-center d-flex p-1"
        style={{ top: 0, left: 0 }}
        onClick={onEditClicked}
      >
        <i className="fa fa-pen fa-xs align-self-center"/>
      </button>
      <input
        type="file"
        className="d-none"
        name="file"
        ref={inputRef}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default AvatarUpdater;
