import React from 'react';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import { useTranslation } from 'react-i18next';
import FormColorPicker from '../../../components/UI/Forms/FormColorPicker';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikResetButton from '../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';

const PartCategoryForm = ({setIsOpen}) => {

  const { t } = useTranslation()

  return (
    <>
      <FormGroup name="label" label={t('part_categories.label')} required>
        <FormInput name="label"/>
      </FormGroup>
      <FormGroup name="label" label={t('part_categories.margin')} required>
        <FormInput name="margin" type="number" min={0} max={100} step={0.01}/>
      </FormGroup>
      <FormGroup name="color" label={t('part_categories.color')} required>
        <FormColorPicker name="color"/>
      </FormGroup>

      <div className="d-flex align-items-center justify-content-between">
        <CancelButton onClick={() => setIsOpen(false)}/>
        <div className="d-flex gap-1">
          <FormikResetButton/>
          <FormikSaveButton/>
        </div>
      </div>
    </>
  )

}

export default PartCategoryForm
