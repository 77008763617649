import React, { useState } from 'react';
import { Alert, } from 'reactstrap';
import { Form, Formik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup'
import { authenticationService as authService } from '../../services/authenticationService';
import FormGroup from '../../components/UI/Forms/FormGroup';
import { FormInput } from '../../components/UI/Forms/FormInput';
import FormikSaveButton from '../../components/UI/Buttons/Save/FormikSaveButton';

const NewPassword = props => {
  const [message, setMessage] = useState(undefined)
  const { token } = useParams();

  const initialValues = {
    email: '',
    password: '',
    password_confirmation: ''
  }

  const validation = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(8).required(),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null],
      "Passwords does not match")
  })


  return (
    <div style={{ width: '340px' }}>

      <h5 className="text-center my-3"><i className="fa fa-check me-1"/>Reset password</h5>

      {message &&
        <Alert color={message?.errors ? 'danger' : 'success'}>{message?.message}</Alert>}

      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setMessage(undefined)
          authService.resetPassword(values.email,
            token || '',
            values.password,
            values.password_confirmation)
          .then(res => {
            setMessage(res)
            setSubmitting(false)
            resetForm()
          })
          .catch(err => {
            setMessage(err)
            setSubmitting(false)
          })
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <FormGroup name="email" required={true} inline={false} label="Email">
              <FormInput name="email" type="email"/>
            </FormGroup>
            <FormGroup label="New password" required={true} inline={false} name="password">
              <FormInput name="password" type="password"/>
            </FormGroup>
            <FormGroup label="Confirm new password" required={true} inline={false}
                       name="password_confirmation">
              <FormInput name="password_confirmation" type="password"/>
            </FormGroup>
            <div className="d-flex align-items-center justify-content-between">
              <FormikSaveButton
                color="primary"
                label="Reset password"
              />
              <Link to="/auth/login">Login</Link>
            </div>
          </Form>
        )}
      </Formik>

    </div>
  );
}

export default NewPassword;
