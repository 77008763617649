import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyLocationsDrawer } from './CompanyLocationsDrawer';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import CreateButton from '../../../../components/UI/Buttons/CreateButton';
import { InputSearch } from '../../../../components/UI/Forms/InputSearch';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import { Link } from 'react-router-dom';

export const CompanyLocations = ({ locations = [], companyUuid, setCompany }) => {

  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [filteredLocations, setFilteredLocations] = useState(locations)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)


  const handleCreate = res => {
    setIsOpen(o => false)
    setSelectedLocation(l => null)
    setCompany(c => ({ ...c, company_locations: [...c.company_locations, res] }))
  }

  const handleUpdate = res => {
    setIsOpen(o => false)
    setSelectedLocation(l => null)
    setCompany(c => ({ ...c, company_locations: c.company_locations.map(cl => cl.uuid === res.uuid ? res : cl) }))
  }

  const handleDelete = location => {
    setIsOpen(false)
    setSelectedLocation(null)
    setCompany(c => ({ ...c, company_locations: c.company_locations.filter(cl => cl.uuid !== location.uuid) }))
  }


  const columns = [{
    Header: <Trans i18nKey='locations.label'/>,
    accessor: 'label',
    Cell: ({ row, value }) => <span onClick={() => {
      setSelectedLocation(l => row.original)
      setIsOpen(o => true)
    }} className='btn btn-link fw-bold'>{value ?? '---'}</span>
  }, {
    Header: <Trans i18nKey='locations.full_address'/>,
    accessor: 'full_address',
    Cell: ({ row, value }) => <Link to={row.original.map_link} target='_blank'>{value}</Link>
  }];


  useEffect(() => {
    setFilteredLocations(() => !!search
      ? _.filter(locations, lc =>
        lc.label.toLowerCase().includes(search.toLowerCase()) ||
        lc.full_address.toLowerCase().includes(search.toLowerCase())
      ) : locations
    )
  }, [search, locations]);


  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('locations.plural')}</CardLabel>
            <div className='d-flex align-items-center justify-content-between gap-2'>
              <InputSearch
                name='search'
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <CreateButton onClick={() => {
                setIsOpen(o => true)
              }}/>
            </div>
          </CardContentBetween>
          <PaginatedTable
            data={filteredLocations}
            columns={columns}
          />
        </CardBody>
      </Card>

      <CompanyLocationsDrawer
        companyUuid={companyUuid}
        isOpen={isOpen}
        setSelectedLocation={setSelectedLocation}
        location={selectedLocation}
        handleUpdate={handleUpdate}
        handleCreate={handleCreate}
        handleDestroy={handleDelete}
        isDefault={false}
        setIsOpen={() => {
          setIsOpen(o => false)
          setSelectedLocation(o => null)
        }}
      />

    </>
  )

}
