import React, { useContext, useState } from 'react';
import OfferWorkDetails from '../components/OfferWorkDetails';
import OfferDetails from '../components/OfferDetails';
import { OfferContext } from '../offerContext/offerContext';
import { Spinner } from 'reactstrap';
import SubOffers from '../components/SubOffers/SubOffers';
import OfferPartCategories from '../components/OfferRevisionPartCategories/OfferPartCategories';
import OfferRevisionMedia from '../components/OfferRevisionMedia';
import OfferRevisionPrintDrawer from './components/Drawers/OfferRevisionPrintDrawer';
import OfferRevisionActions from './components/OfferRevisionActions';
import OfferRevisionNavigator from './components/OfferRevisionNavigator';
import OfferRevisionPercentageDrawer from './components/Drawers/OfferRevisionPercentageDrawer';
import { CardContentBetween } from '../../../../components/UI/Card/CardContentBetween';
import ColorConfigsDrawer from '../../../../components/ColorConfigs/ColorConfigsDrawer';
import { AppContext } from '../../../../components/AppContext';
import offerRevisionsService from '../../../../services/offers/offerRevisionsService';
import { offerActionTypes } from '../offerContext/offerReducer/offerActionTypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const OfferRevision = () => {

  const { offer, dispatchAction, revision_editable } = useContext(OfferContext)
  const { colorParts } = React.useContext(AppContext);
  const { t } = useTranslation();

  const [printDrawerState, setPrintDrawerState] = useState(false);
  const [percentageDrawerState, setPercentageDrawerState] = useState(false);
  const [colorConfigsDrawerState, setColorConfigsDrawerState] = useState(false);

  const handleColorConfigUpdate = values => {
    dispatchAction({
      type: offerActionTypes.UPDATE_COLOR_CONFIG,
      payload: values
    })
    toast.success(t('updated'))
    setColorConfigsDrawerState(() => false)
  }

  return offer?.offer_revision ? (
    <div className='d-flex flex-column gap-4'>
      <CardContentBetween>
        <OfferRevisionNavigator/>
        <OfferRevisionActions
          setPrintDrawerState={setPrintDrawerState}
          setPercentageDrawerState={setPercentageDrawerState}
          setColorConfigsDrawerState={setColorConfigsDrawerState}
        />
      </CardContentBetween>

      <div className='row'>
        <div className="col-xl-3 col-lg-4">
          <div className='row'>
            <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
              <OfferDetails offer={offer}/>
            </div>
            <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
              <OfferPartCategories partCategories={offer.offer_revision?.part_categories}/>
            </div>
            {offer?.work !== null &&
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
                <OfferWorkDetails work={offer.work}/>
              </div>}
            <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch'>
              <OfferRevisionMedia/>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <SubOffers
            offerUuid={offer.uuid}
            offerRevisionUuid={offer?.offer_revision?.uuid}
            offerRevisionIsInvalid={offer?.offer_revision?.is_invalid}
            subOffers={offer?.offer_revision?.sub_offers}
          />
        </div>
      </div>

      <OfferRevisionPrintDrawer
        isOpen={printDrawerState}
        setIsOpen={setPrintDrawerState}
        offer={offer}
      />

      {revision_editable &&
      <OfferRevisionPercentageDrawer
        isOpen={percentageDrawerState}
        setIsOpen={setPercentageDrawerState}
      />}

      {offer.work === null &&
        <ColorConfigsDrawer
          colorParts={colorParts}
          isOpen={colorConfigsDrawerState}
          setIsOpen={setColorConfigsDrawerState}
          colorConfigs={offer?.offer_revision?.color_configs}
          serviceParams={{
            offerUuid: offer.uuid,
            offerRevisionUuid: offer?.offer_revision?.uuid
          }}
          serviceCall={offerRevisionsService.updateColorConfigs}
          onUpdate={handleColorConfigUpdate}
          canEdit={revision_editable}
        />}


    </div>
  ) : <Spinner/>

}

export default OfferRevision
