import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import formConfig from '../components/form.config';
import { toast } from 'react-toastify';
import offersService from '../../../services/offers/offersService';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import CompanyFullSelection from '../../../components/CompanyFullSelection/CompanyFullSelection';
import Drawer from '../../../components/UI/Drawer/Drawer';
import FormikResetButton from '../../../components/UI/Buttons/FormikResetButton';
import { useSanctum } from 'react-sanctum';


const OfferCreateDrawer = ({ isOpen, setIsOpen }) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useSanctum()

  const formik = useFormik({
    validationSchema: formConfig.validation,
    initialValues: formConfig.initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      offersService.store({
        ...values,
        company_id: values.company?.value,
        company_location_id: values.company_location?.value,
        company_person_id: values.company_person?.value
      })
      .then(res => navigate(res.uuid))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => setSubmitting(false))
    }
  })

  return (
    <Drawer
      title={t('new')}
      status={isOpen}
      toggle={() => setIsOpen(false)}
    >
      <FormikProvider value={formik}>
        <Form>
          <h5>{t('offers.sing')}</h5>
          <FormGroup name="description" label={t("description")} required={true}>
            <FormInput name="description"/>
          </FormGroup>
          <FormGroup name="note" label={t("note")} required={true}>
            <FormInput name="note" as="textarea" rows={3}/>
          </FormGroup>
          <div className='row'>
            <div className="col-lg-6">
              <FormGroup name="date" label={t("offers.date")} required={true}>
                <FormInput name="date" type="date"/>
              </FormGroup>
            </div>
          </div>

          <h5>{t('works.customer')}</h5>

          <CompanyFullSelection/>

          <div className="d-flex align-items-center justify-content-between">
            <CancelButton onClick={() => setIsOpen(false)}/>
            <div className="d-flex gap-1">
              <FormikResetButton/>
              <FormikSaveButton/>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Drawer>
  )
}

export default OfferCreateDrawer;
