const draft = 'draft';
const pending = 'pending';
const accepted = 'accepted';
const refused = 'refused';
const canceled = 'canceled';

const values = {
  draft,
  pending,
  accepted,
  refused,
  canceled
};

const collection = [
  { value: draft, label: 'enums.offer_revision_state.draft', color: 'light' },
  { value: pending, label: 'enums.offer_revision_state.pending', color: 'light' },
  { value: accepted, label: 'enums.offer_revision_state.accepted', color: 'light' },
  { value: refused, label: 'enums.offer_revision_state.refused', color: 'light' },
  { value: canceled, label: 'enums.offer_revision_state.canceled', color: 'light' },
];

const OfferRevisionStateEnum = {
  values,
  collection,
};

export default OfferRevisionStateEnum;
