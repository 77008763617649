import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';
import DateDisplay from '../../../components/UI/Displays/DateDisplay';
import UserDisplay from '../../../components/UI/Displays/UserDisplay';
import GenericStateDisplay from '../../../components/UI/Displays/GenericStateDisplay';
import { Link } from 'react-router-dom';
import MarketDisplay from '../../../components/UI/Displays/MarketDisplay';

const offerTableConfig = [{
  Header: <Trans i18nKey='offers.sing'/>,
  accessor: 'number',
  Cell: ({ row, value }) =>
    <div className='d-flex flex-column'>
      <h3 className='mb-0'>
        <TableLink to={`/offers/${row.original?.uuid}`} value={value}/>
      </h3>
      {row.original.description}
    </div>
}, {
  Header: <Trans i18nKey="offers.state"/>,
  accessor: 'state',
  disableSortBy: true,
  Cell: ({ value }) => <GenericStateDisplay state={value}/>,
}, {
  Header: <Trans i18nKey="works.sing"/>,
  accessor: 'work',
  disableSortBy: true,
  Cell: ({ value }) => value
    ? <Link className='fw-bold' to={`/works/${value?.uuid}`}>{value?.number}</Link>
    : <>---</>
}, {
  Header: <Trans i18nKey='offers.customer'/>,
  disableSortBy: true,
  accessor: 'company',
  Cell: ({ value, row }) => value ?
    <div className='d-flex flex-column'>
      <TableLink to={`/companies/${value?.uuid}`} value={value?.business_name}/>
      {row.original.company_location &&
        <span className="fw-bold">{row.original.company_location?.label}</span>}
      {row.original.company_person && <span>{row.original.company_person?.full_name}</span>}
    </div> : <>---</>
}, {
  Header: <Trans i18nKey='market'/>,
  accessor: 'company.market',
  disableSortBy: true,
  Cell: ({ value }) => <MarketDisplay market={value}/>
}, {
  Header: <Trans i18nKey='offers.user'/>,
  disableSortBy: true,
  accessor: 'user',
  Cell: ({ value }) =>
    <UserDisplay
      key={value?.uuid}
      user={value}
      small={true}
    />
}, {
  Header: <Trans i18nKey='offers.date'/>,
  accessor: 'date',
  Cell: ({ value }) => <DateDisplay value={value}/>
}];

export default offerTableConfig;
