import React, { useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { WorkContext } from './WorkContext';
import workReducer from './workReducer/workReducer';
import { workContextInitialState } from './workContextInitialStates';
import worksService from '../../../../services/works/worksService';
import WorksService from '../../../../services/works/worksService';
import { workActionTypes } from './workReducer/workActionTypes';
import mediaService from '../../../../services/media/mediaService';
import utilityService from '../../../../services/utilityService';
import { AppContext } from '../../../../components/AppContext';

const WorkProvider = ({ children }) => {

  const { id } = useParams()

  const [state, dispatch] = useReducer(workReducer, workContextInitialState);
  const { setLoadingOverlayState } = useContext(AppContext)


  useRequest(worksService.fetch, {
    defaultParams: [id],
    refreshDeps: [id],
    onBefore: () => setLoadingOverlayState(true),
    onSuccess: data => dispatch({ type: workActionTypes.INIT, payload: data }),
    onFinally: () => setLoadingOverlayState(false)
  })

  useRequest(WorksService.availableProductionStates, {
    defaultParams: [id],
    onSuccess: data => dispatch({ type: workActionTypes.UPDATE_PRODUCTION_STATES, payload: data })
  })

  useRequest(WorksService.availableStates, {
    defaultParams: [id],
    onSuccess: data => dispatch({ type: workActionTypes.UPDATE_COMMERCIAL_STATES, payload: data })
  })

  useRequest(utilityService.fetchColorParts, {
    onSuccess: data => dispatch({ type: workActionTypes.INIT_COLOR_PARTS, payload: data })
  })

  useRequest(mediaService.fetchAll, {
    defaultParams: [state.work?.id, 'work'],
    ready: state?.work?.id !== undefined,
    refreshDeps: [id],
    onSuccess: data => dispatch({ type: workActionTypes.INIT_MEDIA, payload: data })
  });

  useRequest(WorksService.statesHistory, {
    defaultParams: [id],
    ready: !!id,
    onSuccess: data => dispatch({ type: workActionTypes.INIT_HISTORY, payload: data })
  })

  //
  const contextValue = {
    ...state,
    dispatchAction: dispatch
  }

  return <WorkContext.Provider value={contextValue}>
    {children}
  </WorkContext.Provider>


}

export default WorkProvider
