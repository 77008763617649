import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormGroup from '../../../../../components/UI/Forms/FormGroup';
import { FormSelect } from '../../../../../components/UI/Forms/FormSelect';
import { FormInput } from '../../../../../components/UI/Forms/FormInput';
import CancelButton from '../../../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../../../components/UI/Buttons/Save/FormikSaveButton';
import { toast } from 'react-toastify';
import Drawer from '../../../../../components/UI/Drawer/Drawer';

const WorkStateChangerOffCanvas = ({
                                     work,
                                     oldState,
                                     newState,
                                     isOpen,
                                     onCancel,
                                     onChangeState,
                                     onSuccess
                                   }) => {

  const { t } = useTranslation()

  const initialValues = {
    reason: undefined,
    message: ""
  }

  const validation = Yup.object().shape({
    reason: Yup.string().required(),
    message: Yup.string().max(200).required()
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => onCancel()}
      title={t('works.state')}
    >
      <p>
        {t('state_change', {
          old_state_label: t(`enums.work_state.${oldState?.label || ""}`),
          new_state_label: t(`enums.work_state.${newState?.label || ""}`)
        })}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          onChangeState(work.uuid, {
            state: newState.value,
            message: values.message,
            reason: values.reason
          })
          .then(res => onSuccess(res))
          .catch((err) => toast.error(err?.response?.data?.message))
          .finally(() => setSubmitting(false))
        }}
      >
        {
          () => (
            <Form>
              <FormGroup
                required={true}
                label={t('works.reason')}
                inline={false}
                name='reason'
              >
                <FormSelect
                  name='reason'
                  options={newState?.reasons || []}
                />
              </FormGroup>
              <FormGroup
                required={true}
                label={t('log_messages.message')}
                inline={false}
                name='message'
              >
                <FormInput
                  name='message'
                  as='textarea'
                  rows={5}
                />
              </FormGroup>

              <div className="d-flex align-items-center justify-content-between">
                <CancelButton onClick={() => onCancel()}/>
                <FormikSaveButton/>
              </div>

            </Form>
          )
        }

      </Formik>
    </Drawer>

  )

}

export default WorkStateChangerOffCanvas
