import React, { useMemo } from 'react';
import { useRequest, useSessionStorageState } from 'ahooks';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import partsService from '../../../../services/parts/partsService';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';
import Toolbar from '../../../../components/UI/Toolbar';
import partTypeEnum from '../../../../constants/enums/PartTypeEnum';
import TableCheckbox from '../../../../components/UI/Forms/TableCheckbox';
import optionsService from '../../../../services/optionsService';
import MassiveUpdatePartCategoryDrawer from '../../components/MassiveUpdatePartCategoryDrawer';
import MassiveUpdatePartMarginDrawer from '../../components/MassiveUpdatePartMarginDrawer';
import StandardPartFilters from './StandardPartFilters';
import StandardDropdownCreate from './StandardDropdownCreate';
import standardPartTableConfig from './standardPartTableConfig';
import { useLocation } from "react-router-dom";
import ExportExcelLocalizedButton from '../../../../components/UI/Buttons/Excel/ExportExcelLocalizedButton';
import exportsService from '../../../../services/exportsService';

function StandardParts() {
  const { data = [], loading, run } = useRequest(partsService.fetchAll, { manual: true });
  const { data: categories = [] } = useRequest(optionsService.partCategories)
  const location = useLocation()
  const columns = useMemo(() => standardPartTableConfig, []);
  const [rowSelected, setRowSelected] = React.useState([]);
  const [isMassiveUpdatePartCategoryDrawerOpen, setIsMassiveUpdatePartCategoryDrawerOpen] = React.useState(false);
  const [isMassiveUpdatePartMarginDrawerOpen, setIsMassiveUpdatePartMarginDrawerOpen] = React.useState(false);
  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        code: [],
        description: [],
        drawing_note: '',
        width: [],
        height: [],
        depth: [],
        part_collection: [],
        part_category_id: [],
        state: [],
        works: [],
        part_type: [partTypeEnum.values.WITH_MEASURE, partTypeEnum.values.WITHOUT_MEASURE],
        no_margin: false,
        no_price: false,
        no_cost: false,
      },
    }
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))

  const mapFilters = values => ({
    ...values, filter: {
      ...values.filter,
      code: values.filter.code.map(opt => opt.value),
      description: values.filter.description.map(opt => opt.value),
      drawing_note: values.filter.drawing_note,
      width: values.filter.width.map(opt => opt.value),
      height: values.filter.height.map(opt => opt.value),
      depth: values.filter.depth.map(opt => opt.value),
      part_collection: values.filter.part_collection?.value,
      part_category_id: values.filter.part_category_id.map(opt => opt.value),
      state: values.filter.state.map(opt => opt.value),
      works: values.filter.works.map(opt => opt.value),
      no_margin: values.filter.no_margin,
      no_price: values.filter.no_price,
      no_cost: values.filter.no_cost,
    }
  })

  const handleUpdate = values => run(mapFilters(values))

  const handleExportExcel = locale => exportsService.parts(mapFilters({ ...extraParams, locale: locale }), 'parts');


  return (
    <>
      <Toolbar title={'Standard'}>
        <div className="d-flex gap-2">
          <ExportExcelLocalizedButton buttonId='export_parts' handleClick={handleExportExcel}/>
          <StandardDropdownCreate
            setIsMassiveUpdatePartCategoryDrawerOpen={setIsMassiveUpdatePartCategoryDrawerOpen}
            setIsMassiveUpdatePartMarginDrawerOpen={setIsMassiveUpdatePartMarginDrawerOpen}
            rowSelected={rowSelected}
          />
        </div>
      </Toolbar>

      <StandardPartFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <div className='card'>
        <div className='card-body'>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
            selectable={true}
            SelectComponent={TableCheckbox}
            onSelectedRowIdsChange={(ids, originals) => setRowSelected(originals)}
          />
        </div>
      </div>

      <MassiveUpdatePartCategoryDrawer
        selectedParts={rowSelected}
        isOpen={isMassiveUpdatePartCategoryDrawerOpen}
        setIsOpen={setIsMassiveUpdatePartCategoryDrawerOpen}
        categories={categories}
        onUpdate={() => {
          setIsMassiveUpdatePartCategoryDrawerOpen(false)
          run(extraParams)
        }}
      />

      <MassiveUpdatePartMarginDrawer
        selectedParts={rowSelected}
        isOpen={isMassiveUpdatePartMarginDrawerOpen}
        setIsOpen={setIsMassiveUpdatePartMarginDrawerOpen}
        onUpdate={() => {
          setIsMassiveUpdatePartMarginDrawerOpen(false)
          run(extraParams)
        }}
      />
    </>
  );
}

export default StandardParts;
