import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WorkCustomersDrawer from './WorkCustomersDrawer';
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import EditButton from '../../../../../components/UI/Buttons/EditButton';
import { List } from '../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';
import { InfoTooltip } from '../../../../../components/UI/Tooltips/InfoTooltip';
import WorkTriangulation from './WorkTriangulation';

const WorkCustomers = () => {

  const { work, dispatchAction } = useContext(WorkContext)
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = res => {
    setIsOpen(false)
    dispatchAction({
      type: workActionTypes.UPDATE_CUSTOMER,
      payload: res
    });
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('works.customer')}</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>
          <List>
            <ListItem label={t('works.bill_to')} extra={<InfoTooltip message={t('works.bill_to_message')}/>}>
              {work?.bill_to?.business_name}
            </ListItem>
            <ListItem label={t('works.customer')} extra={<InfoTooltip message={t('works.customer_message')}/>}>
              {work?.company?.business_name}
            </ListItem>
            <ListItem label={t('works.person')} extra={<InfoTooltip message={t('works.person_message')}/>}>
              {work?.company_person?.full_name}
            </ListItem>
            <WorkTriangulation work={work}/>
          </List>
        </CardBody>
      </Card>

      <WorkCustomersDrawer
        onUpdate={handleUpdate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        billTo={work?.bill_to}
        company={work?.company}
        companyLocation={work?.company_location}
        companyPerson={work?.company_person}
      />

    </>
  )

}

export default WorkCustomers
