import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert2';
import CompanyPersonsService from '../../../../services/companies/companyPersonsService';
import { toast } from 'react-toastify';
import { Card } from '../../../../components/UI/Card/Card';
import { CardContentBetween } from '../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import CreateButton from '../../../../components/UI/Buttons/CreateButton';
import { List } from '../../../../components/UI/ItemList/List';
import { ListPersonItem } from '../../../../components/UI/ItemList/ListPersonItem';
import { CompanyPersonDrawer } from './CompanyPersonDrawer';

export const CompanyPersons = ({ persons = [], setCompany, companyUuid, locations }) => {

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState(null)

  useEffect(() => setIsOpen(selectedPerson !== null), [selectedPerson])

  const handleCreate = res => {
    setIsOpen(o => false)
    setCompany(c => ({ ...c, company_persons: [...c.company_persons, res] }))
  }

  const handleUpdate = res => {
    setSelectedPerson(o => null)
    setCompany(c => ({ ...c, company_persons: c.company_persons.map(cp => cp.uuid === res.uuid ? res : cp) }))
  }

  const handleDelete = () => SweetAlert.fire({
    text: `Sei sicuro di voler eliminare il contatto ${selectedPerson.full_name} e i suoi recapiti?`,
    titleText: t('are_you_sure'),
    icon: 'warning',
    showCancelButton: true,
  }).then((response) => {
    if (response.isConfirmed) {
      const uuid = selectedPerson.uuid;
      setSelectedPerson(o => null)
      CompanyPersonsService.destroy(companyUuid, uuid)
      .then(res => {
        setCompany(c => ({ ...c, company_persons: c.company_persons.filter(cp => cp.uuid !== uuid) }))
      })
      .catch(err => toast.error(err?.response?.data?.message))
    }
  });


  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('persons.plural')}</CardLabel>
            <CreateButton onClick={() => setSelectedPerson(undefined)} label={t('new')}/>
          </CardContentBetween>
          <List>
            {persons.length > 0 ? persons.map(person =>
              <ListPersonItem
                person={person}
                key={person.uuid}
                setSelectedPerson={setSelectedPerson}
              />) : <></>}
          </List>
        </CardBody>
      </Card>

      <CompanyPersonDrawer
        companyUuid={companyUuid}
        isOpen={isOpen}
        setSelectedPerson={setSelectedPerson}
        person={selectedPerson}
        locations={locations}
        handleUpdate={handleUpdate}
        handleCreate={handleCreate}
        handleDelete={handleDelete}
      />
    </>
  )

}
