import React from 'react';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';

const Drawer = ({ status, toggle, title, children }) => {

  return (
    <Offcanvas
      backdrop
      direction='end'
      isOpen={status}
      toggle={toggle}
      className='responsive-off-canvas'

    >
      <OffcanvasHeader toggle={toggle}>
        {title}
      </OffcanvasHeader>
      <OffcanvasBody>
        {children}
      </OffcanvasBody>
    </Offcanvas>
  )

}

export default Drawer
