import React, {useMemo} from 'react';
import {useRequest, useSessionStorageState} from 'ahooks';
import {useLocation, useNavigate} from 'react-router-dom';
import {PaginatedTable} from '@dvsoftsrl/react-laravel-paginated-table';
import {Card, CardBody} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import wordsService from '../../../services/parts/wordsService';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import Toolbar from '../../../components/UI/Toolbar';
import wordTableConfig from '../components/wordTable.config';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import WordCreateDrawer from './WordCreateDrawer';
import WordFilters from './WordFilters';

const Words = () => {
    const location = useLocation()
    const {data = [], loading, run} = useRequest(wordsService.fetchAll, {manual: true});
    const {t} = useTranslation();
    const navigate = useNavigate();
    const columns = useMemo(() => wordTableConfig, []);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
        defaultValue: {
            filter: {
                label: [],
                description: [],
            }
        },
    });

    const handleOnCreated = res => navigate(res.uuid)
    const handleSubmit = values => setExtraParams(ext => ({filter: {...ext.filter, ...values}}))
    const handleUpdate = values => {
        run({
            ...values, filter: {
                label: values.filter.label.map(opt => opt.value),
                description: values.filter.description.map(opt => opt.value)
            }
        })
    }

    return (
        <>
            <Toolbar title={t('words.plural')}>
                <div className="d-flex gap-1">
                    <CreateButton onClick={() => setIsDrawerOpen(true)}/>
                </div>
            </Toolbar>

            <WordFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

            <Card>
                <CardBody>
                    <PaginatedTable
                        options={paginatedTableConfig}
                        isLoading={loading}
                        data={data}
                        columns={columns}
                        sortable
                        extraParams={extraParams}
                        onParamsChange={handleUpdate}
                    />
                </CardBody>
            </Card>

            <WordCreateDrawer
                isOpen={isDrawerOpen}
                setIsOpen={setIsDrawerOpen}
                onCreated={handleOnCreated}
            />
        </>
    );
}

export default Words;
