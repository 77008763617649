import React from 'react';
import UserIndex from '../../../pages/User/UserIndex';
import UserWrapper from '../../../pages/User/UserWrapper';

const wordsRoutes = {
  path: 'user/*',
  element: <UserWrapper />,
  children: [{
    path: '',
    index: true,
    element: <UserIndex />,
  }],
};

export default wordsRoutes;
