import React from 'react';
import CompanyPicker from '../Forms/Pickers/CompanyPicker';

const CompanyFilter = ({ name, label, value, setValue, handleSubmit }) => {

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.target.value.trim()) {
      handleSubmit()
    }
  };

  return (
    <CompanyPicker
        value={value}
        defaultOption={true}
      onKeyDown={handleKeyDown}
      placeholder={label}
      isMulti={true}
      handleChange={v => setValue(name, v)}
    />
  );
}

export default CompanyFilter;
