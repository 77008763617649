import React from 'react';
import logo from '../../assets/images/logo-dark.png';
import { Spinner } from 'reactstrap';

const LoadingScreen = () => {

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <img src={logo} alt="3dBeta" height="60"/>
      <h5 className="my-3 text-center fw-bolder">
        L<Spinner size="sm"/>ADING
      </h5>
    </div>
  )

}

export default LoadingScreen
