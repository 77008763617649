import React from 'react';
import { Badge } from 'reactstrap';

function WorkStateEnumDisplay({ state }) {


  return (
    <Badge
      className="p-1 font-size-11"
      color={state?.color}
    >
      {state?.label}
    </Badge>
  );


}

export default WorkStateEnumDisplay;
