import React, { useContext, useState } from 'react';
import WorkUsersDrawer from './WorkUsersDrawer';
import WorkUserRoleEnum from '../../../../../constants/enums/WorkUserRoleEnum';
import { useTranslation } from 'react-i18next';
import { WorkUsersList } from './WorkUsersList';
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardContentBetween } from '../../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import EditButton from '../../../../../components/UI/Buttons/EditButton';
import { List } from '../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';

const WorkUsers = () => {

  const { work, dispatchAction } = useContext(WorkContext)
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const filterUsersByRole = role => work.users.filter(u => u.role === role)

  const handleUpdate = res => {
    setIsOpen(false)
    dispatchAction({
      type: workActionTypes.UPDATE_USERS,
      payload: res
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('users.plural')}</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>
          <List>
            <ListItem label={t('enums.work_user_role.SELLER')}>
              <WorkUsersList users={filterUsersByRole(WorkUserRoleEnum.values.SELLER)}/>
            </ListItem>
            <ListItem label={t('enums.work_user_role.DESIGNER')}>
              <WorkUsersList users={filterUsersByRole(WorkUserRoleEnum.values.DESIGNER)}/>
            </ListItem>
          </List>
        </CardBody>
      </Card>

      <WorkUsersDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        sellers={filterUsersByRole(WorkUserRoleEnum.values.SELLER)}
        designers={filterUsersByRole(WorkUserRoleEnum.values.DESIGNER)}
        onUpdate={handleUpdate}
      />

    </>
  )

}

export default WorkUsers
