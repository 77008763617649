const cancelled = 'cancelled';
const failed = 'failed';
const negotiation = 'negotiation';
const ready = 'ready';
const recovery = 'recovery';
const standby = 'standby';
const success = 'success';
const warning = 'warning';

const values = {
  cancelled,
  failed,
  negotiation,
  ready,
  recovery,
  standby,
  success,
  warning
};

const collection = [
  { value: cancelled, label: 'enums.work_state.cancelled', color: 'light' },
  { value: failed, label: 'enums.work_state.failed', color: 'dark' },
  { value: negotiation, label: 'enums.work_state.negotiation', color: 'info' },
  { value: ready, label: 'enums.work_state.ready', color: 'success' },
  { value: recovery, label: 'enums.work_state.recovery', color: 'danger' },
  { value: standby, label: 'enums.work_state.standby', color: 'light' },
  { value: success, label: 'enums.work_state.success', color: 'success' },
  { value: warning, label: 'enums.work_state.warning', color: 'warning' },
];

const WorkStateEnum = {
  values,
  collection,
};

export default WorkStateEnum;
