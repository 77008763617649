import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, FormikProvider, useFormik } from 'formik';
import formConfig from '../components/form.config';
import codesService from '../../../services/parts/codesService';
import CodeTypeEnum from '../../../constants/enums/CodeTypeEnum';
import PartTypeEnum from '../../../constants/enums/PartTypeEnum';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import SimilarCodes from '../components/SimilarCodes';
import WordsSort from '../components/WordsSort/WordsSort';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import DisplayFormattedMeasures from '../../Parts/creates/components/DisplayFormattedMeasures';

function CodeCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (values, { setSubmitting }) => codesService.store(CodeTypeEnum.values.CODE, {
    ...values, words:
      values.words.map(w => w.value)
  })
  .then((res) => {
    toast.success(t('created'));
    navigate(`../${res.uuid}`);
  })
  .catch((err) => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false));


  const formik = useFormik({
    initialValues: {
      ...formConfig.initialValues,
      part_type: PartTypeEnum.values.WITH_MEASURE
    },
    validationSchema: formConfig.storeValidation,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <h5 className="text-uppercase">
          {t('enums.code_types.CODE')}&nbsp;{t('enums.part_types.WITH_MEASURE')}
        </h5>

        <div className='card'>
          <div className='card-body'>

            <div className='row'>
              <div className='col-lg-4'>
                <FormGroup label={t('codes.code')} name="code" required>
                  <FormInput name="code" maxLength={3} minLength={3}/>
                </FormGroup>
              </div>
              <div className='col-lg-8'>
                <FormDisplay label={t('description')}>
                  {formik.values.words.map(w => w.label).join(' ')}
                  &nbsp;
                  <DisplayFormattedMeasures
                    width={formik.values.width}
                    depth={formik.values.depth}
                    height={formik.values.height}
                  />
                </FormDisplay>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4'>
                <h5>&nbsp;</h5>
              </div>
              <div className='col-lg-4'>
                <WordsSort/>
              </div>
              <div className='col-lg-4'>
                <SimilarCodes words={formik.values.words}/>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end gap-2">
              <CancelButton onClick={() => navigate('../')}/>
              <FormikSaveButton/>
            </div>
          </div>
        </div>

      </Form>
    </FormikProvider>

  );
}

export default CodeCreate;
