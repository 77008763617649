import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { convertToRaw, EditorState } from 'draft-js';

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from "draft-js-import-html";


const FormRichText = ({ name, defaultValue }) => {
  const [field, meta, helpers] = useField(name);

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );

  const handleOnChange = value => {
    setEditorState(value)
    helpers.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    setEditorState(EditorState.createWithContent(stateFromHTML(defaultValue ?? '')))
  }, [defaultValue])

  return (
    <>
      <Editor
        editorClassName="editor-class"
        wrapperClassName="wrapper-class"
        toolbarClassName="toolbar-class"

        editorState={editorState}
        onEditorStateChange={handleOnChange}
        toolbar={{
          options: [
            'inline',
            'list',
            'remove',
            'history',
          ],
        }}
      />
    </>
  );
};

export default FormRichText
