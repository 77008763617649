import React, { useContext } from 'react';
import Drawer from '../../../../../../components/UI/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import SubOffersService from '../../../../../../services/offers/subOffersService';
import { FormInput } from '../../../../../../components/UI/Forms/FormInput';
import FormikSaveButton from '../../../../../../components/UI/Buttons/Save/FormikSaveButton';
import CancelButton from '../../../../../../components/UI/Buttons/CancelButton';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { toast } from 'react-toastify';
import FormFileInput from '../../../../../../components/UI/Forms/FormFile/FormFileInput';
import { AppContext } from '../../../../../../components/AppContext';
import { SubOfferBomDownload } from './SubOfferBomDownload';

export const SubOfferCreateDrawer = ({ isOpen, setIsOpen, offerUuid, offerRevisionUuid, handleCreate }) => {

  const { setLoadingOverlayState } = useContext(AppContext)
  const { t } = useTranslation()
  const initialValues = {
    code: '',
    description: '',
    file: undefined
  }

  const validationSchema = Yup.object().shape({
    code: Yup.string().nullable().required().max(50),
    description: Yup.string().nullable().required().max(255),
    file: Yup.mixed().nullable().notRequired()
  })

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setLoadingOverlayState(true)
    SubOffersService.store(offerUuid, offerRevisionUuid, values)
    .then(res => {
      resetForm()
      handleCreate(res)
    })
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })


  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('new')}>
      <FormikProvider value={formik}>
        <Form>
          <div className='row'>
            <div className='col-md-6'>
              <FormGroup
                name="code"
                label={t('sub_offers.code')}
                required
                inline={false}>
                <FormInput name='code'/>
              </FormGroup>
            </div>
          </div>
          <FormGroup
            name="description"
            label={t('description')}
            required
            inline={false}>
            <FormInput name='description'/>
          </FormGroup>
          <SubOfferBomDownload/>
          <div className='row'>
            <div className='col-md-6'>
              <FormGroup
                name="file"
                label={false}
                inline={false}>
                <FormFileInput
                  name='file'
                  file={undefined}
                  onFileChange={e => formik.setFieldValue('file', e.target.files ? e.target.files[0] : undefined)}
                />
              </FormGroup>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-end gap-2'>
            <CancelButton onClick={() => setIsOpen(false)}/>
            <div className="d-flex gap-1">
              <FormikSaveButton/>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Drawer>
  )

}
