import React from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';

function PartMeasures() {
  const { t } = useTranslation();

  return (
    <>
      <h5>{t('enums.code_types.MEASURE')}</h5>
      <FormGroup label={t('width')} name="width" required={false} inline={false}>
        <FormInput name="width" type="number" step={1}/>
      </FormGroup>
      <FormGroup label={t('depth')} name="depth" required={false} inline={false}>
        <FormInput name="depth" type="number" step={1}/>
      </FormGroup>
      <FormGroup label={t('height')} name="height" required={false} inline={false}>
        <FormInput name="height" type="number" step={1}/>
      </FormGroup>
    </>
  );
}

export default PartMeasures;
