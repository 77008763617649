import React, { useMemo } from 'react';
import CreateButton from '../../../components/UI/Buttons/CreateButton';
import { useTranslation } from 'react-i18next';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import { useRequest, useSessionStorageState } from 'ahooks';
import companiesService from '../../../services/companies/companiesService';
import companyTableConfig from './companyTable.config';
import CompanyCreateDrawer from './CompanyCreateDrawer';
import CompanyFilters from './CompanyFilters';
import { useLocation } from "react-router-dom";
import { Card } from '../../../components/UI/Card/Card';
import { CardBody } from '../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../components/UI/Card/CardLabel';

function Companies() {
  const location = useLocation()
  const { t } = useTranslation()
  const { data = [], loading, run } = useRequest(companiesService.fetchAll, { manual: true });

  const columns = useMemo(() => companyTableConfig, []);
  const [isOpenCreateDrawer, setIsOpenCreateDrawer] = React.useState(false);

  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        business_name: [],
        vat_number: [],
        sdi: [],
        sap_code: []
      },
    }
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))

  const handleUpdate = (values) => {
    run({
      ...values,
      filter:
        {
          ...values.filter,
          business_name: values.filter.business_name.map(opt => opt.value),
          vat_number: values.filter.vat_number.map(opt => opt.value),
          sdi: values.filter.sdi.map(opt => opt.value),
          sap_code: values?.filter.sap_code.map(opt => opt.value)
        }
    })
  }


  return (
    <>
      <CompanyFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('companies.plural')}</CardLabel>
            <CreateButton onClick={() => setIsOpenCreateDrawer(true)}/>
          </CardContentBetween>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
          />
        </CardBody>
      </Card>

      <CompanyCreateDrawer
        isOpen={isOpenCreateDrawer}
        setIsOpen={setIsOpenCreateDrawer}
      />
    </>
  );
}

export default Companies;
