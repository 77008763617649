import api from '../../configs/api.config';

const fetchAll = params => api.get('/offers', { params }).then(res => res.data);
const fetch = offerUuid => api.get(`/offers/${offerUuid}`).then(res => res.data.data);
const store = params => api.post('/offers', params).then(res => res.data.data);
const update = (offerUuid, params) => api.patch(`/offers/${offerUuid}`, params).then(res => res.data.data);
const destroy = offerUuid => api.delete(`/offers/${offerUuid}`).then(res => res.data.data);


const offersService = {
  fetchAll,
  fetch,
  store,
  update,
  destroy
};

export default offersService;
