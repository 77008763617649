import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthMiddleware from './AuthMiddleware';
import PrivateLayout from '../components/Layouts/PrivateLayout/PrivateLayout';
import PublicLayout from '../components/Layouts/PublicLayout/PublicLayout';
import Login from '../pages/Authentication/Login';
import privateRoutes from './private/privateRoutes';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import NewPassword from '../pages/Authentication/NewPassword';

const routes = [{
  path: '/',
  element: <AuthMiddleware auth redirect="auth/login"><PrivateLayout /></AuthMiddleware>,
  children: privateRoutes,
}, {
  path: '/',
  element: <AuthMiddleware auth={false} redirect="/"><PublicLayout /></AuthMiddleware>,
  children: [{
    path: 'auth/login',
    element: <Login />,
  }, {
    path: 'auth/forgot-password',
    element: <ForgotPassword />,
  }, {
    path: 'auth/new-password/:token',
    element: <NewPassword />,
  }, {
    path: '/',
    index: true,
    element: <Navigate to="auth/login" />,
  }],
}];

export default routes;
