import React, { useContext, useState } from 'react';
import EuroDisplay from '../../../../../../components/UI/Displays/EuroDisplay';
import { OfferContext } from '../../../offerContext/offerContext';
import { offerActionTypes } from '../../../offerContext/offerReducer/offerActionTypes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import subOfferItemsService from '../../../../../../services/offers/subOfferItemsService';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import { UncontrolledTooltip } from 'reactstrap';
import { SubOfferItemActions } from './SubOfferItemActions';
import { AppContext } from '../../../../../../components/AppContext';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../../../components/UI/Forms/FormInput';
import SubOfferItemTypeEnum from '../../../../../../constants/enums/SubOfferItemTypeEnum';
import PercentageDisplay from '../../../../../../components/UI/Displays/PercentageDisplay';
import { CardSmMargin } from '../../../../../../components/UI/Card/CardSmMargin';
import { CardBody } from '../../../../../../components/UI/Card/CardBody';
import DefaultButtonIcon from '../../../../../../components/UI/Buttons/DefaultButtonIcon';
import { FormNumber } from '../../../../../../components/UI/Forms/FormNumber';
import { FormEuro } from '../../../../../../components/UI/Forms/FormEuro';
import { FormPercentage } from '../../../../../../components/UI/Forms/FormPercentage';
import { List } from '../../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../../components/UI/ItemList/ListItem';
import { InfoTooltip } from '../../../../../../components/UI/Tooltips/InfoTooltip';
import NumberDisplay from '../../../../../../components/UI/Displays/NumberDisplay';

const validationSchema = Yup.object().shape({
  qty: Yup.number().min(0).nullable().required(),
  price: Yup.number().min(0).nullable().required(),
  cost: Yup.number().min(0).nullable().required(),
  part_margin: Yup.number().min(0).max(100).nullable(),
  note: Yup.string().max(400).nullable().notRequired()
})

const SubOfferItem = ({ subOfferUuid, subOfferItem, children, offerRevisionUuid, pos }) => {

  const { offer, dispatchAction, revision_editable } = useContext(OfferContext)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const { setLoadingOverlayState } = useContext(AppContext)


  const handleUpdateSubOfferItem = (values, { setSubmitting }) => updateSubOfferItem(values, setSubmitting)

  const updateSubOfferItem = (values, setSubmitting) => {
    setLoadingOverlayState(true)
    subOfferItemsService.update(offer.uuid, offerRevisionUuid, subOfferUuid, subOfferItem.uuid, values)
    .then(res => dispatchAction({
      type: offerActionTypes.UPDATE_SUB_OFFER_ITEM,
      payload: { subOfferUuid: subOfferUuid, subOfferItem: res }
    }))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    })
  }

  const handleDeleteItem = () => {
    setLoadingOverlayState(true)
    subOfferItemsService.destroy(offer.uuid, offerRevisionUuid, subOfferUuid, subOfferItem.uuid)
    .then(res => {
      dispatchAction({
        type: offerActionTypes.REMOVE_SUB_OFFER_ITEM,
        payload: { subOfferUuid, index: pos }
      })
    }).catch(err => toast.error(err?.response?.data?.message))
    .finally(() => setLoadingOverlayState(false))
  }

  const formik = useFormik({
    initialValues: {
      qty: subOfferItem.qty,
      cost: subOfferItem.cost,
      price: subOfferItem.price,
      description: subOfferItem?.description ?? '',
      part_margin: subOfferItem.part_margin * 100 ?? '',
      note: ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleUpdateSubOfferItem
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <CardSmMargin>
          <CardBody>
            <UncontrolledTooltip target={`folder_button_${subOfferItem.uuid}`}>Apri/Chiudi dettaglio</UncontrolledTooltip>

            <div className='d-flex justify-content-between align-items-center gap-2'>

              <div className='d-flex flex-column gap-1'>
                <div className='d-flex flex-nowrap align-items-center justify-content-start gap-2'>
                  {children}
                  <DefaultButtonIcon
                    id={`folder_button_${subOfferItem.uuid}`}
                    icon={`${open ? 'folder-open' : 'folder'}`}
                    onClick={() => setOpen(o => !o)}
                  />
                  <h1>
                  </h1>
                  <span
                    className={`fw-bold fs-5 ${subOfferItem.is_price_invalid || subOfferItem.is_cost_invalid ? 'text-danger' : ''}`}>{subOfferItem.code}</span>

                  <SubOfferItemActions subOfferItem={subOfferItem} handleDeleteItem={handleDeleteItem}/>

                  {subOfferItem.is_price_invalid || subOfferItem.is_cost_invalid ?
                    <span className='bx-flashing'>
                        <InfoTooltip message='Costo o prezzo non valido' color='danger'/>
                    </span> : <></>}

                </div>

                <div className='font-size-14'>{subOfferItem.description}</div>

                {subOfferItem.part_category ?
                  <span><i className="fa fa-square me-1" style={{ color: subOfferItem.part_category.color }}/>{subOfferItem.part_category.label}</span> :
                  <></>
                }
              </div>

              <div className='d-flex justify-content-end gap-4 flex-wrap text-end'>
                <div className='d-flex justify-content-start align-items-center gap-4' style={{ minWidth: "100px" }}>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offer_items.position')}</span>
                    <span className='fw-bold'>{subOfferItem.position ?? '---'}</span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('offers.qty')}</span>
                    <span className='fw-bold'>{subOfferItem.qty}</span>
                  </div>
                </div>
                <div className='d-flex justify-content-start align-items-center gap-4'
                     style={{ minWidth: "100px" }}>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offer_items.offer_item_price_total')}</span>
                    <span className='text-info'>
                      <EuroDisplay value={subOfferItem.total_price}/>
                    </span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offer_items.offer_item_net_price_total')}</span>
                    <span className='text-info'>
                      <EuroDisplay value={subOfferItem.total_net_price}/>
                    </span>
                  </div>
                </div>
                <div className='d-flex justify-content-start align-items-center gap-4'
                     style={{ minWidth: "100px" }}>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offer_items.offer_item_cost_total')}</span>
                    <span className='text-success'>
                      <EuroDisplay value={subOfferItem.total_cost}/>
                    </span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offer_items.offer_item_min_sell_price_total')}</span>
                    <span className='text-success'>
                      <EuroDisplay value={subOfferItem.total_net_cost}/>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${open ? 'd-block' : 'd-none'}`}>
              <div className='mt-3'>

                {subOfferItem.type === SubOfferItemTypeEnum.MESSAGE &&
                  <FormGroup label={t('description')} name="description" required inline={false}>
                    <FormInput name="description"/>
                  </FormGroup>}

                <div className='row'>
                  <div className='col-xxl-4 col-md-6'>
                    <List>
                      <ListItem label={t('offers.qty')} inline={true}>
                        {revision_editable ? <FormNumber
                          name='qty'
                          min={0.01}
                          step={0.01}
                          value={formik.values.qty}
                        /> : <NumberDisplay value={formik.values.qty}/>}
                      </ListItem>
                      <ListItem label={t('sub_offer_items.offer_item_price_cad')} inline={true}>
                        {revision_editable ? <FormEuro
                          color='text-info'
                          name='price'
                          min={0.00}
                          step={0.01}
                          value={formik.values.price}
                          handleChange={value => formik.setFieldValue('price', value)}
                        /> : <EuroDisplay value={formik.values.price}/>}
                      </ListItem>
                      <ListItem label={t('sub_offer_items.offer_item_net_margin')} inline={true}>
                        <span className='text-info'>
                            <PercentageDisplay value={subOfferItem.net_margin * 100}/>
                        </span>
                      </ListItem>
                    </List>
                  </div>
                  <div className='col-xxl-4 col-md-6'>
                    <List>
                      <ListItem label={t('parts.margin')} inline={true} extra={<InfoTooltip message=' Se impostata ha priorità sulla marginalità di categoria. Non ha effetto
                      sulla parte associata.'/>}>
                        {revision_editable ? <FormPercentage
                          name='part_margin'
                          min={0}
                          max={100}
                          step={1}
                          value={formik.values.part_margin}
                          color='text-success'
                        /> : <PercentageDisplay value={formik.values.part_margin}/>}
                      </ListItem>
                      <ListItem label={t('sub_offer_items.offer_item_calculated_price')} inline={true}>
                        <EuroDisplay value={subOfferItem.calculated_price}/>
                      </ListItem>
                      <ListItem label={t('sub_offer_items.offer_item_min_margin')} inline={true}>
                      <span className='text-success'>
                        <PercentageDisplay value={subOfferItem.min_margin * 100}/>
                      </span>
                      </ListItem>
                    </List>
                  </div>

                  <div className='col-xxl-4 col-md-6'>
                    <List>
                      <ListItem label={t('sub_offer_items.offer_item_cost_cad')} inline={true}>
                        {revision_editable ? <FormEuro
                          name='cost'
                          min={0}
                          step={0.01}
                          value={formik.values.cost}
                          color='text-success'
                        /> : <EuroDisplay value={formik.values.cost}/>}
                      </ListItem>
                      <ListItem label={t('sub_offer_items.offer_item_min_sell_price')} inline={true}>
                        <EuroDisplay value={subOfferItem.net_cost}/>
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </CardSmMargin>
      </Form>
    </FormikProvider>
  )

}

export default SubOfferItem
