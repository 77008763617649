import React from 'react';
import { useTranslation } from 'react-i18next';

function PrintButton(props) {
  const { t } = useTranslation();

  return (
    <button className="btn btn-soft-secondary"  {...props}>
      <i className='fa fa-print'/>&nbsp;{t(props?.label ?? 'print')}
    </button>
  );
}

export default PrintButton;
