import React, { useContext } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import WorksService from '../../../../../services/works/worksService';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../../components/AppContext';
import { WorkContext } from '../../WorkContext/WorkContext';
import { useTranslation } from 'react-i18next';
import CreateButton from '../../../../../components/UI/Buttons/CreateButton';

const WorkCreateOfferButton = ({ errors = [] }) => {

  const { t } = useTranslation()
  const { work, dispatchAction } = useContext(WorkContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const handleNewOffer = () => SweetAlert.fire({
    text: `Vuoi creare una nuova offerta per la commessa ${work.number} importando le aree al momento attive?`,
    titleText: t('are_you_sure'),
    icon: 'warning',
    showCancelButton: true,
  }).then((response) => {
    if (response.isConfirmed) {
      storeNewOffer()
    }
  });

  const storeNewOffer = () => {
    setLoadingOverlayState(true)
    WorksService.storeOffer(work.uuid)
    .then(res => dispatchAction({ type: workActionTypes.CREATE_OFFER, payload: res, }))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setLoadingOverlayState(false))
  }


  return (
    <>
      <UncontrolledTooltip target='create_new_offer_button'>
        Crea una nuova offerta importando tutte le aree attive
      </UncontrolledTooltip>
      <CreateButton
        disabled={errors.filter(e => e.part_uuid !== null) > 0}
        onClick={handleNewOffer}
        label={t('offers.create')}
        id='create_new_offer_button'>
      </CreateButton>
    </>
  )

}

export default WorkCreateOfferButton
