import React, { useEffect, useMemo, useState } from 'react';
import i18n from '../locales/i18n';
import layoutConfig from '../configs/layout.configs';
import { useRequest } from 'ahooks';
import utilityService from '../services/utilityService';

export const AppContext = React.createContext({
  showRightSidebar: layoutConfig.showRightSidebar,
  setShowRightSidebar: () => {
  },
  showLeftSidebar: layoutConfig.showLeftSidebar,
  setShowLeftSidebar: () => {
  },
  showTopNavigationMenu: false,
  setShowTopNavigationMenu: () => {
  },
  currentLocale: 'it',
  setCurrentLocale: () => {
  },
  loadingOverlayState: false,
  setLoadingOverlayState: () => {}
});

export function AppContextProvider({ children }) {
  const [currentLocale, setCurrentLocale] = useState('it');
  const [showRightSidebar, setShowRightSidebar] = useState(layoutConfig.showRightSidebar);
  const [showLeftSidebar, setShowLeftSidebar] = useState(layoutConfig.showLeftSidebar);
  const [showTopNavigationMenu, setShowTopNavigationMenu] = useState(false);
  const [loadingOverlayState, setLoadingOverlayState] = useState(false);

  const handleSetCurrentLocale = (locale) => i18n.changeLanguage(locale)
    .then(() => setCurrentLocale(locale));


  const { data: colorParts = [], run: loadColorParts } = useRequest(utilityService.fetchColorParts)


  const contextValue = useMemo(() => ({
    currentLocale,
    setCurrentLocale: handleSetCurrentLocale,
    showRightSidebar,
    setShowRightSidebar,
    showLeftSidebar,
    setShowLeftSidebar,
    showTopNavigationMenu,
    setShowTopNavigationMenu,
    setLoadingOverlayState,
    loadingOverlayState,
    loadColorParts,
    colorParts
  }), [
    currentLocale,
    showRightSidebar,
    showLeftSidebar,
    showTopNavigationMenu,
    loadingOverlayState,
    colorParts
  ]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
}
