import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import wordsService from '../../../services/parts/wordsService';
import localesConfig from '../../../locales/locales.config';
import * as Yup from 'yup';
import Drawer from '../../../components/UI/Drawer/Drawer';
import { WordForm } from '../components/WordForm';

const validationSchema = Yup.object().shape({
  label: Yup.string().required(),
  locales: Yup.array().of(
    Yup.object().shape({
      lang: Yup.string().required(),
      description: Yup.string().required(),
      short_description: Yup.string().max(40).required(),
    }))
});

const initialValues = {
  label: '',
  locales: localesConfig.availableTranslations.map((at) => ({
    label: at.label,
    lang: at.locale,
    description: '',
    short_description: '',
  })),
}

const WordCreateDrawer = ({ isOpen, setIsOpen, onCreated }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();



  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => wordsService.store(values)
    .then((res) => onCreated(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('new')}
    >
      <FormikProvider value={formik}>
        <Form>
          <WordForm setIsOpen={setIsOpen}/>
        </Form>
      </FormikProvider>
    </Drawer>
  );
}

export default WordCreateDrawer;
