import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import codeTypeEnum from '../../../constants/enums/CodeTypeEnum';

function CodeTypeEnumDisplay({ type }) {
  const partType = codeTypeEnum.collection.find((ct) => ct.value === type);
  const { t } = useTranslation();

  if (partType) {
    return <Badge className="p-1 font-size-11" color={partType.color}>{t(partType.label)}</Badge>;
  }

  return <></>;
}

export default CodeTypeEnumDisplay;
