import React, { useContext, useEffect, useState } from 'react';
import { WorkContext } from '../../WorkContext/WorkContext';
import { isNull, isUndefined, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import WorkCodesCheckFault from './WorkCodesCheckFault';
import { Link } from 'react-router-dom';
import WorkCreateOfferButton from './WorkCreateOfferButton';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import { CardContentBetween } from '../../../../../components/UI/Card/CardContentBetween';
import { CardActions } from '../../../../../components/UI/Card/CardActions';
import { Tag } from '../../../../../components/UI/Tags/Tag';
import { List } from '../../../../../components/UI/ItemList/List';
import { ListEmptyItem } from '../../../../../components/UI/ItemList/ListEmptyItem';

const WorkCodesChecker = () => {

  const { work } = useContext(WorkContext)
  const [items, setItems] = useState([])
  const { t } = useTranslation()

  const filterItems = (subWorks = []) => subWorks.reduce((accumulator, sw) => {

    const items = sw.items.filter(i => (
        isNull(i.part_cost) || isUndefined(i.part_cost) || i.part_cost === 0 ||
        isNull(i.part_price) || isUndefined(i.part_price) || i.part_price === 0
      ) && !!i.part_uuid
    );

    return uniqBy([...accumulator, ...items], i => i.code);
  }, []);


  useEffect(() => {
    setItems(i => filterItems(work?.sub_works))
  }, [work.sub_works]);


  return (
    <Card>
      <CardBody>
        <CardContentBetween>
          <CardLabel>{t('errors')}</CardLabel>
          <CardActions>
            <Tag color={items.length > 0 ? 'danger' : 'success'}>{items.length}</Tag>
            {work?.offer
              ? <Link to={`/offers/${work.offer.uuid}`} className=' fw-bold'>{work.offer.number}</Link>
              : <WorkCreateOfferButton errors={items}/>}
          </CardActions>
        </CardContentBetween>
        <List>
          {items.length > 0
            ? items.map(item => <WorkCodesCheckFault
              key={item.uuid}
              item={item}
            />)
            : <ListEmptyItem/>
          }
        </List>
      </CardBody>
    </Card>
  )

}

export default WorkCodesChecker
