import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { reactSelectStyles } from '../../../configs/reactSelect.config';
import WorkProductionStateEnum from '../../../constants/enums/WorkProductionStateEnum';

function WorkProductionStateFilter({ name, value, label, setValue, handleSubmit }) {
  const { t } = useTranslation();

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.target.value.trim()) {
      handleSubmit()
    }
  };


  return (
    <Select
      onKeyDown={handleKeyDown}
      defaultValue={value}
      isClearable
      isSearchable
      name={name}
      isMulti
      className='bg-white'
      styles={reactSelectStyles}
      onChange={value => setValue(name, value)}
      placeholder={label}
      options={WorkProductionStateEnum.collection.map(pse => ({ ...pse, label: t(pse.label) }))}
    />
  );
}

export default WorkProductionStateFilter;
