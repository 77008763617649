import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { arrayMoveImmutable } from 'array-move';
import { useTranslation } from 'react-i18next';
import DraggableItem from '../../../../components/UI/DnD/DraggableItem';
import CodeWord from './CodeWord';
import FormGroup from '../../../../components/UI/Forms/FormGroup';
import WordPicker from '../../../../components/UI/Forms/Pickers/WordPicker';
import EmptyDisplay from '../../../../components/UI/Displays/EmptyDisplay';

function WordsSort() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <h5>{t('dictionary')}</h5>

      <FormGroup label={t('words.plural')} name="words" required>
        <WordPicker handleChange={v => setFieldValue('words', [...values.words, v])}/>
      </FormGroup>

      {values.words.length < 1 && <EmptyDisplay/>}

      <FieldArray name="words" render={(arrayHelpers) => (
        <DndProvider backend={HTML5Backend}>
          {values.words.map((word, index) => (
            <DraggableItem
              key={index}
              id={index}
              onDrag={(oldPosition, newPosition) => setFieldValue('words', arrayMoveImmutable(values.words, oldPosition, newPosition))}
              canDrag={values.words.length > 1}
            >
              <CodeWord
                onRemove={() => arrayHelpers.remove(index)}
                index={index}
                word={word}
              />
            </DraggableItem>
          ))}
        </DndProvider>
      )}
      />

    </>
  );
}


export default WordsSort;
