import { Input, UncontrolledTooltip } from 'reactstrap';
import React from 'react';

const FormFileInput = ({ name, file, onFileChange, onUploadClick }) =>
  <div className="input-group ">
    <UncontrolledTooltip target='upload_template_button'>
      Scarica il template di esempio in formato Excel
    </UncontrolledTooltip>
    <Input
      name={name}
      type="file"
      className="form-control"
      onChange={onFileChange}
    />
    <button
      id='upload_template_button'
      className={`btn ${file ? "btn-success" : "btn-danger"}`}
      disabled={file === undefined}
      onClick={() => onUploadClick()}
      type="button">
      <i className='fa fa-upload'/>
    </button>
  </div>

export default FormFileInput
