import React from 'react';
import CompaniesWrapper from '../../../pages/Companies/CompaniesWrapper';
import CompanyCreateDrawer from '../../../pages/Companies/Companies/CompanyCreateDrawer';
import Company from '../../../pages/Companies/Company/Company';
import Companies from '../../../pages/Companies/Companies/Companies';


const companiesRoutes = {
  path: 'companies/*',
  element: <CompaniesWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <Companies/>,
  }, {
    path: 'create',
    element: <CompanyCreateDrawer/>,
  }, {
    path: ':id/*',
    element: <Company/>
  }],
};

export default companiesRoutes;
