export const workActionTypes = {

  INIT: 'INIT',
  INIT_COLOR_PARTS: 'INIT_COLOR_PARTS',
  INIT_MEDIA: 'INIT_MEDIA',
  INIT_HISTORY: "INIT_HISTORY",

  UPDATE_DETAILS: 'UPDATE_OFFER_DETAILS',

  UPDATE_COMMERCIAL_STATES: 'UPDATE_COMMERCIAL_STATES',
  UPDATE_PRODUCTION_STATES: 'UPDATE_PRODUCTION_STATES',

  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_PARTNERS: 'UPDATE_PARTNERS',
  UPDATE_USERS: 'UPDATE_USERS',
  UPDATE_SAP_ORDERS: 'UPDATE_SAP_ORDERS',
  UPDATE_COLOR_CONFIGS: 'UPDATE_COLOR_CONFIGS',

  STORE_MEDIA: 'STORE_MEDIA',
  DELETE_MEDIA: 'DELETE_MEDIA',
  DELETE_ALL_VAULT_MEDIA: 'DELETE_ALL_VAULT_MEDIA',

  TOGGLE_SUBWORK: 'TOGGLE_SUBWORK',
  UPDATE_SUBWORK_COLOR_CONFIGS: 'UPDATE_SUBWORK_COLOR_CONFIGS',
  UPDATE_SUBWORK_ITEM_PRICE_OR_COST: 'UPDATE_SUBWORK_ITEM_PRICE_OR_COST',

  CREATE_OFFER: 'CREATE_OFFER',
  TRIANGULATION_CHANGED: 'TRIANGULATION_CHANGED',
}
