import UserDisplay from '../../Displays/UserDisplay';
import React, { useState } from 'react';
import { CardContentBetween } from '../../Card/CardContentBetween';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import FormGroup from '../../Forms/FormGroup';
import { FormInput } from '../../Forms/FormInput';
import { useTranslation } from 'react-i18next';
import DateDisplay from '../../Displays/DateDisplay';
import { FormActions } from '../../Forms/FormActions';
import EditButton from '../../Buttons/EditButton';
import { CardActions } from '../../Card/CardActions';
import DestroyButtonIcon from '../../Buttons/Destroy/DestroyButtonIcon';
import UserMessageService from '../../../../services/userMessageService';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  message: Yup.string().required().max(500).nullable()
})

export const ListItemUserMessage = ({ userMessage , modelId, modelType, onUpdate, onDelete }) => {

  const { t } = useTranslation();
  const [edit, setEdit] = useState(false)

  const handleSubmit = (values, { setSubmitting }) =>
    UserMessageService.update(modelId, modelType, userMessage.uuid, values)
    .then(res => onUpdate(res))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(s => false)
      setEdit(e => false)
    })

  const handleDelete = () => UserMessageService.destroy(modelId, modelType, userMessage.uuid)
  .then(res => onDelete(userMessage))
  .catch(err => toast.error(err?.response?.data?.message))


  const formik = useFormik({
    initialValues: { message: userMessage?.message ?? '' },
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });


  return (
    <li className="list-group-item px-0 py-1">
      <CardContentBetween>
        <UserDisplay user={userMessage.user} small={true}/>
        <CardActions>
          <div className='small'>
            <DateDisplay value={userMessage.updated_at} formatString={"dd/MM/yyyy HH:ii:ss"}/>
          </div>
          {!edit && <EditButton onClick={() => setEdit(true)}/>}
        </CardActions>
      </CardContentBetween>
      <FormikProvider value={formik}>
        <Form>
          {edit ? <>
              <FormGroup name="message" label={t('user_messages.sing')} required>
                <FormInput name="message" rows={5} as="textarea"/>
              </FormGroup>
              <FormActions withReset={true} onCancel={() => setEdit(false)}>
                <DestroyButtonIcon onClick={handleDelete}/>
              </FormActions>
            </>
            : <>{formik.values.message}</>
          }
        </Form>
      </FormikProvider>
    </li>
  )

}

