import React from 'react';
import { NumericFormat } from 'react-number-format';

function PercentageDisplay({ value }) {

  return <span className='text-nowrap'>
    {value || value === 0 ? <NumericFormat
      value={value}
      displayType={"text"}
      decimalSeparator=','
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator='.'
      suffix={" %"}
    /> : <>---</>}
  </span>
}

export default PercentageDisplay;
