import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../../../components/UI/Drawer/Drawer';
import * as Yup from 'yup';
import worksService from '../../../../../services/works/worksService';
import { toast } from 'react-toastify';
import { Form, FormikProvider, useFormik } from 'formik';
import { FormInput } from '../../../../../components/UI/Forms/FormInput';
import FormGroup from '../../../../../components/UI/Forms/FormGroup';
import { format, parseJSON } from 'date-fns';
import { WorkContext } from '../../WorkContext/WorkContext';
import { AppContext } from '../../../../../components/AppContext';
import { FormCheckbox } from '../../../../../components/UI/Forms/FormCheckbox';
import CreatableSelect from 'react-select/creatable';
import { FormActions } from '../../../../../components/UI/Forms/FormActions';

const WorkDetailsDrawer = ({ isOpen, setIsOpen, onUpdate }) => {

  const { t } = useTranslation();
  const { work } = useContext(WorkContext);
  const { setLoadingOverlayState } = useContext(AppContext)

  const initialValues = {
    description: work?.description ?? "",
    forecast_date: work?.forecast_date ? format(parseJSON(work.forecast_date), 'yyyy-MM-dd') : '',
    delivery_date: work?.delivery_date ? format(parseJSON(work.delivery_date), 'yyyy-MM-dd') : '',
    has_new_guide: !!work?.has_new_guide,
    note: work?.note ?? "",
    tags: work?.tags ?? []
  }
  const validationSchema = Yup.object().shape({
    description: Yup.string().max(255).required(),
    forecast_date: Yup.date().nullable().notRequired(),
    delivery_date: Yup.date().nullable().notRequired(),
    has_new_guide: Yup.boolean().nullable().required(),
    note: Yup.string().max(500).nullable().notRequired(),
    tags: Yup.array().nullable().notRequired().of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string().max(50)
      })
    )
  })

  const handleSubmit = (values, { setSubmitting }) => {
    setLoadingOverlayState(true)
    worksService.update(work.uuid, values)
    .then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('details')}
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="description" label={t('description')} required={true} inline={false}>
            <FormInput name="description"/>
          </FormGroup>
          <div className='row'>
            <div className="col-lg-6">
              <FormGroup name='forecast_date' label={t('works.forecast_date')} required={false} inline={false}>
                <FormInput name="forecast_date" type="date"/>
              </FormGroup>
            </div>
            <div className="col-lg-6">
              <FormGroup name='delivery_date' label={t('works.delivery_date')} required={false} inline={false}>
                <FormInput name="delivery_date" type="date"/>
              </FormGroup>
            </div>
          </div>
          <FormGroup name="tags" label={t('Tags')} required={false} inline={false}> <CreatableSelect
            isMulti={true}
            options={formik.values.tags}
            value={formik.values.tags}
            onChange={value => formik.setFieldValue("tags", value)}
            isClearable={true}
            placeholder={t('Tags')}
          />
          </FormGroup>
          <FormGroup name="has_new_guide" label={t('works.has_new_guide')} inline={false}>
            <FormCheckbox
              name='has_new_guide'
              label={formik.values.has_new_guide ? 'Si' : 'No'}
            />
          </FormGroup>
          <FormGroup name="note" required={false} label={t('note')}>
            <FormInput rows={10} as='textarea' name="note"/>
          </FormGroup>

          <FormActions
            withReset={true}
            onCancel={() => setIsOpen(false)}
          />
        </Form>
      </FormikProvider>

    </Drawer>
  )

}

export default WorkDetailsDrawer
