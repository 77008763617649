import api from '../../configs/api.config';
import PartTypeEnum from '../../constants/enums/PartTypeEnum';

const url = '/parts';
const fetchAll = (params) => api.get(url, { params }).then((res) => res.data);
const fetch = (id) => api.get(`${url}/${id}`).then((res) => res.data.data);
const state = (id) => api.get(`${url}/${id}/state`).then((res) => res.data.data);
const changeState = (id, state) => api.post(`${url}/${id}/change-state`, { state: state?.value })
.then((res) => res.data.data);
const store = (type, params) => {
  let newUrl = url;
  switch (type) {
    case PartTypeEnum.values.WITH_MEASURE:
      newUrl += '/with-measure';
      break;
    case PartTypeEnum.values.WITHOUT_MEASURE:
      newUrl += '/without-measures';
      break;
    case PartTypeEnum.values.ASSEMBLY:
      newUrl += '/assemblies';
      break;
    case PartTypeEnum.values.CUSTOM:
      newUrl += '/customs';
      break;
    case PartTypeEnum.values.JOHN_DOE:
      return '';
    case PartTypeEnum.values.EXCLUSIVE:
      newUrl += '/exclusives';
      break;
    default:
      return '';
  }

  return api.post(newUrl, params).then((res) => res.data.data);
};
const update = (id, params) => api.patch(`${url}/${id}`, params).then((res) => res.data.data);
const destroy = (id) => api.delete(`${url}/${id}`).then((res) => res.data.data);
const partTrends = (id, params) => api.get(`${url}/${id}/part-trends`, { params }).then((res) => res.data);
const vaultSync = uuid => api.get(`${url}/${uuid}/vault-sync`);
const massiveUpdatePartCategory = params =>  api.post(`${url}/part-category`, params).then((res) => res.data);
const massiveUpdatePartMargin = params => api.post(`${url}/part-margin`, params).then((res) => res.data);

const partsService = {
  fetchAll,
  fetch,
  state,
  changeState,
  store,
  update,
  destroy,
  partTrends,
  vaultSync,
  massiveUpdatePartCategory,
  massiveUpdatePartMargin
};

export default partsService;
