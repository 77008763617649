import React, { useEffect, useState } from 'react';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import { useTranslation } from 'react-i18next';
import EuroDisplay from '../../../components/UI/Displays/EuroDisplay';

const RealTimePartPriceCalculator = ({
                                       price = 0,
                                       cost = 0,
                                       margin,
                                       part_category_id,
                                       categories,
                                     }) => {

  const { t } = useTranslation()
  const [realTimeCalculatedPriceByMargin, setRealTimeCalculatedPriceByMargin] = useState(0)
  const [realTimeCalculatedPriceByPartCategory, setRealTimeCalculatedPriceByPartCategory] = useState(0)
  const [recommendedPrice, setRecommendedPrice] = useState(0)

  const getPartCategoryMargin = id => id ? categories.find(c => c.value === id)?.margin : 0
  const getPartMargin = () => margin ? margin / 100 : 0;
  const getCalculatedPrice = margin => cost ? (cost / (1 - margin)) / 0.425 : 0;
  const formatNumber = num => num === Infinity || num < 0 ? 0 : Math.round((num + Number.EPSILON) * 100) / 100;

  useEffect(() => {
    setRealTimeCalculatedPriceByMargin(() => formatNumber(getCalculatedPrice(getPartMargin())))
    setRealTimeCalculatedPriceByPartCategory(() => formatNumber(getCalculatedPrice(getPartCategoryMargin(part_category_id))))
  }, [price, cost, margin, part_category_id]);

  useEffect(() => {
    if (margin) {
      setRecommendedPrice(() => realTimeCalculatedPriceByMargin)
    } else if (part_category_id) {
      setRecommendedPrice(() => realTimeCalculatedPriceByPartCategory)
    } else {
      setRecommendedPrice(() => undefined)
    }

  }, [realTimeCalculatedPriceByMargin, realTimeCalculatedPriceByPartCategory])

  return (
    <FormDisplay label={t('parts.recommended price')}>
      <EuroDisplay value={recommendedPrice}/>
    </FormDisplay>
  )
}

export default RealTimePartPriceCalculator
