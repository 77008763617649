import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { reactSelectStyles } from '../../../configs/reactSelect.config';

const MultipleTextFilter = ({ name, label, setValue, value, handleSubmit }) => {

  const { t } = useTranslation()

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.target.value.trim()) {
      handleSubmit()
    }
  };

  return (
    <CreatableSelect
      onKeyDown={handleKeyDown}
      isMulti
      className='bg-white'
      value={value}
      onChange={value => setValue(name, value)}
      isClearable={true}
      placeholder={label}
      styles={reactSelectStyles}
      noOptionsMessage={() => t('type_and_press_enter')}
    />
  )

}

export default MultipleTextFilter
