import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, FormGroup, Label, } from 'reactstrap';
import { get, map } from 'lodash';
import localesConfig from '../../../locales/locales.config';
import { toast } from 'react-toastify';
import { AppContext } from '../../../components/AppContext';

function UserSettings() {
  const { t } = useTranslation();
  const { availableTranslations, languages } = localesConfig;
  const { setCurrentLocale } = useContext(AppContext);
  const [currentApplicationLanguage, setCurrentApplicationLanguage] = useState(localStorage.getItem(
    localesConfig.I18N_LANGUAGE));
  const [currentTranslationLanguage, setCurrentTranslationLanguage] = useState(localStorage.getItem(
    localesConfig.TRANSLATION_LANGUAGE));

  const setApplicationLanguage = (locale) => {
    localStorage.setItem(localesConfig.I18N_LANGUAGE, locale);
    setCurrentApplicationLanguage(locale);
    toast.success(t('updated'))
    setCurrentLocale(locale)
  };


  const setTranslationLanguage = (locale) => {
    localStorage.setItem(localesConfig.TRANSLATION_LANGUAGE, locale);
    setCurrentTranslationLanguage(locale);
    toast.success(t('updated'))
  };

  return (
    <Card>
      <CardBody>
        <h5>{t('Settings')}</h5>

        <FormGroup>
          <Label>{t('settings.application_language')}</Label>
          <div className="d-flex align-content-center justify-content-start gap-2">
            {map(Object.keys(languages), (key) => (
              <div className="form-check" key={key}>
                <input
                  checked={currentApplicationLanguage === key}
                  name="application_language"
                  value={key}
                  type="radio"
                  className="form-check-input"
                  onChange={(event) => setApplicationLanguage(event.target.value)}
                />
                <label>
                  <img src={get(languages, `${key}.flag`)} height="16" className="me-1"/>
                  {get(languages, `${key}.label`)}
                </label>
              </div>
            ))}
          </div>
        </FormGroup>

        <FormGroup>
          <Label>{t('settings.translation_language')}</Label>
          <div className="d-flex align-content-center justify-content-start gap-2">
            {availableTranslations.map((translation, key) => (
              <div className="form-check" key={key}>
                <input
                  checked={currentTranslationLanguage === translation.locale}
                  name="translation_language"
                  value={translation.locale}
                  type="radio"
                  className="form-check-input"
                  onChange={(event) => setTranslationLanguage(event.target.value)}
                />
                <label>
                  <img src={translation.flag} height="16" className="me-1"/>
                  {translation.label}
                </label>
              </div>
            ))}
          </div>
        </FormGroup>

      </CardBody>
    </Card>
  );
}

export default UserSettings;
