import React, { useContext, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import worksService from '../../../../../services/works/worksService';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import WorkStateChangerOffCanvas from './WorkStateChangerOffCanvas';
import { toast } from 'react-toastify';
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';

const WorkProductionStateChanger = () => {


  const { work, production_states, dispatchAction } = useContext(WorkContext)
  const { t } = useTranslation()

  const [newState, setNewState] = useState(undefined)
  const [isOpen, setIsOpen] = useState(false);
  const [isCanvasOpen, setIsCanvasOpen] = useState(false)

  const handleChangeCancelled = () => {
    setNewState(undefined)
  }

  const { runAsync: handleChangeState } = useRequest(worksService.changeProductionState, { manual: true });
  const { runAsync: handleHistoryData } = useRequest(worksService.statesHistory, { manual: true })

  useEffect(() => {
    if (newState === undefined) {
      setIsCanvasOpen(false);
    } else {
      if (newState.reason) {
        setIsCanvasOpen(newState.reason)
      } else {
        handleChangeState(work?.uuid, {
          state: newState.value
        })
        .then(res => handleSuccess(res))
        .catch((err) => toast.error(err?.response?.data?.message))
        .finally(() => setNewState(undefined))
      }
    }
  }, [newState])

  const handleSuccess = res => {
    setNewState(undefined)
    dispatchAction({
      type: workActionTypes.UPDATE_PRODUCTION_STATES,
      payload: res
    })
    handleHistoryData(work?.uuid).then(res => dispatchAction({
      type: workActionTypes.INIT_HISTORY,
      payload: res
    })).catch((err) => toast.error(err?.response?.data?.message))
  }


  return (
    <>
      {
        production_states === undefined
          ? <Spinner size="sm"/>
          : <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle caret color={production_states?.state?.color}>
              {t(`enums.work_production_state.${production_states?.state?.value}`)}
              {production_states?.available_states?.length > 0 &&
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>}
            </DropdownToggle>
            <DropdownMenu end>
              {production_states?.available_states?.length > 0 && production_states?.available_states.map((state) =>
                <DropdownItem
                  key={state.value}
                  onClick={() => setNewState(state)}>
                  {t(`enums.work_production_state.${state.value}`)}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
      }

      <WorkStateChangerOffCanvas
        work={work}
        newState={newState}
        oldState={production_states?.state}
        isOpen={isCanvasOpen}
        onCancel={handleChangeCancelled}
        onSuccess={handleSuccess}
        onChangeState={handleChangeState}
      />

    </>
  )


}

export default WorkProductionStateChanger
