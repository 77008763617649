import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import EuroDisplay from '../../../../../components/UI/Displays/EuroDisplay';
import * as Yup from 'yup';
import { OfferContext } from '../../offerContext/offerContext';
import { AppContext } from '../../../../../components/AppContext';
import offerRevisionsService from '../../../../../services/offers/offerRevisionsService';
import { offerActionTypes } from '../../offerContext/offerReducer/offerActionTypes';
import { toast } from 'react-toastify';
import { FormPercentage } from '../../../../../components/UI/Forms/FormPercentage';
import { CardSmMargin } from '../../../../../components/UI/Card/CardSmMargin';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import FormikSaveButtonIcon from '../../../../../components/UI/Buttons/Save/FormikSaveButtonIcon';
import { FormNumber } from '../../../../../components/UI/Forms/FormNumber';
import { FormEuro } from '../../../../../components/UI/Forms/FormEuro';
import PercentageDisplay from '../../../../../components/UI/Displays/PercentageDisplay';
import NumberDisplay from '../../../../../components/UI/Displays/NumberDisplay';


const validationSchema = Yup.object().shape({
  hour_amount: Yup.number().min(0).nullable().required(),
  hour_cost: Yup.number().min(0).nullable().required(),
  project_margin: Yup.number().min(0).max(100).nullable().required(),
})

const SubOfferProjectItem = ({ offerRevision }) => {


  const { offer, dispatchAction, revision_editable } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)
  const { t } = useTranslation()

  const initialValues = {
    hour_amount: offerRevision?.hour_amount ?? 0,
    hour_cost: offerRevision?.hour_cost ?? 0,
    project_margin: offerRevision?.project_margin * 100 ?? 0,
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setLoadingOverlayState(true)
    offerRevisionsService.update(offer.uuid, offer.offer_revision.uuid, {
      ...values,
      project_margin: values.project_margin / 100,
      discount: offerRevision.discount * 100
    })
    .then(res => dispatchAction({
      type: offerActionTypes.UPDATE_REVISION,
      payload: res
    }))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <CardSmMargin>
          <CardBody>
            <div className='d-flex justify-content-between align-items-center gap-4'>

              <div className='d-flex flex-column'>
                <div className='d-flex flex-nowrap align-items-center justify-content-start fs-5 gap-2'>
                  <div className='fw-bold '>{t('sub_offers.project')}</div>
                  {revision_editable && <FormikSaveButtonIcon/>}
                </div>
                <div>{t('sub_offers.project_description')}</div>
              </div>
              <div className='d-flex justify-content-end gap-4 flex-wrap text-end'>

                <div className='d-flex justify-content-start justify-content-end gap-4' style={{ minWidth: "100px" }}>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small text-success'>{t('sub_offers.offer_sub_project_margin')}</span>
                    {revision_editable ? <FormPercentage
                      name='margin'
                      min={0}
                      max={100}
                      step={1}
                      value={formik.values.project_margin}
                    /> : <PercentageDisplay value={formik.values.project_margin}/>}
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small text-success'>{t('sub_offers.offer_sub_min_sell_hour_amount')}</span>
                    {revision_editable ? <FormNumber
                      name='hour_amount'
                      min={0}
                      step={0.5}
                      value={formik.values.hour_amount}
                    /> : <NumberDisplay value={formik.values.hour_amount}/>}
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small text-success'>{t('sub_offers.offer_sub_min_sell_hour_cost')}</span>
                    {revision_editable ? <FormEuro
                      name='hour_amount'
                      min={0}
                      step={0.01}
                      value={formik.values.hour_cost}
                    /> : <EuroDisplay value={formik.values.hour_cost}/>}
                  </div>
                </div>

                <div className='d-flex justify-content-start justify-content-end gap-4' style={{ minWidth: "100px" }}>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offers.offer_sub_min_sell_project_cost')}</span>
                    <span className='fw-bold'><EuroDisplay value={offerRevision.project_cost}/></span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offers.offer_sub_min_sell_project_net_cost')}</span>
                    <span className='fw-bold'><EuroDisplay value={offerRevision.project_net_cost}/></span>
                  </div>
                </div>

              </div>


            </div>
          </CardBody>
        </CardSmMargin>
      </Form>
    </FormikProvider>
  )

}

export default SubOfferProjectItem
