import api from '../../configs/api.config';

const buildUrl = (partUuid, partBomUuid) => partBomUuid
  ? `/parts/${partUuid}/part-boms/${partBomUuid}`
  : `/parts/${partUuid}/part-boms`;

const fetchAll = (partUuid, params) => api.get(buildUrl(partUuid), { params })
.then((res) => res.data);
const fetch = (partUuid, partBomUuid) => api.get(buildUrl(partUuid, partBomUuid))
.then((res) => res.data.data);
const updateRow = (partUuid, partBomUuid, partBomRowUuid, params) => api.patch(`${buildUrl(partUuid,
  partBomUuid)}/part-bom-rows/${partBomRowUuid}`, params).then((res) => res.data.data);
const storeUrl = (partUuid) => buildUrl(partUuid);

const partBomService = {
  fetchAll,
  fetch,
  storeUrl,
  updateRow,
};

export default partBomService;
