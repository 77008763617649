import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../components/UI/Drawer/Drawer';
import PercentageDisplay from '../../../components/UI/Displays/PercentageDisplay';
import * as Yup from 'yup';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormPercentage } from '../../../components/UI/Forms/FormPercentage';
import { Form, FormikProvider, useFormik } from 'formik';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import partsService from '../../../services/parts/partsService';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  margin: Yup.number().min(0).max(100).nullable(),
  ids: Yup.array().min(1)
})

const MassiveUpdatePartMarginDrawer = ({ isOpen, setIsOpen, selectedParts = [], onUpdate }) => {

  const { t } = useTranslation()

  const initialValues = {
    margin: "",
    ids: selectedParts.map(sp => sp.id)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      partsService.massiveUpdatePartMargin(values)
      .then(() => {

        setSubmitting(false)
        resetForm();
        toast.success(t('updated'))
        onUpdate()
      })
      .catch((err) => toast.error(err?.response?.data?.message))
    }
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title={t('massive_update_part_margin')}
    >
      <FormikProvider value={formik}>
        <Form>
          <ul className='list-group list-group-flush'>
            {selectedParts.map(sp =>
              <li className='list-group-item px-0' key={sp.uuid}>
                <div className='fw-bold'>{sp.code}</div>
                <div>{sp.description}</div>
                <div>{t('part_categories.sing')} <span className='fw-bold' style={{ color: sp.part_category?.color }}>{sp.part_category?.label}</span></div>
                <div>{t('parts.margin')} <span className='fw-bold'><PercentageDisplay value={sp.margin * 100}/></span></div>
              </li>
            )}
          </ul>
          <FormGroup name='margin' label={t('parts.margin')}>
            <FormPercentage name='margin'/>
          </FormGroup>
          <div className="d-flex align-items-center justify-content-between">
            <CancelButton onClick={() => setIsOpen(false)}/>
            <FormikSaveButton/>
          </div>
        </Form>
      </FormikProvider>
    </Drawer>
  )

}

export default MassiveUpdatePartMarginDrawer
