import api from '../configs/api.config';

const fetchColorParts = () => api.get('/color-parts').then(res => res.data.data);

const utilityService = {
  fetchColorParts
}

export default utilityService;


