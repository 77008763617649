import React from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import partCategoriesService from '../../../services/parts/partCategoriesService';
import PartCategoryParts from './PartCategoryParts';
import PartCategoryDetails from './PartCategoryDetails';

const PartCategory = () => {


  const { id } = useParams()
  const { data: partCategory, mutate } = useRequest(partCategoriesService.fetch, { defaultParams: [id] })

  return (
    <>
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex align-items-center justify-content-between gap-4'>
          <div>
            <div className='fs-3 fw-bolder text-dark'>{partCategory?.label}</div>
          </div>
        </div>

        <div className='row'>
          <div className="col-xl-3 col-lg-4 ">
            <PartCategoryDetails
              partCategory={partCategory}
              setPartCategory={mutate}
            />
          </div>
          <div className="col-xl-9 col-lg-8 ">
            <PartCategoryParts partCategoryId={partCategory?.id}/>
          </div>
        </div>
      </div>


    </>
  )

}

export default PartCategory
