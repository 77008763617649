import React, { useEffect } from 'react';
import { useRequest } from 'ahooks';
import partBomService from '../../../../services/parts/partBomsService';
import PartBomsService from '../../../../services/parts/partBomsService';
import { FormFile } from '../../../../components/UI/Forms/FormFile/FormFile';
import PartBom from './PartBom';
import EmptyDisplay from '../../../../components/UI/Displays/EmptyDisplay';
import PartBomNavigation from './PartBomNavigation';
import templatesService from '../../../../services/parts/templatesService';
import DownloadButtonIcon from '../../../../components/UI/Buttons/Download/DownloadButtonIcon';
import { UncontrolledTooltip } from 'reactstrap';

const PartBoms = ({ part }) => {

  const { data = { data: [], links: {}, meta: {} }, run } = useRequest(partBomService.fetchAll, { manual: true })

  const [params, setParams] = React.useState({
    per_page: 1,
    page: 1
  })

  const handleRun = () => {
    run(part?.uuid, params)
  }

  useEffect(() => {
    if (part?.uuid) {
      handleRun()
    }
  }, [part?.uuid, params])

  return (
    <div className='card w-100'>
      <div className='card-body'>
        <div className='d-flex justify-content-between align-items-center gap-4'>
          <div>
            <PartBomNavigation
              paginationResponse={data}
              onPrevClicked={(pageNumber) => setParams({ ...params, page: pageNumber })}
              onCurrentClicked={(pageNumber) => setParams({ ...params, page: pageNumber })}
              onNextClicked={(pageNumber) => setParams({ ...params, page: pageNumber })}
            />
          </div>

          <div className="d-flex justify-content-start align-items-center gap-2">
            <UncontrolledTooltip target='download_template_button'>
              Scarica il template di esempio in formato Excel
            </UncontrolledTooltip>
            <DownloadButtonIcon id='download_template_button' onClick={() => templatesService.partBom()}/>
            <FormFile
              name='file'
              url={PartBomsService.storeUrl(part?.uuid)}
              onUploadFinished={(res) => setParams({ ...params, page: 1 })}
            />
          </div>

        </div>

        {data && data.data.length > 0 ? <PartBom part={part} partBom={data?.data[0]} onRowUpdated={handleRun}/> : <EmptyDisplay/>}

      </div>
    </div>
  )

}

export default PartBoms
