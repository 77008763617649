import React from 'react';

export const PageHeader = ({
                             title = "",
                             subTitle = "",
                             children = undefined
                           }) => {
  return (
    <div className='d-flex align-items-center justify-content-between gap-4 mb-3'>
      <div>
        {!!title && <div className='fs-3 fw-bold text-dark'>{title}</div>}
        {!!subTitle && <div className='fs-5'>{subTitle}</div>}
      </div>
      {children}
    </div>
  )
}
