import React from 'react';
import { useTranslation } from 'react-i18next';

function DefaultButtonIcon(props) {
  const { t } = useTranslation();

  return (
    <button className="btn btn-icon btn-soft-secondary" type='button'  {...props}>
      <i className={`fa fa-${props.icon}`}/>
    </button>
  );
}

export default DefaultButtonIcon;
