import React, { useMemo } from 'react';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';
import partTableConfig from '../../../Parts/components/partTableConfig';
import { useTranslation } from 'react-i18next';

function PartList({ parts = [], loading }) {

  const {t} = useTranslation()
  const columns = useMemo(() => partTableConfig, []);

  return (
    <div className='card'>
      <div className='card-body'>
        <h5>{t('parts.plural')}</h5>
        <PaginatedTable
          isLoading={loading}
          options={paginatedTableConfig}
          sortable={false}
          data={parts}
          columns={columns}
        />
      </div>
    </div>
  );
}

export default PartList;
