import React, { useMemo } from 'react';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';
import companiesService from '../../../services/companies/companiesService';
import { useTranslation } from 'react-i18next';
import { CompanyDetails } from './CompanyDetails/CompanyDetails';
import { CompanyPersons } from './CompanyPersons/CompanyPersons';
import { CompanyLocations } from './CompanyLocations/CompanyLocations';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../../configs/paginatedTable.config';
import worksService from '../../../services/works/worksService';
import workTableConfig from '../../Works/Works/workTable.config';
import { DefaultCompanyLocation } from './DefaultCompanyLocation/DefaultCompanyLocation';
import { CompanyPriceList } from './CompanyPriceList/CompanyPriceList';
import { Card } from '../../../components/UI/Card/Card';
import { CardBody } from '../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../components/UI/Card/CardLabel';
import { PageHeader } from '../../../components/UI/Page/PageHeader';

function Company() {

  const { id } = useParams();
  const { t } = useTranslation()
  const { data: company, mutate: setCompany } = useRequest(companiesService.fetch, { defaultParams: [id] });

  const { data = [], loading, run } = useRequest(worksService.fetchAll, { manual: true });
  const columns = useMemo(() => workTableConfig, []);


  const handleLoadWorks = params => run({
    ...params,
    filter: {
      ...params.filter,
      companies: [company.id]
    }
  });

  return company ? (
    <>
      <PageHeader title={company.business_name}/>

      <div className='d-flex flex-column gap-4'>

        <div className='row'>
          <div className="col-xl-3 col-lg-4">

            <div className='row'>
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch align-items-lg-start'>
                <CompanyDetails
                  company={company}
                  setCompany={setCompany}
                />
              </div>
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch align-items-lg-start'>
                <CompanyPriceList
                  company={company}
                  setCompany={setCompany}
                />
              </div>
            </div>

          </div>
          <div className="col-xl-9 col-lg-8">

            <div className='row'>
              <div className='col-md-6 d-flex d-block align-items-stretch align-items-start'>
                <DefaultCompanyLocation
                  company={company}
                  setCompany={setCompany}
                />
              </div>
              <div className='col-md-6 d-flex d-block align-items-stretch align-items-start'>
                <CompanyPersons
                  companyUuid={company.uuid}
                  locations={company.company_locations}
                  setCompany={setCompany}
                  persons={company.company_persons}
                />
              </div>
            </div>

            <CompanyLocations
              companyUuid={company.uuid}
              setCompany={setCompany}
              locations={company.company_locations.filter(cl => !cl.default)}
            />

            <Card>
              <CardBody>
                <CardLabel>{t('works.plural')}</CardLabel>
                <PaginatedTable
                  options={paginatedTableConfig}
                  isLoading={loading}
                  data={data}
                  columns={columns}
                  sortable
                  onParamsChange={handleLoadWorks}
                />
              </CardBody>
            </Card>

          </div>
        </div>

      </div>
    </>
  ) : <></>;
}

export default Company;
