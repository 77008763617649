import React from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert2';

function DestroyButton(props) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    SweetAlert.fire({
      text: props.message || t('are_you_sure'),
      icon: 'warning',
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        props.onClick();
      }
    });
  };

  return (
    <button type='button' className={`btn btn-soft-danger ${props?.size}`} {...props} onClick={() => handleConfirm()}>
      {t('delete')}
    </button>
  );
}

export default DestroyButton;
