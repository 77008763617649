import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const FormikResetButton = props => {
  const { t } = useTranslation();
  const { isSubmitting, dirty, resetForm } = useFormikContext();

  return (
    <button
      className='btn btn-soft-secondary'
      disabled={isSubmitting || !dirty}
      type="button"
      onClick={() => resetForm()}
      {...props}
    >
      {t('reset')}
    </button>
  );
}

export default FormikResetButton;
