import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function BoolDisplay({ value }) {

  return value
    ? <span className='fa fa-check text-success' />
    : <span className='fa fa-times text-danger' />
}

export default BoolDisplay;
