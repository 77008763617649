import React from 'react';
import { DropdownToggle } from 'reactstrap';

const DropdownToggleButton = () => {

  return (
    <DropdownToggle caret className='btn btn-icon btn-soft-secondary '>
      <i className='fa fa-ellipsis-v px-1' />
    </DropdownToggle>
  )

}

export default DropdownToggleButton
