import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StandardDropdownCreate = ({ rowSelected, setIsMassiveUpdatePartCategoryDrawerOpen, setIsMassiveUpdatePartMarginDrawerOpen }) => {

  const { t } = useTranslation()

  return (
    <>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary">
          <i className="fa fa-plus me-1"/>
          {t('new')}
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem>
            <Link to="../create-with-measure">
              {t('enums.part_types.WITH_MEASURE')}
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="../create-without-measure">
              {t('enums.part_types.WITHOUT_MEASURE')}
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="primary" disabled={rowSelected.length < 1}>
          {t('edit')} <span className='badge bg-white text-primary'>{rowSelected.length}</span>
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={() => setIsMassiveUpdatePartCategoryDrawerOpen(true)}>
            {t('massive_update_part_category')}
          </DropdownItem>
          <DropdownItem onClick={() => setIsMassiveUpdatePartMarginDrawerOpen(true)}>
            {t('massive_update_part_margin')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </>
  )

}

export default StandardDropdownCreate
