import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import partsService from '../../../../services/parts/partsService';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';

function PartPicker({ handleChange, defaultParams }) {

  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(partsService.fetchAll, { manual: true });
  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({
      filter: {
        ...defaultParams ?? {},
        q: value || ''
      },
    }).then((res) => callback(res.data))
    .catch(() => callback([]));
  };

  const onChange = (value) => {
    if (value) {
      handleChange(value);
    }
  };

  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
      <AsyncSelect
        cacheOptions
        className='bg-white'
        loadOptions={handleLoadOptions}
        isLoading={loading}
        isClearable
        onChange={onChange}
        loading={loading}
        placeholder={t('parts.sing')}
        getOptionLabel={(option) => `${option.code} - ${option.description}`}
        getOptionValue={(option) => option.id}
        styles={reactSelectStyles}
        maxMenuHeight={'120px'}
        multiple={false}
        noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
      />
  );
}

export default PartPicker;
