const indeterminate = 'indeterminate';
const raw = 'raw';
const painting = 'painting';
const mount = 'mount';
const shipment = 'shipment';
const installation = 'installation';
const delivered = 'delivered';

const values = {
  indeterminate,
  raw,
  painting,
  mount,
  shipment,
  installation,
  delivered
};
const collection = [
  { value: indeterminate, label: 'enums.work_production_state.indeterminate', color: 'light' },
  { value: raw, label: 'enums.work_production_state.raw', color: 'primary' },
  { value: painting, label: 'enums.work_production_state.painting', color: 'primary' },
  { value: mount, label: 'enums.work_production_state.mount', color: 'primary' },
  { value: shipment, label: 'enums.work_production_state.shipment', color: 'info' },
  { value: installation, label: 'enums.work_production_state.installation', color: 'info' },
  { value: delivered, label: 'enums.work_production_state.delivered', color: 'success' }
];

const WorkProductionStateEnum = {
  values,
  collection,
};

export default WorkProductionStateEnum;
