import React from 'react';
const SelectedItem = ({label, onDelete}) => {

  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <button
        className='btn btn-soft-danger btn-icon btn-sm '
        type='button'
        onClick={onDelete}>
        <i className='fa fa-times'/>
      </button>
      <span>{label}</span>
    </div>
  )

}

export default SelectedItem
