import React, { useMemo } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import formConfig from '../../components/form.config';
import { FormDisplay } from '../../../../components/UI/Displays/FormDisplay';
import PartTypeEnumDisplay from '../../../../components/UI/Displays/Parts/PartTypeEnumDisplay';
import CodeTypeEnumDisplay from '../../../../components/UI/Displays/CodeTypeEnumDisplay';
import CancelButton from '../../../../components/UI/Buttons/CancelButton';
import FormikResetButton from '../../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../../components/UI/Buttons/Save/FormikSaveButton';
import SimilarCodes from '../../components/SimilarCodes';
import codesService from '../../../../services/parts/codesService';
import DisplayFormattedMeasures from '../../../Parts/creates/components/DisplayFormattedMeasures';
import WordsSort from '../../components/WordsSort/WordsSort';
import PartTypeEnum from '../../../../constants/enums/PartTypeEnum';
import TranslatableDisplay from '../../../../components/UI/Displays/TranslatableDisplay';

const CodeEdit = ({ data, onUpdate }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const initialValues = useMemo(() => (data ? formConfig.setInitialValues(data) : formConfig.initialValues), [data]);

  const handleSubmit = (values, { setSubmitting }) => codesService.update(data?.code_type, data.uuid, {
    words: values.words.map(w => w.value)
  }).then((res) => {
    toast.success(t('updated'));
    onUpdate({ ...data, ...res });
  })
  .catch((err) => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false));

  const formik = useFormik({
    initialValues,
    validationSchema: formConfig.updateValidation,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })


  return (
    <FormikProvider value={formik}>
      <Form>

        <div className='card'>
          <div className='card-body'>

            <div className='row'>
              <div className="col-xl-4">
                <FormDisplay label={t('codes.code')}>{data?.code}</FormDisplay>
              </div>
              <div className="col-xl-8">

              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4'>
                <h5>&nbsp;</h5>
                <FormDisplay label={t('part_type')}>
                  <PartTypeEnumDisplay type={data?.part_type}/>
                </FormDisplay>
                <FormDisplay label={t('code_type')}>
                  <CodeTypeEnumDisplay type={data?.code_type}/>
                </FormDisplay>
              </div>
              <div className='col-lg-4'>
                {data && data.part_type === PartTypeEnum.values.JOHN_DOE
                  ? <>
                    <TranslatableDisplay
                      currentTranslation={data.description}
                      translatableArray={data.descriptions}
                    />
                    <TranslatableDisplay
                      currentTranslation={data.short_description}
                      translatableArray={data.short_descriptions}
                    />
                  </>
                  : <WordsSort/>}
              </div>
              <div className='col-lg-4'>
                <SimilarCodes words={formik.values.words} id={data?.id}/>
              </div>
            </div>


            <div className="d-flex align-items-center justify-content-between">
              <CancelButton onClick={() => navigate('../')}/>
              <div className="d-flex gap-2">
                <FormikResetButton/>
                <FormikSaveButton/>
              </div>
            </div>
          </div>
        </div>

      </Form>
    </FormikProvider>
  );
}

export default CodeEdit;
