import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../../components/UI/Drawer/Drawer';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import companiesService from '../../../../services/companies/companiesService';
import { toast } from 'react-toastify';
import FormGroup from '../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../components/UI/Forms/FormInput';
import { contactsValidation } from '../Contacts/contacts.form';
import { ContactsArrayEditor } from '../Contacts/ContactsArrayEditor';
import CreatableSelect from "react-select/creatable";
import MarketPicker from '../../../../components/UI/Forms/Pickers/MarketPicker';
import { FormActions } from '../../../../components/UI/Forms/FormActions';

const validationSchema = Yup.object().shape({
  business_name: Yup.string().max(100).required(),
  market: Yup.string().notRequired().nullable(),
  sdi: Yup.string().max(10).nullable().notRequired(),
  sap_code: Yup.string().max(50).nullable().notRequired(),
  vat_number: Yup.string().max(20).nullable().notRequired(),
  fiscal_code: Yup.string().max(20).nullable().notRequired(),
  note: Yup.string().nullable().notRequired(),
  contacts: contactsValidation,
  tags: Yup.array().nullable().notRequired().of(
    Yup.object().shape({
      value: Yup.string(),
      label: Yup.string().max(50)
    })
  )
});

export const CompanyDetailsDrawer = ({ company, isOpen, setIsOpen, handleUpdate }) => {

  const { t } = useTranslation()

  const initialValues = {
    business_name: company.business_name ?? "",
    market: company.market?.value ?? undefined,
    sdi: company.sdi ?? "",
    sap_code: company.sap_code ?? "",
    vat_number: company.vat_number ?? "",
    fiscal_code: company.fiscal_code ?? "",
    note: company.note ?? "",
    contacts: company.contacts.map(c => ({
      id: c.id,
      label: c.label,
      value: c.value
    })),
    tags: company?.tags ?? []
  }

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => companiesService.update(company.uuid, values)
    .then(res => {
      setIsOpen(false)
      handleUpdate(res)
    })
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => setSubmitting(false))
  })

  return (
    <Drawer
      title={company.business_name}
      toggle={() => setIsOpen(false)}
      status={isOpen}>
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="business_name" label={t('companies.business_name')} required>
            <FormInput name="business_name"/>
          </FormGroup>

          <FormGroup name="market" label={t('market')} required={false} inline={false}>
            <MarketPicker name='market'/>
          </FormGroup>

          <FormGroup name="tags" label={t('Tags')} required={false} inline={false}>
            <CreatableSelect
              isMulti={true}
              options={formik.values.tags}
              value={formik.values.tags}
              onChange={value => formik.setFieldValue("tags", value)}
              isClearable={true}
              placeholder={t('Tags')}
            />
          </FormGroup>

          <div className='row'>
            <div className='col-lg-6'>
              <FormGroup name="vat_number" label={t('companies.vat_number')} required={false}>
                <FormInput name="vat_number"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="fiscal_code" label={t('companies.fiscal_code')} required={false}>
                <FormInput name="fiscal_code"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="sdi" label={t('companies.sdi')} required={false}>
                <FormInput name="sdi"/>
              </FormGroup>
            </div>
            <div className='col-lg-6'>
              <FormGroup name="sap_code" label={t('companies.sap_code')} required={false}>
                <FormInput name="sap_code"/>
              </FormGroup>
            </div>
          </div>

          <ContactsArrayEditor/>

          <FormGroup name="note" label={t('note')} required={false}>
            <FormInput name="note" as="textarea"/>
          </FormGroup>

          <FormActions
            onCancel={() => setIsOpen(false)}
            withReset={true}
          />

        </Form>
      </FormikProvider>
    </Drawer>
  )

}
