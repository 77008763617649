import React, { useContext } from 'react';
import { OfferContext } from '../../offerContext/offerContext';
import SubOfferSummaryItem from './SubOfferSummaryItem';
import EuroDisplay from '../../../../../components/UI/Displays/EuroDisplay';
import PercentageDisplay from '../../../../../components/UI/Displays/PercentageDisplay';
import { useTranslation } from 'react-i18next';
import SubOfferProjectItem from './SubOfferProjectItem';
import SubOfferToolsItem from './SubOfferToolsItem';

const SubOffersSummary = ({ offerRevisionUuid, isInvalid, handleTabSelected }) => {

  const { offer } = useContext(OfferContext)
  const { t } = useTranslation()


  return (
    <div className='mb-3'>

      <div className="card-group gap-1">

        <div className={`card my-1 text-white bg-opacity-75 bg-${isInvalid ? 'danger' : 'info'}`}>
          <div className='card-body text-end d-flex flex-column'>
            <span className='small'>{t('sub_offer_revisions.offer_rev_price_total')}</span>
            <span className='fs-5 fw-bold'><EuroDisplay value={offer?.offer_revision?.total_price}/></span>
            <span className='small'>{t('sub_offer_revisions.offer_rev_price_total_with_tools')}</span>
            <span className='fw-bold'><EuroDisplay value={offer?.offer_revision?.total_price_with_tools}/></span>
          </div>
        </div>

        <div className='card my-1 text-white bg-opacity-75 bg-info'>
          <div className='card-body text-end d-flex flex-column'>
            <span className='small'>{t('sub_offer_revisions.offer_rev_net_price_total')}</span>
            <span className='fs-5 fw-bold'><EuroDisplay value={offer?.offer_revision?.total_net_price}/></span>
            <span className='small'>Marg con sconto</span>
            <PercentageDisplay value={offer.offer_revision.discount_margin * 100}/>
            <span className='small'>Marg con sconto e progettazione</span>
            <PercentageDisplay value={offer.offer_revision.full_discount_margin * 100}/>
          </div>
        </div>

        <div className='card my-1 text-white bg-opacity-75 bg-success'>
          <div className='card-body text-end d-flex flex-column'>
            <span className='small'>{t('sub_offer_revisions.offer_rev_cost_total')}</span>
            <span className='fs-5 fw-bold'><EuroDisplay value={offer?.offer_revision?.total_cost}/></span>
            <span className='small'>{t('sub_offer_revisions.offer_rev_cost_full')}</span>
            <span className=' fw-bold'><EuroDisplay value={offer?.offer_revision?.full_cost}/></span>
          </div>
        </div>

        <div className='card my-1 text-white bg-opacity-75 bg-success'>
          <div className='card-body text-end d-flex flex-column'>
            <span className='small'>{t('sub_offer_revisions.offer_rev_min_sell_price_total')}</span>
            <span className='fs-5 fw-bold'><EuroDisplay value={offer?.offer_revision?.total_net_cost}/></span>
            <span className='small'>{t('sub_offer_revisions.margin')}</span>
            <span className='fw-bold'><PercentageDisplay value={offer?.offer_revision?.margin * 100}/></span>
            <span className='small'>{t('sub_offer_revisions.full_margin')}</span>
            <span className='fw-bold'><PercentageDisplay value={offer?.offer_revision?.full_margin * 100}/></span>
          </div>
        </div>

      </div>

      <SubOfferProjectItem offerRevision={offer?.offer_revision}/>
      {offer?.offer_revision?.sub_offers?.map((subOffer, index) =>
        <SubOfferSummaryItem
          handleTabSelected={() => handleTabSelected(index)}
          key={subOffer.uuid}
          subOffer={subOffer}
          offerRevisionUuid={offerRevisionUuid}
        />
      )}
      <SubOfferToolsItem offerRevision={offer?.offer_revision}/>


    </div>
  )

}

export default SubOffersSummary
