export const offerContextInitialState = {
  loading: false,
  revision_editable: false,
  offer: {
    discount: 0,
    total_price: 0,
    total_net_price: 0,
    total_cost: 0,
    total_net_cost: 0,
    sub_offers: []
  },
  current_selected_tab: "default"
}
