import React, { useContext, useEffect, useState } from 'react';
import { WorkContext } from '../../WorkContext/WorkContext';
import { get, groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import WorkMediaGroupedList from './WorkMediaGroupedList';
import { List } from '../../../../../components/UI/ItemList/List';

const WorkMediaVaultList = ({ media = [], onDownload, onDownloadMerged, onDelete, onDownloadBomsPdf, onDownloadColorConfig }) => {

  const { work } = useContext(WorkContext)
  const { t } = useTranslation()
  const [allMediaGroupedByType, setAllMediaGroupedByType] = useState({
    'customer': [],
    'production': [],
    'mounting': []
  })

  useEffect(() => {
    let allMedia = [...media];
    work.sub_works.map(sw => {
      allMedia = [...allMedia, ...get(sw, 'media', [])]
    })
    setAllMediaGroupedByType(m => groupBy(allMedia, m => {
      let name = m.name.toLowerCase();
      if (name.includes('produzione'))
        return 'production';
      else if (name.includes('montaggio'))
        return 'mounting'
      else
        return 'customer'
    }));
  }, [media])


  return (
    <List>
      <WorkMediaGroupedList
        label='customer_media'
        tooltipContent=' Scarica la documentazione tecnica di progetto destinata al Cliente finale'
        media={get(allMediaGroupedByType, 'customer', [])}
        onDelete={onDelete}
        onDownload={onDownload}
        onDownloadMerged={() => onDownloadMerged(0)}
      />
      <WorkMediaGroupedList
        label='production_media'
        tooltipContent=' Scarica la documentazione tecnica di progetto destinata alla produzione'
        media={get(allMediaGroupedByType, 'production', [])}
        onDelete={onDelete}
        onDownload={onDownload}
        onDownloadMerged={() => onDownloadMerged(1)}
        onDownloadBomsPdf={onDownloadBomsPdf}
        onDownloadColorConfig={onDownloadColorConfig}
      />
      <WorkMediaGroupedList
        label='mounting_media'
        tooltipContent=' Scarica la documentazione tecnica di progetto destinata al Cliente finale'
        media={get(allMediaGroupedByType, 'mounting', [])}
        onDelete={onDelete}
        onDownload={onDownload}
        onDownloadMerged={() => onDownloadMerged(0)}
      />
    </List>
  )

}

export default WorkMediaVaultList
