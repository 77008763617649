import React from 'react';

function NavBarDropdownTitle({
  state, handleState, label, icon,
}) {
  return (
    <div
      className="nav-link dropdown-toggle arrow-none cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        handleState(!state);
      }}
    >
      {icon && <i className={`${icon} me-2`} />}
      {label}
      {' '}
      {state}
      <div className="arrow-down" />
    </div>
  );
}

export default NavBarDropdownTitle;
