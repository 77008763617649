import React, { useMemo } from 'react';
import { useRequest } from 'ahooks';
import usersService from '../../services/usersService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateButton from '../../components/UI/Buttons/CreateButton';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import paginatedTableConfig from '../../configs/paginatedTable.config';
import userTableConfig from './components/userTableConfig';
import { Card } from '../../components/UI/Card/Card';
import { CardBody } from '../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../components/UI/Card/CardLabel';
import { UserDrawer } from './User/UserDrawer';

function Users() {

  const { data = [], loading, run } = useRequest(usersService.fetchAll, { manual: true })
  const { data: permissions = [] } = useRequest(usersService.fetchPermissions)
  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns = useMemo(() => userTableConfig, []);

  const handleCreate = user => {
    setIsOpen(o => false)
    navigate(user.uuid)
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('users.plural')}</CardLabel>
            <CreateButton onClick={() => setIsOpen(o => !o)}/>
          </CardContentBetween>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={run}
          />
        </CardBody>
      </Card>

      <UserDrawer
        user={undefined}
        permissions={permissions}
        onUpdate={handleCreate}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </>
  );
}

export default Users;
