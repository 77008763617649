import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../../components/UI/Buttons/TableLink';
import WorkStateEnumDisplay from '../../../../components/UI/Displays/WorkStateEnumDisplay';
import WorkStateEnum from '../../../../constants/enums/WorkStateEnum';

const workPartTableConfig = [{
  Header: <Trans i18nKey='works.sing'/>,
  accessor: 'number',
  Cell: ({ row, value }) =>
    <div className='d-flex flex-column'>
      <h3 className='mb-0'>
        <TableLink
          to={`/works/${row.original.uuid}`}
          value={value}
          className={`${row.original.state.state === WorkStateEnum.values.recovery && 'text-danger'}`}
        />
      </h3>
      {row.original.description}
    </div>
}, {
  Header: <Trans i18nKey="works.state"/>,
  accessor: 'state',
  disableSortBy: true,
  Cell: ({ value }) => <WorkStateEnumDisplay state={value}/>,
}, {
  Header: <Trans i18nKey='works.customer'/>,
  disableSortBy: true,
  accessor: 'company',
  Cell: ({ value, row }) =>
    <div className='d-flex flex-column'>
      <TableLink to={`/companies/${value?.company?.uuid}`} value={value?.business_name}/>
      {row.original.company_location &&
        <span className="fw-bold">{row.original.company_location?.label}</span>}
      {row.original.company_person && <span>{row.original.company_person?.full_name}</span>}
    </div>
}, {
  Header: <Trans i18nKey='works.sap_orders'/>,
  disableSortBy: true,
  accessor: 'sap_orders',
  Cell: ({ value }) =>
    <>{value.join(", ")}</>
}];

export default workPartTableConfig;
