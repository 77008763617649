import api from '../../configs/api.config';
import FileDownload from 'js-file-download';

const url = '/works';
const fetchAll = params => api.get(url, { params }).then(res => res.data);
const fetch = id => api.get(`${url}/${id}`).then(res => res.data.data);
const store = params => api.post(url, params).then(res => res.data.data);
const update = (id, params) => api.patch(`${url}/${id}`, params).then(res => res.data.data);
const destroy = id => api.delete(`${url}/${id}`).then(res => res.data.data);
const syncUsers = (id, params) => api.post(`${url}/${id}/users`, params).then(res => res.data)
const syncCompanies = (id, params) => api.post(`${url}/${id}/companies`, params).then(res => res.data.data)
const fetchAllSubWorks = (id) => api.get(`${url}/${id}/sub-works`).then(res => res.data.data)
const fetchSubWork = (id, subId) => api.get(`${url}/${id}/sub-works/${subId}`).then(res => res.data.data)
const toggleSubWorkActivation = (id, subId) => api.patch(`${url}/${id}/sub-works/${subId}`).then(res => res.data.data)
const downloadSubWorkBom = (id, subId, filename) => api.get(`${url}/${id}/sub-works/${subId}/export`, { responseType: 'blob' })
.then(res => FileDownload(res.data, `${filename}.xlsx`))
const fetchOffers = id => api.get(`${url}/${id}/offers`).then(res => res.data.data)
const storeOffer = id => api.post(`${url}/${id}/offers`).then(res => res.data.data)
const availableStates = id => api.get(`${url}/${id}/available-states`).then(res => res.data.data)
const statesHistory = id => api.get(`${url}/${id}/work-state-logs`).then(res => res.data.data)
const changeState = (id, params) => api.post(`${url}/${id}/change-state`, params).then(res => res.data.data)
const availableProductionStates = id => api.get(`${url}/${id}/available-production-states`).then(res => res.data.data)
const changeProductionState = (id, params) => api.post(`${url}/${id}/change-production-state`, params).then(res => res.data.data)
const storeWorkLocation = (uuid, params) => api.post(`${url}/${uuid}/work-locations`, params).then(res => res.data.data)
const destroyWorkLocation = (uuid, locationUuid) =>api.delete(`${url}/${uuid}/work-locations/${locationUuid}`).then(res => res.data.data)

const worksService = {
  fetchAll,
  fetch,
  store,
  update,
  destroy,
  syncUsers,
  syncCompanies,
  fetchAllSubWorks,
  fetchSubWork,
  toggleSubWorkActivation,
  downloadSubWorkBom,
  fetchOffers,
  storeOffer,
  availableStates,
  changeState,
  availableProductionStates,
  changeProductionState,
  statesHistory,
  storeWorkLocation,
  destroyWorkLocation
};

export default worksService;
