import api from '../../configs/api.config';

const url = '/part-collections';
const fetchAll = (params) => api.get(url, { params }).then((res) => res.data.data);

const fetch = (id) => api.get(`${url}/${id}`).then((res) => res.data.data);
const partCollectionService = {
  fetchAll,
  fetch,
};

export default partCollectionService;
