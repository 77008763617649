const PREFIX = 'PREFIX';
const MEASURE = 'MEASURE';
const CATEGORY = 'CATEGORY';
const CODE = 'CODE';

const values = {
  PREFIX,
  MEASURE,
  CATEGORY,
  CODE,
};

const collection = [
  { value: PREFIX, label: 'enums.code_types.PREFIX', color: 'dark' },
  { value: MEASURE, label: 'enums.code_types.MEASURE', color: 'info' },
  { value: CATEGORY, label: 'enums.code_types.CATEGORY', color: 'warning' },
  { value: CODE, label: 'enums.code_types.CODE', color: 'danger' },
];

const CodeTypeEnum = {
  values,
  collection,
};

export default CodeTypeEnum;
