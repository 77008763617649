import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import workCompanyPartnershipEnum from '../../../../../constants/enums/WorkCompanyPartnershipEnum';
import WorkPartnersDrawer from './WorkPartnersDrawer';
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import WorkPartner from './WorkPartner';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import EditButton from '../../../../../components/UI/Buttons/EditButton';
import { List } from '../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';

const WorkPartners = () => {


  const { work, dispatchAction } = useContext(WorkContext)

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const filterCompanyByRelationship = relationship => work?.companies.filter(c => c.partnership_type === relationship)

  const [c, setC] = useState({
    partners: filterCompanyByRelationship(workCompanyPartnershipEnum.values.PARTNER),
    agents: filterCompanyByRelationship(workCompanyPartnershipEnum.values.AGENT),
    distributors: filterCompanyByRelationship(workCompanyPartnershipEnum.values.DISTRIBUTOR),
    signalmen: filterCompanyByRelationship(workCompanyPartnershipEnum.values.SIGNALMAN),
    brand_ambassadors: filterCompanyByRelationship(workCompanyPartnershipEnum.values.BRAND_AMBASSADOR),
  })

  useEffect(() => {
    setC({
      partners: filterCompanyByRelationship(workCompanyPartnershipEnum.values.PARTNER),
      agents: filterCompanyByRelationship(workCompanyPartnershipEnum.values.AGENT),
      distributors: filterCompanyByRelationship(workCompanyPartnershipEnum.values.DISTRIBUTOR),
      signalmen: filterCompanyByRelationship(workCompanyPartnershipEnum.values.SIGNALMAN),
      brand_ambassadors: filterCompanyByRelationship(workCompanyPartnershipEnum.values.BRAND_AMBASSADOR)
    })
  }, [work.companies])

  const handleUpdate = res => {
    setIsOpen(false)
    dispatchAction({
      type: workActionTypes.UPDATE_PARTNERS,
      payload: res
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>Partners</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>
          <List>
            <ListItem label={t('enums.company_partnership.PARTNER')}>
              <WorkPartner type='partner' companies={c.partners}/>
            </ListItem>
            <ListItem label={t('enums.company_partnership.AGENT')}>
              <WorkPartner type='agents' companies={c.agents}/>
            </ListItem>
            <ListItem label={t('enums.company_partnership.DISTRIBUTOR')}>
              <WorkPartner type='distributors' companies={c.distributors}/>
            </ListItem>
            <ListItem label={t('enums.company_partnership.BRAND_AMBASSADOR')}>
              <WorkPartner type='brand_ambassadors' companies={c.brand_ambassadors}/>
            </ListItem>
            <ListItem label={t('enums.company_partnership.SIGNALMAN')}>
              <WorkPartner type='signalmen' companies={c.signalmen}/>
            </ListItem>
          </List>
        </CardBody>
      </Card>

      <WorkPartnersDrawer
        isOpen={isOpen}
        onUpdate={handleUpdate}
        setIsOpen={setIsOpen}
        companies={c}
      />


    </>
  )

}

export default WorkPartners
