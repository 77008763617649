const trendChartOptions = {
  colors: ['#50a5f1', "#34c38f", "#74788d"],
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: [3, 3, 1],
    dashArray: [0, 0, 5]
  },
  dataLabels: {
    enabled: true,
  },
  xaxis: {
    categories: [],
  },
}

const setTrendChartOptions = (chartOptions) => ({ ...trendChartOptions, ...chartOptions });

const trendChart = {
  trendChartOptions,
  setTrendChartOptions
}

export default trendChart
