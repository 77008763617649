import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import colorPresetsService from '../../../../services/colors/colorPresetsService';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import { reactSelectStyles } from '../../../../configs/reactSelect.config';

const ColorPresetPicker = ({ handleChange, defaultParams = {} }) => {

  const { t } = useTranslation();
  const { loading, runAsync } = useRequest(colorPresetsService.fetchAll, { manual: true });

  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({
      filter: {
        ...defaultParams,
        label: value || '',
      },
    }).then((res) => callback(res.data))
    .catch(() => callback([]));
  };

  const onChange = (value) => {
    if (value) {
      handleChange(value);
    }
  };
  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={[]}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={onChange}
      loading={loading}
      placeholder={t('color_presets.sing')}
      getOptionValue={(option) => option.id}
      styles={reactSelectStyles}
      multiple={false}
      noOptionsMessage={({inputValue}) => !inputValue ? t("type_to_search") : t("no-results")}
    />
  );
}

export default ColorPresetPicker
