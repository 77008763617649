import api from '../../configs/api.config';

const url = '/part-categories';
const fetchAll = params => api.get(url, { params }).then((res) => res.data);
const store = params => api.post(url, params).then((res) => res.data.data);
const update = (uuid, params) => api.patch(`${url}/${uuid}`, params).then((res) => res.data.data);
const fetch = uuid => api.get(`${url}/${uuid}`).then((res) => res.data.data);
const destroy = uuid => api.delete(`${url}/${uuid}`).then((res) => res.data);

const partCategoriesService = {
  fetchAll,
  store,
  update,
  fetch,
  destroy
};

export default partCategoriesService;
