import { UncontrolledTooltip } from 'reactstrap';

export const InfoTooltip = ({message, color = "primary"}) => {

  const id = 'id_' + Math.random().toString(16).slice(2)

  return !!message && (
    <>
      <UncontrolledTooltip target={id}>{message}</UncontrolledTooltip>
      <i id={id} className={`fa fa-info-circle fs-5 text-${color}`} />
    </>
  )

}
