import { Trans } from 'react-i18next';
import React from 'react';

const paginatedTableConfig = {
  defaultPerPageOption: 50,
  perPageOptions: [25, 50, 100],
  table: {
    tableClass: 'table table-sm table-hover align-middle table-row-bordered text-nowrap',
    containerClass: 'table-responsive',
  },
  labels: {
    search: <Trans i18nKey="search" />,
    loading: <Trans i18nKey="table.loading" />,
    show: <Trans i18nKey="table.show" />,
    total_result: <Trans i18nKey="table.total_result" />,
  },
  pagination: {
    buttonIcons: false,
    nextButtonText: '>',
    prevButtonText: '<',
    containerClass: 'pagination',
    nextButtonClass: 'btn btn-link',
    nextButtonIcon: 'btn btn-link',
    prevButtonClass: 'btn btn-link',
    prevButtonIcon: 'btn btn-link',
    numberButtonClass: 'btn btn-link',
    numberClass: '',
    activeClass: 'bg-light',
  },
};

export default paginatedTableConfig;
