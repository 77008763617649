import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useRequest } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import WorkStateEnumDisplay from '../../../components/UI/Displays/WorkStateEnumDisplay';
import WidgetPrevNext from './WidgetPrevNext';
import worksService from '../../../services/works/worksService';
import { useSanctum } from 'react-sanctum';
import WorkStateEnum from '../../../constants/enums/WorkStateEnum';

const WorksWidget = () => {

  const { user } = useSanctum()
  const { data: works = [], loading, run } = useRequest(worksService.fetchAll, { manual: true })
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [params, setParams] = useState({
    per_page: 10,
    filter: {
      users: [user.id],
      number: [],
      state: [WorkStateEnum.values.warning, WorkStateEnum.values.ready, WorkStateEnum.values.negotiation]
    }
  })

  useEffect(() => {
    if (user.id) {
      run(params)
    }
  }, [user, params]);


  return (
    <div className='card'>
      <div className='card-body'>
        <div className='d-flex justify-content-between align-items-center gap-4'>

          <div className='d-flex align-items-center justify-content-start gap-2 text-nowrap'>
            <span className='text-black-50'>{t('works.plural')}</span>
            {works?.meta?.total ? <span className='bg-primary text-white px-1 rounded-2'>{works?.meta?.total}</span> : <></>}
            {loading && <Spinner size='sm'/>}
          </div>

          <WidgetPrevNext
            setParams={setParams}
            currentPage={works?.meta?.current_page}
            prev={works?.links?.prev}
            next={works?.links?.next}
            loading={loading}
          />

        </div>
      </div>
      <ul className='list-group list-group-flush'>
        {works?.data && works?.data.length > 0 ? works.data.map((work) =>
          <li className="list-group-item list-group-item-action cursor-pointer" key={work.uuid} onClick={() => navigate(`/works/${work.uuid}`)}>
            <div className='d-flex align-items-center justify-content-between gap-2'>
              <span className='fs-5 fw-bold text-primary'>{work.number}</span>
              <WorkStateEnumDisplay state={work.state}/>
            </div>
            <div className='d-flex flex-column'>
              <span>{work.description}</span>
              <span>{work.company?.business_name}</span>
            </div>
          </li>
          ) : <></>}
      </ul>
    </div>

)

}

export default WorksWidget
