import React, { useContext } from 'react';
import Switch from "react-switch";
import ExportExcelButtonIcon from '../../../../../components/UI/Buttons/Excel/ExportExcelButtonIcon';
import { WorkContext } from '../../WorkContext/WorkContext';

const WorkSubWorkAction = ({ subWork, onDownloadSubworkBom, handleChangeStatus }) => {


  const { work } = useContext(WorkContext)

  return (
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <ExportExcelButtonIcon buttonId={`excel_button_${subWork.uuid}`} handleClick={onDownloadSubworkBom}/>
      {work?.must_vault_synced &&
      <Switch
        className='shadow-sm opacity-50'
        onChange={handleChangeStatus}
        onColor={'#34c38f'}
        offColor={'#f46a6a'}
        checked={subWork.is_enabled}
        handleDiameter={16}
        height={20}
        width={40}
      />}
    </div>
  )

}

export default WorkSubWorkAction
