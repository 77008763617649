export const reactSelectStyles = {
  control: (provided, {isDisabled}) => ({
    ...provided,
    minWidth: 100,
    background: isDisabled ? "#eff2f7" : "none",
    backgroundColor: "white",
    paddingTop: '0',
    paddingBottom: '0',
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected || isFocused ? "#E6EBF1" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
    opacity: 1,
  }),
};
