import React from 'react';
import UsersPicker from '../Forms/Pickers/UsersPicker';

const UsersFilter = ({ name, label, value, setValue, handleSubmit }) => {

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.target.value.trim()) {
      handleSubmit()
    }
  };


  return (
    <UsersPicker
        value={value}
        defaultOption={true}
      onKeyDown={handleKeyDown}
      placeholder={label}
      isMulti={true}
      handleChange={v => setValue(name, v)}
    />
  );
}
export default UsersFilter;
