import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import partsService from '../../../services/parts/partsService';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const PartStateChanger = ({ part, onStateChange }) => {

  const { data: states, loading, mutate } = useRequest(partsService.state, {
    defaultParams: [part?.uuid],
    ready: part !== undefined
  });

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectedNewState = (state) => {
    SweetAlert.fire({
      text: t('states.confirm_change', {
        fromState: states?.state?.label,
        toState: state.label
      }),
      icon: "warning",
      showCancelButton: true
    }).then(response => {
      if (response.isConfirmed) {
        partsService.changeState(part.uuid, state)
        .then((res) => {
          mutate(() => res);
          onStateChange(res.state)
        })
        .catch((err) => toast.error(err?.response?.data?.message))
        setIsOpen(false);
      }
    })
  };

  return loading
    ? <Spinner size="sm"/>
    : <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle caret color={states?.state?.color}>
        {states?.state?.label}
        {states?.available_states.length > 0 &&
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>}
      </DropdownToggle>
      <DropdownMenu end>
        {states?.available_states.length > 0 && states?.available_states.map((state) =>
          <DropdownItem
            key={state.value}
            onClick={() => handleSelectedNewState(state)}>
            {state.label}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>

}

export default PartStateChanger
