import React, { useContext } from 'react';
import Drawer from '../../../../../components/UI/Drawer/Drawer';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import FormGroup from '../../../../../components/UI/Forms/FormGroup';
import UsersPicker from '../../../../../components/UI/Forms/Pickers/UsersPicker';
import { useTranslation } from 'react-i18next';
import worksService from '../../../../../services/works/worksService';
import { toast } from 'react-toastify';
import { WorkContext } from '../../WorkContext/WorkContext';
import { AppContext } from '../../../../../components/AppContext';
import { FormActions } from '../../../../../components/UI/Forms/FormActions';


const WorkUsersDrawer = ({ isOpen, setIsOpen, sellers, designers, onUpdate }) => {

  const { work } = useContext(WorkContext)
  const { t } = useTranslation();
  const { setLoadingOverlayState } = useContext(AppContext)

  const initialValues = {
    sellers: sellers,
    designers: designers
  }
  const validationSchema = Yup.object().shape({
    sellers: Yup.array().min(1).required(),
    designers: Yup.array().required(),
  })


  const handleSubmit = (values, { setSubmitting }) => {
    setLoadingOverlayState(true)
    worksService.syncUsers(work.uuid, {
      sellers: values.sellers.map(u => u.value),
      designers: values.designers.map(u => u.value),
    })
    .then(res => onUpdate(res))
    .catch((err) => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  })

  return (
    <Drawer
      status={isOpen}
      toggle={() => setIsOpen(false)}
      title='Risorse umane'
    >
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="sellers" label={t('enums.work_user_role.SELLER')} required={true}>
            <UsersPicker
              handleChange={values => formik.setFieldValue("sellers", values)}
              value={formik.values.sellers}
              placeholder={t('works.users')}
              isMulti={true}
            />
          </FormGroup>
          <FormGroup name="designers" label={t('enums.work_user_role.DESIGNER')} required={true}>
            <UsersPicker
              handleChange={values => formik.setFieldValue("designers", values)}
              value={formik.values.designers}
              placeholder={t('works.users')}
              isMulti={true}
            />
          </FormGroup>

          <FormActions
            onCancel={() => setIsOpen(false)}
            withReset={true}
          />
        </Form>
      </FormikProvider>

    </Drawer>
  )

}

export default WorkUsersDrawer
