import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PercentageDisplay from '../../../../components/UI/Displays/PercentageDisplay';
import { List } from '../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../components/UI/ItemList/ListItem';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import { CardContentBetween } from '../../../../components/UI/Card/CardContentBetween';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import EditButton from '../../../../components/UI/Buttons/EditButton';
import { CompanyPriceListDrawer } from './CompanyPriceListDrawer';
import { InfoTooltip } from '../../../../components/UI/Tooltips/InfoTooltip';

export const CompanyPriceList = ({ company, setCompany }) => {

  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = res => {
    setCompany(c => ({
      ...company,
      beta_pricing_discount: res.beta_pricing_discount,
      internal_pricing_discount: res.internal_pricing_discount,
      internal_pricing_increment: res.internal_pricing_increment,
    }))

    setIsOpen(o => false);
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('companies.pricing')}</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>
          <List>
            <ListItem
              label={t('companies.beta_pricing_discount')}
              extra={<InfoTooltip message="Sconto applicato al listino Beta in offerta"/>}
            >
              <PercentageDisplay value={company.beta_pricing_discount}/>
            </ListItem>
            <ListItem
              label={t('companies.internal_pricing_discount')}
              extra={<InfoTooltip message="Sconto modificabile generale applicato all'offerta"/>}
            >
              <PercentageDisplay value={company.internal_pricing_discount}/>
            </ListItem>
            <ListItem
              label={t('companies.internal_pricing_increment')}
              extra={<InfoTooltip message="Maggiorazione applicata al listino 3DBeta in offerta"/>}
            >
              <PercentageDisplay value={company.internal_pricing_increment}/>
            </ListItem>
          </List>
        </CardBody>
      </Card>

      <CompanyPriceListDrawer
        company={company}
        onUpdate={handleUpdate}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />

    </>
  )

}
