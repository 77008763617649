import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';

const colorTableConfig = [{
  Header: <Trans i18nKey="description"/>,
  accessor: 'description',
  Cell: ({ row, value }) => <TableLink
    to={`/colors/${row.original.uuid}`}
    value={value}
  />,
}, {
  Header: <Trans i18nKey="colors.supplier_code"/>,
  accessor: 'supplier_code'
}, {
  Header: <Trans i18nKey="colors.supplier_business_name"/>,
  accessor: 'supplier_business_name'
}, {
  Header: <Trans i18nKey="colors.sap_code"/>,
  accessor: 'sap_code'
}, {
  Header: <Trans i18nKey="colors.stock"/>,
  accessor: 'stock'
}];

export default colorTableConfig;
