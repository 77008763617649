import React, { useContext } from 'react';
import { Collapse } from 'reactstrap';
import UtManagementDropdown from './_components/UtManagementDropdown';
import { AppContext } from '../../../../AppContext';
import CommManagementDropdown from './_components/CommManagementDropdown';
import UserManagementDropdown from './_components/UserManagementDropdown';

function Navbar() {
  const { showTopNavigationMenu } = useContext(AppContext);

  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
          <Collapse className="navbar-collapse" isOpen={showTopNavigationMenu}>
            <UtManagementDropdown/>
            <CommManagementDropdown/>
            <UserManagementDropdown/>
          </Collapse>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
