import React from 'react';

export function FormDisplay({
  children, label, inline = false, color = 'dark', bordered = false
}) {
  return (
    <>
      {inline
        ? (
          <div className={`row mb-1 ${bordered ? 'border-bottom border-1 py-2' : ''}`}>
            <label className={`form-label fw-bold text-${color} col-md-4`}>{label}</label>
            <div className="fs-6 col-md-8">{children || '---'}</div>
          </div>
        )
        : (
          <div className={`mb-1 ${bordered ? 'border-bottom border-1 py-2' : ''}`}>
            <label className={`form-label mb-1 text-${color}`}>{label}</label>
            <div className="fs-6 fw-b">{children || '---'}</div>
          </div>
        )}
    </>
  );
}
