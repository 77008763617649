import { ListItem } from './ListItem';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../Companies/Contacts/Contact';
import React from 'react';

export const ListContactsItem = ({ contacts = [] }) => {

  const { t } = useTranslation();

  return (
    <ListItem label={t('contacts.plural')}>
      {contacts.length > 0 ? contacts.map(c => <Contact contact={c} key={c.uuid}/>) : '---'}
    </ListItem>
  )

}

