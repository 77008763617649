import api from '../../configs/api.config';
import { serialize } from 'object-to-formdata'

const fetch = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid
) => api.get(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}`).then(res => res.data.data)

const store = (
  offerUuid,
  offerRevisionUuid,
  params
) => api.post(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers`, serialize(params), { headers: { 'Content-Type': 'multipart/form-data' } })
.then(res => res.data.data)

const update = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid,
  params
) => api.patch(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}`, params)
.then(res => res.data.data)

const destroy = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid
) => api.delete(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}`).then(res => res.data)

const swap = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid,
  params
) => api.post(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/swap-order`, params)
.then(res => res.data.data)

const toggle = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid
) => api.patch(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/toggle`)
.then(res => res.data.data)


const attach = (
  offerUuid,
  offerRevisionUuid,
  subOfferUuid,
  params
) => api.post(`offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/attach`,
  serialize(params),
  { headers: { 'Content-Type': 'multipart/form-data' } })
.then(res => res.data.data)

const updateColorConfigs = (
  { offerUuid, offerRevisionUuid, subOfferUuid },
  params
) => api.post(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/color-configs`, params).then(res => res.data.data);


const destroyColorConfigs = ({
                              offerUuid,
                              offerRevisionUuid,
                              subOfferUuid
                            }) => api.delete(`/offers/${offerUuid}/offer-revisions/${offerRevisionUuid}/sub-offers/${subOfferUuid}/color-configs`).then(
  res => res.data.data);

const subOffersService = {
  fetch,
  store,
  update,
  toggle,
  destroy,
  swap,
  attach,
  updateColorConfigs,
  destroyColorConfigs
};

export default subOffersService;
