import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkContext } from '../../WorkContext/WorkContext';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardContentBetween } from '../../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import { List } from '../../../../../components/UI/ItemList/List';
import WorkSubWork from './WorkSubWork';
import { ListEmptyItem } from '../../../../../components/UI/ItemList/ListEmptyItem';

const WorkSubWorks = () => {

  const { work } = useContext(WorkContext)
  const { t } = useTranslation()

  return (
    <Card>
      <CardBody>
        <CardContentBetween>
          <CardLabel>{t('sub_works.plural')}</CardLabel>
        </CardContentBetween>
        <List>
          {work.sub_works.length > 0
            ? work.sub_works.map(subWork => <WorkSubWork key={subWork.uuid} subWork={subWork}/>)
            : <ListEmptyItem/>}
        </List>
      </CardBody>
    </Card>
  )

}

export default WorkSubWorks
