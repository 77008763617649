// import translationENG from './en/translation.json';
import translationIT from './it/translation.json';
import translationENG from './it/translation.json';
import italy from '../assets/images/flags/italy.svg';
import english from '../assets/images/flags/uk.svg';
import usFlag from '../assets/images/flags/uk.svg';
import germany from '../assets/images/flags/germany.svg';
import spain from '../assets/images/flags/spain.svg';
import france from '../assets/images/flags/france.svg';

const defaultFallback = 'it';

const I18N_LANGUAGE = 'I18N_LANGUAGE';
const TRANSLATION_LANGUAGE = 'TRANSLATION_LANGUAGE';

const languages = {
  it: {
    label: 'Italiano',
    flag: italy,
    locale: 'it',
  },
  en: {
    label: 'English',
    flag: usFlag,
    locale: 'en',
  },
};

// Al momento viene sempre caricato l'IT, cambia import in alto
const resources = {
  it: { translation: translationIT },
  en: { translation: translationENG },
};

const availableTranslations = [
  {
    label: 'Italiano',
    flag: italy,
    locale: 'it',
  },
  {
    label: 'English',
    flag: english,
    locale: 'en',
  },
  {
    label: 'Francais',
    flag: france,
    locale: 'fr',
  },
  {
    label: 'Deutsch',
    flag: germany,
    locale: 'de',
  },
  {
    label: 'Espanol',
    flag: spain,
    locale: 'es',
  },
];

const localesConfig = {
  I18N_LANGUAGE,
  TRANSLATION_LANGUAGE,
  defaultFallback,
  languages,
  resources,
  availableTranslations,
};

export default localesConfig;
