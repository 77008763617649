import api from '../configs/api.config';

const importCostsUrl = () => '/costs-import';
const importPriceUrl = () => '/prices-import';
const importBetaToolsUrl = () => '/beta-tools-import';

const fetchImportCostsLogs = (params) => api.get(importCostsUrl(), { params }).then(res => res.data)
const fetchImportPricesLogs = (params) => api.get(importPriceUrl(), { params }).then(res => res.data)
const fetchImportBetaToolsLogs = (params) => api.get(importBetaToolsUrl(), { params }).then(res => res.data)


const batchService = {
  importCostsUrl,
  importPriceUrl,
  importBetaToolsUrl,
  fetchImportCostsLogs,
  fetchImportPricesLogs,
  fetchImportBetaToolsLogs,
}

export default batchService
