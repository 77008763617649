import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRequest } from 'ahooks';
import partTypeEnum from '../../../constants/enums/PartTypeEnum';
import formConfig from '../components/form.config';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import { FormRadio } from '../../../components/UI/Forms/FormRadio';
import codesService from '../../../services/parts/codesService';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import CodeTypeEnum from '../../../constants/enums/CodeTypeEnum';
import WordsSort from '../components/WordsSort/WordsSort';
import DisplayFormattedMeasures from '../../Parts/creates/components/DisplayFormattedMeasures';
import { FormDisplay } from '../../../components/UI/Displays/FormDisplay';
import SimilarCodes from '../components/SimilarCodes';


function CategoryCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const availablePartTypes = [partTypeEnum.values.WITH_MEASURE, partTypeEnum.values.WITHOUT_MEASURE, partTypeEnum.values.ASSEMBLY];
  const partTypes = useMemo(() => partTypeEnum.collection.filter(pt => availablePartTypes.includes(pt.value)), []);
  const { data: categoryNumber } = useRequest(codesService.nextAssemblyCategory);

  const handleSubmit = (values, { setSubmitting }) => codesService.store(CodeTypeEnum.values.CATEGORY, {
    ...values,
    words: values.words.map(w => w.value)
  }).then((res) => {
    toast.success(t('created'));
    Array.isArray(res) ? navigate('../') : navigate(`../${res.uuid}`);
  })
  .catch((err) => toast.error(err?.response?.data?.message))
  .finally(() => setSubmitting(false));

  const formik = useFormik({
    initialValues: formConfig.initialValues,
    validationSchema: formConfig.storeValidation,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form>

        <h5 className="text-uppercase">
          {t('enums.code_types.CATEGORY')}&nbsp;{formik.values.part_type && t(`enums.part_types.${formik.values.part_type}`)}
        </h5>

        <div className='card'>
          <div className='card-body'>

            <div className='row'>
              <div className='col-lg-4'>
                <FormGroup label={t('codes.code')} name="code" required>
                  <FormInput
                    disabled={formik.values.part_type === partTypeEnum.values.ASSEMBLY}
                    maxLength="3"
                    name="code"
                  />
                </FormGroup>
              </div>
              <div className='col-lg-8'>
                <FormDisplay label={t('description')}>
                  {formik.values.words.map(w => w.label).join(' ')}
                  &nbsp;
                  <DisplayFormattedMeasures
                    width={formik.values.width}
                    depth={formik.values.depth}
                    height={formik.values.height}
                  />
                </FormDisplay>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-4'>
                <h5>&nbsp;</h5>
                <FormGroup label={t('part_type')} name="part_type" required>
                  {partTypes.map((pt, index) => (
                    <FormRadio
                      key={index}
                      name="part_type"
                      value={pt.value}
                      label={t(pt.label)}
                      onChange={(e) => formik.setValues({
                        ...formik.values,
                        code: e.target.value === partTypeEnum.values.ASSEMBLY ? categoryNumber?.next_number.toString() : '',
                        part_type: e.target.value,
                      })}
                    />
                  ))}
                </FormGroup>
              </div>
              <div className='col-lg-4'>
                <WordsSort/>
              </div>
              <div className='col-lg-4'>
                <SimilarCodes words={formik.values.words}/>
              </div>
            </div>


            <div className="d-flex align-items-center justify-content-end gap-2">
              <CancelButton onClick={() => navigate('../')}/>
              <FormikSaveButton/>
            </div>

          </div>
        </div>



      </Form>
    </FormikProvider>
  );
}

export default CategoryCreate;
