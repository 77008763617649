import { Trans } from 'react-i18next';
import TableLink from '../../../../components/UI/Buttons/TableLink';
import EuroDisplay from '../../../../components/UI/Displays/EuroDisplay';
import PartStateEnumDisplay from '../../../../components/UI/Displays/Parts/PartStateEnumDisplay';
import DateDisplay from '../../../../components/UI/Displays/DateDisplay';
import PartCostDisplay from '../../../../components/UI/Displays/Parts/PartCostDisplay';
import PartPriceDisplay from '../../../../components/UI/Displays/Parts/PartPriceDisplay';
import PartMinimumPriceDisplay from '../../../../components/UI/Displays/Parts/PartMinimumPriceDisplay';
import PartRealMarginDisplay from '../../../../components/UI/Displays/Parts/PartRealMarginDisplay';
import PartCategoryDisplay from '../../../../components/UI/Displays/Parts/PartCategoryDisplay';

const assemblyPartTableConfig = [{
  Header: <Trans i18nKey="parts.code"/>,
  accessor: 'code',
  Cell: ({ row, value }) => <TableLink to={`/parts/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="description"/>,
  accessor: 'description',
  disableSortBy: true
},, {
  Header: <Trans i18nKey="parts.cost"/>,
  accessor: 'cost',
  Cell: ({ value }) => <PartCostDisplay cost={value}/>
}, {
  Header: <Trans i18nKey="parts.state"/>,
  accessor: 'state',
  disableSortBy: true,
  Cell: ({ value }) => <PartStateEnumDisplay state={value} onlyDot={true}/>,
}, {
  Header: <Trans i18nKey="updated"/>,
  accessor: 'updated_at',
  Cell: ({ value }) => <DateDisplay value={value} formatString='dd/MM/yyyy HH:mm'/>
}];

export default assemblyPartTableConfig;
