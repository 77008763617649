import { Trans } from 'react-i18next';
import TableLink from '../../../../components/UI/Buttons/TableLink';
import EuroDisplay from '../../../../components/UI/Displays/EuroDisplay';
import PartStateEnumDisplay from '../../../../components/UI/Displays/Parts/PartStateEnumDisplay';
import DateDisplay from '../../../../components/UI/Displays/DateDisplay';
import PartCostDisplay from '../../../../components/UI/Displays/Parts/PartCostDisplay';
import PartPriceDisplay from '../../../../components/UI/Displays/Parts/PartPriceDisplay';
import PartMinimumPriceDisplay from '../../../../components/UI/Displays/Parts/PartMinimumPriceDisplay';
import PartRealMarginDisplay from '../../../../components/UI/Displays/Parts/PartRealMarginDisplay';
import PartCategoryDisplay from '../../../../components/UI/Displays/Parts/PartCategoryDisplay';

const customPartTableConfig = [{
  Header: <Trans i18nKey="parts.code"/>,
  accessor: 'code',
  Cell: ({ row, value }) => <TableLink to={`/parts/${row.original.uuid}`} value={value}/>,
}, {
  Header: <Trans i18nKey="description"/>,
  accessor: 'description',
  disableSortBy: true
}, {
  Header: <Trans i18nKey="width"/>,
  accessor: 'width',
}, {
  Header: <Trans i18nKey="depth"/>,
  accessor: 'depth',
}, {
  Header: <Trans i18nKey="height"/>,
  accessor: 'height',
}, {
  Header: <Trans i18nKey="parts.drawing_note"/>,
  accessor: 'drawing_note',
  disableSortBy: true
}, {
  Header: <Trans i18nKey="parts.cost"/>,
  accessor: 'cost',
  Cell: ({ value }) => <PartCostDisplay cost={value}/>
}, {
  Header: <Trans i18nKey="parts.price"/>,
  accessor: 'price',
  Cell: ({ row, value }) => <PartPriceDisplay price={value} cost={row.original.cost}/>
}, {
  Header: <Trans i18nKey="parts.minimum_sale_price"/>,
  accessor: 'minimum_sale_price',
  Cell: ({ row, value }) => <PartMinimumPriceDisplay
    price={row.original.price}
    minimumSalePrice={row.original.minimum_sale_price}
  />
}, {
  Header: <Trans i18nKey="parts.real_sale_margin_price"/>,
  accessor: 'real_sale_margin_price',
  Cell: ({ row }) => <PartRealMarginDisplay
    realMargin={row.original.real_sale_margin_price}
    margin={row.original.margin ?? row.original?.part_category?.margin}
  />
}, {
  Header: <Trans i18nKey="part_categories.sing"/>,
  accessor: 'part_category',
  Cell: ({ row, value }) => <PartCategoryDisplay
    partCategory={value}
    margin={row.original.margin}
  />
}, {
  Header: <Trans i18nKey="parts.state"/>,
  accessor: 'state',
  disableSortBy: true,
  Cell: ({ value }) => <PartStateEnumDisplay state={value} onlyDot={true}/>,
}, {
  Header: <Trans i18nKey="updated"/>,
  accessor: 'updated_at',
  Cell: ({ value }) => <DateDisplay value={value} formatString='dd/MM/yyyy HH:mm'/>
}];

export default customPartTableConfig;
