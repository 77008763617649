import DateDisplay from '../Displays/DateDisplay';
import DestroyButtonIcon from '../Buttons/Destroy/DestroyButtonIcon';

export const ListItemFile = ({ media, onDownload, canDelete = true, onDelete }) => {
  return (
    <li className='px-0 list-group-item d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center gap-sm-4 gap-2'>
      <div className='link-primary cursor-pointer text-nowrap' title={media.name} onClick={() => onDownload(media)}>
        <i className='fa fa-arrow-down me-1'/>
        {media.name.slice(0, 15)}
      </div>
      <div className='d-flex align-items-center justify-content-start gap-2 small'>
        <span>{media.size}</span>
        <span><DateDisplay value={media.created_at} formatString='dd/MM/yyyy HH:mm:ss'/></span>
        <span className={`text-uppercase fw-bold text`}>{media.extension}</span>

        {canDelete && <DestroyButtonIcon onClick={() => onDelete(media)}/>}
      </div>
    </li>
  )

}

