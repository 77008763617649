import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemHeader } from '../../../../../components/UI/ItemList/ListItemHeader';
import { WorkMediaVaultAction } from './WorkMediaVaultAction';
import { ListItemFile } from '../../../../../components/UI/ItemList/ListItemFile';
import { ListEmptyItem } from '../../../../../components/UI/ItemList/ListEmptyItem';

const WorkMediaGroupedList = ({
                                label,
                                tooltipContent,
                                media = [],
                                onDownload,
                                onDownloadMerged,
                                onDelete,
                                onDownloadColorConfig = null,
                                onDownloadBomsPdf = null
                              }) => {

  const { t } = useTranslation()

  return (
    <>
      <ListItemHeader
        label={t(label)}
        extra={
          <WorkMediaVaultAction
            label={label}
            tooltipContent={tooltipContent}
            onDownloadMerged={onDownloadMerged}
            onDownloadColorConfig={onDownloadColorConfig}
            onDownloadBomsPdf={onDownloadBomsPdf}
          />
        }
      />

      {media.length > 0 ? media.map(m => <ListItemFile
          key={m.uuid}
          media={m}
          onDownload={onDownload}
          canDelete={false}
          onDelete={onDelete}
        />
      ) : <ListEmptyItem/>}
    </>
  )

}

export default WorkMediaGroupedList
