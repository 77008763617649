import React from 'react';
import DecimalDisplay from '../../../../../components/UI/Displays/DecimalDisplay';

const WorkSubWorkInlineBom = ({ items = [] }) => {

  return (
    <table className='table table-responsive align-middle table-striped table-condensed'>
      <tbody>
      {items.length > 0
        ? items.map(item =>
          <tr key={item.uuid}>
            <td className='flex flex-column gap-1'>
              <div className='fw-bold'>{item.code}</div>
              {item.description}
            </td>
            <td className='text-right'><DecimalDisplay value={item.qty}/></td>
          </tr>
        )
        : <tr>
          <td className='text-center' colSpan={2}>Nessuna parte</td>
        </tr>
      }
      </tbody>
    </table>
  )

}

export default WorkSubWorkInlineBom
