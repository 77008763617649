import React from 'react';
import WorksWrapper from '../../../../pages/Works/Works/WorksWrapper';
import Works from '../../../../pages/Works/Works/Works';
import { WorkWrapper } from '../../../../pages/Works/Work/WorkWrapper';

const worksRoutes = {
  path: 'works/*',
  element: <WorksWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <Works/>,
  }, {
    path: ':id',
    element: <WorkWrapper/>,
  }],
};

export default worksRoutes;
