export const ListItemHeader = ({ label = "", children = undefined, extra = undefined }) => {
  return (
    <li className="list-group-item px-0 py-1">
      <div className="d-flex align-items-center justify-content-between gap-1">
        <span className='fw-bold'>{label}</span>
        {extra}
      </div>
      {children}
    </li>
  )

}

