import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkContext } from '../../WorkContext/WorkContext';
import Drawer from "../../../../../components/UI/Drawer/Drawer";
import UserDisplay from "../../../../../components/UI/Displays/UserDisplay";
import DateDisplay from "../../../../../components/UI/Displays/DateDisplay";
import WorkStateEnumDisplay from '../../../../../components/UI/Displays/WorkStateEnumDisplay';
import ButtonIcon from '../../../../../components/UI/Buttons/ButtonIcon';

const WorkStateHistory = () => {
  const { t } = useTranslation()
  const { history } = useContext(WorkContext)

  const [isOpen, setIsOpen] = useState(false);


  return (
    <>
      <ButtonIcon onClick={() => setIsOpen(!isOpen)} icon='fa fa-history'/>

      <Drawer title={t('works.change-state-history')} status={isOpen} toggle={() => setIsOpen(false)}>
        <ul className='list-group list-group-flush'>
          {history && history.map(s =>
            <li className='px-1 list-group-item d-flex flex-column justify-content-between gap-2' key={s.id}>

              <div className='d-flex align-items-center justify-content-between gap-2'>
                <UserDisplay user={s.user} small={true}/>
                <div className='d-flex text-muted align-items-center justify-content-start gap-2 small'>
                  <WorkStateEnumDisplay state={s.state_from}/>
                  <i className={"fa fa-arrow-right "}/>
                  <WorkStateEnumDisplay state={s.state_to}/>
                </div>
                <div className='d-flex flex-column gap-1 small'>
                  <span>{t('updated_at')}</span>
                  <DateDisplay value={s.updated_at} formatString={"dd/MM/yyyy HH:ii:ss"}/>
                </div>
              </div>

              {s?.message && <span className='text-muted'>{s.message}</span>}
            </li>
          )}
        </ul>
      </Drawer>

    </>
  )


}

export default WorkStateHistory
