import React from 'react';
import { Trans } from 'react-i18next';
import TableLink from '../../../components/UI/Buttons/TableLink';

const colorPresetTableConfig = [{
  Header: <Trans i18nKey="color_presets.label"/>,
  accessor: 'label',
  Cell: ({ row, value }) => <TableLink
    to={`/color-presets/${row.original.uuid}`}
    value={value}
  />,
}];

export default colorPresetTableConfig;
