import React from 'react';
import { Outlet } from 'react-router-dom';

const ColorsWrapper = () => {

  return (
    <Outlet/>
  )

}

export default ColorsWrapper
