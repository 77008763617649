import React from 'react';
import FormGroup from '../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../components/UI/Forms/FormInput';
import { FieldArray, useFormikContext } from 'formik';
import CancelButton from '../../../components/UI/Buttons/CancelButton';
import FormikResetButton from '../../../components/UI/Buttons/FormikResetButton';
import FormikSaveButton from '../../../components/UI/Buttons/Save/FormikSaveButton';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

export const WordForm = ({ setIsOpen }) => {

  const { values, setValues } = useFormikContext()
  const { t } = useTranslation()
  const handleExtends = locale => {
    const selectedLocale = values.locales.find(l => l.lang === locale.lang)
    if (selectedLocale) {
      setValues({
        ...values, locales: values.locales.map(l => ({
          ...l,
          description: selectedLocale.description,
          short_description: selectedLocale.short_description,
        }))
      })
    }
  }


  return (
    <>
      <FormGroup name="label" label={t('words.label')} required>
        <FormInput name="label"/>
      </FormGroup>
      <FieldArray
        name="locales"
        render={() =>
          <div>
            {values.locales.map((locale, index) => (
              <div key={locale.lang}>

                <div className='d-flex align-items-center justify-content-between'>
                  <h5>{locale.label}</h5>
                  <>
                    <button
                      type='button'
                      className='btn btn-light btn-icon'
                      id={`${locale.lang}_copy_translation_button`}
                      onClick={() => handleExtends(locale)}
                    >
                      <i className='far fa-copy'/>
                    </button>
                    <UncontrolledTooltip target={`${locale.lang}_copy_translation_button`}>
                      Copia la traduzione {locale.label} per tutte le traduzioni.
                    </UncontrolledTooltip>
                  </>
                </div>

                <div className='row'>
                  <div className='col-md-8'>
                    <FormGroup
                      name={`locales.${index}.description`}
                      label={t('description')}
                      required
                    >
                      <FormInput name={`locales.${index}.description`}/>
                    </FormGroup>
                  </div>
                  <div className='col-md-4'>
                    <FormGroup
                      name={`locales.${index}.short_description`}
                      label={t('short_description')}
                      required
                    >
                      <FormInput name={`locales.${index}.short_description`}/>
                    </FormGroup>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
      />
      <div className="d-flex align-items-center justify-content-between">
        <CancelButton onClick={() => setIsOpen(false)}/>
        <div className="d-flex gap-1">
          <FormikResetButton/>
          <FormikSaveButton/>
        </div>
      </div>
    </>
  )
}
