import React from 'react';
import WordsWrapper from '../../../pages/Words/WordsWrapper';
import Words from '../../../pages/Words/Words/Words';
import Word from '../../../pages/Words/Word/Word';

const wordsRoutes = {
  path: 'words/*',
  element: <WordsWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <Words/>,
  }, {
    path: ':id',
    element: <Word/>,
  }],
};

export default wordsRoutes;
