import React from 'react';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function EmptyDisplay({ children }) {
  const { t } = useTranslation();

  return (
    <Alert color="light">
      <i className="fa fa-eye-slash" />
      {' '}
      {children || t('no-results')}
    </Alert>
  );
}

export default EmptyDisplay;
