import React from 'react';
import PercentageDisplay from '../PercentageDisplay';
import { useTranslation } from 'react-i18next';

function PartCategoryDisplay({ partCategory = null, margin = 0 }) {

  const {t} = useTranslation();

  return (
    <div className="badge fs-6 bg-light">
      {partCategory || margin ? (
          <>
            <i
              className='fa fa-dot-circle'
              title={partCategory?.label ?? 'Nessuna categoria'}
              style={{ color: partCategory?.color }}
            /> &nbsp;
            <PercentageDisplay value={(partCategory?.margin ?? margin) * 100}/>
          </>)
        : (
          <>
            <i className='fa fa-dot-circle text-danger'/>&nbsp;
            {t('no_margin')}
          </>
        )
      }
    </div>
  )

}

export default PartCategoryDisplay;
