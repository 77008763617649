import React, { useContext, useState } from 'react';
import EuroDisplay from '../../../../../../components/UI/Displays/EuroDisplay';
import { OfferContext } from '../../../offerContext/offerContext';
import { offerActionTypes } from '../../../offerContext/offerReducer/offerActionTypes';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import subOfferItemsService from '../../../../../../services/offers/subOfferItemsService';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import { UncontrolledTooltip } from 'reactstrap';
import { SubOfferItemActions } from '../SubOfferItem/SubOfferItemActions';
import { AppContext } from '../../../../../../components/AppContext';
import SubOfferItemTypeEnum from '../../../../../../constants/enums/SubOfferItemTypeEnum';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { FormInput } from '../../../../../../components/UI/Forms/FormInput';
import DefaultButtonIcon from '../../../../../../components/UI/Buttons/DefaultButtonIcon';
import { InfoTooltip } from '../../../../../../components/UI/Tooltips/InfoTooltip';
import { List } from '../../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../../components/UI/ItemList/ListItem';
import { FormNumber } from '../../../../../../components/UI/Forms/FormNumber';
import { FormEuro } from '../../../../../../components/UI/Forms/FormEuro';
import NumberDisplay from '../../../../../../components/UI/Displays/NumberDisplay';

const validationSchema = Yup.object().shape({
  qty: Yup.number().min(1).nullable().required(),
  price: Yup.number().min(0).nullable().required()
})

const SubOfferToolItem = ({ subOfferUuid, subOfferItem, children, offerRevisionUuid, pos, isTool = false }) => {

  const { offer, dispatchAction, revision_editable } = useContext(OfferContext)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false);
  const { setLoadingOverlayState } = useContext(AppContext)


  const handleUpdateSubOfferItem = (values, { setSubmitting }) => updateSubOfferItem(values, setSubmitting)

  const updateSubOfferItem = (values, setSubmitting) => {
    setLoadingOverlayState(true)
    subOfferItemsService.update(offer.uuid, offerRevisionUuid, subOfferUuid, subOfferItem.uuid, values)
    .then(res => dispatchAction({
      type: offerActionTypes.UPDATE_SUB_OFFER_TOOL_ITEM,
      payload: { subOfferItem: res }
    }))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    })
  }

  const handleDeleteItem = () => {
    setLoadingOverlayState(true)
    subOfferItemsService.destroy(offer.uuid, offerRevisionUuid, subOfferUuid, subOfferItem.uuid)
    .then(res => {
      dispatchAction({
        type: offerActionTypes.REMOVE_SUB_OFFER_TOOL_ITEM,
        payload: { subOfferUuid, index: pos }
      })
    }).catch(err => toast.error(err?.response?.data?.message))
    .finally(() => setLoadingOverlayState(false))
  }

  const formik = useFormik({
    initialValues: {
      description: subOfferItem?.description ?? '',
      qty: subOfferItem.qty,
      cost: 0,
      price: subOfferItem.price
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleUpdateSubOfferItem
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='card mb-1'>
          <div className='card-body'>
            <UncontrolledTooltip target={`folder_button_${subOfferItem.uuid}`}>Apri/Chiudi dettaglio</UncontrolledTooltip>

            <div className='d-flex justify-content-between align-items-center gap-4'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-nowrap align-items-center justify-content-start gap-2'>
                  {children}

                  <DefaultButtonIcon
                    id={`folder_button_${subOfferItem.uuid}`}
                    icon={`${open ? 'folder-open' : 'folder'}`}
                    onClick={() => setOpen(o => !o)}
                  />
                  <span className={`fw-bold fs-5 ${subOfferItem.is_price_invalid && 'text-danger'}`}>{subOfferItem.code}</span>

                  <SubOfferItemActions
                    subOfferItem={subOfferItem}
                    handleDeleteItem={handleDeleteItem}
                  />


                  {subOfferItem.is_price_invalid &&
                    <span className='bx-flashing'>
                        <InfoTooltip message='Costo o prezzo non valido' color='danger'/>
                    </span>}
                </div>

                <div className='font-size-14'>{subOfferItem.description}</div>
              </div>

              <div className='d-flex justify-content-end gap-4 flex-wrap text-end'>
                <div className='d-flex justify-content-start align-items-center gap-4' style={{ minWidth: "100px" }}>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('offers.qty')}</span>
                    <span className='fw-bold'>{subOfferItem.qty}</span>
                  </div>
                  <div className='d-flex flex-column align-items-end'>
                    <span className='small'>{t('sub_offer_items.offer_item_price_total')}</span>
                    <span className='fw-bold text-info'>
                      <EuroDisplay value={subOfferItem.total_price}/>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${open ? 'd-block' : 'd-none'}`}>
              <div className='mt-3'>
                {subOfferItem.type === SubOfferItemTypeEnum.MESSAGE &&
                  <FormGroup label={t('description')} name="description" required inline={false}>
                    {revision_editable ? <FormInput name="description"/>
                      : <>{formik.values.description}</>}
                  </FormGroup>}

                <div className='row'>
                  <div className='col-xxl-4 col-md-6'>
                    <List>
                      <ListItem label={t('offers.qty')} inline={false}>
                        {revision_editable ? <FormNumber
                          name='qty'
                          min={0.01}
                          step={0.01}
                          value={formik.values.qty}
                        /> : <NumberDisplay value={formik.values.qty}/>}
                      </ListItem>
                    </List>
                  </div>
                  <div className='col-xxl-4 col-md-6'>
                    <List>
                      <ListItem label={t('sub_offer_items.offer_item_price_cad')} inline={false}>
                        {revision_editable ?
                          <FormEuro
                            color='text-info'
                            name='price'
                            min={0.00}
                            step={0.01}
                            value={formik.values.price}
                          /> : <EuroDisplay value={formik.values.price}/>}
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )

}

export default SubOfferToolItem
