import React, { useContext } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { OfferContext } from '../../offerContext/offerContext';
import offerRevisionsService from '../../../../../services/offers/offerRevisionsService';
import { offerActionTypes } from '../../offerContext/offerReducer/offerActionTypes';
import { toast } from 'react-toastify';
import { CardActions } from '../../../../../components/UI/Card/CardActions';

const OfferRevisionNavigator = () => {

  const { offer, dispatchAction } = useContext(OfferContext)
  const handleChangeRevision = revisionUuid => offerRevisionsService.fetch(offer.uuid, revisionUuid)
  .then(res => dispatchAction({ type: offerActionTypes.CHANGE_OFFER_REVISION, payload: res }))
  .catch(err => toast.error(err?.response?.data?.message))

  return (
    <CardActions>
      {offer.offer_revision.previous_offer_revision
        ? <>
          <UncontrolledTooltip target='previous_revision_button'>Revisione precedente</UncontrolledTooltip>
          <button
            id='previous_revision_button'
            type='button'
            className='btn btn-soft-secondary btn-icon'
            onClick={() => handleChangeRevision(offer.offer_revision.previous_offer_revision.uuid)}
          >
            <i className='fa fa-chevron-left'/>
          </button>
        </> : <></>
      }
      <div className='fs-3 fw-bold text-dark'>{offer.number} Rev. {offer.offer_revision?.revision}</div>
      {offer.offer_revision.next_offer_revision
        ? <>
          <UncontrolledTooltip target='next_revision_button'>Revisione successiva</UncontrolledTooltip>
          <button
            id='next_revision_button'
            type='button'
            className='btn btn-soft-secondary btn-icon'
            onClick={() => handleChangeRevision(offer.offer_revision.next_offer_revision.uuid)}
          >
            <i className='fa fa-chevron-right'/>
          </button>
        </>
        : <></>
      }
    </CardActions>
  )

}

export default OfferRevisionNavigator
