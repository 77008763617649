import React, { useContext } from 'react';
import ExportExcelButton from '../../../../../components/UI/Buttons/Excel/ExportExcelButton';
import { DropdownItem, DropdownMenu, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import exportsService from '../../../../../services/exportsService';
import { OfferContext } from '../../offerContext/offerContext';
import SweetAlert from 'sweetalert2';
import offerRevisionsService from '../../../../../services/offers/offerRevisionsService';
import { offerActionTypes } from '../../offerContext/offerReducer/offerActionTypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import OfferRevisionStateChanger from "./OfferRevisionStateChanger";
import PrintButton from '../../../../../components/UI/Buttons/PrintButton';
import CreateButton from '../../../../../components/UI/Buttons/CreateButton';
import DropdownToggleButton from '../../../../../components/UI/Buttons/Dropdown/DropdownToggleButton';

const OfferRevisionActions = ({ setPrintDrawerState, setPercentageDrawerState, setColorConfigsDrawerState }) => {

  const { t } = useTranslation()
  const { offer, dispatchAction, revision_editable } = useContext(OfferContext)

  const handleExportExcel = () => exportsService.offerRevision(offer.uuid, offer.offer_revision.uuid, offer.number);

  const handleNewRevision = () => SweetAlert.fire({
    text: 'Creando una nuova revisione la presente verrà archiviata e non sarà più modificabile.',
    titleText: t('are_you_sure'),
    icon: 'warning',
    showCancelButton: true,
  }).then((response) => {
    if (response.isConfirmed) {
      offerRevisionsService.store(offer.uuid)
      .then(res => dispatchAction({ type: offerActionTypes.CHANGE_OFFER_REVISION, payload: res }))
      .catch(err => toast.error(err?.response?.data?.message))
    }
  });

  const handleResetPrice = () => SweetAlert.fire({
    text: "Tutti i prezzi di codici conosciuti verranno allineati al listino corrente.",
    titleText: t('are_you_sure'),
    icon: 'warning',
    showCancelButton: true,
  }).then((response) => {
    if (response.isConfirmed) {
      offerRevisionsService.massivePriceReset(offer.uuid, offer.offer_revision.uuid)
      .then(res => dispatchAction({ type: offerActionTypes.CHANGE_OFFER_REVISION, payload: res }))
      .catch(err => toast.error(err?.response?.data?.message))
    }
  });

  return (
    <div className='d-flex justify-content-end align-items-center gap-2 text-nowrap'>
      <UncontrolledTooltip target='print_rev_button'>Stampa la revisione corrente</UncontrolledTooltip>
      <PrintButton id='print_rev_button' onClick={() => setPrintDrawerState(true)}/>

      <ExportExcelButton handleClick={handleExportExcel}/>

      {offer.work === null &&
        <>
          <UncontrolledTooltip target='color_config_rev_button'>{t('color_configs.plural')}</UncontrolledTooltip>
          <button
            onClick={() => setColorConfigsDrawerState(() => true)}
            className='btn btn-soft-secondary'
            id='color_config_rev_button'
            type='button'>
            <i className='fa fa-palette'/>
          </button>
        </>}

      {revision_editable &&
        <UncontrolledDropdown>
          <DropdownToggleButton/>
          <DropdownMenu>
            <DropdownItem
              onClick={() => setPercentageDrawerState(true)}
              id='change_rev_perc_button'
            >
              {t('offers.offer_massive_percentage_update')}
            </DropdownItem>
            <DropdownItem
              onClick={() => handleResetPrice()}
              id='change_rev_perc_button'
            >
              {t('offers.reset_price')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>}


      {revision_editable &&
        <>
          <UncontrolledTooltip target='create_rev_button'>Crea una nuova revisione di offerta</UncontrolledTooltip>
          <CreateButton
            id='create_rev_button'
            onClick={handleNewRevision}
            label={t('sub_offer_revisions.sing')}
          />
        </>
      }
      <OfferRevisionStateChanger/>
    </div>
  )

}

export default OfferRevisionActions
