import React from 'react';
import { NumericFormat } from 'react-number-format';

function EuroDisplay({ value }) {
  return value || value === 0 ? <span className='text-nowrap'>
    <NumericFormat
      value={value}
      displayType={"text"}
      decimalSeparator=','
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator='.'
      suffix={" €"}
    />
  </span> : '---'
}

export default EuroDisplay;
