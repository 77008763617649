import React, { useMemo } from 'react';
import { useRequest, useSessionStorageState } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import partsService from '../../../../services/parts/partsService';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';
import Toolbar from '../../../../components/UI/Toolbar';
import partTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { Link, useLocation } from 'react-router-dom';
import AssemblyPartFilters from './AssemblyPartFilters';
import assemblyPartTableConfig from './assemblyPartTableConfig';

function AssemblyParts() {
  const { data = [], loading, run } = useRequest(partsService.fetchAll, { manual: true });
  const location = useLocation();
  const columns = useMemo(() => assemblyPartTableConfig, []);
  const { t } = useTranslation();
  const [extraParams, setExtraParams] = useSessionStorageState(location.pathname, {
    defaultValue: {
      filter: {
        code: [],
        description: [],
        drawing_note: '',
        width: [],
        height: [],
        depth: [],
        state: [],
        part_type: [partTypeEnum.values.ASSEMBLY],
      },
    }
  });

  const handleSubmit = values => setExtraParams(ext => ({ filter: { ...ext.filter, ...values } }))

  const handleUpdate = values => {
    run({
      ...values, filter: {
        ...values.filter,
        code: values.filter.code.map(opt => opt.value),
        description: values.filter.description.map(opt => opt.value),
        drawing_note: values.filter.drawing_note,
        width: values.filter.width.map(opt => opt.value),
        height: values.filter.height.map(opt => opt.value),
        depth: values.filter.depth.map(opt => opt.value),
        state: values.filter.state.map(opt => opt.value)
      }
    })
  }

  return (
    <>

      <Toolbar title={t('enums.part_types.ASSEMBLY')}>
        <div className="d-flex gap-1">
          <Link className='btn btn-primary' to="../create-assembly">
            <i className='fa fa-plus me-1'/>{t('new')}
          </Link>
        </div>
      </Toolbar>

      <AssemblyPartFilters handleUpdate={handleSubmit} defaultValues={extraParams.filter}/>

      <Card>
        <CardBody>
          <PaginatedTable
            options={paginatedTableConfig}
            isLoading={loading}
            data={data}
            columns={columns}
            sortable
            onParamsChange={handleUpdate}
            extraParams={extraParams}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default AssemblyParts;
