import React from 'react';
import { useTranslation } from 'react-i18next';

const DownloadButton = props => {
  const { t } = useTranslation();

  return (
    <button className='btn btn-soft-secondary' type='button' {...props}>
      <i className='fa fa-arrow-down'/>&nbsp;{t(props?.label ?? 'download')}
    </button>
  );
}

export default DownloadButton;
