const WITHOUT_MEASURE = 'WITHOUT_MEASURE';
const WITH_MEASURE = 'WITH_MEASURE';
const ASSEMBLY = 'ASSEMBLY';
const CUSTOM = 'CUSTOM';
const EXCLUSIVE = "EXCLUSIVE";
const BETA = 'BETA';
const BETA_TOOL = 'BETA_TOOL';
const JOHN_DOE = 'JOHN_DOE';

const values = {
  WITHOUT_MEASURE,
  WITH_MEASURE,
  ASSEMBLY,
  CUSTOM,
  EXCLUSIVE,
  BETA,
  BETA_TOOL,
  JOHN_DOE
};

const collection = [
  { value: WITHOUT_MEASURE, label: 'enums.part_types.WITHOUT_MEASURE', color: 'success' },
  { value: WITH_MEASURE, label: 'enums.part_types.WITH_MEASURE', color: 'info' },
  { value: ASSEMBLY, label: 'enums.part_types.ASSEMBLY', color: 'info' },
  { value: CUSTOM, label: 'enums.part_types.CUSTOM', color: 'info' },
  { value: EXCLUSIVE, label: "enums.part_types.EXCLUSIVE", color: "info" },
  { value: BETA, label: 'enums.part_types.BETA', color: 'info' },
  { value: BETA_TOOL, label: 'enums.part_types.BETA_TOOL', color: 'info' },
  { value: JOHN_DOE, label: 'enums.part_types.JOHN_DOE', color: 'info' },
];

const PartTypeEnum = {
  values,
  collection,
};

export default PartTypeEnum;
