import React from 'react';
import { Link } from 'react-router-dom';
import CodeTypeEnumDisplay from '../../../../components/UI/Displays/CodeTypeEnumDisplay';
import { useTranslation } from 'react-i18next';

export const PartCodeDetails = ({ part }) => {
  const { t } = useTranslation();

  return (
    <div className='card w-100'>
      <div className='card-body'>
        <span className='text-black-50'>{t('codes.plural')}</span>

        <ul className='list-group list-group-flush'>
          {(part.codes ?? []).map(code => (
            <li className="list-group-item px-1 d-flex align-items-center justify-content-between" key={code.uuid}>
              <Link to={`/codes/${code.uuid}`}>{code?.code ?? t('no_code')}</Link>
              <CodeTypeEnumDisplay type={code.code_type}/>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

}
