import React from 'react';
import PartCategoriesWrapper from '../../../pages/PartCategories/PartCategoriesWrapper';
import PartCategories from '../../../pages/PartCategories/PartCategories/PartCategories';
import PartCategory from '../../../pages/PartCategories/PartCategory/PartCategory';

const wordsRoutes = {
  path: 'part-categories/*',
  element: <PartCategoriesWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <PartCategories/>,
  },{
    path: ':id',
    element: <PartCategory/>,
  }],
};

export default wordsRoutes;
