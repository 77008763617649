import React, { useState } from 'react';
import { Alert, } from 'reactstrap';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import FormGroup from '../../components/UI/Forms/FormGroup';
import { FormInput } from '../../components/UI/Forms/FormInput';
import { FormCheckbox } from '../../components/UI/Forms/FormCheckbox';
import FormikSaveButton from '../../components/UI/Buttons/Save/FormikSaveButton';
import * as Yup from 'yup';

const Login = props => {
  const { signIn } = useSanctum();

  const [error, setError] = useState(null);

  const initialValues = {
    email: '',
    password: '',
    remember_me: false,
  };

  const validation = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(8).required(),
    remember_me: false,
  })

  return (
    <div style={{ width: '340px' }}>

      <h5 className='text-center my-3'><i className='fa fa-key me-1'/> Login</h5>

      {error && <Alert color="danger">{error}</Alert>}

      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setError(null);
          signIn(values.email, values.password, values.remember)
          .then((response) => true)
          .catch((error) => {
            setError(error.response.data.message);
          })
          .finally(() => setSubmitting(false));
        }}
      >
        {() => (
          <Form>
            <FormGroup label="Email" required={true} inline={false} name="email">
              <FormInput name="email" type="email"/>
            </FormGroup>
            <FormGroup label="Password" required={true} inline={false} name="password">
              <FormInput name="password" type="password"/>
            </FormGroup>
            <FormCheckbox name="remember_me" label="Remember me"/>

            <div className="d-flex align-items-center justify-content-between">
              <FormikSaveButton
                color="primary"
                label="Log in"
              />
              <Link to="/auth/forgot-password">Forgot password?</Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Login;
