import React, { useContext } from 'react';
import ProfileMenu from './components/ProfileMenu';
import HeaderLogo from './components/HeaderLogo';
import { AppContext } from '../../../../AppContext';
import SearchRecordsForm from './components/SearchRecordsForm';

function Header() {
  const { showTopNavigationMenu, setShowTopNavigationMenu } = useContext(AppContext);

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <HeaderLogo/>
          <button
            type="button"
            className="btn px-3 font-size-16 d-lg-none header-item"
            data-toggle="collapse"
            onClick={() => setShowTopNavigationMenu(!showTopNavigationMenu)}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars"/>
          </button>
        </div>
        <div className="d-flex">
          {/*<NotificationDropdown />*/}
          <SearchRecordsForm/>
          <ProfileMenu/>
        </div>
      </div>
    </header>
  );
}

export default Header;
