import React from 'react';

function TextFilter({ name, label, value, setValue, handleSubmit }) {

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  };

  return (
    <input
      onKeyDown={handleKeyDown}
      className='form-control'
      name={name}
      value={value}
      placeholder={label}
      onChange={e => setValue(name, e.target.value ?? '')}
    />
  );
}

export default TextFilter;
