import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag } from '../../../../components/UI/Tags/Tag';
import { UncontrolledTooltip } from 'reactstrap';

const TargetParts = ({ parts }) => {

  const { t } = useTranslation();

  return parts && parts.length > 0 && (
    <div className='h5 d-flex align-items-center justify-content-start gap-2'>
      <span className='fw-bold'>{t('parts.target_parts')}</span>
      {parts.map(part =>
        <Tag color='danger' key={part.uuid}>
          <UncontrolledTooltip target={`traget_${part.uuid}`}>
            {part.description}
          </UncontrolledTooltip>
          <Link to={`/parts/${part.uuid}`} id={`traget_${part.uuid}`} className='link-danger'>
            {part.code}
          </Link>
        </Tag>
      )}
    </div>
  )

}

export default TargetParts
