import React from 'react';
import EuroDisplay from '../../../../components/UI/Displays/EuroDisplay';
import DecimalDisplay from '../../../../components/UI/Displays/DecimalDisplay';
import PartTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { useTranslation } from 'react-i18next';
import { FormDisplay } from '../../../../components/UI/Displays/FormDisplay';
import PartCostDisplay from '../../../../components/UI/Displays/Parts/PartCostDisplay';
import PartPriceDisplay from '../../../../components/UI/Displays/Parts/PartPriceDisplay';
import PartMinimumPriceDisplay from '../../../../components/UI/Displays/Parts/PartMinimumPriceDisplay';
import CalculatedPriceByMarginDisplay from '../../../../components/UI/Displays/Parts/CalculatedPriceByMarginDisplay';
import PartCategoryDisplay from '../../../../components/UI/Displays/Parts/PartCategoryDisplay';
import PartRealMarginDisplay from '../../../../components/UI/Displays/Parts/PartRealMarginDisplay';

export const PartCostsDetails = ({ part }) => {

  const { t } = useTranslation()

  return (
    <div className='row'>
      <div className='col-lg-4 col-sm-6 col-xs-12 d-flex align-items-stretch'>
        <div className='card w-100'>
          <div className='card-body d-flex flex-column gap-2'>
            <FormDisplay label={t('parts.cost')}>
              <PartCostDisplay cost={part?.cost}/>
            </FormDisplay>

            <FormDisplay label={t('parts.hypothetical_cost')}>
              <PartCostDisplay cost={part?.hypothetical_cost}/>
            </FormDisplay>
          </div>
        </div>
      </div>
      <div className='col-lg-4 col-sm-6 col-xs-12 d-flex align-items-stretch'>
        <div className='card w-100'>
          <div className='card-body d-flex flex-column gap-2'>
            <FormDisplay label={t('parts.price')}>
              <PartPriceDisplay cost={part?.cost} price={part?.price}/>
            </FormDisplay>
            <FormDisplay label={t('parts.minimum_sale_price')}>
              <PartMinimumPriceDisplay price={part?.price} minimumSalePrice={part?.minimum_sale_price}/>
            </FormDisplay>
            <FormDisplay label={t('parts.calculated_price_by_margin')}>
              <CalculatedPriceByMarginDisplay
                margin={part?.margin}
                calculatedPriceByPartMargin={part?.calculated_price_by_margin}
                partCategory={part?.part_category}
                calculatedPriceByPartCategory={part?.calculated_price_by_part_category}
              />
            </FormDisplay>
          </div>
        </div>
      </div>
      <div className='col-lg-4 col-sm-12 d-flex align-items-stretch'>
        <div className='card w-100'>
          <div className='card-body d-flex flex-column gap-2'>
            <FormDisplay label={t('parts.margin')}>
              <PartCategoryDisplay
                margin={part?.margin}
                partCategory={part?.part_category}
              />
            </FormDisplay>
            <FormDisplay label={t('parts.real_sale_margin_price')}>
              <PartRealMarginDisplay margin={part?.margin} realMargin={part?.real_sale_margin_price}/>
            </FormDisplay>
          </div>
        </div>
      </div>
    </div>
  )

}
