import * as Yup from 'yup';
import { format, parseJSON, startOfQuarter } from 'date-fns';

const initialValues = {
  company: null,
  company_location: null,
  company_person: null,
  date: format(startOfQuarter(new Date), "yyyy-MM-dd"),
  description: "",
  note: ""
};

const setInitialValues = (data) => ({
  company_id: data?.company_id || null,
  company_location_id: data?.company_location_id || null,
  company_person_id: data?.company_person_id || null,
  date: data?.date ? format(parseJSON(data.date), 'yyyy-MM-dd') : '',
  description: data?.description || "",
  note: data?.note || ""
});

const validation = Yup.object().shape({
  company: Yup.object().nullable().required(),
  company_location: Yup.object().nullable().notRequired(),
  company_person: Yup.object().nullable().notRequired(),
  date: Yup.date("Data non valida").nullable().notRequired(),
  description: Yup.string().max(255).required(),
  note: Yup.string().max(800).nullable().notRequired(),
});

const formConfig = {
  initialValues,
  setInitialValues,
  validation,
};

export default formConfig;
