import EuroDisplay from '../../../../../../components/UI/Displays/EuroDisplay';
import React, { useContext } from 'react';
import { OfferContext } from '../../../offerContext/offerContext';
import { offerActionTypes } from '../../../offerContext/offerReducer/offerActionTypes';
import { useTranslation } from 'react-i18next';
import DecimalDisplay from '../../../../../../components/UI/Displays/DecimalDisplay';
import { toast } from 'react-toastify';
import subOffersService from '../../../../../../services/offers/subOffersService';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import { AppContext } from '../../../../../../components/AppContext';
import PercentageDisplay from '../../../../../../components/UI/Displays/PercentageDisplay';
import FormikSaveButton from '../../../../../../components/UI/Buttons/Save/FormikSaveButton';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { FormNumber } from '../../../../../../components/UI/Forms/FormNumber';
import { FormInput } from '../../../../../../components/UI/Forms/FormInput';
import { CardSmMargin } from '../../../../../../components/UI/Card/CardSmMargin';
import { CardBody } from '../../../../../../components/UI/Card/CardBody';

const validationSchema = Yup.object().shape({
  code: Yup.string().max(20).nullable().required(),
  description: Yup.string().max(100).nullable().required(),
  qty: Yup.number().min(0).nullable().required()
})

const SubOfferTotals = ({ subOffer, offerRevisionUuid, revision_editable }) => {

  const { t } = useTranslation()
  const { offer, dispatchAction } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const formik = useFormik({
    initialValues: {
      code: subOffer?.code ?? "",
      description: subOffer?.description ?? "",
      qty: subOffer?.qty ?? ""
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoadingOverlayState(true)
      subOffersService.update(offer.uuid, offerRevisionUuid, subOffer.uuid, values)
      .then(res => dispatchAction({ type: offerActionTypes.UPDATE_SUB_OFFER, payload: { subOffer: res } }))
      .catch(err => toast.error(err?.response?.data?.message))
      .finally(() => {
        setSubmitting(false)
        setLoadingOverlayState(false)
      })
    }
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='card-group gap-1 py-1'>
          <div className={`card text-white bg-opacity-75 bg-${subOffer.is_invalid ? 'danger' : 'info'}`}>
            <div className='card-body text-end d-flex flex-column'>
              <span>{t('sub_offers.offer_sub_price_total')}</span>
              <span className='fs-5 fw-bold'><EuroDisplay value={subOffer.total_price}/></span>
              <span className='small '>{subOffer.qty} x <EuroDisplay value={subOffer.price}/> = <EuroDisplay value={subOffer.total_price}/></span>
            </div>
          </div>

          <div className='card text-white bg-opacity-75 bg-info'>
            <div className='card-body text-end d-flex flex-column'>
              <span>{t('sub_offers.offer_sub_net_price_total')}</span>
              <span className='fs-5 fw-bold'><EuroDisplay value={subOffer.total_net_price}/></span>
              <span className='small '>{subOffer.qty} x <EuroDisplay value={subOffer.net_price}/> = <EuroDisplay
                value={subOffer.total_net_price}/></span>
            </div>
          </div>

          <div className='card text-white bg-opacity-75 bg-success'>
            <div className='card-body text-end d-flex flex-column'>
              <span>{t('sub_offers.offer_sub_cost_total')}</span>
              <span className='fs-5 fw-bold'><EuroDisplay value={subOffer.total_cost}/></span>
              <span className='small '>{subOffer.qty} x <EuroDisplay value={subOffer.cost}/> = <EuroDisplay value={subOffer.total_cost}/></span>
            </div>
          </div>

          <div className='card text-white bg-opacity-75 bg-success'>
            <div className='card-body text-end d-flex flex-column'>
              <span>{t('sub_offers.offer_sub_min_sell_price_total')}</span>
              <span className='fs-5 fw-bold'><EuroDisplay value={subOffer.total_net_cost}/></span>
              <span className='small'>{subOffer.qty} x <EuroDisplay value={subOffer.net_cost}/> = <EuroDisplay
                value={subOffer.total_net_cost}/>
                </span>
              <span>{t('sub_offers.margin')}</span>
              <span className='fw-bold'><PercentageDisplay value={subOffer.margin * 100}/></span>
            </div>
          </div>

        </div>

        <CardSmMargin>
          <CardBody>
            <div className='d-flex justify-content-start align-items-center gap-2'>
              <FormGroup label={t('sub_offers.code')} name='code' inline={false}>
                {revision_editable ? <FormInput
                  name='code'
                  maxLength={20}
                  value={formik.values.code}
                  onChange={e => formik.setFieldValue('code', e.target.value)}
                /> : <>{formik.values.code}</>}
              </FormGroup>
              <div className='w-100'>
                <FormGroup label={t('description')} name='description' inline={false}>
                  {revision_editable ? <FormInput
                    name='description'
                    maxLength={100}
                    value={formik.values.description}
                    onChange={e => formik.setFieldValue('description', e.target.value)}
                  /> : <>{formik.values.description}&nbsp;</>}
                </FormGroup>
              </div>
              <FormGroup label={t('offers.qty')} name="qty" inline={false}>
                {subOffer.sub_work || !revision_editable
                  ? <DecimalDisplay value={subOffer.qty}/>
                  : <FormNumber
                    name='qty'
                    value={formik.values.qty}
                    step={1}
                    min={0}
                  />
                }
              </FormGroup>
              {revision_editable && <FormikSaveButton/>}
            </div>
          </CardBody>
        </CardSmMargin>

      </Form>
    </FormikProvider>
  )

}

export default SubOfferTotals
