import React from 'react';
import { useTranslation } from 'react-i18next';

export const EditButton = (props) => {
  const { t } = useTranslation();

  return (
    <button className='btn btn-soft-secondary' type='button'  {...props}>
      {t('edit')}
    </button>
  );
}

export default EditButton;
