import React, { useContext, useEffect, useReducer } from 'react';
import offerReducer from './offerReducer/offerReducer';
import { OfferContext } from './offerContext';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import offersService from '../../../../services/offers/offersService';
import { offerActionTypes } from './offerReducer/offerActionTypes';
import { offerContextInitialState } from './offerContextInitialStates';
import { AppContext } from '../../../../components/AppContext';
import OfferRevisionsService from "../../../../services/offers/offerRevisionsService";

const OfferProvider = ({ children }) => {

  const { id } = useParams()

  const [state, dispatch] = useReducer(offerReducer, offerContextInitialState);
  const { setLoadingOverlayState } = useContext(AppContext)

  useRequest(offersService.fetch, {
    defaultParams: [id],
    refreshDeps: [id],
    onBefore: () => setLoadingOverlayState(true),
    onSuccess: (data) => dispatch({
      type: offerActionTypes.INIT,
      payload: data
    }),
    onFinally: () => setLoadingOverlayState(false)
  })

  const { run: fetchStates } = useRequest(OfferRevisionsService.availableStates, {
    defaultParams: [id, state?.offer?.offer_revision?.uuid],
    refreshDeps: [id, state?.offer?.offer_revision],
    ready: !!state?.offer?.offer_revision?.uuid,
    manual: true,
    onSuccess: data => dispatch({ type: offerActionTypes.UPDATE_OFFER_REVISION_STATES, payload: data })
  })

  useEffect(() => {
    state?.offer?.offer_revision?.uuid !== undefined && fetchStates(id, state?.offer?.offer_revision?.uuid)
  }, [state?.offer?.offer_revision?.uuid]);

  //
  const contextValue = {
    ...state,
    dispatchAction: dispatch
  }

  return <OfferContext.Provider value={contextValue}>
    {children}
  </OfferContext.Provider>


}

export default OfferProvider
