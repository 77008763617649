import axios from 'axios';
import qs from 'qs';
import localesConfig from '../locales/locales.config';

const onRequest = (config) => {

  config.headers['Accept-Language'] = localStorage.getItem(localesConfig.TRANSLATION_LANGUAGE);
  config.paramsSerializer = (params) => qs.stringify(params, {
    arrayFormat: 'comma',
    skipNulls: true,
  });

  return config;
};

const onRequestError = (error) => Promise.reject(error);

const onResponse = (response) => response;

const onResponseError = (error) => Promise.reject(error);

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const api = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    withCredentials: true,
    withXSRFToken: true,
  }),
);

export default api;
