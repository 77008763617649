import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PercentageDisplay from '../../../components/UI/Displays/PercentageDisplay';
import { PartCategoryDetailsDrawer } from './PartCategoryDetailsDrawer';
import DestroyButton from '../../../components/UI/Buttons/Destroy/DestroyButton';
import PartCategoriesService from '../../../services/parts/partCategoriesService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PartCategoryDetails = ({ partCategory, setPartCategory }) => {

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const handleUpdate = res => setPartCategory(() => res)
  const handleDestroy = () => PartCategoriesService.destroy(partCategory?.uuid)
  .then((res) => {
    toast.success(t('deleted'));
    navigate(`/part-categories`);
  })
  .catch((err) => toast.error(err?.response?.data?.message))

  return partCategory ? (
    <>
      <div className='card w-100'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center gap-4'>
            <span className='text-black-50'>{t('details')}</span>
            <div className='d-flex align-items-center justify-content-end gap-1'>
              <button className='btn btn-light' type='button' onClick={() => setIsOpen(true)}>
                {t('edit')}
              </button>
              <DestroyButton onClick={() => handleDestroy()}/>
            </div>
          </div>
          <ul className='list-group list-group-flush'>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('part_categories.label')}</div>
              {partCategory.label}
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('part_categories.margin')}</div>
              <PercentageDisplay value={partCategory.margin * 100}/>
            </li>
            <li className="list-group-item px-0">
              <div className='fw-bold'>{t('part_categories.color')}</div>
              <div className='d-flex align-items-center justify-content-start gap-2'>
                <i className='fa fa-2x fa-circle' style={{ color: partCategory.color }}/>
                <span>{partCategory.color}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <PartCategoryDetailsDrawer
        partCategory={partCategory}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onUpdate={handleUpdate}
      />

    </>
  ) : <></>

}

export default PartCategoryDetails
