import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup'
import FormGroup from '../../Forms/FormGroup';
import { FormInput } from '../../Forms/FormInput';
import { FormActions } from '../../Forms/FormActions';
import UserMessageService from '../../../../services/userMessageService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object().shape({
  message: Yup.string().required().max(500).nullable()
})

export const ListItemUserMessageCreate = ({ modelId, modelType, onStore, setStore }) => {

  const {t} = useTranslation();

  const handleSubmit = (values, { setSubmitting }) =>
    UserMessageService.store(modelId, modelType, values)
    .then(res => onStore(res))
    .catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(s => false)
    })

  const formik = useFormik({
    initialValues: { message: '' },
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });


  return (
    <li className="list-group-item px-0 py-1">
      <FormikProvider value={formik}>
        <Form>
          <FormGroup name="message" label={t('user_messages.sing')} required>
            <FormInput name="message" rows={5} as="textarea"/>
          </FormGroup>
          <FormActions withReset={true} onCancel={() => setStore(false)}/>
        </Form>
      </FormikProvider>
    </li>
  )

}

