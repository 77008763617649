import api from '../configs/api.config';

const url = 'options';

const users = params => api.get(`${url}/users`, { params }).then((res) => res.data);
const words = params => api.get(`${url}/words`, { params }).then((res) => res.data);
const works = params => api.get(`${url}/works`, { params }).then((res) => res.data);
const markets = params => api.get(`${url}/markets`, { params }).then((res) => res.data);
const companies = params => api.get(`${url}/companies`, { params }).then((res) => res.data);
const companyLocations = params => api.get(`${url}/company-locations`, { params }).then((res) => res.data);
const companyPersons = params => api.get(`${url}/company-persons`, { params }).then((res) => res.data);
const baseCodes = params => api.get(`${url}/base-codes`, { params }).then((res) => res.data);
const partCategories = params => api.get(`${url}/part-categories`, { params }).then((res) => res.data);
const assemblyCategories = params => api.get(`${url}/assembly-categories`, { params }).then((res) => res.data);
const partCollections = () => api.get(`${url}/part-collections`).then((res) => res.data);
const searchRecords = params => api.get(`${url}/search-records`, { params }).then((res) => res.data);

const optionsService = {
  users,
  words,
  works,
  markets,
  companies,
  companyLocations,
  companyPersons,
  baseCodes,
  partCategories,
  assemblyCategories,
  partCollections,
  searchRecords,
};

export default optionsService;
