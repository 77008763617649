import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Sanctum } from 'react-sanctum';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from './components/AppContext';
import { sanctumConfig } from './configs/sanctum.config';
import './locales/i18n';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Sanctum config={sanctumConfig}>
    <BrowserRouter>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </Sanctum>,
);

serviceWorker.unregister();
