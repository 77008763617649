import React, { useContext, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledTooltip } from 'reactstrap';
import { AppContext } from '../../../../../components/AppContext';
import { OfferContext } from "../../offerContext/offerContext";
import { toast } from "react-toastify";
import { offerActionTypes } from "../../offerContext/offerReducer/offerActionTypes";
import offerRevisionsService from "../../../../../services/offers/offerRevisionsService";
import OfferRevisionStateEnum from '../../../../../constants/enums/OfferRevisionStateEnum';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const
  OfferRevisionStateChanger = () => {

    const { t } = useTranslation()
    const navigate = useNavigate();
    const { offer, dispatchAction, states } = useContext(OfferContext)

    const [newState, setNewState] = useState(undefined)
    const [isOpen, setIsOpen] = useState(false);
    const { setLoadingOverlayState } = useContext(AppContext)

    const { runAsync: handleChangeState } = useRequest(offerRevisionsService.changeState, { manual: true });

    useEffect(() => {
      newState !== undefined &&
      handleChangeState(offer?.uuid, offer?.offer_revision?.uuid, { state: newState?.value })
      .then(res => handleSuccess(res))
      .catch((err) => toast.error(err?.response?.data?.message))
      .finally(() => {
        setNewState(undefined)
      })
    }, [newState])

    const handleSuccess = res => {
      setNewState(undefined)
      dispatchAction({
        type: offerActionTypes.UPDATE_OFFER_REVISION_STATES,
        payload: res
      })
    }

    const handleCreateWork = () => {
      SweetAlert.fire({
        text: t('offer_revisions.create_work_from_offer'),
        titleText: t('are_you_sure'),
        icon: 'warning',
        showCancelButton: true,
      }).then((response) => {
        if (response.isConfirmed) {
          setLoadingOverlayState(true)
          offerRevisionsService.storeWork(offer?.uuid, offer?.offer_revision?.uuid)
          .then(res => navigate(`/works/${res.uuid}`))
          .catch((err) => toast.error(err?.response?.data?.message))
          .finally(() => {
            setLoadingOverlayState(false)
            toast.success(t('created'))
          })
        }
      });
    }


    return (
      <>
        {states === undefined
          ? <Spinner size="sm"/>
          : <>
            <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
              <DropdownToggle caret color={states?.state?.color}>
                {t(`enums.offer_revision_state.${states?.state?.value}`)}
                {states?.available_states?.length > 0 &&
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>}
              </DropdownToggle>
              {states?.available_states.length > 0 && <DropdownMenu end>
                {states?.available_states.map((state) =>
                  <DropdownItem
                    key={state?.value}
                    onClick={() => setNewState(state)}>
                    {t(`enums.offer_revision_state.${state.value}`)}
                  </DropdownItem>
                )}
              </DropdownMenu>}
            </Dropdown>

            {states?.state.value === OfferRevisionStateEnum.values.accepted && offer.work === null && <>
              <UncontrolledTooltip target='create_work_from_offer'>
                Crea la commessa dalla revisione di offerta accettata
              </UncontrolledTooltip>
              <button
                id='create_work_from_offer'
                className='btn btn-soft-danger'
                type='button'
                onClick={handleCreateWork}>
                <i className='fa fa-draw-polygon me-2'/>
                {t('works.sing')}
              </button>
            </>}

          </>
        }

      </>
    )


  }

export default OfferRevisionStateChanger
