import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { FormInput } from '../../../../components/UI/Forms/FormInput';
import { useTranslation } from 'react-i18next';
import CreateButton from '../../../../components/UI/Buttons/CreateButton';

export const ContactsArrayEditor = () => {

  const { t } = useTranslation()
  const formik = useFormikContext()

  return (
    <FieldArray name="contacts">
      {({ remove, push }) => (
        <>
          <div className='d-flex align-items-center justify-content-between gap-2'>
            <div className='fw-bold'>{t('contacts.plural')}</div>
            <CreateButton
              label={t('contacts.add')}
              type='button'
              onClick={() => push({ id: undefined, label: '', value: '' })}
            />
          </div>
          <ul className='list-group list-group-flush'>
            {formik.values.contacts.length > 0
              ? formik.values.contacts.map((contact, index) => (
                <li className='list-group-item px-0 d-flex justify-content-between align-items-center gap-4 ' key={index}>
                  <FormInput name={`contacts.${index}.label`} placeholder={t('contacts.label')}
                             className={_.get(formik.errors, `contacts.${index}.label`) ? 'border-danger' : ''}/>
                  <FormInput name={`contacts.${index}.value`} placeholder={t('contacts.value')}
                             className={_.get(formik.errors, `contacts.${index}.value`) ? 'border-danger' : ''}/>
                  <button className='btn btn-icon btn-soft-danger ' type='button' onClick={() => remove(index)}>
                    <i className='fa fa-times'/>
                  </button>
                </li>
              ))
              : <li className='list-group-item px-0'>{t('no-results')}</li>
            }
          </ul>
        </>
      )}
    </FieldArray>
  )

}
