import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSanctum } from 'react-sanctum';
import { useTranslation } from 'react-i18next';

function ProfileMenu() {
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();

  const { user, signOut } = useSanctum();

  return (
    <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
      <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
        <img className="rounded-circle header-profile-user" src={user?.avatar} alt="Avatar"/>
        <span className="d-none d-xl-inline-block ms-2 me-1">{user?.full_name}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem>
          <Link to="user">
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {t('Profile')}
          </Link>
        </DropdownItem>
        <div className="dropdown-divider"/>
        <Link onClick={() => signOut()} className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
          <span>{t('Logout')}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ProfileMenu;
