import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import optionsService from '../../../services/optionsService';
import { reactSelectStyles } from '../../../configs/reactSelect.config';

const PartCollectionFilter = ({ name, value, setValue, handleSubmit }) => {

  const { t } = useTranslation();
  const [partCollections, setPartCollections] = useState([]);

  useMemo(() => optionsService.partCollections().then(res => setPartCollections(res)), []);

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.target.value.trim()) {
      handleSubmit()
    }
  };

  return (
    <Select
      onKeyDown={handleKeyDown}
      defaultValue={value}
      isClearable
      isSearchable
      styles={reactSelectStyles}
      className='bg-white'
      name={name}
      onChange={value => setValue(name, value)}
      placeholder={t('part_collections.sing')}
      options={partCollections}
    />
  );
}

export default PartCollectionFilter;
