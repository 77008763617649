import api from '../../configs/api.config';

const buildUrl = (companyUuid) => `/companies/${companyUuid}/company-locations`;
const fetchAll = (companyUuid, params) => api.get(buildUrl(companyUuid), { params }).then((res) => res.data.data);
const fetch = (companyUuid, locationUuid) => api.get(`${buildUrl(companyUuid)}/${locationUuid}`).then((res) => res.data.data);
const store = (companyUuid, params) => api.post(buildUrl(companyUuid), params).then((res) => res.data.data);
const update = (companyUuid, locationUuid, params) => api.patch(`${buildUrl(companyUuid)}/${locationUuid}`, params).then((res) => res.data.data);
const destroy = (companyUuid, locationUuid) => api.delete(`${buildUrl(companyUuid)}/${locationUuid}`).then((res) => res.data.data);

const companyLocationsService = {
  fetchAll,
  fetch,
  store,
  update,
  destroy,
};

export default companyLocationsService;
