import React, { useMemo } from 'react';
import { useRequest } from 'ahooks';
import worksService from '../../../../services/works/worksService';
import paginatedTableConfig from '../../../../configs/paginatedTable.config';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import workPartTableConfig from './workPartTable.config';
import PartWorkFilters from './PartWorkFilters';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import { useTranslation } from 'react-i18next';

const PartWorks = ({ partId }) => {

  const { t } = useTranslation();
  const columns = useMemo(() => workPartTableConfig, []);
  const { data, loading, run } = useRequest(worksService.fetchAll, {
    manual: true,
    ready: partId
  })

  const [extraParams, setExtraParams] = React.useState({
    filter: {
      part_id: partId,
      number: [],
      description: [],
      year: [],
      sap_orders: []
    }
  })

  const handleSubmit = values => setExtraParams(ext => ({
    filter: {
      ...ext.filter,
      number: values.number.map(v => v.value),
      description: values.description.map(v => v.value),
      year: values.year.map(v => v.value),
      sap_orders: values.sap_orders.map(v => v.value),
    }
  }))

  return (
    <Card>
      <CardBody>
        <CardLabel>{t('works.plural')}</CardLabel>
        <PartWorkFilters
          defaultValues={extraParams.filter}
          handleUpdate={handleSubmit}
        />
        <PaginatedTable
          options={paginatedTableConfig}
          isLoading={loading}
          data={data || []}
          columns={columns}
          sortable
          onParamsChange={run}
          extraParams={extraParams}
        />
      </CardBody>
    </Card>
  )

}

export default PartWorks
