import React from 'react';
import { Outlet } from 'react-router-dom';

function BatchWrapper() {

  return (
    <Outlet/>
  );
}

export default BatchWrapper;
